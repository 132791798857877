import { actionPending, actionSuccess, actionError } from "../actionTypes";

import { toastSuccess, toastError } from "../../../components/commons/toast";

import API from "../API";
import { handleResponse } from "../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_CORP_RESERVATION_PENDING = "GET_CORP_RESERVATION_PENDING";
export const GET_CORP_RESERVATION_SUCCESS = "GET_CORP_RESERVATION_SUCCESS";
export const GET_CORP_RESERVATION_ERROR = "GET_CORP_RESERVATION_ERROR";
export const POST_CORP_RESERVATION_PENDING = "POST_CORP_RESERVATION_PENDING";
export const POST_CORP_RESERVATION_SUCCESS = "POST_CORP_RESERVATION_SUCCESS";
export const POST_CORP_RESERVATION_ERROR = "POST_CORP_RESERVATION_ERROR";
export const FIND_AVAIL_PRICE_PENDING =
  "FIND_AVAIL_PRICE_PENDING";
export const FIND_AVAIL_PRICE_SUCCESS =
  "FIND_AVAIL_PRICE_SUCCESS";
export const FIND_AVAIL_PRICE_ERROR =
  "FIND_AVAIL_PRICE_ERROR";
export const CALCULATE_CORP_RESERVATION_PENDING =
  "CALCULATE_CORP_RESERVATION_PENDING";
export const CALCULATE_CORP_RESERVATION_SUCCESS =
  "CALCULATE_CORP_RESERVATION_SUCCESS";
export const CALCULATE_CORP_RESERVATION_ERROR =
  "CALCULATE_CORP_RESERVATION_ERROR";
export const PUT_CORP_RESERVATION_PENDING = "PUT_CORP_RESERVATION_PENDING";
export const PUT_CORP_RESERVATION_SUCCESS = "PUT_CORP_RESERVATION_SUCCESS";
export const PUT_CORP_RESERVATION_ERROR = "PUT_CORP_RESERVATION_ERROR";
export const DELETE_CORP_RESERVATION_PENDING =
  "DELETE_CORP_RESERVATION_PENDING";
export const DELETE_CORP_RESERVATION_SUCCESS =
  "DELETE_CORP_RESERVATION_SUCCESS";
export const DELETE_CORP_RESERVATION_ERROR = "DELETE_CORP_RESERVATION_ERROR";
export const ADD_EXTRA_ORDER_PENDING =
  "ADD_EXTRA_ORDER_PENDING";
export const ADD_EXTRA_ORDER_SUCCESS =
  "ADD_EXTRA_ORDER_SUCCESS";
export const ADD_EXTRA_ORDER_ERROR =
  "ADD_EXTRA_ORDER_ERROR";

// URL: URL_{URL}
const CORP_RESERVATION_URL = `v1/order/corporate`;
const CORP_RESERVATION_URL_V2 = `v2/order/corporate`;
const CALCULATE_CORP_RESERVATION_URL = `v2/order/corporate/calculate-price`;
const FIND_AVAILIBILTY_PRICE = `v2/order/corporate/find-availability-price`;
const CORP_RESERVATION_CANCEL_URL = `v2/order/corporate/cancel`;
const CHANGE_ASSISTANT_URL = `v1/order/change-assisstant`;
const ADD_EXTRA_ORDER_URL = `v2/order/corporate/add-extra-order`;
const DELETE_EXTRA_ORDER_URL = `v2/order/corporate/delete-extra-order`;
const NEW_CORPORATE_URL = `v1/report/data-analytic/user/new-user`;

const lang = localStorage.getItem("joumpa_language");

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_CORP_RESERVATION_PENDING));
  API.get(CORP_RESERVATION_URL_V2, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_CORP_RESERVATION_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
            type_label:
                lang === "id"
                    ? item?.type_label
                    : item?.type_label_eng,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_CORP_RESERVATION_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const change_assistant = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PUT_CORP_RESERVATION_PENDING));
  API.put(CHANGE_ASSISTANT_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_CORP_RESERVATION_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? "Berhasil Ubah Data Assistant"
          : "Change Assistant Data Successful"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_CORP_RESERVATION_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const post = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(POST_CORP_RESERVATION_PENDING));
  API.post(CORP_RESERVATION_URL_V2, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_CORP_RESERVATION_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? "Berhasil Tambah Data Reservasi Korporasi"
          : "Add Corporate Reservation Data Successful"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_CORP_RESERVATION_ERROR, err.response));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const calculate_price = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(CALCULATE_CORP_RESERVATION_PENDING));
  API.post(CALCULATE_CORP_RESERVATION_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(CALCULATE_CORP_RESERVATION_SUCCESS, res));

      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(CALCULATE_CORP_RESERVATION_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const put = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PUT_CORP_RESERVATION_PENDING));
  API.put(CORP_RESERVATION_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_CORP_RESERVATION_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? "Berhasil Ubah Data Harga Korporat"
          : "Change Corporate Price Data Successful"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_CORP_RESERVATION_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const del = (param, resolve, callback, name) => (dispatch) => {
  dispatch(actionPending(DELETE_CORP_RESERVATION_PENDING));
  API.post(CORP_RESERVATION_CANCEL_URL + `?cancel_reason=${param.cancel_reason}&order_id=${param.order_id}`)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_CORP_RESERVATION_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? `Reservasi telah dibatalkan`
          : "Reservation has been cancelled"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_CORP_RESERVATION_ERROR, err.response));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const findAvailPrice = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(FIND_AVAIL_PRICE_PENDING));
  API.get(FIND_AVAILIBILTY_PRICE, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(FIND_AVAIL_PRICE_SUCCESS, res));
      if (resolve) {
        let data = res.data.data;
        resolve({
          data: data,
          message: res.data.message,
          status: res.data.status,
        });
      }
    })
    .catch((err) => {
      dispatch(actionError(FIND_AVAIL_PRICE_ERROR, err?.response));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const addExtraOrder = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(ADD_EXTRA_ORDER_PENDING));
  API.post(ADD_EXTRA_ORDER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(ADD_EXTRA_ORDER_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? "Berhasil Ubah Data Layanan Tambahan"
          : "Successfully Change Additional Service Data"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(ADD_EXTRA_ORDER_ERROR, err.response));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const deleteExtraOrder = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_CORP_RESERVATION_PENDING));
  API.delete(DELETE_EXTRA_ORDER_URL + `?extra_order_id=${param.id}`)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_CORP_RESERVATION_SUCCESS, res));
      toastSuccess(
        lang === "id"
        ? "Berhasil Hapus Data Layanan Tambahan"
        : "Successfully Delete Additional Service Data"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_CORP_RESERVATION_ERROR, err.response));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const getNewCorporate = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_CORP_RESERVATION_PENDING));
  API.get(NEW_CORPORATE_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_CORP_RESERVATION_SUCCESS, res));
      if (resolve) {
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1,
        }));
        resolve({
          data: data,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_CORP_RESERVATION_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const corp_reservation = {
  get,
  post,
  put,
  change_assistant,
  del,
  calculate_price,
  findAvailPrice,
  addExtraOrder,
  deleteExtraOrder,
  getNewCorporate
};
export default corp_reservation;
