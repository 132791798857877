import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import Input from "../../../../components/forms/Input";
import Select2 from "../../../../components/forms/Select2";
import { withTrans } from "../../../../i18n/withTrans";
import { getUser } from "../../../../utils/User";

import CityService from "../../../../store/actions/master_data/city";

const Airport = ({ t, formik, city }) => {

    const [cityList, setCityList] = useState([]);
    const exceptThisSymbols = ["e", "E", "+", "-", "."];
    const phoneExceptSymbols = ["e", "E", ",", "-", "."];

    const dispatch = useDispatch();
    const currentUser = getUser();

    useEffect(() => {
        const param = {
            draw: 1,
            length: 99999,
            page: 1,
        };
        dispatch(CityService.get(param));

    }, []);

    useEffect(() => {
        if (city?.data?.data?.data) {
            let cityData = []
            city?.data?.data?.data.map((item) => {
                cityData = [...cityData, { value: item, label: item }]
            })

            setCityList(cityData)
        }
    }, [city]);

    return (
        <>
            <Input
                name="name"
                errors={formik.errors}
                touched={formik.touched}
                {...formik.getFieldProps("name")}
                title={t('field.airportName')}
                type="text"
                disabled={currentUser?.user.role_code !== "admin_pusat"}
            />
            <Input
                name="code"
                errors={formik.errors}
                touched={formik.touched}
                {...formik.getFieldProps("code")}
                title={t('field.code')}
                type="text"
                disabled={currentUser?.user.role_code !== "admin_pusat"}
            />
            <Select2
                title={t('field.city')}
                name="city"
                options={cityList}
                errors={formik?.errors}
                touched={formik?.touched}
                value={formik?.values?.city}
                onChange={(name, value) => {
                    formik?.setFieldValue("city", value);
                }}
                onBlur={formik?.setFieldTouched}
                disabled={currentUser?.user.role_code !== "admin_pusat"}
            />
            <Input
                name="phone"
                errors={formik.errors}
                touched={formik.touched}
                {...formik.getFieldProps("phone")}
                title={t('field.phone')}
                type="number"
                onKeyDown={(e) => phoneExceptSymbols.includes(e.key) && e.preventDefault()}
                disabled={currentUser?.user.role_code !== "admin_pusat"}
            />
            <Input
                name="email"
                errors={formik.errors}
                touched={formik.touched}
                {...formik.getFieldProps("email")}
                title="Email"
                type="email"
                disabled={currentUser?.user.role_code !== "admin_pusat"}
            />
            <Input
                name="ppn_percentage"
                errors={formik.errors}
                touched={formik.touched}
                {...formik.getFieldProps("ppn_percentage")}
                title="PPN"
                type="number"
                min={0}
                onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
            />
             <Input
                name="concession_percentage"
                errors={formik.errors}
                touched={formik.touched}
                {...formik.getFieldProps("concession_percentage")}
                title="Concession"
                type="number"
                min={0}
                onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
            />
        </>
    )
}
const mapStateToProps = ({
    city,
}) => {
    return { city };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Airport));