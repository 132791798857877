import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
} from "reactstrap";
import { Formik, Form } from "formik";
import NumberFormat from "react-number-format";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import * as Yup from "yup";
import moment from "moment";
import { withTrans } from "../../../i18n/withTrans";
import { toastError } from "../../../components/commons/toast";
import { countries } from "../../../utils/Constants";
import Input from "../../../components/forms/Input";
import Select2 from "../../../components/forms/Select2";
import ButtonModal from "../../../components/modal/ButtonModal";
import ConfirmationModal from "../../../components/modal/ConfirmModal";

const ModalEditPassenger = ({
  t,
  show,
  toggle,
  action,
  data,
  confirm,
  reset,
  listPassengersLength,
}) => {
  const formikRef = useRef();
  const [confirmModal, setConfirmModal] = useState(false);
  const [passenger, setPassenger] = useState({});
  const [dataCountries, setDataCountries] = useState(
    countries?.map((item) => ({
      label: item.name,
      value: item.name,
      countryCode: item.code?.toLowerCase(),
    }))
  );

  const title =
    (data ? t("commons.edit") : t("commons.add")) + " " + t("field.passenger");
  const initial = {
    // id: data?.id ?? '',
    title: data?.title ?? '',
    name: data?.name ?? '',
    passenger_date_of_birth: data?.passenger_date_of_birth ?? '',
    passenger_nationality: data?.passenger_nationality ?? '',
    email: data?.email ?? '',
    phoneNumber: data?.country_code ? `${data?.country_code.replace("+", "")}${data?.phone_number}` : data?.phone_number,
    countryCode: data?.country_code?.includes("+") ? data?.country_code.replace("+", "") : data?.country_code,
    countryId: data?.country_id ?? ''
  }

  const validation = Yup.object().shape({
    title: Yup.string().required(`${t("field.title")} ${t("commons.required")}`).nullable(),
    name: Yup.string().required(`${t("field.name")} ${t("commons.required")}`).nullable(),
    passenger_nationality: Yup.string().required(`${t("field.nationality")} ${t("commons.required")}`).nullable(),
    passenger_date_of_birth: Yup.date().when("title", {
      is: "Infant",
      then: (d) => d.required(`${t("field.birth_date")} ${t("commons.required")}`).nullable()
    }),
  });

  const showConfirmation = (item) => {
    setConfirmModal(!confirmModal);
    setPassenger({id: data?.id, ...item});
  };

  const confirmDelete = () => {
    setConfirmModal(!confirmModal); 
    reset(passenger);
  }

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {title}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={initial}
          validationSchema={validation}
          onSubmit={async (values, { setStatus }) => {
            if (values.title === "Infant") {
              const yearDiff = moment().diff(values.passenger_date_of_birth, "year");
              if(yearDiff > 2) {
                toastError(`${t("commons.birth_date_less_than_message")}!`);
                return;
              }
            }
            if (
              values?.title !== "Infant" &&
              values?.title !== "Child"
            ) {
              if (values.email === "") {
                toastError(`${t("commons.empty_data_message")} Email!`);
                return;
              } else if (values.phoneNumber === "") {
                toastError(
                  `${t("commons.empty_data_message")} ${t("field.phone")}!`
                );
                return;
              }
            }
            if (data) {
              values.id = data?.id;
            }
            setStatus();
            action(values);
            await delay(3000);
          }}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Container>
                  <Select2
                    name="title"
                    type="text"
                    errors={props.errors}
                    touched={props.touched}
                    // options={!data || (data && listPassengersLength > 1) ? [
                    //   { label: t("commons.mr"), value: "Mr." },
                    //   { label: t("commons.mrs"), value: "Mrs." },
                    //   { label: t("commons.child"), value: "Child" },
                    //   { label: t("commons.infant"), value: "Infant" },
                    // ] : [
                    //   { label: t("commons.mr"), value: "Mr." },
                    //   { label: t("commons.mrs"), value: "Mrs." },
                    // ]}
                    options={[
                      { label: t("commons.mr"), value: "Mr." },
                      { label: t("commons.mrs"), value: "Mrs." },
                      { label: t("commons.ms"), value: "Ms." },
                      { label: t("commons.child"), value: "Child" },
                      { label: t("commons.infant"), value: "Infant" },
                    ]}
                    {...props.getFieldProps("title")}
                    value={props?.values?.title}
                    onChange={(name, value) => {
                      props?.setFieldValue("title", value);
                    }}
                    onBlur={props?.setFieldTouched}
                    title={t("field.title")}
                  />
                  <Input
                    name="name"
                    errors={props?.errors}
                    touched={props?.touched}
                    value={props?.values?.name ?? ""}
                    onChange={(e) => {
                      let value = e.target.value;
                      props?.setFieldValue("name", value);
                    }}
                    title={t("field.name")}
                    type="text"
                  />
                  {props?.values?.title === "Infant" && (
                    <Input
                      title={t("field.birth_date")}
                      name="passenger_date_of_birth"
                      type="date"
                      max={moment().format("YYYY-MM-DD")}
                      errors={props.errors}
                      touched={props.touched}
                      value={props?.values?.passenger_date_of_birth ?? ""}
                      onChange={(e) => {
                        let value = e.target.value;
                        props?.setFieldValue("passenger_date_of_birth", value);
                      }}
                    />
                  )}
                  {/* <Input
                    name="passenger_nationality"
                    errors={props?.errors}
                    touched={props?.touched}
                    value={props?.values?.passenger_nationality ?? ""}
                    onChange={(e) => {
                      let value = e.target.value;
                      props?.setFieldValue("passenger_nationality", value);
                    }}
                    title={t("field.nationality")}
                    type="text"
                  /> */}
                  <Select2
                    clearable={!!props.values?.passenger_nationality}
                    name="passenger_nationality"
                    errors={props.errors}
                    touched={props.touched}
                    title={t("field.nationality")}
                    options={dataCountries}
                    value={props.values?.passenger_nationality}
                    onChange={(name, value) => {
                      const index = dataCountries
                        ?.map(function (e) {
                          return e.value;
                        })
                        .indexOf(value);
                      props?.setFieldValue("passenger_nationality", value);
                      // props?.setFieldValue("countryCode", dataCountries[index]?.countryCode);
                    }}
                    onBlur={props.setFieldTouched}
                  />
                  <Input
                    name="email"
                    type="email"
                    errors={props?.errors}
                    touched={props?.touched}
                    value={props?.values?.email ?? ""}
                    onChange={(e) => {
                      let value = e.target.value;
                      props?.setFieldValue("email", value);
                    }}
                    title={t("field.email")}
                  />
                  <div className="form-group">
                    <div className="form-label">{t("field.phone")}</div>
                    <div className="input-wrapper w-100">
                      <PhoneInput
                        enableTerritories={true}
                        countryCodeEditable={false}
                        placeholder={t("field.placeHolderPhoneNumber")}
                        country={props?.values?.countryId ?? "id"}
                        inputStyle={{ width: '100%' }}
                        enableSearch={true}
                        value={props?.values?.phoneNumber}
                        onChange={(phone, country) => {
                          props?.setFieldValue("countryCode", country?.dialCode);
                          props?.setFieldValue("phoneNumber", phone);
                          props?.setFieldValue("countryId", country?.countryCode);
                          // props?.setFieldValue("passenger_nationality", country?.name);
                        }}
                      />
                      {/* <NumberFormat
                        allowNegative={false}
                        className="form-control form-input"
                        name="phoneNumber"
                        type="tel"
                        {...props?.getFieldProps("phoneNumber")}
                        style={{ padding: '25px', }}
                        placeholder={`${t("commons.input")} ${t("field.phone")}`}
                      /> */}
                    </div>
                  </div>
                </Container>
                <div style={{ margin: "50px 20px 0 20px" }}>
                  {data && listPassengersLength > 1 ? (
                    <ButtonModal
                      reset={() => showConfirmation(props?.values)}
                      toggle={toggle}
                      confirm={confirm}
                      confirmTitle={t("commons.save")}
                      resetTitle={t("commons.delete")}
                      cancelTitle={t("commons.cancel")}
                      disabledConfirm={props.isSubmitting}
                    />
                  ) : !data || (data && listPassengersLength <= 1) ? (
                    <ButtonModal
                      toggle={toggle}
                      confirm={confirm}
                      confirmTitle={t("commons.save")}
                      cancelTitle={t("commons.cancel")}
                      disabledConfirm={props.isSubmitting}
                    />
                  ) : <></>}
                </div>
              </ModalBody>
            </Form>
          )}
        </Formik>
      </Modal>
      <ConfirmationModal
        show={confirmModal}
        toggle={() => setConfirmModal(false)}
        confirm={() => confirmDelete()}
        icon="info"
        param={passenger?.name}
        isDelete
      />
    </>
  );
};

export default withTrans(ModalEditPassenger);
