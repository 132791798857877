import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";

import Table from '../../../components/table';
import { withTrans } from "../../../i18n/withTrans";
// Service
import ReportService from "../../../store/actions/report/";

const Index = ({ t, filterYear, filterAirport, isMonthLessThanCurrent, refetch }) => {
  const dispatch = useDispatch();
  let lang = localStorage.getItem('joumpa_language');
  const [dataReport, setDataReport] = useState([]);
  const [dataTotalRKAP, setDataTotalRKAP] = useState(null);
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        year: filterYear,
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        airport_id: filterAirport,
      };
      dispatch(ReportService.getRKAPProduction(param, resolve));
    }).then((res) => {
      setDataReport(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  const handleRefreshTotalRKAP = () => {
    setDataTotalRKAP(null);
    new Promise((resolve) => {
      const param = {
        year: filterYear,
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        airport_id: filterAirport,
      };
      dispatch(ReportService.getTotalProductionRKAP(param, resolve));
    }).then((res) => {
      if (res?.data) {
        setDataTotalRKAP(res?.data);
      }
    });
  };

  useEffect(() => {
    handleRefresh();
    handleRefreshTotalRKAP();
  }, [page, filterAirport, filterYear, refetch]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [filterAirport, filterYear, refetch]);

  const columnProduction = [
    {
      Header: "NO",
      accessor: "no",
      colSpanFooter: 2,
      Footer: () => {
        return (
          <div style={{ fontWeight: 800, minWidth: 150 }}>
            <p>
              {lang === "id" ? "Total Keseluruhan" : "Total"}
            </p>
            <p>
              {t("report.avgOverall")}
            </p>
          </div>
        )
      },
    },
    {
      Header: t("field.airport").toUpperCase(),
      accessor: "airport",
      hideColFooter: true,
    },
    {
      Header: t("month.jan").toUpperCase(),
      textAlign: isMonthLessThanCurrent(0) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "prod_1",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProduction_1 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProduction_1 ?? "-"}
                </p>
              </div>
            );
          },
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "prod_real_1",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProductionReal_1 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProductionReal_1 ?? "-"}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(0)
        }
      ]
    },
    {
      Header: t("month.feb").toUpperCase(),
      textAlign: isMonthLessThanCurrent(1) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "prod_2",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProduction_2 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProduction_2 ?? "-"}
                </p>
              </div>
            );
          },
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "prod_real_2",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProductionReal_2 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProductionReal_2 ?? "-"}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(1)
        }
      ]
    },
    {
      Header: t("month.mar").toUpperCase(),
      textAlign: isMonthLessThanCurrent(2) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "prod_3",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProduction_3 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProduction_3 ?? "-"}
                </p>
              </div>
            );
          },
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "prod_real_3",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProductionReal_3 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProductionReal_3 ?? "-"}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(2)
        }
      ]
    },
    {
      Header: "APRIL",
      textAlign: isMonthLessThanCurrent(3) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "prod_4",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProduction_4 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProduction_4 ?? "-"}
                </p>
              </div>
            );
          },
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "prod_real_4",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProductionReal_4 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProductionReal_4 ?? "-"}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(3)
        }
      ]
    },
    {
      Header: t("month.may").toUpperCase(),
      textAlign: isMonthLessThanCurrent(4) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "prod_5",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProduction_5 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProduction_5 ?? "-"}
                </p>
              </div>
            );
          },
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "prod_real_5",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProductionReal_5 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProductionReal_5 ?? "-"}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(4)
        }
      ]
    },
    {
      Header: t("month.jun").toUpperCase(),
      textAlign: isMonthLessThanCurrent(5) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "prod_6",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProduction_6 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProduction_6 ?? "-"}
                </p>
              </div>
            );
          },
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "prod_real_6",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProductionReal_6 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProductionReal_6 ?? "-"}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(5)
        }
      ]
    },
    {
      Header: t("month.jul").toUpperCase(),
      textAlign: isMonthLessThanCurrent(6) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "prod_7",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProduction_7 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProduction_7 ?? "-"}
                </p>
              </div>
            );
          },
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "prod_real_7",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProductionReal_7 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProductionReal_7 ?? "-"}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(6)
        }
      ]
    },
    {
      Header: t("month.aug").toUpperCase(),
      textAlign: isMonthLessThanCurrent(7) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "prod_8",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProduction_8 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProduction_8 ?? "-"}
                </p>
              </div>
            );
          },
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "prod_real_8",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProductionReal_8 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProductionReal_8 ?? "-"}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(7)
        }
      ]
    },
    {
      Header: "SEPTEMBER",
      textAlign: isMonthLessThanCurrent(8) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "prod_9",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProduction_9 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProduction_9 ?? "-"}
                </p>
              </div>
            );
          },
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "prod_real_9",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProductionReal_9 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProductionReal_9 ?? "-"}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(8)
        }
      ]
    },
    {
      Header: t("month.oct").toUpperCase(),
      textAlign: isMonthLessThanCurrent(9) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "prod_10",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProduction_10 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProduction_10 ?? "-"}
                </p>
              </div>
            );
          },
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "prod_real_10",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProductionReal_10 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProductionReal_10 ?? "-"}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(9)
        }
      ]
    },
    {
      Header: "NOVEMBER",
      textAlign: isMonthLessThanCurrent(10) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "prod_11",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProduction_11 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProduction_11 ?? "-"}
                </p>
              </div>
            );
          },
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "prod_real_11",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProductionReal_11 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProductionReal_11 ?? "-"}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(10)
        }
      ]
    },
    {
      Header: t("month.dec").toUpperCase(),
      textAlign: isMonthLessThanCurrent(11) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "prod_12",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProduction_12 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProduction_12 ?? "-"}
                </p>
              </div>
            );
          },
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "prod_real_12",
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalProductionReal_12 ?? "-"}
                </p>
                <p>
                  {dataTotalRKAP?.averageProductionReal_12 ?? "-"}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(11)
        }
      ]
    },
  ];

  return (
    <Table
      footer
      customFooterGroup
      columns={columnProduction.map((item) => ({
        ...item,
        columns: item?.columns?.filter((col) => col.show !== false)
        }))}
      data={dataReport ?? []}
      pagination={{ page, totalData, maxPage, toggle: setPage }}
    />
  )
}

export default withTrans(Index)