import React from "react";
import {
  Container,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import moment from "moment";
import CardFlush from "../../../components/cards/cardFlush";
import defaultImage from '../../../assets/images/empty-state/Gambar Tidak Ditemukan.png';
import { withTrans } from "../../../i18n/withTrans";

const DetailUserModal = ({
  t,
  show,
  toggle,
  data,
  detailUser,
}) => {

  return (
    <>
      <Modal isOpen={show}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {`Detail ${t("field.customer")}`}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Container className="px-3">
          <ModalBody className="px-4">
            <div className="d-flex flex-column m-auto justify-content-center text-center">
              <div
                className="d-flex justify-content-center"
              >
                <div 
                  style={{
                    width: '92px',
                    height: '92px',
                    padding: '5px',
                    background: 'transparent',
                    border: '2px solid #63AE5C',
                    borderRadius: '100%',
                  }}
                >
                  <img
                    src={
                      data?.img_url
                        ? process.env.REACT_APP_API_URL +
                          data?.img_url?.substr(
                            1,
                            data?.img_url?.length
                          )
                        : defaultImage
                    }
                    alt="profile-customer"
                    style={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </div>
              </div>
              <p className="title text-extra-bold" style={{ marginTop: 16, marginBottom: 5 }}>
                {data?.name}
              </p>
              <p className="text-primary text-bold">
                #ID {data?.customer_id}
              </p>

            </div>
            <div className="wrapper-detail-card">
              {
                (detailUser = [
                  {
                    label: t("field.birth_date"),
                    desc: data?.date_of_birth 
                      ? moment(data?.date_of_birth)?.format("D MMMM YYYY") 
                      : "-"
                  },
                  {
                    label: t("field.gender"), 
                    desc: data?.gender !== null
                      ? data?.gender === 1
                        ? t("commons.men")
                        : t("commons.woman")
                      : "-" 
                  },
                  {
                    label: t("field.phone"), 
                    desc:
                      data?.handphone && data?.country_code
                        ? `${data?.country_code}${data?.handphone}`
                        : data?.handphone && !data?.country_code
                        ? data?.handphone
                        : "-" 
                  },
                  { label: "Email", desc: data?.email ?? "-", last: true },
                ].map((detailUser, index) => (
                  <CardFlush
                    list
                    key={index}
                    label={detailUser.label}
                    desc={detailUser.desc}
                    last={detailUser.last ?? false}
                  />
                )))
              }
            </div>
          </ModalBody>
        </Container>
      </Modal>
    </>
  );
};

export default withTrans(DetailUserModal);
