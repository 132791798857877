import React from "react";
import { Formik, Form } from "formik";
import moment from "moment";

import Select2 from "../../../../components/forms/Select2";
import ButtonModal from "../../../../components/modal/ButtonModal";
import DynamicButton from "../../../../components/forms/DynamicButton";
import DatePicker from "../../../../components/forms/DateRangePicker";
import { withTrans } from "../../../../i18n/withTrans";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const ModalFilter = ({
  t,
  show,
  toggle,
  filter,
  toggleFilter,
  onApplyFilter,
}) => {
  const handleReset = (formik) => {
    formik.setFieldValue("start_date", moment().format("YYYY-MM-DD"));
    formik.setFieldValue("end_date", moment().format("YYYY-MM-DD"));
    formik.setFieldValue("expired_status", "");
    toggleFilter({
      expired_status: "",
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
    });
    toggle();
  };

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Filter{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody style={{ margin: "0px 20px" }}>
          <DynamicButton titleLeft={t("field.chooseFilter")} iconLeft="info" />
          <Formik
            initialValues={{
              expired_status: filter?.expired_status ?? "",
              start_date: filter?.start_date ?? "",
              end_date: filter?.end_date ?? "",
            }}
            enableReinitialize
            onSubmit={(values) => {
              onApplyFilter({
                start_date:
                  values.start_date === "" || values.start_date === undefined
                    ? ""
                    : moment(values.start_date).format("YYYY-MM-DD"),
                end_date:
                  values.end_date === "" || values.end_date === undefined
                    ? ""
                    : moment(values.end_date).format("YYYY-MM-DD"),
                expired_status: values.expired_status,
              });
              toggle();
            }}
          >
            {(props) => (
              <Form style={{ marginTop: 30 }}>
                <DatePicker
                  title={t("field.expiredDate")}
                  name="start_date"
                  handlechange={(startDate, endDate) => {
                    props.setFieldValue("start_date", startDate);
                    props.setFieldValue("end_date", endDate);
                  }}
                  format="DD MMMM YYYY"
                  startDate={filter ? filter?.start_date : props.values?.start_date}
                  endDate={filter ? filter?.end_date : props.values?.end_date}
                  className="mx-auto"
                  errors={props.errors}
                  touched={props.touched}
                />
                <Select2
                  title={t("field.expiredStatus")}
                  name="expired_status"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} Status`,
                    },
                    {
                      value: "active",
                      label: t("promo.active"),
                    },
                    {
                      value: "upcoming",
                      label: t("status.notStarted"),
                    },
                    {
                      value: "expired",
                      label: t("field.expired"),
                    },
                    {
                      value: "expired_soon",
                      label: t("field.expiredSoon"),
                    },
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.expired_status}
                  placeholder="Pilih status"
                  onChange={(name, value) => {
                    props.setFieldValue("expired_status", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <div style={{ marginTop: 50, marginBottom: 30 }}>
                  <ButtonModal
                    toggle={toggle}
                    reset={() => handleReset(props)}
                    confirmTitle={t("field.apply")}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}

export default withTrans(ModalFilter);
