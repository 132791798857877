import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Col,
} from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Compressor from "compressorjs";
import Card from "../../../components/cards/card";
import CardBody from "../../../components/cards/cardBody";
import ButtonModal from "../../../components/modal/ButtonModal";
import { toastError } from "../../../components/commons/toast";
import { withTrans } from "../../../i18n/withTrans";
import DynamicButton from "../../../components/forms/DynamicButton";
import Select2 from "../../../components/forms/Select2";

import Booking from "../../../store/actions/booking";

const UploadPaymentModal = ({ t, show, toggle, payment_type, payment_proof, order_id }) => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const fileRef = useRef();

  const [selectedImage, setSelectedImage] = useState();
  const [fileImage, setFileImage] = useState();

  async function getFileFromUrl(url){
    const response = await fetch(url);
    const data = await response.blob();
    return setFileImage(new File([data], payment_proof.split('/').pop(), {
      type: data.type || 'image/png',
    }));
  };

  useEffect(() => {
    if (payment_proof) {
      getFileFromUrl(payment_proof);
      setSelectedImage(payment_proof);
    } else {
      setSelectedImage(null);
    }
  }, [payment_proof]);

  const openFileDialog = () => {
    fileRef.current.click();
  };

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const image = e.target.files[0];
      const imageName = image.name;
      new Compressor(image, {
        quality: 0.6,
        success: (res) => {
          setSelectedImage(URL.createObjectURL(res));
          const file = new File([res], imageName);
          formikRef?.current?.setFieldValue("file", file);
        },
        error(err) {
          console.log(err.message);
        },
      });
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage(null);
    formikRef?.current?.setFieldValue("file", null);
    fileRef.current.value = "";
  };

  const initial = {
    payment_type: payment_type ?? "",
    file: payment_proof ? fileImage : null,
  };

  const validation = Yup.object().shape({});

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {t("booking.paymentProof")}{" "}
          <span className="close clickable"
            onClick={() => {
              if (payment_proof === null) {
                setSelectedImage(null);
              }
              toggle();
            }}
          >
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={initial}
          validationSchema={validation}
          onSubmit={async (values, { setStatus, setSubmitting }) => {
            if (values?.payment_type !== "" && values?.file === null) { 
              setSubmitting(false);
              toastError(
                `${t("commons.empty_data_message")} ${t(
                  "booking.paymentProof"
                )}!`
              );
              return;
            } 
            if (values?.payment_type === "" && values?.file !== null) { 
              setSubmitting(false);
              toastError(
                `${t("commons.empty_data_message")} ${t(
                  "booking.paymentType"
                )}!`
              );
              return;
            } 
            if (values?.payment_type === "" && values?.file === null){
              setSubmitting(false);
              toastError(
                `${t("commons.empty_data_message")} ${t(
                  "booking.paymentProof"
                )}!`
              );
              return;
            }

            const callback = () => {
              setSelectedImage(null);
              toggle();
            };
            
            let formData = new FormData();
            formData.append("file", values?.file);
            formData.append("payment_type", values?.payment_type);
            formData.append("order_id", order_id);
            setStatus();
            dispatch(Booking.upload_payment_proof(formData, callback));
            await delay(3000);
            setSubmitting(false);
          }}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Container>
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <div className="text-bold">{t("booking.paymentType")}</div>
                        <Col md={6} style={{ paddingRight: 0 }}>
                          <Select2
                            name="payment_type"
                            errors={props.errors}
                            options={[
                              {
                                value: "cash",
                                label: `${t("field.cash")}`,
                              },
                              {
                                value: "transfer",
                                label: "Transfer",
                              },
                              {
                                value: "deposit",
                                label: "Deposit",
                              },
                            ]}
                            touched={props.touched}
                            value={props?.values?.payment_type}
                            onChange={(name, value) => {
                              props.setFieldValue("payment_type", value);
                            }}
                            onBlur={props.setFieldTouched}
                          />
                        </Col>
                      </div>
                      <div>
                        <DynamicButton
                          titleCenter={t("booking.paymentProof")}
                          subtitleCenter={t("booking.uploadPaymentProof")}
                          iconCenter="add_photo_alternate"
                          toggle={openFileDialog}
                          errors={props.error}
                        />
                        <input
                          type="file"
                          ref={fileRef}
                          style={{ display: "none" }}
                          onChange={imageChange}
                          accept="image/png, image/jpeg"
                        />
                        {selectedImage && (
                          <div
                            style={{
                              isplay: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={selectedImage}
                              style={{
                                width: "100%",
                                maxHeight: 320,
                                objectFit: "cover",
                              }}
                              alt="Thumb"
                            />
                            <button
                              onClick={removeSelectedImage}
                              style={{
                                cursor: "pointer",
                                padding: 8,
                                background: "#d10d0d",
                                color: "white",
                                border: "none",
                                width: "100%",
                              }}
                            >
                              {t("commons.delete")}
                            </button>
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Container>
              </ModalBody>
              <ModalFooter>
                <ButtonModal
                  toggle={() => {
                    if (payment_proof === null) {
                      setSelectedImage(null);
                    }
                    toggle();
                  }}
                  disabledConfirm={props?.isSubmitting}
                  confirmTitle={t("commons.upload")}
                  icon
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default withTrans(UploadPaymentModal);
