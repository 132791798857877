import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";

import Table from "../../../components/table";
import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import Button from "../../../components/buttons/Button";
import SearchButton from "../../../components/buttons/SearchButton";
import DetailButton from "../../../components/table/detailButton";
import Loader from "../../../components/commons/Loader";
import MasterDataModal from "./MasterDataModal";
import ConfirmationModal from "../../../components/modal/ConfirmModal";
import { withTrans } from "../../../i18n/withTrans";
import { history } from "../../../utils/History";
import { getUser } from "../../../utils/User";

// ACTION REDUX
import AirportsService from "../../../store/actions/master_data/airport";

function Index({ t, airport }) {
  const dispatch = useDispatch();
  const currentUser = getUser();
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [dataAirports, setDataAirports] = useState([]);
  const [dataAirportsRow, setDataAirportsRow] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const showEditModal = (data) => {
    setEditModal(!editModal);
    setDataAirportsRow(data);
  };

  const showAddModal = () => {
    setAddModal(!addModal);
  };

  const showDeleteModal = (data) => {
    setDeleteModal(!deleteModal);
    setDataAirportsRow(data);
  };

  const handleEdit = (param) => {
    const callback = () => {
      handleRefresh();
      setEditModal(!editModal);
    };

    if (param) {
      dispatch(AirportsService.put(param, callback));
    }
  };

  const handleAdd = (param) => {
    const callback = () => {
      handleRefresh();
      setAddModal(!addModal);
    };

    dispatch(AirportsService.post(param, callback));
  };

  const handleDelete = () => {
    const callback = () => {
      handleRefresh();
      setPage(1);
      setDeleteModal(!deleteModal);
    };

    if (dataAirportsRow) {
      const id = dataAirportsRow?.id;
      dispatch(AirportsService.deleted(id, callback, dataAirportsRow?.name));
    }
  };

  const columns = [
    {
      Header: "NO",
      accessor: "no",
    },
    {
      Header: t("field.code").toUpperCase(),
      accessor: "code",
    },
    {
      Header: t("field.airport").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        let name = original.name;
        name = name.length > 40 ? name.substring(0, 40) + "..." : name;
        return <>{name}</>;
      },
    },
    {
      Header: t("field.city").toUpperCase(),
      accessor: "city",
    },
    {
      Header: "",
      accessor: "actions",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <DetailButton>
            <MenuItem
              onClick={() =>
                history.push(`/data-master/airport/detail/${original?.id}`)
              }
            >
              <span style={{ fontSize: 14 }}>{t("commons.detail")}</span>
            </MenuItem>
            {currentUser?.user.role_code !== "contact_center" &&
              currentUser?.user.role_code !== "lead_contact_center" &&
              currentUser?.user.role_code !== "admin_bandara" &&
            ( 
              <MenuItem onClick={() => showDeleteModal(original)}>
                <span style={{ fontSize: 14 }}>{t("commons.delete")}</span>
              </MenuItem>
            )}
          </DetailButton>
        );
      },
    },
  ];

  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };

  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = {
        id: currentUser?.user.role_code === "admin_bandara" ? currentUser?.user.airport_id : '',
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        search_text: searchText,
        is_service: true
      };
      dispatch(AirportsService.get(param, resolve));
    }).then((res) => {
      setDataAirports(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, searchText]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [searchText]);

  return (
    <>
      {airport?.pending && <Loader sideContent loading={airport?.pending} />}
      <HeaderMenu title={t("masterData.airport")}>
        <SearchButton
          placeholder={t("commons.enterKeyword")}
          toggle={(value) => handleSearch(value)}
          style={{ marginRight: 20 }}
        />
        {currentUser?.user.role_code !== "contact_center" &&
          currentUser?.user.role_code !== "lead_contact_center" &&
          currentUser?.user.role_code !== "admin_bandara" && 
        (
          <>
          <div className="nav-separator mr-3" />
          <Button
            title={t("commons.add") + " " + t("masterData.airport")}
            leftIcon="add"
            rounded={true}
            variant="primary"
            onClick={showAddModal}
          />
          </>
        )}
      </HeaderMenu>
      <div className="content-container-side">
        <div className="overflow">
          <Table
            columns={columns}
            data={dataAirports ?? []}
            pagination={{ page, totalData, maxPage, toggle: setPage }}
          />
        </div>
      </div>

      <MasterDataModal
        show={addModal}
        toggle={showAddModal}
        action={handleAdd}
      />

      <MasterDataModal
        show={editModal}
        toggle={showEditModal}
        action={handleEdit}
        data={dataAirportsRow}
      />

      <ConfirmationModal
        show={deleteModal}
        toggle={showDeleteModal}
        confirm={handleDelete}
        icon="info"
        message={t("commons.areYouSureDelete")}
        messageData={
          dataAirportsRow?.name?.length > 40
            ? dataAirportsRow?.name?.substring(0, 40) + "..."
            : dataAirportsRow?.name
        }
        isDelete
      />
    </>
  );
}

const mapStateToProps = ({ airport }) => {
  return { airport };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Index));
