import { Formik, Form } from "formik";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { withTrans } from "../../../i18n/withTrans";
import Select2 from "../../../components/forms/Select2";
import ButtonModal from "../../../components/modal/ButtonModal";
import DynamicButton from "../../../components/forms/DynamicButton";
import DatePicker from "../../../components/forms/DateRangePicker";
import moment from "moment";

function ModalFilter({
  t,
  show,
  airport,
  product,
  toggle,
  filter,
  toggleFilter,
  isDelete,
  onApplyFilter,
  user,
}) {

  const handleReset = (formik) => {
    formik.setFieldValue("start_date", moment().format("YYYY-MM-DD"));
    formik.setFieldValue("end_date", moment().format("YYYY-MM-DD"));
    formik.setFieldValue("product_id", "");
    formik.setFieldValue("airport_id", "");
    toggleFilter({
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      product_id: "",
      airport_id: "",
    });
    toggle();
  };

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Filter{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody style={{ margin: "0px 20px" }}>
          <DynamicButton titleLeft={t("field.chooseFilter")} iconLeft="info" />
          <Formik
            initialValues={{
              start_date: filter?.start_date ?? "",
              end_date: filter?.end_date ?? "",
              airport_id: filter?.airport_id ?? "",
              product_id: filter?.product_id ?? "",
            }}
            enableReinitialize
            onSubmit={(values) => {
              onApplyFilter({
                airport_id:
                  user.role_code === "admin_bandara" ||
                  user.role_code === "supervisor"
                    ? user.airport_id
                    : values.airport_id,
                start_date:
                  values.start_date === "" || values.start_date === undefined
                    ? ""
                    : moment(values.start_date).format("YYYY-MM-DD"),
                end_date:
                  values.end_date === "" || values.end_date === undefined
                    ? ""
                    : moment(values.end_date).format("YYYY-MM-DD"),
                product_id: values.product_id,
              });
              toggle();
            }}
          >
            {(props) => (
              <Form style={{ marginTop: 30 }}>
                <DatePicker
                  title={t("report.serviceTime")}
                  name="start_date"
                  handlechange={(startDate, endDate) => {
                    props.setFieldValue("start_date", startDate);
                    props.setFieldValue("end_date", endDate);
                  }}
                  format="DD MMMM YYYY"
                  startDate={filter ? filter?.start_date : props.values?.start_date}
                  endDate={filter ? filter?.end_date : props.values?.end_date}
                  className="mx-auto"
                  errors={props.errors}
                  touched={props.touched}
                />
                <Select2
                  title={t("field.airport")}
                  name="airport_id"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t("field.airport")}`,
                    },
                    ...airport,
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.airport_id}
                  onChange={(name, value) => {
                    props.setFieldValue("airport_id", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title={t("field.product")}
                  name="product_id"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t("field.product")}`,
                    },
                    ...product,
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.product_id}
                  onChange={(name, value) => {
                    props.setFieldValue("product_id", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
               
                <div style={{ marginTop: 50, marginBottom: 30 }}>
                  <ButtonModal
                    toggle={toggle}
                    reset={() => handleReset(props)}
                    deleted={isDelete}
                    confirmTitle={t("field.apply")}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}

export default withTrans(ModalFilter);
