import React from "react";
import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Select from "react-select";

import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import Loader from "../../components/commons/Loader";
import Button from "../../components/buttons/Button";
import SelectFilter from "../../components/forms/SelectFilter";
import UploadModal from "./UploadModal";
import RKAPSales from "./rkapSales";
import RKAPProduction from "./rkapProduction";
import { withTrans } from "../../i18n/withTrans";
import { reactSelectCustomStyles } from "../../utils/Constants";

// Service
import ReportService from "../../store/actions/report/";
import AirportService from "../../store/actions/master_data/airport";

const Index = ({ t, pending, data }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("0");
  const [dataAirport, setDataAirport] = useState([]);
  const [filterYear, setFilterYear] = useState(new Date().getFullYear());
  const [filterAirport, setFilterAirport] = useState("");
  const [refetchData, setRefetchData] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [showModalUpload, setShowModalUpload] = useState(false);

  let yearOptions = [];
  for (let i = new Date().getFullYear(); i >= 2021; i--) {
    yearOptions = [...yearOptions, { label: i, value: i }];
  };

  const isMonthLessThanCurrent = (month) => {
    const currentMonth = new Date().getMonth();
    return month < currentMonth;
  };

  const handleAirport = () => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      setDataAirport(
        res?.data?.map((airport) => ({
          value: airport?.id,
          label: `${airport?.code}-${airport?.name}`,
        }))
      );
    });
  };

  useEffect(() => {
    handleAirport();
  }, []);

  const handleDownloadReport = () => {
    new Promise((resolve) => {
      setLoadingDownload(true);
      let param = {
        year: filterYear,
        airport_id: filterAirport,
      }
      dispatch(ReportService.downloadRKAP(param, resolve))
    }).then((res) => {
      setLoadingDownload(false);
    });
  };

  return (
    <>
      {pending && <Loader fullScreen loading={pending} />}
      <HeaderMenu title="RKAP">
        <div style={{ maxWidth: '300px' }}>
          <Select
            classNamePrefix="select"
            className="basic-single mr-3"
            isSearchable
            name="airport_id"
            styles={reactSelectCustomStyles}
            options={[
              {
                value: "",
                label: `${t("commons.all")} ${t("field.airport")}`,
              },
              ...dataAirport,
            ]}
            value={
              dataAirport?.filter((obj) => {
                return obj?.value === filterAirport;
              })[0] ?? {
                label: `${t("commons.all")} ${t("field.airport")}`,
                value: "",
              }
            }
            onChange={(item) => {
              setFilterAirport(item?.value);
            }}
          />
        </div>
        <SelectFilter
          value={filterYear}
          options={yearOptions}
          placeholder={`Filter ${t("field.year")}`}
          onChange={(data) => setFilterYear(data.value)}
        />
        <div className="nav-separator mr-3 ml-1" />
        <Button
          title={(
            <>
              {loadingDownload && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
              )}
              {`${t("report.downloadReport")} RKAP`}
            </>
          )}
          leftIcon={!loadingDownload ? "download" : null}
          rounded={true}
          variant="button-light btn-lg"
          onClick={handleDownloadReport}
        />
        <Button
          title="Upload RKAP"
          leftIcon="upload"
          rounded={true}
          variant="primary"
          onClick={() => setShowModalUpload(!showModalUpload)}
        />
      </HeaderMenu>
      <div className="content-container-side">
        <div className="overflow" style={{ maxWidth: "100%" }}>
          <div className="tab-menu overflow">
            <div style={{ width: "fit-content" }}>
              <Tabs
                scrollButtons="auto"
                variant="scrollable"
                value={activeTab}
                textColor="primary"
                indicatorColor="primary"
                onChange={(event, newValue) => {
                  setActiveTab(newValue);
                }}
              >
                <Tab
                  label={t("report.production")}
                  value="0"
                />
                <Tab
                  label={t("field.income")}
                  value="1"
                />
              </Tabs>
            </div>
          </div>
          {activeTab === "0"
            ? <RKAPProduction
                filterYear={filterYear}
                filterAirport={filterAirport}
                isMonthLessThanCurrent={isMonthLessThanCurrent}
                refetch={refetchData}
              /> 
            : <RKAPSales
                filterYear={filterYear}
                filterAirport={filterAirport}
                isMonthLessThanCurrent={isMonthLessThanCurrent}
              />
          }
        </div>
      </div>
      <UploadModal 
        show={showModalUpload}
        toggle={() => {
          setShowModalUpload(!showModalUpload);
          setRefetchData(!refetchData);
        }}
      />
    </>
  );
};

const mapStateToProps = ({ report: { pending, data } }) => {
  return { pending, data };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(Index)
);
