import { useState, useEffect } from "react";
import { withTrans } from "../../i18n/withTrans";
import { useDispatch } from "react-redux";
import { getUser } from "../../utils/User";

import SideMenu from "../../components/commons/menu/SideMenu";
import AssistantManagement from "./assistant_management";
import UserManagement from "./user_management";
import CustomerManagement from "./customer_management";

// Service
import AirportService from "../../store/actions/master_data/airport";
import { Role } from "../../utils/Constants";
const Index = ({ t }) => {
  const currentUser = getUser();
  const dispatch = useDispatch();

  const [show, setShow] = useState(
    (currentUser?.user?.role_code === Role.Central_admin ||
      currentUser?.user?.role_code === Role.Contact_center ||
      currentUser?.user?.role_code === Role.Lead_Contact_center
    ) ? "user" 
      : "assistant"
  );
  const [dataAirport, setDataAirport] = useState([]);

  const handleAirport = () => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
        is_service: true
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      setDataAirport(res?.data);
    });
  };

  useEffect(() => {
    handleAirport();
  }, []);

  return (
    <div className="menu-container">
      <div className="grid-header">
        <div className="side-menu">
          <div className="menu-title text-extra-bold">{t("field.user")}</div>
          {(currentUser?.user?.role_code === Role.Central_admin ||
            currentUser?.user?.role_code === Role.Contact_center ||
            currentUser?.user?.role_code === Role.Lead_Contact_center) && 
          (
            <SideMenu
              title={t("field.user_back_office")}
              subtitle={t("user.list_user_back_office")}
              toggle={() => setShow("user")}
              active={show === "user"}
            />
          )}
          <SideMenu
            title={t("field.assistant")}
            subtitle={t("user.list_assistant")}
            toggle={() => setShow("assistant")}
            active={show === "assistant"}
          />
          {(currentUser?.user?.role_code === Role.Central_admin ||
            currentUser?.user?.role_code === Role.Contact_center) &&
          (
            <SideMenu
              title={t("field.customer")}
              subtitle={t("user.list_customer")}
              toggle={() => setShow("customer")}
              active={show === "customer"}
            />
          )}
        </div>
        {show === "assistant" && (
          <>
            <AssistantManagement dataAirport={dataAirport} />
          </>
        )}
        {show === "user" && (
          <>
            <UserManagement />
          </>
        )}
        {show === "customer" && (
          <>
            <CustomerManagement />
          </>
        )}
      </div>
    </div>
  );
};

export default withTrans(Index);
