import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ButtonModal from "./ButtonModal";
import { withTrans } from "../../i18n/withTrans";

const Index = ({
  t,
  show,
  toggle,
  title,
  content,
  confirmTitle,
  cancelTitle,
  confirm,
  disabledConfirm,
  noIcon,
  withAction,
}) => {
  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        toggle={toggle}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {title ?? "Modal"}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              marginLeft: 20,
              marginRight: 20,
              display: "flex",
              lineHeight: "2rem",
            }}
          >
            {content}
          </div>
        </ModalBody>
        <ModalFooter>
          {withAction && (
            <ButtonModal
              toggle={toggle}
              confirm={confirm}
              confirmTitle={confirmTitle}
              cancelTitle={cancelTitle}
              disabledConfirm={disabledConfirm}
              noIcon={noIcon}
            />
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default withTrans(Index);
