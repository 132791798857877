import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";
import ReactTooltip from "react-tooltip";
import moment from "moment";

import AssistantModal from "./modal/AssistantModal";
import Card from "../../../components/cards/card";
import CardBody from "../../../components/cards/cardBody";
import CardFlush from "../../../components/cards/cardFlush";
import CardHeader from "../../../components/cards/cardHeader";
import { toastError } from "../../../components/commons/toast";
import { withTrans } from "../../../i18n/withTrans";
import { getUser } from "../../../utils/User";

import CorpReservService from "../../../store/actions/corp_reservation";

const Checkout = ({ button, formik, data, t }) => {
  const currentUser = getUser();
  const role = currentUser?.user?.role_code;
  const [groupPrice, setGroupPrice] = useState(0);
  const [transportPrice, setTransportPrice] = useState(0);
  const [grandTotalPrice, setGrandTotalPrice] = useState(0);
  const [dataCheckout, setDataCheckout] = useState(formik?.values);
  const [passengersAdult, setPassengersAdult] = useState(0);
  const [passengersChild, setPassengersChild] = useState(0);
  const [passengersInfant, setPassengersInfant] = useState(0);
  const [showPriceService, setShowPriceService] = useState(false);
  const [showPriceUnderQuota, setShowPriceUnderQuota] = useState(false);
  const [showLounge, setShowLounge] = useState(false);
  const [showTransport, setShowTransport] = useState(false);
  const [showOthers, setShowOthers] = useState(false);
  const [assistantModal, setAssistantModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getPriceFromApi();
  }, [data]);

  const getPriceFromApi = () => {
    new Promise((resolve) => {
      if (formik?.values?.passenger_list?.length < 1) {
        toastError(
          `${t("commons.empty_data_message")} ${t("field.passenger")}!`
        );
        return;
      }

      let values = {
        additional_service_description: 
          formik?.values?.additional_service_description === "" 
            ? null 
            : formik?.values?.additional_service_description,
        additional_service_concession_percentage: 
          parseFloat(formik?.values?.additional_service_concession_percentage),
        additional_service_ppn_percentage: 
          parseFloat(formik?.values?.additional_service_ppn_percentage),
        additional_service_price:
          formik?.values?.additional_service_price === null || 
          formik?.values?.additional_service_price === 0
            ? null 
            : formik?.values?.additional_service_price,
        additional_service_dpp:
          formik?.values?.additional_service_dpp === null ||
          formik?.values?.additional_service_dpp === 0
            ? null
            : formik?.values?.additional_service_dpp,
        additional_service_is_include_ppn: 
          formik?.values?.additional_service_is_include_ppn == 1 
            ? true 
            : false,
        additional_service_is_include_concession: 
          formik?.values?.additional_service_is_include_concession == 1 
            ? true 
            : false,
        airplane_name: formik?.values?.airplane_name,
        airplane_name_transfer_to: formik?.values?.airplane_name_transfer_to,
        airplane_number: formik?.values?.airplane_number,
        airplane_number_transfer_to:
          formik?.values?.airplane_number_transfer_to,
        airport_id_from: formik?.values?.airport_id_from,
        airport_id_to: formik?.values?.airport_id_to,
        airport_id_transfer: formik?.values?.airport_id_transfer,
        assistant_id: formik?.values?.assistant_id,
        bookers_title: formik?.values?.bookers_title,
        bookers_name: formik?.values?.bookers_name,
        bookers_nationality: formik?.values?.bookers_nationality,
        bookers_email: formik?.values?.bookers_email,
        bookers_phone: formik?.values?.bookers_phone,
        corporate_id: formik?.values?.corporate_id,
        corporate_lounge_id:
          formik?.values?.corporate_lounge_id === '' 
            ? null 
            : formik?.values?.corporate_lounge_id,
        corporate_price_id: formik?.values?.corporate_price_id,
        corporate_type_group_id: formik?.values?.corporate_type_group_id,
        corporate_type_group_property_id:
          formik?.values?.corporate_type_group_property_id,
        corporate_type_id: formik?.values?.corporate_type_id,
        customer_id: null,
        date: formik?.values?.date,
        date_transfer_to: formik?.values?.date_transfer_to,
        lounge_adult_number:
          formik?.values?.lounge_adult_number === ""
            ? 0
            : formik?.values?.lounge_adult_number,
        lounge_child_number:
          formik?.values?.lounge_child_number === ""
            ? 0
            : formik?.values?.lounge_child_number,
        lounge_infant_number:
          formik?.values?.lounge_infant_number === ""
            ? 0
            : formik?.values?.lounge_infant_number,
        lounge_id: 
          formik?.values?.lounge_id === '' 
            ? null : 
            formik?.values?.lounge_id,
        passenger_list: formik?.values?.passenger_list,
        passenger_notes: formik?.values?.passenger_notes,
        paging_name: formik?.values?.paging_name,
        price_period_id: formik?.values?.price_period_id,
        product_code: formik?.values?.product_code,
        product_list_id: formik?.values?.product_list_id,
        promo_id: formik?.values?.promo_id,
        region: formik?.values?.region,
        time: formik?.values?.time,
        time_transfer_to: formik?.values?.time_transfer_to,
        transport_price_id: formik?.values?.orderExtras?.car?.id
          ? formik?.values?.orderExtras?.car?.id
          : null,
        transport_number: formik?.values?.total_unit,
        type: formik?.values?.flight_type + 1,
      };
      dispatch(CorpReservService.calculate_price(values, resolve));
    }).then((res) => {
      setGrandTotalPrice(res?.data?.total_price);
      setGroupPrice(res?.data?.price_service_original);
      setTransportPrice(res?.data?.transportPrice);
      setDataCheckout({...formik?.values, ...res?.data});
      setPassengersAdult( 
        formik?.values?.passenger_list?.filter(
          (item) => item?.title === "Mr." || item?.title === "Mrs." || item?.title === "Ms."
        ).length
      );
      setPassengersChild(
        formik?.values?.passenger_list?.filter(
          (item) => item?.title === "Child"
        ).length
      );
      setPassengersInfant( 
        formik?.values?.passenger_list?.filter(
          (item) => item?.title === "Infant"
        ).length
      );
      formik.setFieldValue("total_service_price", res?.data?.price_service);
      formik.setFieldValue("total_lounge_price", res?.data?.totalLoungePrice);
      formik.setFieldValue("total_transport_price", res?.data?.totalTransportPrice);
    });
  };

  const numberWithCommas = (x) => {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const toggleLounge = () => {
    setShowLounge(!showLounge);
  };

  const toggleTransport = () => {
    setShowTransport(!showTransport);
  };

  const togglePriceService = () => {
    setShowPriceService(!showPriceService);
  };

  const handleDeleteAsst = (formik) => {
    formik?.setFieldValue("assistant_list", null);
  };
  
  const handleAddAssistant = (param) => {
    formik?.setFieldValue("assistant_list", param?.assistant_list);
    setDataCheckout({...dataCheckout, assistant_list: param?.assistant_list});
  };

  const infoTax = (
    id,
    include_ppn,
    include_concession,
    ppn_percentage,
    concession_percentage,
    ppn_price,
    concession_price
  ) => {
    return (
      <>
        <span
          className="material-icons"
          style={{ fontSize: 18 }}
          data-tip
          data-for={id}
        >
          info_outlined
        </span>
        <ReactTooltip id={id} place="top" effect="solid">
          <p style={{ whiteSpace: "pre-line" }}>
            {(include_ppn && !include_concession && ppn_percentage !== null) ||
            (include_ppn &&
              include_concession &&
              concession_percentage === null &&
              ppn_percentage !== null) ? (
              <span>
                {id ==='taxOthers' && 
                formik?.values?.additional_service_dpp !== null &&
                formik?.values?.additional_service_dpp !== 0
                  ? `${t("product.includes_ppn")} ${ppn_percentage}% of DPP Rp ${
                      numberWithCommas(formik?.values?.additional_service_dpp)}, Rp ${
                      numberWithCommas(ppn_price)
                    }`
                  : `${t("product.includes_ppn")} ${ppn_percentage}%, Rp ${numberWithCommas(ppn_price)}`
                }
              </span>
            ) : !include_ppn &&
              include_concession &&
              concession_percentage !== null ? (
              <span>
                {id ==='taxOthers' && 
                formik?.values?.additional_service_dpp !== null &&
                formik?.values?.additional_service_dpp !== 0
                  ? `${t("product.includes_concession")} ${concession_percentage}% of DPP Rp ${
                      numberWithCommas(formik?.values?.additional_service_dpp)}, Rp ${
                      numberWithCommas(concession_price)
                    }`
                  : `${t("product.includes_concession")} ${concession_percentage}%, Rp ${
                      numberWithCommas(concession_price)
                    }`
                }
              </span>
            ) : include_ppn &&
              include_concession &&
              concession_percentage !== null &&
              ppn_percentage !== null ? (
              <span>
                {id ==='taxOthers' && 
                formik?.values?.additional_service_dpp !== null &&
                formik?.values?.additional_service_dpp !== 0
                  ? `${t("product.includes_ppn")} ${ppn_percentage}% of DPP Rp ${
                      numberWithCommas(formik?.values?.additional_service_dpp)}, Rp ${
                      numberWithCommas(ppn_price)} & ${t("product.includes_concession")} ${
                      concession_percentage}% of DPP Rp ${
                      numberWithCommas(formik?.values?.additional_service_dpp)}, Rp ${numberWithCommas(
                      concession_price
                    )}`
                  : `${t(
                      "product.includes_ppn"
                    )} ${ppn_percentage}%, Rp ${numberWithCommas(ppn_price)} & ${t(
                      "product.includes_concession"
                    )} ${concession_percentage}%, Rp ${numberWithCommas(
                      concession_price
                    )}`
                }
              </span>
            ) : (
              ""
            )}
          </p>
        </ReactTooltip>
      </>
    );
  };

  return (
    <>
      <Row>
        <Col md={9}>
          <Card className="rounded">
            <CardHeader title="Booking Resume" />
            <CardBody>
              <CardFlush
                list
                label={t("field.flightType")}
                desc={formik.values?.flight_type === 0
                  ? t("field.arrival")
                  : formik.values?.flight_type === 1
                  ? t("field.departure")
                  : "Transfer"
                }
                size={{ label: "5", desc: "5" }}
              />
              <CardFlush
                list
                label={
                  formik.values.flight_type === 0 ||
                  formik.values.flight_type === 2
                    ? t("field.arrvAirline") + " / No."
                    : t("field.airline") + " / No."
                }
                desc={
                  formik.values?.airplane_name +
                  " / " +
                  formik?.values?.airplane_code +
                  formik.values?.airplane_number
                }
                size={{ label: "5", desc: "5" }}
              />
              {formik.values?.flight_type === 2 && (
                <CardFlush
                  list
                  label={t("field.deptAirline") + " / No."}
                  desc={
                    formik.values?.airplane_name_transfer_to +
                    " / " +
                    formik?.values?.airplane_code_transfer +
                    formik.values?.airplane_number_transfer_to
                  }
                  size={{ label: "5", desc: "5" }}
                />
              )}
              <CardFlush
                list
                label={t("field.origin")}
                desc={formik.values?.airport_uniform_from !== "Indonesia"
                  ? formik.values?.airport_name_from +
                    "-" +
                    formik.values?.airport_uniform_from
                  : formik.values?.airport_name_from
                }
                size={{ label: "5", desc: "5" }}
              />
              {formik.values?.flight_type === 2 && (
                <CardFlush
                  list
                  label={t("Transfer")}
                  desc={
                    formik.values?.airport_uniform_transfer !== "Indonesia"
                      ? formik.values?.airport_name_transfer +
                        "-" +
                        formik.values?.airport_uniform_transfer
                      : formik.values?.airport_name_transfer
                  }
                  size={{ label: "5", desc: "5" }}
                />
              )}
              {
                (data = [
                  {
                    label: t("field.destination"),
                    desc:
                      formik.values?.airport_uniform_to !== "Indonesia"
                        ? formik.values?.airport_name_to +
                          "-" +
                          formik.values?.airport_uniform_to
                        : formik.values?.airport_name_to,
                  },
                  {
                    label:
                      formik.values.flight_type === 0 ||
                      formik.values.flight_type === 2
                        ? t("field.arrivalTime")
                        : t("field.departureTime"),
                    desc:
                      moment(formik.values.date).format("DD/MM/YYYY") +
                      ", " +
                      formik.values.time,
                  },
                ].map((item, i) => (
                  <CardFlush
                    list
                    label={item.label}
                    desc={item.desc}
                    size={{ label: "5", desc: "5" }}
                  />
                )))
              }
              {formik.values?.flight_type === 2 && (
                <CardFlush
                  list
                  label={t("field.departureTime")}
                  desc={
                    moment(formik.values?.date_transfer_to).format(
                      "DD/MM/YYYY"
                    ) +
                    ", " +
                    formik.values?.time_transfer_to
                  }
                  size={{ label: "5", desc: "5" }}
                />
              )}
            </CardBody>
          </Card>
          <Card className="rounded">
            <CardHeader title={t("field.additionalService")} />
            <CardBody>
              <div className="d-flex justify-content-left">
                <div
                  className="rounded-fill"
                  style={{
                    backgroundColor:
                      dataCheckout?.lounge !== "" && 
                      (dataCheckout?.lounge_adult_number > 0 ||
                      dataCheckout?.lounge_child_number > 0 ||
                      dataCheckout?.lounge_infant_number > 0)
                        ? "#ecf5eb" 
                        : "#fff",
                    border:
                      dataCheckout?.lounge !== "" && 
                      (dataCheckout?.lounge_adult_number > 0 ||
                      dataCheckout?.lounge_child_number > 0 ||
                      dataCheckout?.lounge_infant_number > 0)
                        ? "1px solid #63AE5C"
                        : "1px solid rgb(165, 165, 192)",
                  }}
                >
                  <span
                    className="material-icons"
                    style={{
                      fontSize: 25,
                      color:
                        dataCheckout?.lounge !== "" && 
                        (dataCheckout?.lounge_adult_number > 0 ||
                        dataCheckout?.lounge_child_number > 0 ||
                        dataCheckout?.lounge_infant_number > 0)
                          ? "#63ae5c" 
                          : "#A5A5C0",
                    }}
                  >
                    chair
                  </span>
                </div>
                <div className="d-flex flex-column ml-3">
                  <div className="text-bold">Lounge</div>
                  <div className="normal-title">
                    {dataCheckout?.lounge !== "" && 
                      (dataCheckout?.lounge_adult_number > 0 ||
                      dataCheckout?.lounge_child_number > 0 ||
                      dataCheckout?.lounge_infant_number > 0)
                        ? dataCheckout.lounge_name
                        : t("commons.none")}
                  </div>
                </div>
              </div>
              <div className="my-3" />
              <div className="d-flex justify-content-left">
                <div
                  className="rounded-fill"
                  style={{
                    backgroundColor:
                      dataCheckout.car_type !== ""
                        ? "#ecf5eb"
                        : "#fff",
                    border:
                      dataCheckout.car_type !== ""
                        ? "1px solid #63AE5C"
                        : "1px solid rgb(165, 165, 192)",
                  }}
                >
                  <span
                    className="material-icons"
                    style={{
                      fontSize: 25,
                      color:
                        dataCheckout.car_type !== ""
                          ? "#63ae5c"
                          : "#A5A5C0",
                    }}
                  >
                    local_taxi
                  </span>
                </div>
                <div className="d-flex flex-column ml-3">
                  <div className="text-bold">{t("field.transportation")}</div>
                  <div className="normal-title">
                    {dataCheckout?.car_type !== ""
                      ? dataCheckout?.orderExtras?.car?.name +
                        " (" +
                        dataCheckout?.total_unit +
                        ")"
                      : t("commons.none")}
                  </div>
                </div>
              </div>
              <div className="my-3" />
              <div className="d-flex justify-content-left">
                <div
                  className="rounded-fill"
                  style={{
                    backgroundColor:
                      (formik?.values?.additional_service_description !== "" &&
                      formik?.values?.additional_service_concession_percentage !== "" &&
                      formik?.values?.additional_service_ppn_percentage !== "" &&
                      formik?.values?.additional_service_price > 0)
                        ? "#ecf5eb"
                        : "#fff",
                    border:
                      (formik?.values?.additional_service_description !== "" &&
                      formik?.values?.additional_service_concession_percentage !== "" &&
                      formik?.values?.additional_service_ppn_percentage !== "" &&
                      formik?.values?.additional_service_price > 0)
                        ? "1px solid #63AE5C"
                        : "1px solid rgb(165, 165, 192)",
                  }}
                >
                  <span
                    className="material-icons"
                    style={{
                      fontSize: 25,
                      color:
                        (formik?.values?.additional_service_description !== "" &&
                        formik?.values?.additional_service_concession_percentage !== "" &&
                        formik?.values?.additional_service_ppn_percentage !== "" &&
                        formik?.values?.additional_service_price > 0)
                          ? "#63ae5c"
                          : "#A5A5C0",
                    }}
                  >
                    loupe
                  </span>
                </div>
                <div className="d-flex flex-column ml-3">
                  <div className="text-bold">Others</div>
                  <div className="normal-title">
                    {(formik?.values?.additional_service_description !== "" &&
                        formik?.values?.additional_service_concession_percentage !== "" &&
                        formik?.values?.additional_service_ppn_percentage !== "" &&
                        formik?.values?.additional_service_price > 0)
                      ? formik?.values?.additional_service_description
                      : t("commons.none")}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          {role !== "corporate" && (
            <Card className="rounded">
              <CardHeader title={t("booking.assistantAssign")} />
              <CardBody>
                {formik?.values?.assistant_list === null ||
                formik?.values?.assistant_list === undefined ? (
                  <div
                    className={`border px-4 py-3 rounded 
                      ${formik?.values?.date <=
                          moment().add(1, "days").format("YYYY-MM-DD")
                          ? "clickable"
                          : "disable"
                      }`}
                    onClick={() => setAssistantModal(true)}
                  >
                    <div className="d-flex flex-column">
                      <div className="normal-title pb-1 text-extra-bold">
                        {t("field.assistantNotChosen")}
                      </div>
                      <div className="normal-title">
                        {t("field.selectAssistant")}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="px-4 py-3 rounded card-activated d-flex justify-content-between clickable"
                  >
                    <div
                      className="d-flex flex-column"
                      onClick={() => setAssistantModal(true)}
                    >
                      <div className="normal-title pb-1 text-extra-bold">
                        {formik?.values?.assistant_list
                          ?.map((item) => item?.assistant_name)
                          .join(", ")}
                      </div>
                      <div className="normal-title">
                        {`${t("field.main_assistant")} : ${
                          formik?.values?.assistant_list?.find(
                            (item) => item?.is_main === true
                          )?.assistant_name
                        }`}
                      </div>
                    </div>
                    <div
                      className="trigger"
                      onClick={() => handleDeleteAsst(formik)}
                    >
                      <span className="material-icons-outlined">close</span>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          )}
          <Card className="rounded">
            <CardHeader title={t("field.detailPrice")} />
            <div className="d-flex card-body justify-content-between py-3">
              <div className="normal-title text-extra-bold">
                {t("field.grandTotal")}
              </div>
              <div className="normal-title text-extra-bold">
                Rp {numberWithCommas(grandTotalPrice)}
              </div>
            </div>
            <div className="card-body border-top py-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {dataCheckout?.corporateType === "MOB" && (
                  dataCheckout?.mobAdultUnderQuotaNumber !== 0 ||
                  dataCheckout?.mobChildUnderQuotaNumber !== 0 ||
                  dataCheckout?.mobInfantUnderQuotaNumber !== 0
                ) ? (
                  <>
                  <div className="normal-title text-extra-bold">
                    {`MOB ${t("field.priceUnderQuota")} ${formik?.values?.product_name}`}
                  </div>
                  <div
                    onClick={() => setShowPriceUnderQuota(!showPriceUnderQuota)}
                    className="d-flex align-items-center"
                    style={{ marginRight: "-30px", cursor: "pointer" }}
                  >
                    <span className="normal-title text-extra-bold mr-2">
                      Rp{" "}
                      {numberWithCommas(dataCheckout?.price_service)}
                    </span>
                    <span className="material-icons-round left-icon">
                      expand_more
                    </span>
                  </div>
                  </>
                ) : (dataCheckout?.corporateType === "MOB" &&
                  dataCheckout?.mobAdultUnderQuotaNumber === 0 &&
                  dataCheckout?.mobChildUnderQuotaNumber === 0 &&
                  dataCheckout?.mobInfantUnderQuotaNumber === 0 &&
                  dataCheckout?.mobAdultIndividualNumber === 0 &&
                  dataCheckout?.mobChildIndividualNumber === 0 &&
                  dataCheckout?.mobInfantIndividualNumber === 0) ? (
                    <>
                    <div className="d-flex align-items-center normal-title text-extra-bold">
                      {t("field.MOBPrice")} {formik?.values?.product_name}
                      <span
                        className="material-icons"
                        style={{ fontSize: 18 }}
                        data-tip
                        data-for="includeMOBQuota"
                      >
                        info_outlined
                      </span>
                      <ReactTooltip id="includeMOBQuota" place="top" effect="solid">
                        <p style={{ whiteSpace: "pre-line" }}>
                          {t("booking.priceMOBQuota")}
                        </p>
                      </ReactTooltip>
                    </div>
                    <div
                      className="normal-title"
                      style={{ cursor: "pointer" }}
                    >
                      Rp 0
                    </div>
                    </>
                ) : (
                  <>
                  <div className="normal-title text-extra-bold">
                    {dataCheckout?.corporateType === "Group"
                      ? `${t("field.groupPrice")} ${formik?.values?.product_name}`
                      : dataCheckout?.corporateType === "Package"
                      ? `${t("field.packagePrice")} ${formik?.values?.product_name}`
                      : dataCheckout?.corporateType === "FIT"
                      ? `${t("field.FITPrice")} ${formik?.values?.product_name}`
                      : `${t("field.individualPrice")} ${formik?.values?.product_name}`}
                  </div>
                  <div
                    onClick={togglePriceService}
                    className="d-flex align-items-center"
                    style={{ marginRight: "-30px", cursor: "pointer" }}
                  >
                    <span className="normal-title text-extra-bold mr-2">
                      Rp{" "}
                      {numberWithCommas(dataCheckout?.price_service)}
                    </span>
                    <span className="material-icons-round left-icon">
                      expand_more
                    </span>
                  </div>
                  </>
                )}
              </div>
              {showPriceUnderQuota && (
                <>
                {dataCheckout?.mobAdultUnderQuotaNumber !== 0 && (
                  <>
                    <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                      {t("commons.adult")}
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>
                        <div className="d-flex align-items-center normal-text">
                          {t("field.price")}
                          {(dataCheckout?.isIncludePpn ||
                            dataCheckout?.isIncludeConcession) && ( 
                            <>
                              {infoTax('taxIndividualAdult', 
                                dataCheckout?.isIncludePpn,
                                dataCheckout?.isIncludeConcession,
                                dataCheckout?.ppnPercentage,
                                dataCheckout?.concessionPercentage,
                                dataCheckout?.ppnAdultPrice,
                                dataCheckout?.concessionAdultPrice
                              )}
                            </>
                          )}
                        </div>
                        {!dataCheckout?.isIncludePpn && (
                          <div className="normal-text mt-1">
                            {`PPN ${dataCheckout?.ppnPercentage}%`}
                          </div>
                        )}
                        {!dataCheckout?.isIncludeConcession && (
                          <div className="normal-text mt-1">
                            {`Concession ${dataCheckout?.concessionPercentage}%`}
                          </div>
                        )}
                      </div>
                      <div className="text-right">
                        <div className="normal-text">
                          {dataCheckout?.mobAdultUnderQuotaNumber + " "}
                          <span>
                            {" x  Rp " +
                              numberWithCommas(dataCheckout?.adultPriceOriginalUnderQuota)}
                          </span>
                        </div>
                        {!dataCheckout?.isIncludePpn && (
                          <div className="normal-text mt-1">
                            {"Rp " +
                              numberWithCommas(
                                dataCheckout?.ppnAdultPrice
                              )}
                          </div>
                        )}
                        {!dataCheckout?.isIncludeConcession && (
                          <div className="normal-text mt-1">
                            {"Rp " +
                              numberWithCommas(
                                dataCheckout?.concessionAdultPrice
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {dataCheckout?.mobChildUnderQuotaNumber !== 0 && (
                  <>
                    <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                      {t("commons.child")}
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>
                        <div className="d-flex align-items-center normal-text">
                          {t("field.price")}
                          {(dataCheckout?.isIncludePpn ||
                            dataCheckout?.isIncludeConcession) && ( 
                            <>
                              {infoTax('taxIndividualChild',
                                dataCheckout?.isIncludePpn,
                                dataCheckout?.isIncludeConcession,
                                dataCheckout?.ppnPercentage,
                                dataCheckout?.concessionPercentage,
                                dataCheckout?.ppnChildPrice,
                                dataCheckout?.concessionChildPrice
                              )}
                            </>
                          )}
                        </div>
                        {!dataCheckout?.isIncludePpn && (
                          <div className="normal-text mt-1">
                            {`PPN ${dataCheckout?.ppnPercentage}%`}
                          </div>
                        )}
                        {!dataCheckout?.isIncludeConcession && (
                          <div className="normal-text mt-1">
                            {`Concession ${dataCheckout?.concessionPercentage}%`}
                          </div>
                        )}
                      </div>
                      <div className="text-right">
                        <div className="normal-text">
                          {dataCheckout?.mobChildUnderQuotaNumber + " "}
                          <span>
                            {" x  Rp " + 
                              numberWithCommas(dataCheckout?.childPriceOriginalUnderQuota)}
                          </span>
                        </div>
                        {!dataCheckout?.isIncludePpn && (
                          <div className="normal-text mt-1">
                            {"Rp " +
                              numberWithCommas(dataCheckout?.ppnChildPrice)}
                          </div>
                        )}
                        {!dataCheckout?.isIncludeConcession && (
                          <div className="normal-text mt-1">
                            {"Rp " +
                              numberWithCommas(
                                dataCheckout?.concessionChildPrice
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {dataCheckout?.mobInfantUnderQuotaNumber !== 0 && (
                  <>
                    <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                      {t("commons.infantLabel")}
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>
                        <div className="d-flex align-items-center normal-text">
                          {t("field.price")}
                          {(dataCheckout?.isIncludePpn ||
                            dataCheckout?.isIncludeConcession) && ( 
                            <>
                              {infoTax('taxIndividualInfant',
                                dataCheckout?.isIncludePpn,
                                dataCheckout?.isIncludeConcession,
                                dataCheckout?.ppnPercentage,
                                dataCheckout?.concessionPercentage,
                                dataCheckout?.ppnInfantPrice,
                                dataCheckout?.concessionInfantPrice
                              )}
                            </>
                          )}
                        </div>
                        {!dataCheckout?.isIncludePpn && (
                          <div className="normal-text mt-1">
                            {`PPN ${dataCheckout?.ppnPercentage}%`}
                          </div>
                        )}
                        {!dataCheckout?.isIncludeConcession && (
                          <div className="normal-text mt-1">
                            {`Concession ${dataCheckout?.concessionPercentage}%`}
                          </div>
                        )}
                      </div>
                      <div className="text-right">
                        <div className="normal-text">
                          {dataCheckout?.mobInfantUnderQuotaNumber + " "}
                          <span>
                            {" x  Rp " +
                              numberWithCommas(dataCheckout?.infantPriceOriginalUnderQuota)}
                          </span>
                        </div>
                        {!dataCheckout?.isIncludePpn && (
                          <div className="normal-text mt-1">
                            {"Rp " +
                              numberWithCommas(
                                dataCheckout?.ppnInfantPrice
                              )}
                          </div>
                        )}
                        {!dataCheckout?.isIncludeConcession && (
                          <div className="normal-text mt-1">
                            {"Rp " +
                              numberWithCommas(
                                dataCheckout?.concessionInfantPrice
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                </>
              )}
              {showPriceService && (
                <>
                {dataCheckout?.corporateType === "FIT" || dataCheckout?.corporateType === "Group" ? (
                  <>
                  {passengersAdult !== 0 && (
                    <>
                      <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                        {t("commons.adult")}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center normal-text">
                            {t("field.price")}
                            {(dataCheckout?.isIncludePpn ||
                              dataCheckout?.isIncludeConcession) && ( 
                              <>
                                {infoTax('taxAdult', 
                                  dataCheckout?.isIncludePpn,
                                  dataCheckout?.isIncludeConcession,
                                  dataCheckout?.ppnPercentage,
                                  dataCheckout?.concessionPercentage,
                                  dataCheckout?.ppnAdultPrice,
                                  dataCheckout?.concessionAdultPrice
                                )}
                              </>
                            )}
                          </div>
                          {!dataCheckout?.isIncludePpn && (
                            <div className="normal-text mt-1">
                              {`PPN ${dataCheckout?.ppnPercentage}%`}
                            </div>
                          )}
                          {!dataCheckout?.isIncludeConcession && (
                            <div className="normal-text mt-1">
                              {`Concession ${dataCheckout?.concessionPercentage}%`}
                            </div>
                          )}
                        </div>
                        <div className="text-right">
                          <div className="normal-text">
                            {passengersAdult + " "}
                            <span>
                              {" x  Rp " +
                                numberWithCommas(dataCheckout.adultPriceOriginal)}
                            </span>
                          </div>
                          {!dataCheckout?.isIncludePpn && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  dataCheckout?.ppnAdultPrice
                                )}
                            </div>
                          )}
                          {!dataCheckout?.isIncludeConcession && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  dataCheckout?.concessionAdultPrice
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {passengersChild !== 0 && (
                    <>
                      <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                        {t("commons.child")}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center normal-text">
                            {t("field.price")}
                            {(dataCheckout?.isIncludePpn ||
                              dataCheckout?.isIncludeConcession) && ( 
                              <>
                                {infoTax('taxChild',
                                  dataCheckout?.isIncludePpn,
                                  dataCheckout?.isIncludeConcession,
                                  dataCheckout?.ppnPercentage,
                                  dataCheckout?.concessionPercentage,
                                  dataCheckout?.ppnChildPrice,
                                  dataCheckout?.concessionChildPrice
                                )}
                              </>
                            )}
                          </div>
                          {!dataCheckout?.isIncludePpn && (
                            <div className="normal-text mt-1">
                              {`PPN ${dataCheckout?.ppnPercentage}%`}
                            </div>
                          )}
                          {!dataCheckout?.isIncludeConcession && (
                            <div className="normal-text mt-1">
                              {`Concession ${dataCheckout?.concessionPercentage}%`}
                            </div>
                          )}
                        </div>
                        <div className="text-right">
                          <div className="normal-text">
                            {passengersChild + " "}
                            <span>
                              {" x  Rp " + numberWithCommas(dataCheckout.childPriceOriginal)}
                            </span>
                          </div>
                          {!dataCheckout?.isIncludePpn && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(dataCheckout?.ppnChildPrice)}
                            </div>
                          )}
                          {!dataCheckout?.isIncludeConcession && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  dataCheckout?.concessionChildPrice
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {passengersInfant !== 0 && (
                    <>
                      <div className="subtitle-text txt-medium text-primary mt-2 mb-1">
                        {t("commons.infantLabel")}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center normal-text">
                            {t("field.price")}
                            {(dataCheckout?.isIncludePpn ||
                              dataCheckout?.isIncludeConcession) && ( 
                              <>
                                {infoTax('taxInfant',
                                  dataCheckout?.isIncludePpn,
                                  dataCheckout?.isIncludeConcession,
                                  dataCheckout?.ppnPercentage,
                                  dataCheckout?.concessionPercentage,
                                  dataCheckout?.ppnInfantPrice,
                                  dataCheckout?.concessionInfantPrice
                                )}
                              </>
                            )}
                          </div>
                          {!dataCheckout?.isIncludePpn && (
                            <div className="normal-text mt-1">
                              {`PPN ${dataCheckout?.ppnPercentage}%`}
                            </div>
                          )}
                          {!dataCheckout?.isIncludeConcession && (
                            <div className="normal-text mt-1">
                              {`Concession ${dataCheckout?.concessionPercentage}%`}
                            </div>
                          )}
                        </div>
                        <div className="text-right">
                          <div className="normal-text">
                            {passengersInfant + " "}
                            <span>
                              {" x  Rp " + numberWithCommas(dataCheckout?.infantPriceOriginal)}
                            </span>
                          </div>
                          {!dataCheckout?.isIncludePpn && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  dataCheckout?.ppnInfantPrice
                                )}
                            </div>
                          )}
                          {!dataCheckout?.isIncludeConcession && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  dataCheckout?.concessionInfantPrice
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  </>
                ) : dataCheckout?.corporateType === "Package" ? (
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="d-flex align-items-center normal-text mt-2">
                        {t("field.price")}
                        {(dataCheckout?.isIncludePpn ||
                          dataCheckout?.isIncludeConcession) && ( 
                          <>
                            {infoTax('taxGroup', 
                              dataCheckout?.isIncludePpn,
                              dataCheckout?.isIncludeConcession,
                              dataCheckout?.ppnPercentage,
                              dataCheckout?.concessionPercentage,
                              dataCheckout?.ppnTaxPrice,
                              dataCheckout?.concessionTaxPrice
                            )}
                          </>
                        )}
                      </div>
                      {!dataCheckout?.isIncludePpn && (
                        <div className="normal-text mt-1">
                          {`PPN ${dataCheckout?.ppnPercentage}%`}
                        </div>
                      )}
                      {!dataCheckout?.isIncludeConcession && (
                        <div className="normal-text mt-1">
                          {`Concession ${dataCheckout?.concessionPercentage}%`}
                        </div>
                      )}
                    </div>
                    <div className="text-right">
                      <div className="normal-text mt-2">
                        {"Rp " + numberWithCommas(groupPrice)}
                      </div>
                      {!dataCheckout?.isIncludePpn && (
                        <div className="normal-text mt-1">
                          {"Rp " +
                            numberWithCommas(
                              dataCheckout?.ppnTaxPrice
                            )}
                        </div>
                      )}
                      {!dataCheckout?.isIncludeConcession && (
                        <div className="normal-text mt-1">
                          {"Rp " +
                            numberWithCommas(
                              dataCheckout?.concessionTaxPrice
                            )}
                        </div>
                      )}
                    </div>
                  </div>
                ) : dataCheckout?.corporateType === "MOB" &&
                  (dataCheckout?.mobAdultIndividualNumber !== 0 ||
                  dataCheckout?.mobChildIndividualNumber !== 0 ||
                  dataCheckout?.mobInfantIndividualNumber !== 0) ? (
                  <>
                  {dataCheckout?.mobAdultIndividualNumber !== 0 && (
                    <>
                      <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                        {t("commons.adult")}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center normal-text">
                            {t("field.price")}
                            {(dataCheckout?.isIncludePpn ||
                              dataCheckout?.isIncludeConcession) && ( 
                              <>
                                {infoTax('taxIndividualAdult', 
                                  dataCheckout?.isIncludePpn,
                                  dataCheckout?.isIncludeConcession,
                                  dataCheckout?.ppnPercentage,
                                  dataCheckout?.concessionPercentage,
                                  dataCheckout?.ppnAdultPrice,
                                  dataCheckout?.concessionAdultPrice
                                )}
                              </>
                            )}
                          </div>
                          {!dataCheckout?.isIncludePpn && (
                            <div className="normal-text mt-1">
                              {`PPN ${dataCheckout?.ppnPercentage}%`}
                            </div>
                          )}
                          {!dataCheckout?.isIncludeConcession && (
                            <div className="normal-text mt-1">
                              {`Concession ${dataCheckout?.concessionPercentage}%`}
                            </div>
                          )}
                        </div>
                        <div className="text-right">
                          <div className="normal-text">
                            {dataCheckout?.mobAdultIndividualNumber + " "}
                            <span>
                              {" x  Rp " +
                                numberWithCommas(dataCheckout.adultPriceOriginal)}
                            </span>
                          </div>
                          {!dataCheckout?.isIncludePpn && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  dataCheckout?.ppnAdultPrice
                                )}
                            </div>
                          )}
                          {!dataCheckout?.isIncludeConcession && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  dataCheckout?.concessionAdultPrice
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {dataCheckout?.mobChildIndividualNumber !== 0 && (
                    <>
                      <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                        {t("commons.child")}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center normal-text">
                            {t("field.price")}
                            {(dataCheckout?.isIncludePpn ||
                              dataCheckout?.isIncludeConcession) && ( 
                              <>
                                {infoTax('taxIndividualChild',
                                  dataCheckout?.isIncludePpn,
                                  dataCheckout?.isIncludeConcession,
                                  dataCheckout?.ppnPercentage,
                                  dataCheckout?.concessionPercentage,
                                  dataCheckout?.ppnChildPrice,
                                  dataCheckout?.concessionChildPrice
                                )}
                              </>
                            )}
                          </div>
                          {!dataCheckout?.isIncludePpn && (
                            <div className="normal-text mt-1">
                              {`PPN ${dataCheckout?.ppnPercentage}%`}
                            </div>
                          )}
                          {!dataCheckout?.isIncludeConcession && (
                            <div className="normal-text mt-1">
                              {`Concession ${dataCheckout?.concessionPercentage}%`}
                            </div>
                          )}
                        </div>
                        <div className="text-right">
                          <div className="normal-text">
                            {dataCheckout?.mobChildIndividualNumber + " "}
                            <span>
                              {" x  Rp " + 
                                numberWithCommas(dataCheckout.childPriceOriginal )}
                            </span>
                          </div>
                          {!dataCheckout?.isIncludePpn && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(dataCheckout?.ppnChildPrice)}
                            </div>
                          )}
                          {!dataCheckout?.isIncludeConcession && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  dataCheckout?.concessionChildPrice
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {dataCheckout?.mobInfantIndividualNumber !== 0 && (
                    <>
                      <div className="subtitle-text txt-medium text-primary mt-2 mb-1">
                        {t("commons.infantLabel")}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center normal-text">
                            {t("field.price")}
                            {(dataCheckout?.isIncludePpn ||
                              dataCheckout?.isIncludeConcession) && ( 
                              <>
                                {infoTax('taxIndividualInfant',
                                  dataCheckout?.isIncludePpn,
                                  dataCheckout?.isIncludeConcession,
                                  dataCheckout?.ppnPercentage,
                                  dataCheckout?.concessionPercentage,
                                  dataCheckout?.ppnInfantPrice,
                                  dataCheckout?.concessionInfantPrice
                                )}
                              </>
                            )}
                          </div>
                          {!dataCheckout?.isIncludePpn && (
                            <div className="normal-text mt-1">
                              {`PPN ${dataCheckout?.ppnPercentage}%`}
                            </div>
                          )}
                          {!dataCheckout?.isIncludeConcession && (
                            <div className="normal-text mt-1">
                              {`Concession ${dataCheckout?.concessionPercentage}%`}
                            </div>
                          )}
                        </div>
                        <div className="text-right">
                          <div className="normal-text">
                            {dataCheckout?.mobInfantIndividualNumber + " "}
                            <span>
                              {" x  Rp " +
                                numberWithCommas(dataCheckout?.infantPriceOriginal)}
                            </span>
                          </div>
                          {!dataCheckout?.isIncludePpn && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  dataCheckout?.ppnInfantPrice
                                )}
                            </div>
                          )}
                          {!dataCheckout?.isIncludeConcession && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  dataCheckout?.concessionInfantPrice
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  </>
                ) : (<></>)} 
                </>
              )}
            </div>
            <div className="card-body border-top py-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="normal-title text-extra-bold">
                  {t("field.lounge")}
                </div>
                {formik?.values?.lounge ? (
                  <div
                    onClick={toggleLounge}
                    className="d-flex align-items-center"
                    style={{ marginRight: "-30px", cursor: "pointer" }}
                  >
                    <span className="normal-title text-extra-bold mr-2">
                      Rp{" "}
                      {numberWithCommas(dataCheckout?.totalLoungePrice)}
                    </span>
                    <span className="material-icons-round left-icon">
                      expand_more
                    </span>
                  </div>
                ) : (
                  <div
                    className="normal-title text-extra-bold"
                    style={{ cursor: "pointer" }}
                  >
                    Rp 0
                  </div>
                )}
              </div>
              {showLounge && (
                <>
                  {formik?.values?.lounge_adult_number !== "" &&
                    formik?.values?.lounge_adult_number !== 0 && (
                      <>
                        <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                          {t("commons.adult")}
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <div className="d-flex align-items-center normal-text mb-1">
                              {t("field.price")}
                              {(dataCheckout?.isIncludePpnLounge ||
                              dataCheckout?.isIncludeConcessionLounge) && ( 
                                <>
                                  {infoTax('taxLoungeAdult', 
                                    dataCheckout?.isIncludePpnLounge,
                                    dataCheckout?.isIncludeConcessionLounge,
                                    dataCheckout?.ppnPercentageLounge,
                                    dataCheckout?.concessionPercentageLounge,
                                    dataCheckout?.ppnAdultLoungePrice,
                                    dataCheckout?.concessionAdultLoungePrice
                                  )}
                                </>
                              )}
                            </div>
                            {!dataCheckout?.isIncludePpnLounge && (
                              <div className="normal-text mt-1">
                                {`PPN ${dataCheckout?.ppnPercentageLounge}%`}
                              </div>
                            )}
                            {!dataCheckout?.isIncludeConcessionLounge && (
                              <div className="normal-text mt-1">
                                {`Concession ${dataCheckout?.concessionPercentageLounge}%`}
                              </div>
                            )}
                          </div>
                          <div className="text-right">
                            <div className="normal-text mb-1">
                              {formik?.values?.lounge_adult_number + " "}
                              <span>
                                {" x  Rp " +
                                  numberWithCommas(
                                    formik?.values?.lounge_price_adult
                                  )}
                              </span>
                            </div>
                            {!dataCheckout?.isIncludePpnLounge && (
                              <div className="normal-text mt-1">
                                {"Rp " +
                                  numberWithCommas(
                                    dataCheckout?.ppnAdultLoungePrice
                                  )}
                              </div>
                            )}
                            {!dataCheckout?.isIncludeConcessionLounge && (
                              <div className="normal-text mt-1">
                                {"Rp " +
                                  numberWithCommas(
                                    dataCheckout?.concessionAdultLoungePrice
                                  )}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  {formik?.values?.lounge_child_number !== "" &&
                    formik?.values?.lounge_child_number !== 0 && (
                      <>
                        <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                          {t("commons.child")}
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <div className="d-flex align-items-center normal-text mb-1">
                              {t("field.price")}
                              {(dataCheckout?.isIncludePpnLounge||
                              dataCheckout?.isIncludeConcessionLounge) && ( 
                                <>
                                  {infoTax('taxLoungeChild', 
                                    dataCheckout?.isIncludePpnLounge,
                                    dataCheckout?.isIncludeConcessionLounge,
                                    dataCheckout?.ppnPercentageLounge,
                                    dataCheckout?.concessionPercentageLounge,
                                    dataCheckout?.ppnChildLoungePrice,
                                    dataCheckout?.concessionChildLoungePrice
                                  )}
                                </>
                              )}
                            </div>
                            {!dataCheckout?.isIncludePpnLounge && (
                              <div className="normal-text mt-1">
                                {`PPN ${dataCheckout?.ppnPercentageLounge}%`}
                              </div>
                            )}
                            {!dataCheckout?.isIncludeConcessionLounge && (
                              <div className="normal-text mt-1">
                                {`Concession ${dataCheckout?.concessionPercentageLounge}%`}
                              </div>
                            )}
                          </div>
                          <div className="text-right">
                            <div className="normal-text mb-1">
                              {formik?.values?.lounge_child_number + " "}
                              <span>
                                {" x  Rp " +
                                  numberWithCommas(
                                    formik?.values?.lounge_price_child
                                  )}
                              </span>
                            </div>
                            {!dataCheckout?.isIncludePpnLounge && (
                              <div className="normal-text mt-1">
                                {"Rp " +
                                  numberWithCommas(
                                    dataCheckout?.ppnChildLoungePrice
                                  )}
                              </div>
                            )}
                            {!dataCheckout?.isIncludeConcessionLounge && (
                              <div className="normal-text mt-1">
                                {"Rp " +
                                  numberWithCommas(
                                    dataCheckout?.concessionChildLoungePrice
                                  )}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  {formik?.values?.lounge_infant_number !== "" &&
                    formik?.values?.lounge_infant_number !== 0 && (
                      <>
                        <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                          {t("commons.infantLabel")}
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <div className="normal-text mb-1">
                              {t("field.price")}
                              {(dataCheckout?.isIncludePpnLounge||
                                dataCheckout?.isIncludeConcessionLounge) && ( 
                                <>
                                  {infoTax('taxLoungeInfant', 
                                    dataCheckout?.isIncludePpnLounge,
                                    dataCheckout?.isIncludeConcessionLounge,
                                    dataCheckout?.ppnPercentageLounge,
                                    dataCheckout?.concessionPercentageLounge,
                                    dataCheckout?.ppnInfantLoungePrice,
                                    dataCheckout?.concessionInfantLoungePrice
                                  )}
                                </>
                              )}
                            </div>
                            {!dataCheckout?.isIncludePpnLounge && (
                              <div className="normal-text mt-1">
                                {`PPN ${dataCheckout?.ppnPercentageLounge}%`}
                              </div>
                            )}
                            {!dataCheckout?.isIncludeConcessionLounge && (
                              <div className="normal-text mt-1">
                                {`Concession ${dataCheckout?.concessionPercentageLounge}%`}
                              </div>
                            )}
                          </div>
                          <div className="text-right">
                            <div className="normal-text mb-1">
                              {formik?.values?.lounge_infant_number + " "}
                              <span>
                                {" x  Rp " +
                                  numberWithCommas(
                                    formik?.values?.lounge_price_infant
                                  )}
                              </span>
                            </div>
                            {!dataCheckout?.isIncludePpnLounge && (
                              <div className="normal-text mt-1">
                                {"Rp " +
                                  numberWithCommas(
                                    dataCheckout?.ppnInfantLoungePrice
                                  )}
                              </div>
                            )}
                            {!dataCheckout?.isIncludeConcessionLounge && (
                              <div className="normal-text mt-1">
                                {"Rp " +
                                  numberWithCommas(
                                    dataCheckout?.concessionInfantLoungePrice
                                  )}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                </>
              )}
            </div>
            <div className="card-body border-top py-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="normal-title text-extra-bold">
                  {t("field.transportation")}
                </div>
                {formik?.values?.car_type ? (
                  <div
                    onClick={toggleTransport}
                    className="d-flex align-items-center"
                    style={{ marginRight: "-30px", cursor: "pointer" }}
                  >
                    <span className="normal-title text-extra-bold mr-2">
                      Rp{" "}
                      {numberWithCommas(dataCheckout?.totalTransportPrice)}
                    </span>
                    <span className="material-icons-round left-icon">
                      expand_more
                    </span>
                  </div>
                ) : (
                  <div
                    className="normal-title text-extra-bold"
                    style={{ cursor: "pointer" }}
                  >
                    Rp 0
                  </div>
                )}
              </div>
              {showTransport && (
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="d-flex align-items-center normal-text mt-2">
                      {t("field.price")}
                      {(dataCheckout?.isIncludePpnTransport ||
                        dataCheckout?.isIncludeConcessionTransport) && ( 
                        <>
                          {infoTax('taxTransport', 
                            dataCheckout?.isIncludePpnTransport,
                            dataCheckout?.isIncludeConcessionTransport,
                            dataCheckout?.ppnPercentageTransport,
                            dataCheckout?.concessionPercentageTransport,
                            dataCheckout?.ppnTransport,
                            dataCheckout?.concessionTransport
                          )}
                        </>
                      )}
                    </div>
                    {!dataCheckout?.isIncludePpnTransport && (
                      <div className="normal-text mt-1">
                        {`PPN ${dataCheckout?.ppnPercentageTransport}%`}
                      </div>
                    )}
                    {!dataCheckout?.isIncludeConcessionTransport && (
                      <div className="normal-text mt-1">
                        {`Concession ${dataCheckout?.concessionPercentageTransport}%`}
                      </div>
                    )}
                  </div>
                  <div className="text-right">
                    <div className="normal-text mt-2">
                      {formik?.values?.total_unit +
                        " unit  x Rp" + numberWithCommas(transportPrice)}
                    </div>
                    {!dataCheckout?.isIncludePpnTransport && (
                      <div className="normal-text mt-1">
                        {"Rp " +
                          numberWithCommas(
                            dataCheckout?.ppnTransport
                          )}
                      </div>
                    )}
                    {!dataCheckout?.isIncludeConcessionTransport && (
                      <div className="normal-text mt-1">
                        {"Rp " +
                          numberWithCommas(
                            dataCheckout?.concessionTransport
                          )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            {formik?.values?.additional_service_price > 0 && (
              <div className="card-body bg-light-secondary border-top py-3">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="normal-title text-extra-bold">
                    {t('masterData.others')}
                  </div>
                  <div
                    onClick={() => setShowOthers(!showOthers)}
                    className="d-flex align-items-center"
                    style={{ marginRight: "-30px", cursor: "pointer" }}
                  >
                    <span className="normal-title text-extra-bold mr-2">
                      Rp{" "}
                      {numberWithCommas(dataCheckout?.additionalServicePriceTotal)}
                    </span>
                    <span className="material-icons-round left-icon">
                      expand_more
                    </span>
                  </div>
                </div>
                {showOthers && (
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="d-flex align-items-center normal-text mb-1">
                        {t("field.price")}
                        {(formik?.values?.additional_service_is_include_ppn ||
                          formik?.values?.additional_service_is_include_concession) && ( 
                          <>
                            {infoTax('taxOthers', 
                              formik?.values?.additional_service_is_include_ppn,
                              formik?.values?.additional_service_is_include_concession,
                              formik?.values?.additional_service_ppn_percentage,
                              formik?.values?.additional_service_concession_percentage,
                              dataCheckout?.ppnAdditionalService,
                              dataCheckout?.concessionAdditionalService
                            )}
                          </>
                        )}
                      </div>
                      {!formik?.values?.additional_service_is_include_ppn && (
                        <div className="normal-text mt-1">
                          {formik?.values?.additional_service_dpp !== null && 
                            formik?.values?.additional_service_dpp !== 0
                            ? `PPN ${formik?.values?.additional_service_ppn_percentage}% x DPP Rp ${
                                numberWithCommas(formik?.values?.additional_service_dpp)
                              }`
                            : `PPN ${formik?.values?.additional_service_ppn_percentage}%`
                          }
                        </div>
                      )}
                      {!formik?.values?.additional_service_is_include_concession && (
                        <div className="normal-text mt-1">
                          {formik?.values?.additional_service_dpp !== null && 
                            formik?.values?.additional_service_dpp !== 0
                            ? `Concession ${
                                formik?.values?.additional_service_concession_percentage
                              }% x DPP Rp ${
                                numberWithCommas(formik?.values?.additional_service_dpp)
                              }`
                            : `Concession ${formik?.values?.additional_service_concession_percentage}%`
                          }
                        </div>
                      )}
                    </div>
                    <div className="text-right">
                      <div className="normal-text">
                          {"Rp " + numberWithCommas(formik?.values?.additional_service_price)}
                      </div>
                      {!formik?.values?.additional_service_is_include_ppn && (
                        <div className="normal-text mt-1">
                          {"Rp " +
                            numberWithCommas(dataCheckout?.ppnAdditionalService)}
                        </div>
                      )}
                      {!formik?.values?.additional_service_is_include_concession && (
                        <div className="normal-text mt-1">
                          {"Rp " +
                            numberWithCommas(dataCheckout?.concessionAdditionalService)}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* <div
              className="card-body d-flex justify-content-between border-top py-3"
            >
              <div className="d-flex align-items-center normal-title text-extra-bold">
                {t("field.transportation")}
                {formik.values.total_unit > 0 &&
                  dataCheckout?.isIncludePpnLounge && (
                    <>
                      <span
                        className="material-icons"
                        style={{ fontSize: 18 }}
                        data-tip
                        data-for="taxTransports"
                      >
                        info_outlined
                      </span>
                      <ReactTooltip id="taxTransports" place="top" effect="solid">
                        <p style={{ whiteSpace: "pre-line" }}>
                          {`${t("product.includes_ppn")} ${
                            dataCheckout?.ppnPercentageLounge
                          }%, Rp ${numberWithCommas(
                            dataCheckout?.ppnTransport
                          )}`}
                        </p>
                      </ReactTooltip>
                    </>
                  )}
              </div>
              <div className="normal-title ">
                {formik.values.total_unit > 0
                  ? formik.values.total_unit +
                    " unit  x Rp" +
                    numberWithCommas(transportPrice)
                  : "Rp 0"}
              </div>
            </div> */}
          </Card>
          {button(null, dataCheckout)}
        </Col>
      </Row>
      <AssistantModal
        show={assistantModal}
        formik={formik}
        action={handleAddAssistant}
        toggle={() => setAssistantModal(!assistantModal)}
      />
    </>
  );
};

const mapStateToProps = ({ corporate }) => {
  return { corporate };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(Checkout)
);
