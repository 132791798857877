import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import 'moment/locale/id'

import Card from "../../components/cards/card";
import CardHeader from "../../components/cards/cardHeader";
import CardBody from "../../components/cards/cardBody";
import ProductionCard from "../../components/cards/ProductionCard";
import DatePicker from "../../components/forms/DatePicker";
import Table from "../../components/table";
import Button from "../../components/buttons/Button";
import { withTrans } from "../../i18n/withTrans";
import { numberWithCommas } from "../../utils/Constants";

import DashboardService from "../../store/actions/dashboard";

const DashboardReport = ({ t, pending }) => {
  const dispatch = useDispatch();
  const [filterDate, setFilterDate] = useState(new Date());
  const [dataReport, setdataReport] = useState([]);
  const [prodRKAP, setProdRKAP] = useState(0);
  const [prodDaily, setProdDaily] = useState(0);
  const [prodMonthly, setProdMonthly] = useState(0);
  const [prodAverage, setProdAverage] = useState(0);
  const [incomeRKAP, setIncomeRKAP] = useState(0);
  const [incomeDaily, setIncomeDaily] = useState(0);
  const [incomeMonthly, setIncomeMonthly] = useState(0);
  const [incomeAverage, setIncomeAverage] = useState(0);
  const [crossSellingDaily, setCrossSellingDaily] = useState(0);
  const [crossSellingMonthly, setCrossSellingMonthly] = useState(0);
  const [crossSellingAverage, setCrossSellingAverage] = useState(0);
  const [loadingDownload, setLoadingDownload] = useState(false);
  
  let lang = localStorage.getItem('joumpa_language');
  if (lang === "id") {
    moment.locale('id');
  }

  const handleTable = () => {
    new Promise((resolve) => {
      const param = {
        date: moment(filterDate).format("YYYY-MM-DD")
      };
      dispatch(DashboardService.getDashboardTable(param, resolve));
    }).then((res) => {
      setdataReport(res?.data);
    });
  };

  const handleSummary = () => {
    new Promise((resolve) => {
      const param = {
        date: moment(filterDate).format("YYYY-MM-DD")
      };
      dispatch(DashboardService.getSummaryTable(param, resolve));
    }).then((res) => {
      setProdRKAP(res?.data?.prod_rkap);
      setProdDaily(res?.data?.prod_daily);
      setProdMonthly(res?.data?.prod_monthly);
      setProdAverage(res?.data?.average_prod_monthly);
      setIncomeRKAP(`Rp ${numberWithCommas(res?.data?.income_rkap)}`);
      setIncomeDaily(`Rp ${numberWithCommas(res?.data?.income_daily)}`);
      setIncomeMonthly(`Rp ${numberWithCommas(res?.data?.income_monthly)}`);
      setIncomeAverage(`Rp ${numberWithCommas(res?.data?.average_income_monthly)}`);
      setCrossSellingDaily(`Rp ${numberWithCommas(res?.data?.cross_selling_daily)}`);
      setCrossSellingMonthly(`Rp ${numberWithCommas(res?.data?.cross_selling_monthly)}`);
      setCrossSellingAverage(`Rp ${numberWithCommas(res?.data?.average_selling_monthly)}`);
    });
  };

  useEffect(() => {
    handleSummary();
    handleTable();
  }, [filterDate]);

  const handleDownloadReport = () => {
    new Promise((resolve) => {
      setLoadingDownload(true);
      const param = {
        date: moment(filterDate).format("YYYY-MM-DD")
      }
      const fileName = `Joumpa Daily Report ${moment(filterDate).format("D MMMM YYYY")}.xlsx`
      dispatch(DashboardService.downloadReport(param, fileName, resolve));
    }).then((res) => {
      setLoadingDownload(false);
    });
  };

  const columnReport = [
    {
      Header: "NO",
      accessor: "no",
    },
    {
      Header: t("field.airport").toUpperCase(),
      accessor: "airport",
    },
    {
      Header: `RKAP ${t("report.production").toUpperCase()}`,
      accessor: "prod_rkap",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <div className="text-center">
            {original?.prod_rkap}
          </div>
        );
      },
    },
    {
      Header: () => {
        return (
          <>
            <div className="text-center">
              PAX
            </div>
            <div className="text-center">
              {moment(filterDate)?.format("D MMMM YYYY").toUpperCase()}
            </div>
          </>
        )
      },
      accessor: "prod_daily",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <div className="text-center">
            {original?.prod_daily}
          </div>
        );
      },
    },
    {
      Header: () => {
        return (
          <>
            <div className="text-center">
              {t("field.actual").toUpperCase()} (PAX)
            </div>
            <div className="text-center">
              {`1-${moment(filterDate)?.format("D")} ${moment(filterDate)?.format("MMMM YYYY").toUpperCase()}`}
            </div>
          </>
        )
      },
      accessor: "prod_monthly",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <div className="text-center">
            {original?.prod_monthly}
          </div>
        );
      },
    },
    {
      Header: () => {
        return (
          <>
            <div className="text-center">
              {t("field.average").toUpperCase()} PAX
            </div>
            <div className="text-center">
              / {t("field.day").toUpperCase()} ({moment(filterDate)?.format("D MMMM YYYY").toUpperCase()})
            </div>
          </>
        )
      },
      accessor: "average_prod_monthly",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <div className="text-center">
            {original?.average_prod_monthly}
          </div>
        );
      },
    },
    {
      Header: `RKAP ${t("field.income").toUpperCase()}`,
      minWidth: 150,
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <div className="text-center">
            {"Rp " +
              (original?.income_rkap
                ? numberWithCommas(original?.income_rkap)
                : 0)}
          </div>
        );
      },
    },
    {
      Header: () => {
        return (
          <>
            <div className="text-center">
              {t("field.income").toUpperCase()}
            </div>
            <div className="text-center">
              {moment(filterDate)?.format("D MMMM YYYY").toUpperCase()}
            </div>
          </>
        )
      },
      minWidth: 150,
      accessor: "income_daily",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <div className="text-center">
            {"Rp " +
              (original?.income_daily
                ? numberWithCommas(original?.income_daily) 
                : 0)}
          </div>
        );
      },
    },
    {
      Header: () => {
        return (
          <>
            <div className="text-center">
              {`${t("field.actual").toUpperCase()} ${t("field.income").toUpperCase()}`}
            </div>
            <div className="text-center">
              {`1-${moment(filterDate)?.format("D")} ${moment(filterDate)?.format("MMMM YYYY").toUpperCase()}`}
            </div>
          </>
        )
      },
      minWidth: 150,
      accessor: "income_monthly",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <div className="text-center">
            {"Rp " +
              (original?.income_monthly
                ? numberWithCommas(original?.income_monthly)
                : 0)}
          </div>
        );
      },
    },
    {
      Header: () => {
        return (
          <>
            <div className="text-center">
              {`${t("field.average").toUpperCase()} ${t("field.income").toUpperCase()}`}
            </div>
            <div className="text-center">
              {` / ${t("field.day").toUpperCase()} (${moment(filterDate)?.format("D MMMM YYYY").toUpperCase()})`}
            </div>
          </>
        )
      },
      minWidth: 150,
      accessor: "average_income_monthly",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <div className="text-center">
            {"Rp " +
              (original?.average_income_monthly
                ? numberWithCommas(original?.average_income_monthly)
                : 0)}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Row
        className="content-container-side mb-3 align-items-center"
      >
        <p
          className="text-bold"
          style={{ 
            fontSize: '20px',
            lineHeight: '30px',
            marginBottom: 0
          }}
        >
          Daily Report
        </p>
        <div className="d-flex ml-auto">
          <Button
            title={(
              <>
                {loadingDownload && (
                  <span className="spinner-border spinner-border-sm mr-2"></span>
                )}
                {t("report.downloadReport")}
              </>
            )}
            leftIcon={!loadingDownload ? "download" : null}
            rounded={true}
            variant="button-light btn-lg"
            onClick={handleDownloadReport}
          />
          <div className="nav-separator mr-3 ml-2" />
          <DatePicker
            showIcon
            isLocale
            useCustomInput
            customDateFormat="d MMMM yyyy"
            value={filterDate}
            onChange={(date) => setFilterDate(date)}
          />
        </div>
      </Row>
      <Row className="content-container-side">
        <Card rounded className="col-md mr-4">
          <CardHeader isDashboard title={`Total ${t("menuItem.report")} ${t("report.production")}`} />
          <CardBody>
            <Row>
              <Col md="3">
                <ProductionCard
                  className="col-md"
                  color="abu"
                  title="RKAP"
                  total={prodRKAP}
                  icon="check_circle"
                  pending={pending}
                />
              </Col>
              <Col md="3">
                <ProductionCard
                  className="col-md"
                  color="abu"
                  title="Pax"
                  total={prodDaily}
                  icon="check_circle"
                  pending={pending}
                />
              </Col>
              <Col md="3">
                <ProductionCard
                  className="col-md"
                  color="abu"
                  title={`${t("field.actual")} Pax (1-${moment(filterDate)?.format("D")} ${moment(filterDate)?.format("MMMM YYYY").toUpperCase()})`}
                  total={prodMonthly}
                  icon="check_circle"
                  pending={pending}
                />
              </Col>
              <Col md="3">
                <ProductionCard
                  className="col-md"
                  color="abu"
                  title={`${t("field.average")} Pax/${t("field.day")}`}
                  total={prodAverage}
                  icon="check_circle"
                  pending={pending}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>
      <Row className="content-container-side">
        <Card rounded className="col-md mr-4">
          <CardHeader isDashboard title={`Total ${t("menuItem.report")} ${t("field.sales")}`} />
          <CardBody>
            <Row className="justify-content-between">
              <Col md="3">
                <ProductionCard
                  className="col-md"
                  color="abu"
                  title="RKAP"
                  total={incomeRKAP !== "Rp undefined" ? incomeRKAP : "Rp 0"}
                  icon="check_circle"
                  pending={pending}
                />
              </Col>
              <Col md="3">
                <ProductionCard
                  className="col-md"
                  color="abu"
                  title={t("field.income")}
                  total={incomeDaily !== "Rp undefined" ? incomeDaily : "Rp 0"}
                  icon="check_circle"
                  pending={pending}
                />
              </Col>
              <Col md="3">
                <ProductionCard
                  className="col-md"
                  color="abu"
                  title={`${t("field.actual")} ${t("field.income")} (1-${moment(filterDate)?.format("D")} ${moment(filterDate)?.format("MMMM YYYY").toUpperCase()})`}
                  total={incomeMonthly !== "Rp undefined" ? incomeMonthly : "Rp 0"}
                  icon="check_circle"
                  pending={pending}
                />
              </Col>
              <Col md="3">
                <ProductionCard
                  className="col-md"
                  color="abu"
                  title={`${t("field.average")} ${t("field.income")}/${t("field.day")}`}
                  total={incomeAverage !== "Rp undefined" ? incomeAverage : "Rp 0"}
                  icon="check_circle"
                  pending={pending}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>
      <hr style={{ margin: "0 40px" }} />
      <div className="content-container-side">
        <div className="overflow" style={{ maxWidth: "100%" }}>
          <Table
            columns={columnReport}
            data={dataReport ?? []}
            isLoading={pending}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ dashboard: { pending, data } }) => {
  return { pending, data };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(DashboardReport));
