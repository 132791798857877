import { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";
import { Divider } from "@mui/material";
import ReactTooltip from "react-tooltip";
import Compressor from "compressorjs";
import moment from "moment";

import Card from "../../../components/cards/card";
import CardHeader from "../../../components/cards/cardHeader";
import CardBody from "../../../components/cards/cardBody";
import CardFlush from "../../../components/cards/cardFlush";
import VoucherModal from "./modal/VoucherModal";
import AssistantModal from "./modal/AssistantModal";
import DynamicButton from "../../../components/forms/DynamicButton";
import Select2 from "../../../components/forms/Select2";
import { withTrans } from "../../../i18n/withTrans";
import { getUser } from "../../../utils/User";
import { toastError } from "../../../components/commons/toast";

import BookingService from "../../../store/actions/booking";

const Checkout = ({ currentUrl, button, formik, reg_price, data, t }) => {
  const currentUser = getUser();
  const role = currentUser?.user?.role_code;
  const dispatch = useDispatch();
  const fileRef = useRef();
  const [assistantModal, setAssistantModal] = useState(false);
  const [voucherModal, setVoucherModal] = useState(false);
  const [transportPrice, setTransportPrice] = useState(0);
  const [grandTotalPrice, setGrandTotalPrice] = useState(0);
  const [adultPrice, setAdultPrice] = useState(0);
  const [childPrice, setChildPrice] = useState(0);
  const [infantPrice, setInfantPrice] = useState(0);
  const [dataCheckout, setDataCheckout] = useState(formik?.values);
  const [passengersAdult, setPassengersAdult] = useState(0);
  const [passengersChild, setPassengersChild] = useState(0);
  const [passengersInfant, setPassengersInfant] = useState(0);
  const [showPriceService, setShowPriceService] = useState(false);
  const [showLounge, setShowLounge] = useState(false);
  const [showOthers, setShowOthers] = useState(false);

  useEffect(() => {
    getPriceFromApi();
  }, [data, formik?.values?.promo_id, formik?.values?.promo_id_cross_selling]);

  const getPriceFromApi = () => {
    new Promise((resolve) => {
      // let values = formik?.values;
      let values = {
        additional_service_description:
          formik?.values?.additional_service_description === ""
            ? null
            : formik?.values?.additional_service_description,
        additional_service_concession_percentage: parseFloat(
          formik?.values?.additional_service_concession_percentage
        ),
        additional_service_ppn_percentage: parseFloat(
          formik?.values?.additional_service_ppn_percentage
        ),
        additional_service_price:
          formik?.values?.additional_service_price === null ||
          formik?.values?.additional_service_price === 0
            ? null
            : formik?.values?.additional_service_price,
        additional_service_dpp:
          formik?.values?.additional_service_dpp === null ||
          formik?.values?.additional_service_dpp === 0
            ? null
            : formik?.values?.additional_service_dpp,
        additional_service_is_include_ppn:
          formik?.values?.additional_service_is_include_ppn == 1 ? true : false,
        additional_service_is_include_concession:
          formik?.values?.additional_service_is_include_concession == 1
            ? true
            : false,
        airplane_name:
          formik?.values?.airplane_name === ""
            ? null
            : formik?.values?.airplane_name,
        airplane_name_transfer_to:
          formik?.values?.airplane_name_transfer_to === ""
            ? null
            : formik?.values?.airplane_name_transfer_to,
        airplane_number:
          formik?.values?.airplane_number === ""
            ? null
            : formik?.values?.airplane_number,
        airplane_number_transfer_to:
          formik?.values?.airplane_number_transfer_to === ""
            ? null
            : formik?.values?.airplane_number_transfer_to,
        airport_id_from:
          formik?.values?.airport_id_from === ""
            ? null
            : formik?.values?.airport_id_from,
        airport_id_to:
          formik?.values?.airport_id_to === ""
            ? null
            : formik?.values?.airport_id_to,
        airport_id_transfer:
          formik?.values?.airport_id_transfer === ""
            ? null
            : formik?.values?.airport_id_transfer,
        airport_lounge_id:
          formik?.values?.lounge === "" ? null : formik?.values?.lounge,
        assistant_id:
          formik?.values?.assistant_id === ""
            ? null
            : formik?.values?.assistant_id,
        customer_id: null,
        date: formik?.values?.date === "" ? null : formik?.values?.date,
        date_transfer_to:
          formik?.values?.date_transfer_to === ""
            ? null
            : formik?.values?.date_transfer_to,
        // flight_type:
        //   formik?.values?.flight_type === ""
        //     ? null
        //     : formik?.values?.flight_type,
        group_price_id:
          formik?.values?.group_price_id !== undefined &&
          formik?.values?.group_price_id !== null
            ? formik?.values?.group_price_id
            : null,
        is_cross_selling: formik?.values?.is_cross_selling == 1 ? true : false,
        lounge_adult_number:
          formik?.values?.lounge_adult_number === ""
            ? 0
            : formik?.values?.lounge_adult_number,
        lounge_child_number:
          formik?.values?.lounge_child_number === ""
            ? 0
            : formik?.values?.lounge_child_number,
        lounge_infant_number:
          formik?.values?.lounge_infant_number === ""
            ? 0
            : formik?.values?.lounge_infant_number,
        midtrans_token_id:
          formik?.values?.midtrans_token_id === ""
            ? null
            : formik?.values?.midtrans_token_id,
        passenger_list:
          formik?.values?.passenger_list === ""
            ? null
            : formik?.values?.passenger_list,
        passenger_notes:
          formik?.values?.passenger_notes === ""
            ? null
            : formik?.values?.passenger_notes,
        payment_type:
          formik?.values?.payment_type === ""
            ? null
            : formik?.values?.payment_type,
        period_id:
          formik?.values?.period_id === "" ? null : formik?.values?.period_id,
        price_id:
          formik?.values?.price_id !== undefined &&
          formik?.values?.price_id !== null
            ? formik?.values?.price_id
            : null,
        product_list_id:
          formik?.values?.product_list_id === ""
            ? null
            : formik?.values?.product_list_id,
        product_name:
          formik?.values?.product_type === ""
            ? null
            : formik?.values?.product_type,
        promo_id:
          formik?.values?.promo_id === "" ? null : formik?.values?.promo_id,
        promo_id_cross_selling:
          formik?.values?.promo_id_cross_selling ?? null,
        region: formik?.values?.region === "" ? null : formik?.values?.region,
        time: formik?.values?.time === "" ? null : formik?.values?.time,
        time_transfer_to:
          formik?.values?.time_transfer_to === ""
            ? null
            : formik?.values?.time_transfer_to,
        type: formik?.values?.flight_type + 1,
        transport_price_id:
          formik?.values?.orderExtras?.car?.id === ""
            ? null
            : formik?.values?.orderExtras?.car?.id,
        transport_number:
          formik?.values?.total_unit === "" ? null : formik?.values?.total_unit,
      };

      if (values.passenger_list.length < 1) {
        toastError(
          `${t("commons.empty_data_message")} ${t("field.passenger")}!`
        );
        return;
      }
      dispatch(BookingService.calculate_price(values, resolve));
    }).then((res) => {
      setGrandTotalPrice(res?.data?.total_price);
      setAdultPrice(res?.data?.adultPriceOriginal);
      setChildPrice(res?.data?.childPriceOriginal);
      setInfantPrice(res?.data?.infantPriceOriginal);
      setTransportPrice(res?.data?.transportPrice);
      setDataCheckout({...formik?.values, ...res?.data});
      setPassengersAdult( 
        formik?.values?.passenger_list?.filter(
          (item) => item?.title === "Mr." || item?.title === "Mrs." || item?.title === "Ms."
        ).length
      );
      setPassengersChild(
        formik?.values?.passenger_list?.filter(
          (item) => item?.title === "Child"
        ).length
      );
      setPassengersInfant( 
        formik?.values?.passenger_list?.filter(
          (item) => item?.title === "Infant"
        ).length
      );
      formik.setFieldValue("product_id", res?.data?.product_id);
    });
  };

  const numberWithCommas = (x) => {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleDeleteVoucher = (formik) => {
    formik?.setFieldValue("promo_id", null);
    formik?.setFieldValue("promo_id_cross_selling", null);
    formik?.setFieldValue("applied_cross_selling", null);
    formik?.setFieldValue("applied_promo", null);
  };

  const handleDeleteAsst = (formik) => {
    // formik?.setFieldValue("assistant_id", null);
    formik?.setFieldValue("assistant_list", null);
  };

  const handleAddAssistant = (param) => {
    formik?.setFieldValue("assistant_list", param?.assistant_list);
  };

  const openFileDialog = () => {
    fileRef.current.click();
  };

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const image = e.target.files[0];
      const imageName = image.name;
      new Compressor(image, {
        quality: 0.6,
        success: (res) => {
          formik?.setFieldValue("paymentProof", URL.createObjectURL(res));
          const file = new File([res], imageName);
          formik.setFieldValue("file", file);
        },
        error(err) {
          console.log(err.message);
        },
      });
    }
  };

  const removeSelectedImage = () => {
    formik.setFieldValue("paymentProof", null);
    formik.setFieldValue("file", null);
    fileRef.current.value = "";
  };

  const toggleLounge = () => {
    setShowLounge(!showLounge);
  };

  const togglePriceService = () => {
    setShowPriceService(!showPriceService);
  };

  const infoTax = (
    id,
    include_ppn,
    include_concession,
    ppn_percentage,
    concession_percentage,
    ppn_price,
    concession_price
  ) => {
    return (
      <>
        <span
          className="material-icons"
          style={{ fontSize: 18 }}
          data-tip
          data-for={id}
        >
          info_outlined
        </span>
        <ReactTooltip id={id} place="top" effect="solid">
          <p style={{ whiteSpace: "pre-line" }}>
            {(include_ppn && !include_concession && ppn_percentage !== null) ||
            (include_ppn &&
              include_concession &&
              concession_percentage === null &&
              ppn_percentage !== null) ? (
              <span>
                {id ==='taxOthers' && 
                formik?.values?.additional_service_dpp !== null &&
                formik?.values?.additional_service_dpp !== 0
                  ? `${t("product.includes_ppn")} ${ppn_percentage}% of DPP Rp ${
                      numberWithCommas(formik?.values?.additional_service_dpp)}, Rp ${
                      numberWithCommas(ppn_price)
                    }`
                  : `${t("product.includes_ppn")} ${ppn_percentage}%, Rp ${numberWithCommas(ppn_price)}`
                }
              </span>
            ) : !include_ppn &&
              include_concession &&
              concession_percentage !== null ? (
              <span>
                {id ==='taxOthers' && 
                formik?.values?.additional_service_dpp !== null &&
                formik?.values?.additional_service_dpp !== 0
                  ? `${t("product.includes_concession")} ${concession_percentage}% of DPP Rp ${
                      numberWithCommas(formik?.values?.additional_service_dpp)}, Rp ${
                      numberWithCommas(concession_price)
                    }`
                  : `${t("product.includes_concession")} ${concession_percentage}%, Rp ${
                      numberWithCommas(concession_price)
                    }`
                }
              </span>
            ) : include_ppn &&
              include_concession &&
              concession_percentage !== null &&
              ppn_percentage !== null ? (
              <span>
                {id ==='taxOthers' && 
                formik?.values?.additional_service_dpp !== null &&
                formik?.values?.additional_service_dpp !== 0
                  ? `${t("product.includes_ppn")} ${ppn_percentage}% of DPP Rp ${
                      numberWithCommas(formik?.values?.additional_service_dpp)}, Rp ${
                      numberWithCommas(ppn_price)} & ${t("product.includes_concession")} ${
                      concession_percentage}% of DPP Rp ${
                      numberWithCommas(formik?.values?.additional_service_dpp)}, Rp ${numberWithCommas(
                      concession_price
                    )}`
                  : `${t(
                      "product.includes_ppn"
                    )} ${ppn_percentage}%, Rp ${numberWithCommas(ppn_price)} & ${t(
                      "product.includes_concession"
                    )} ${concession_percentage}%, Rp ${numberWithCommas(
                      concession_price
                    )}`
                }
              </span>
            ) : (
              ""
            )}
          </p>
        </ReactTooltip>
      </>
    );
  };

  return (
    <>
      <Row>
        <Col md={9}>
          <Card className="rounded">
            <CardHeader title="Booking Resume" />
            <CardBody>
              <CardFlush
                list
                label={t("field.flightType")}
                desc={
                  formik.values?.flight_type === 0
                    ? t("field.arrival")
                    : formik.values?.flight_type === 1
                    ? t("field.departure")
                    : "Transfer"
                }
                size={{ label: "5", desc: "5" }}
              />
              <CardFlush
                list
                label={
                  formik.values.flight_type === 0 ||
                  formik.values.flight_type === 2
                    ? t("field.arrvAirline") + " / No."
                    : t("field.airline") + " / No."
                }
                desc={
                  formik.values?.airplane_name +
                  " / " +
                  formik?.values?.airplane_code +
                  formik.values?.airplane_number
                }
                size={{ label: "5", desc: "5" }}
              />
              {formik.values?.flight_type === 2 && (
                <CardFlush
                  list
                  label={t("field.deptAirline") + " / No."}
                  desc={
                    formik.values?.airplane_name_transfer_to +
                    " / " +
                    formik?.values?.airplane_code_transfer +
                    formik.values?.airplane_number_transfer_to
                  }
                  size={{ label: "5", desc: "5" }}
                />
              )}
              {formik?.values?.airport_cross_selling && (
                <CardFlush
                  list
                  label={t("field.cross_selling")}
                  desc={formik?.values?.airport_cross_selling}
                  size={{ label: "5", desc: "5" }}
                />
              )}
              <CardFlush
                list
                label={t("field.origin")}
                desc={
                  formik.values?.airport_uniform_from !== "Indonesia"
                    ? formik.values?.airport_name_from +
                      " - " +
                      formik.values?.airport_uniform_from
                    : formik.values?.airport_name_from
                }
                size={{ label: "5", desc: "5" }}
              />
              {formik.values?.flight_type === 2 && (
                <CardFlush
                  list
                  label={t("Transfer")}
                  desc={
                    formik.values?.airport_uniform_transfer !== "Indonesia"
                      ? formik.values?.airport_name_transfer +
                        " - " +
                        formik.values?.airport_uniform_transfer
                      : formik.values?.airport_name_transfer
                  }
                  size={{ label: "5", desc: "5" }}
                />
              )}
              {
                (data = [
                  {
                    label: t("field.destination"),
                    desc:
                      formik.values?.airport_uniform_to !== "Indonesia"
                        ? formik.values?.airport_name_to +
                          " - " +
                          formik.values?.airport_uniform_to
                        : formik.values?.airport_name_to,
                  },
                  {
                    label:
                      formik.values.flight_type === 0 ||
                      formik.values.flight_type === 2
                        ? t("field.arrivalTime")
                        : t("field.departureTime"),
                    desc:
                      moment(formik.values.date).format("DD/MM/YYYY") +
                      ", " +
                      formik.values.time,
                  },
                ].map((item, i) => (
                  <CardFlush
                    list
                    label={item.label}
                    desc={item.desc}
                    size={{ label: "5", desc: "5" }}
                  />
                )))
              }
              {formik.values?.flight_type === 2 && (
                <CardFlush
                  list
                  label={t("field.departureTime")}
                  desc={
                    moment(formik.values?.date_transfer_to).format(
                      "DD/MM/YYYY"
                    ) +
                    ", " +
                    formik.values?.time_transfer_to
                  }
                  size={{ label: "5", desc: "5" }}
                />
              )}
            </CardBody>
          </Card>
          <Card className="rounded">
            <CardHeader title={t("field.additionalService")} />
            <CardBody>
              <div className="d-flex justify-content-left">
                <div
                  className="rounded-fill"
                  style={{
                    backgroundColor:
                      formik.values.lounge !== "" &&
                      (formik?.values?.lounge_adult_number > 0 ||
                        formik?.values?.lounge_child_number > 0 ||
                        formik?.values?.lounge_infant_number > 0)
                        ? "#ecf5eb"
                        : "#fff",
                    border:
                      formik.values.lounge !== "" &&
                      (formik?.values?.lounge_adult_number > 0 ||
                        formik?.values?.lounge_child_number > 0 ||
                        formik?.values?.lounge_infant_number > 0)
                        ? "1px solid #63AE5C"
                        : "1px solid rgb(165, 165, 192)",
                  }}
                >
                  <span
                    className="material-icons"
                    style={{
                      fontSize: 25,
                      color:
                        formik.values.lounge !== "" &&
                        (formik?.values?.lounge_adult_number > 0 ||
                          formik?.values?.lounge_child_number > 0 ||
                          formik?.values?.lounge_infant_number > 0)
                          ? "#63ae5c"
                          : "#A5A5C0",
                    }}
                  >
                    chair
                  </span>
                </div>
                <div className="d-flex flex-column ml-3">
                  <div className="text-bold">Lounge</div>
                  <div className="normal-title">
                    {formik.values.lounge !== "" &&
                    (formik?.values?.lounge_adult_number > 0 ||
                      formik?.values?.lounge_child_number > 0 ||
                      formik?.values?.lounge_infant_number > 0)
                      ? formik.values.orderExtras.lounge.name
                      : t("commons.none")}
                  </div>
                </div>
              </div>
              <div className="my-3" />
              <div className="d-flex justify-content-left">
                <div
                  className="rounded-fill"
                  style={{
                    backgroundColor:
                      formik.values.region !== "" ? "#ecf5eb" : "#fff",
                    border:
                      formik.values.region !== ""
                        ? "1px solid #63AE5C"
                        : "1px solid rgb(165, 165, 192)",
                  }}
                >
                  <span
                    className="material-icons"
                    style={{
                      fontSize: 25,
                      color:
                        formik.values.region !== "" ? "#63ae5c" : "#A5A5C0",
                    }}
                  >
                    local_taxi
                  </span>
                </div>
                <div className="d-flex flex-column ml-3">
                  <div className="text-bold">{t("field.transportation")}</div>
                  <div className="normal-title">
                    {formik.values.region !== ""
                      ? formik.values.region +
                        " - " +
                        formik.values.orderExtras.car.name +
                        " (" +
                        formik.values.total_unit +
                        " unit)"
                      : t("commons.none")}
                  </div>
                </div>
              </div>
              <div className="my-3" />
              <div className="d-flex justify-content-left">
                <div
                  className="rounded-fill"
                  style={{
                    backgroundColor:
                      formik?.values?.additional_service_description !== "" &&
                      formik?.values?.additional_service_concession_percentage !== "" &&
                      formik?.values?.additional_service_ppn_percentage !==
                        "" &&
                      formik?.values?.additional_service_price > 0
                        ? "#ecf5eb"
                        : "#fff",
                    border:
                      formik?.values?.additional_service_description !== "" &&
                      formik?.values?.additional_service_concession_percentage !== "" &&
                      formik?.values?.additional_service_ppn_percentage !==
                        "" &&
                      formik?.values?.additional_service_price > 0
                        ? "1px solid #63AE5C"
                        : "1px solid rgb(165, 165, 192)",
                  }}
                >
                  <span
                    className="material-icons"
                    style={{
                      fontSize: 25,
                      color:
                        formik?.values?.additional_service_description !== "" &&
                        formik?.values?.additional_service_concession_percentage !== "" &&
                        formik?.values?.additional_service_ppn_percentage !==
                          "" &&
                        formik?.values?.additional_service_price > 0
                          ? "#63ae5c"
                          : "#A5A5C0",
                    }}
                  >
                    loupe
                  </span>
                </div>
                <div className="d-flex flex-column ml-3">
                  <div className="text-bold">Others</div>
                  <div className="normal-title">
                    {formik?.values?.additional_service_description !== "" &&
                    formik?.values?.additional_service_concession_percentage !== "" &&
                    formik?.values?.additional_service_ppn_percentage !== "" &&
                    formik?.values?.additional_service_price > 0
                      ? formik?.values?.additional_service_description
                      : t("commons.none")}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="rounded">
            <CardHeader title={t("field.detailPrice")} />
            {formik?.values?.promo_id || formik?.values?.promo_id_cross_selling ? (
              <div className="rounded mx-4 card-activated">
                <div className="d-flex justify-content-between p-3">
                  <div className="d-flex flex-column">
                    <div className="text-bold" style={{ fontSize: '16px' }}>
                      Voucher
                    </div>
                    {formik?.values?.promo_id && 
                    formik?.values?.promo_id_cross_selling && (
                      <div className="normal-text mt-2">
                        {`2 voucher ${t("field.applied").toLowerCase()}`}
                      </div>
                    )}
                  </div>
                  <div className="d-flex justify-content-between clickable align-items-center">
                    {formik?.values?.promo_id && 
                      formik?.values?.promo_id_cross_selling ? (
                        <>
                        <div className="normal-text text-ellipsis">
                          {!formik?.values?.promo_name?.includes("Promo") 
                            ? `Promo ${formik?.values?.promo_name}`
                            : formik?.values?.promo_name}{" & "}
                        </div>
                        <div className="normal-text text-ellipsis mr-2">
                          {formik?.values?.promo_name_cross_selling}
                        </div>
                        </>
                    ) : !formik?.values?.promo_id && 
                    formik?.values?.promo_id_cross_selling ? (
                      <div className="normal-text mr-5">
                        {formik?.values?.promo_name_cross_selling}
                      </div>
                    ) : (
                      <div className="normal-text mr-5">
                        {formik?.values?.promo_percent
                          ? `${t("commons.discount")} ${formik?.values?.promo_percent}% ${formik?.values?.promo_name}`
                          : `${t("commons.discount")} ${numberWithCommas(
                              formik?.values?.promo_price
                            )} ${formik?.values?.promo_name}`}
                      </div>
                    )}
                    {formik?.values?.promo_id && 
                    formik?.values?.promo_id_cross_selling ? (
                      <span
                        className="material-icons-outlined"
                        onClick={() => setVoucherModal(true)}
                      >
                        keyboard_arrow_right
                      </span>
                    ) : (
                      <span
                        className="material-icons-outlined"
                        onClick={() => handleDeleteVoucher(formik)}
                      >
                        close
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="border rounded mx-4">
                <div className="d-flex justify-content-between p-3">
                  <div className="text-extra-bold">Voucher</div>
                  <div
                    className="d-flex justify-content-between clickable"
                    onClick={() => setVoucherModal(true)}
                  >
                    <div className="normal-title text-light mr-5">
                      {t("field.selectVoucher")}
                    </div>
                    <span className="material-icons-outlined">
                      navigate_next
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div className="d-flex card-body justify-content-between py-3">
              <div className="normal-title text-extra-bold">
                {t("field.grandTotal")}
              </div>
              <div className="normal-title text-extra-bold">
                Rp{" "}
                {numberWithCommas(grandTotalPrice)}
              </div>
            </div>
            <div className="card-body bg-light-secondary border-top py-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="normal-title text-extra-bold">
                  {t("field.price")} {formik?.values?.available_service}
                </div>
                <div
                  onClick={togglePriceService}
                  className="d-flex align-items-center"
                  style={{ marginRight: "-30px", cursor: "pointer" }}
                >
                  <span className="normal-title text-extra-bold mr-2">
                    Rp {numberWithCommas(dataCheckout?.price_service)}
                  </span>
                  <span className="material-icons-round left-icon">
                    expand_more
                  </span>
                </div>
              </div>
              {showPriceService && (
                <>
                  {passengersAdult !== 0 && (
                    <>
                      <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                        {t("commons.adult")}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center normal-text">
                            {t("field.price")}
                            {(dataCheckout?.isIncludePpn ||
                              dataCheckout?.isIncludeConcession) && (
                              <>
                                {infoTax(
                                  "taxAdult",
                                  dataCheckout?.isIncludePpn,
                                  dataCheckout?.isIncludeConcession,
                                  dataCheckout?.ppnPercentage,
                                  dataCheckout?.concessionPercentage,
                                  dataCheckout?.ppnAdultPrice,
                                  dataCheckout?.concessionAdultPrice
                                )}
                              </>
                            )}
                          </div>
                          {!dataCheckout?.isIncludePpn && (
                            <div className="normal-text mt-1">
                              {`PPN ${dataCheckout?.ppnPercentage}%`}
                            </div>
                          )}
                          {!dataCheckout?.isIncludeConcession && (
                            <div className="normal-text mt-1">
                              {`Concession ${dataCheckout?.concessionPercentage}%`}
                            </div>
                          )}
                        </div>
                        <div className="text-right">
                          <div className="normal-text">
                            {passengersAdult + " "}
                            <span>
                              {" x  Rp " + numberWithCommas(adultPrice)}
                            </span>
                          </div>
                          {!dataCheckout?.isIncludePpn && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  dataCheckout?.ppnAdultPrice
                                )}
                            </div>
                          )}
                          {!dataCheckout?.isIncludeConcession && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  dataCheckout?.concessionAdultPrice
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {passengersChild !== 0 && (
                    <>
                      <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                        {t("commons.child")}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center normal-text">
                            {t("field.price")}
                            {(dataCheckout?.isIncludePpn ||
                              dataCheckout?.isIncludeConcession) && (
                              <>
                                {infoTax(
                                  "taxChild",
                                  dataCheckout?.isIncludePpn,
                                  dataCheckout?.isIncludeConcession,
                                  dataCheckout?.ppnPercentage,
                                  dataCheckout?.concessionPercentage,
                                  dataCheckout?.ppnChildPrice,
                                  dataCheckout?.concessionChildPrice
                                )}
                              </>
                            )}
                          </div>
                          {!dataCheckout?.isIncludePpn && (
                            <div className="normal-text mt-1">
                              {`PPN ${dataCheckout?.ppnPercentage}%`}
                            </div>
                          )}
                          {!dataCheckout?.isIncludeConcession && (
                            <div className="normal-text mt-1">
                              {`Concession ${dataCheckout?.concessionPercentage}%`}
                            </div>
                          )}
                        </div>
                        <div className="text-right">
                          <div className="normal-text">
                            {passengersChild + " "}
                            <span>
                              {" x  Rp " + numberWithCommas(childPrice)}
                            </span>
                          </div>
                          {!dataCheckout?.isIncludePpn && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  dataCheckout?.ppnChildPrice
                                )}
                            </div>
                          )}
                          {!dataCheckout?.isIncludeConcession && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  dataCheckout?.concessionChildPrice
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {passengersInfant !== 0 && (
                    <>
                      <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                        {t("commons.infantLabel")}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center normal-text">
                            {t("field.price")}
                            {(dataCheckout?.isIncludePpn ||
                              dataCheckout?.isIncludeConcession) && (
                              <>
                                {infoTax(
                                  "taxInfant",
                                  dataCheckout?.isIncludePpn,
                                  dataCheckout?.isIncludeConcession,
                                  dataCheckout?.ppnPercentage,
                                  dataCheckout?.concessionPercentage,
                                  dataCheckout?.ppnInfantPrice,
                                  dataCheckout?.concessionInfantPrice
                                )}
                              </>
                            )}
                          </div>
                          {!dataCheckout?.isIncludePpn && (
                            <div className="normal-text mt-1">
                              {`PPN ${dataCheckout?.ppnPercentage}%`}
                            </div>
                          )}
                          {!dataCheckout?.isIncludeConcession && (
                            <div className="normal-text mt-1">
                              {`Concession ${dataCheckout?.concessionPercentage}%`}
                            </div>
                          )}
                        </div>
                        <div className="text-right">
                          <div className="normal-text">
                            {passengersInfant + " "}
                            <span>
                              {" x  Rp " + numberWithCommas(infantPrice)}
                            </span>
                          </div>
                          {!dataCheckout?.isIncludePpn && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  dataCheckout?.ppnInfantPrice
                                )}
                            </div>
                          )}
                          {!dataCheckout?.isIncludeConcession && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  dataCheckout?.concessionInfantPrice
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            <div className="card-body bg-light-secondary border-top py-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="normal-title text-extra-bold">
                  {t("field.lounge")}
                </div>
                {formik?.values?.lounge ? (
                  <div
                    onClick={toggleLounge}
                    className="d-flex align-items-center"
                    style={{ marginRight: "-30px", cursor: "pointer" }}
                  >
                    <span className="normal-title text-extra-bold mr-2">
                      Rp {numberWithCommas(dataCheckout?.totalLoungePrice)}
                    </span>
                    <span className="material-icons-round left-icon">
                      expand_more
                    </span>
                  </div>
                ) : (
                  <div
                    className="normal-title text-extra-bold"
                    style={{ cursor: "pointer" }}
                  >
                    Rp 0
                  </div>
                )}
              </div>
              {showLounge && (
                <>
                  {formik?.values?.lounge_adult_number !== "" &&
                    formik?.values?.lounge_adult_number !== 0 && (
                      <>
                        <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                          {t("commons.adult")}
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <div className="d-flex align-items-center normal-text mb-1">
                              {t("field.price")}
                              {dataCheckout?.isIncludePpnLounge && (
                                <>
                                  {infoTax(
                                    "taxLoungeAdult",
                                    dataCheckout?.isIncludePpnLounge,
                                    dataCheckout?.isIncludeConcessionLounge,
                                    dataCheckout?.ppnPercentageLounge,
                                    dataCheckout?.concessionPercentageLounge,
                                    dataCheckout?.ppnAdultLoungePrice,
                                    dataCheckout?.concessionAdultLoungePrice
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                          <div className="text-right">
                            <div className="normal-text mb-1">
                              {formik?.values?.lounge_adult_number + " "}
                              <span>
                                {" x  Rp " +
                                  numberWithCommas(
                                    formik?.values?.lounge_price_adult
                                  )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  {formik?.values?.lounge_child_number !== "" &&
                    formik?.values?.lounge_child_number !== 0 && (
                      <>
                        <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                          {t("commons.child")}
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <div className="d-flex align-items-center normal-text mb-1">
                              {t("field.price")}
                              {dataCheckout?.isIncludePpnLounge && (
                                <>
                                  {infoTax(
                                    "taxLoungeChild",
                                    dataCheckout?.isIncludePpnLounge,
                                    dataCheckout?.isIncludeConcessionLounge,
                                    dataCheckout?.ppnPercentageLounge,
                                    dataCheckout?.concessionPercentageLounge,
                                    dataCheckout?.ppnChildLoungePrice,
                                    dataCheckout?.concessionChildLoungePrice
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                          <div className="text-right">
                            <div className="normal-text mb-1">
                              {formik?.values?.lounge_child_number + " "}
                              <span>
                                {" x  Rp " +
                                  numberWithCommas(
                                    formik?.values?.lounge_price_child
                                  )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  {formik?.values?.lounge_infant_number !== "" &&
                    formik?.values?.lounge_infant_number !== 0 && (
                      <>
                        <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                          {t("commons.infantLabel")}
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <div className="normal-text mb-1">
                              {t("field.price")}
                              {dataCheckout?.isIncludePpnLounge && (
                                <>
                                  {infoTax(
                                    "taxLoungeInfant",
                                    dataCheckout?.isIncludePpnLounge,
                                    dataCheckout?.isIncludeConcessionLounge,
                                    dataCheckout?.ppnPercentageLounge,
                                    dataCheckout?.concessionPercentageLounge,
                                    dataCheckout?.ppnInfantLoungePrice,
                                    dataCheckout?.concessionInfantLoungePrice
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                          <div className="text-right">
                            <div className="normal-text mb-1">
                              {formik?.values?.lounge_infant_number + " "}
                              <span>
                                {" x  Rp " +
                                  numberWithCommas(
                                    formik?.values?.lounge_price_infant
                                  )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                </>
              )}
            </div>
            <div className="d-flex card-body bg-light-secondary justify-content-between border-top py-3">
              <div className="d-flex align-items-center normal-title text-extra-bold">
                {t("field.transportation")}
                {formik.values.total_unit > 0 && (
                  <>
                    <span
                      className="material-icons"
                      style={{ fontSize: 18 }}
                      data-tip
                      data-for="taxTransports"
                    >
                      info_outlined
                    </span>
                    <ReactTooltip id="taxTransports" place="top" effect="solid">
                      <p style={{ whiteSpace: "pre-line" }}>
                        {`${t("product.includes_ppn")} ${
                          dataCheckout?.ppnPercentageTransport
                        }%, Rp ${numberWithCommas(
                          dataCheckout?.ppnTransport
                        )}`}
                      </p>
                    </ReactTooltip>
                  </>
                )}
              </div>
              <div className="normal-title ">
                {formik.values.total_unit > 0
                  ? formik.values.total_unit +
                    " unit  x Rp" +
                    numberWithCommas(transportPrice)
                  : "Rp 0"}
              </div>
            </div>
            {formik?.values?.additional_service_price > 0 && (
              <div
                className={`card-body bg-light-secondary border-top py-3 ${
                  formik.values.promo_id === null && formik.values.promo_id_cross_selling === null 
                    ? "rounded-last-card" 
                    : ""
                }`}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="normal-title text-extra-bold">Others</div>
                  <div
                    onClick={() => setShowOthers(!showOthers)}
                    className="d-flex align-items-center"
                    style={{ marginRight: "-30px", cursor: "pointer" }}
                  >
                    <span className="normal-title text-extra-bold mr-2">
                      Rp{" "}
                      {numberWithCommas(
                        dataCheckout?.additionalServicePriceTotal
                      )}
                    </span>
                    <span className="material-icons-round left-icon">
                      expand_more
                    </span>
                  </div>
                </div>
                {showOthers && (
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="d-flex align-items-center normal-text mb-1">
                        {t("field.price")}
                        {(formik?.values?.additional_service_is_include_ppn ||
                          formik?.values
                            ?.additional_service_is_include_concession) && (
                          <>
                            {infoTax(
                              "taxOthers",
                              formik?.values?.additional_service_is_include_ppn,
                              formik?.values?.additional_service_is_include_concession,
                              formik?.values?.additional_service_ppn_percentage,
                              formik?.values?.additional_service_concession_percentage,
                              dataCheckout?.ppnAdditionalService,
                              dataCheckout?.concessionAdditionalService
                            )}
                          </>
                        )}
                      </div>
                      {!formik?.values?.additional_service_is_include_ppn && (
                        <div className="normal-text mt-1">
                          {formik?.values?.additional_service_dpp !== null && 
                            formik?.values?.additional_service_dpp !== 0
                            ? `PPN ${formik?.values?.additional_service_ppn_percentage}% x DPP Rp ${
                                numberWithCommas(formik?.values?.additional_service_dpp)
                              }`
                            : `PPN ${formik?.values?.additional_service_ppn_percentage}%`
                          }
                        </div>
                      )}
                      {!formik?.values?.additional_service_is_include_concession && (
                        <div className="normal-text mt-1">
                          {formik?.values?.additional_service_dpp !== null && 
                            formik?.values?.additional_service_dpp !== 0
                            ? `Concession ${
                                formik?.values?.additional_service_concession_percentage
                              }% x DPP Rp ${
                                numberWithCommas(formik?.values?.additional_service_dpp)
                              }`
                            : `Concession ${formik?.values?.additional_service_concession_percentage}%`
                          }
                        </div>
                      )}
                    </div>
                    <div className="text-right">
                      <div className="normal-text">
                        {"Rp " +
                          numberWithCommas(
                            formik?.values?.additional_service_price
                          )}
                      </div>
                      {!formik?.values?.additional_service_is_include_ppn && (
                        <div className="normal-text mt-1">
                          {"Rp " +
                            numberWithCommas(
                              dataCheckout?.ppnAdditionalService
                            )}
                        </div>
                      )}
                      {!formik?.values
                        ?.additional_service_is_include_concession && (
                        <div className="normal-text mt-1">
                          {"Rp " +
                            numberWithCommas(
                              dataCheckout?.concessionAdditionalService
                            )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
            {formik?.values?.promo_id !== null && (
              <>
              <Divider orientation="horizontal" />
              <div
                className={`card-body bg-light-secondary py-3 ${
                  formik?.values?.promo_id_cross_selling ? 'rounded-last-card' : ''
                }`}
              >
                <div className="normal-title text-extra-bold mb-1">
                  Promo
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="normal-text">
                    {formik?.values?.promo_percent
                      ? `${formik?.values?.promo_name} ${formik?.values?.promo_percent}%`
                      : formik?.values?.promo_name}
                      <span
                        style={{
                          borderRight: "1px solid #A9A9A9",
                          height: 20,
                          marginLeft: 8,
                          marginRight: 8,
                        }}
                      ></span>
                      <span className="text-primary">
                        {formik?.values?.promo_type === "blast"
                          ? "Blast"
                          : formik?.values?.promo_type === "specific"
                          ? t("promo.special")
                          : ""}
                      </span>
                  </div>
                  <div className="normal-text ">
                    - Rp{numberWithCommas(dataCheckout?.price_cut ?? 0)}
                  </div>
                </div>
              </div>
              </>
            )}
            {formik?.values?.promo_id_cross_selling !== null && (
              <>
              <Divider orientation="horizontal" />
              <div className="card-body bg-light-secondary rounded-last-card py-3">
                <div className="normal-title text-extra-bold mb-2">
                  {t("field.cross_selling")}
                </div>
                <div className="d-flex justify-content-between align-items-center mt-1">
                  <div className="normal-text">
                    {t("promo.airportCrossSelling")}
                      <span
                        style={{
                          borderRight: "1px solid #A9A9A9",
                          height: 20,
                          marginLeft: 8,
                          marginRight: 8,
                        }}
                      ></span>
                      <span className="text-primary">
                        {formik?.values?.promo_percent_cross_selling}%
                      </span>
                  </div>
                  <div className="normal-text text-primary">
                    Rp {numberWithCommas(dataCheckout?.price_cut_cross_selling ?? 0)}
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-1">
                  <div className="normal-text">
                    {t("promo.airportService")}
                      <span
                        style={{
                          borderRight: "1px solid #A9A9A9",
                          height: 20,
                          marginLeft: 8,
                          marginRight: 8,
                        }}
                      ></span>
                      <span className="text-primary">
                        {parseInt(100 - formik?.values?.promo_percent_cross_selling)}%
                      </span>
                  </div>
                  <div className="normal-text text-primary">
                    Rp {numberWithCommas(dataCheckout?.total_price_cross_selling ?? 0)}
                  </div>
                </div>
              </div>
              </>
            )}
          </Card>
          {role !== "admin_pusat" && 
            formik?.values?.is_cross_selling != 1 && 
            currentUrl !== 'booking-contact-center' &&
          (
            <Card className="rounded">
              <CardHeader title={t("booking.assistantAssign")} />
              <CardBody>
                {formik?.values?.assistant_list === null ||
                formik?.values?.assistant_list === undefined ? (
                  <div
                    className={`border px-4 py-3 rounded 
                      ${
                        formik?.values?.date <=
                          moment().add(1, "days").format("YYYY-MM-DD")
                          ? "clickable"
                          : "disable"
                      }`}
                    onClick={() => setAssistantModal(true)}
                  >
                    <div className="d-flex flex-column">
                      <div className="normal-title pb-1 text-extra-bold">
                        {t("field.assistantNotChosen")}
                      </div>
                      <div className="normal-title">
                        {t("field.selectAssistant")}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="px-4 py-3 rounded card-activated clickable d-flex justify-content-between">
                    <div
                      className="d-flex flex-column"
                      onClick={() => setAssistantModal(true)}
                    >
                      <div className="normal-title pb-1 text-extra-bold">
                        {formik?.values?.assistant_list
                          ?.map((item) => item?.assistant_name)
                          .join(", ")}
                      </div>
                      <div className="normal-title">
                        {`${t("field.main_assistant")} : ${
                          formik?.values?.assistant_list?.find(
                            (item) => item?.is_main === true
                          )?.assistant_name
                        }`}
                      </div>
                    </div>
                    <div
                      className="trigger"
                      onClick={() => handleDeleteAsst(formik)}
                    >
                      <span className="material-icons-outlined">close</span>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          )}
          {currentUrl !== 'booking-contact-center' && (
            <Card className="rounded">
              <CardHeader title={t("booking.paymentProof")} />
              <CardBody>
                <div className="d-flex justify-content-between">
                  <div className="text-bold">{t("booking.paymentType")}</div>
                  <Col md={6} style={{ paddingRight: 0 }}>
                    <Select2
                      name="payment_type"
                      errors={formik.errors}
                      options={[
                        {
                          value: "cash",
                          label: `${t("field.cash")}`,
                        },
                        {
                          value: "transfer",
                          label: "Transfer",
                        },
                        {
                          value: "deposit",
                          label: "Deposit",
                        },
                      ]}
                      clearable={!!formik.values?.payment_type}
                      touched={formik.touched}
                      value={formik?.values?.payment_type}
                      onChange={(name, value) => {
                        formik.setFieldValue("payment_type", value);
                      }}
                      onBlur={formik.setFieldTouched}
                    />
                  </Col>
                </div>
                <div>
                  <DynamicButton
                    titleCenter={t("booking.paymentProof")}
                    subtitleCenter={t("booking.uploadPaymentProof")}
                    iconCenter="add_photo_alternate"
                    toggle={openFileDialog}
                    errors={formik.error}
                  />
                  <input
                    type="file"
                    ref={fileRef}
                    style={{ display: "none" }}
                    onChange={imageChange}
                    accept="image/png, image/jpeg"
                  />
                  {formik?.values?.paymentProof && (
                    <div
                      style={{
                        isplay: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={formik?.values?.paymentProof}
                        style={{
                          width: "100%",
                          maxHeight: 320,
                          objectFit: "cover",
                        }}
                        alt="Thumb"
                      />
                      <button
                        onClick={removeSelectedImage}
                        style={{
                          cursor: "pointer",
                          padding: 15,
                          background: "#d10d0d",
                          color: "white",
                          border: "none",
                          width: "100%",
                        }}
                      >
                        {t("commons.delete")}
                      </button>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          )}
          {/* )} */}
          {button()}
        </Col>
      </Row>

      <VoucherModal
        show={voucherModal}
        cancel={() => handleDeleteVoucher(formik)}
        toggle={() => setVoucherModal(!voucherModal)}
        formik={formik}
      />
      <AssistantModal
        show={assistantModal}
        formik={formik}
        action={handleAddAssistant}
        toggle={() => setAssistantModal(!assistantModal)}
      />
    </>
  );
};
const mapStateToProps = ({ reg_price }) => {
  return { reg_price };
};

const mapDispatchToProps = () => {
  return {};
};
export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(Checkout)
);
