import { Formik, Form } from "formik";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { withTrans } from "../../../i18n/withTrans";
import { activities, payment_list } from "../../../utils/Constants"
import Select2 from "../../../components/forms/Select2";
import ButtonModal from "../../../components/modal/ButtonModal";
import DynamicButton from "../../../components/forms/DynamicButton";
import DatePicker from "../../../components/forms/DateRangePicker";
import moment from "moment";

function ModalFilter({
  t,
  show,
  airport,
  product,
  corporate,
  toggle,
  filter,
  toggleFilter,
  isDelete,
  onApplyFilter,
  user,
  haveAirport
}) {

  const handleReset = (formik) => {
    formik.setFieldValue("start_date", moment().format("YYYY-MM-DD"));
    formik.setFieldValue("end_date", moment().format("YYYY-MM-DD"));
    formik.setFieldValue("corporate_id", "");
    formik.setFieldValue("product_id", "");
    formik.setFieldValue("airport_id", "");
    formik.setFieldValue("service_type", "");
    formik.setFieldValue("payment_type", "");
    toggleFilter({
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      corporate_id: "",
      product_id: "",
      airport_id: "",
      service_type: "",
      payment_type: "",
    });
    toggle();
  };

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Filter{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody style={{ margin: "0px 20px" }}>
          <DynamicButton titleLeft={t("field.chooseFilter")} iconLeft="info" />
          <Formik
            initialValues={{
              corporate_id: filter?.corporate_id ?? "all",
              airport_id: filter?.airport_id ?? "",
              product_id: filter?.product_id ?? "",
              start_date: filter?.start_date ?? "",
              end_date: filter?.end_date ?? "",
              service_type: filter?.service_type ?? "",
              payment_type: filter?.payment_type ?? "",
            }}
            enableReinitialize
            onSubmit={(values) => {
              onApplyFilter({
                airport_id:
                  user.role_code === "admin_bandara" ||
                  user.role_code === "supervisor"
                    ? user.airport_id
                    : values.airport_id,
                start_date:
                  values.start_date === "" || values.start_date === undefined
                    ? ""
                    : moment(values.start_date).format("YYYY-MM-DD"),
                end_date:
                  values.end_date === "" || values.end_date === undefined
                    ? ""
                    : moment(values.end_date).format("YYYY-MM-DD"),
                product_id: values.product_id,
                corporate_id: values.corporate_id,
                service_type: values.service_type,
                payment_type: values.payment_type,
              });
              toggle();
            }}
          >
            {(props) => (
              <Form style={{ marginTop: 30 }}>
                <DatePicker
                  title={t("report.serviceTime")}
                  name="start_date"
                  handlechange={(startDate, endDate) => {
                    props.setFieldValue("start_date", startDate);
                    props.setFieldValue("end_date", endDate);
                  }}
                  format="DD MMMM YYYY"
                  startDate={filter ? filter?.start_date : props.values?.start_date}
                  endDate={filter ? filter?.end_date : props.values?.end_date}
                  className="mx-auto"
                  errors={props.errors}
                  touched={props.touched}
                />
                {!haveAirport && (
                  <Select2
                    title={t("field.airport")}
                    name="airport_id"
                    options={[
                      {
                        value: "",
                        label: `${t("commons.all")} ${t("field.airport")}`,
                      },
                      ...airport,
                    ]}
                    errors={props.errors}
                    touched={props.touched}
                    value={props.values?.airport_id}
                    onChange={(name, value) => {
                      props.setFieldValue("airport_id", value);
                    }}
                    onBlur={props.setFieldTouched}
                  />
                )}
                <Select2
                  title={t("report.agent")}
                  name="corporate_id"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} (Non ${t("report.agent")} + ${t("report.agent")})`,
                    },
                    {
                      value: "Regular",
                      label: `Non ${t("report.agent")}`
                    },
                    {
                      value: "Corporate",
                      label: `${t("commons.all")} ${t("report.agent")}`,
                    },
                    ...corporate,
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.corporate_id}
                  onChange={(name, value) => {
                    props.setFieldValue("corporate_id", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title={t("field.product")}
                  name="product_id"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t("field.product")}`,
                    },
                    ...product,
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.product_id}
                  onChange={(name, value) => {
                    props.setFieldValue("product_id", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title={t("commons.activities")}
                  name="service_type"
                  options={activities(t)}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.service_type}
                  onChange={(name, value) => {
                    props.setFieldValue("service_type", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title={t("field.paymentType")}
                  name="payment_type"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t("field.paymentType")}`,
                    },
                    ...payment_list.map((item) => (
                      { label: item.label, value: item.value.toLowerCase() }
                    )),
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.payment_type}
                  onChange={(name, value) => {
                    props.setFieldValue("payment_type", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
               
                <div style={{ marginTop: 50, marginBottom: 30 }}>
                  <ButtonModal
                    toggle={toggle}
                    reset={() => handleReset(props)}
                    deleted={isDelete}
                    confirmTitle={t("field.apply")}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}

export default withTrans(ModalFilter);
