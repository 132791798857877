import React from "react";
import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import moment from "moment";

import Table from "../../../components/table";
import { withTrans } from "../../../i18n/withTrans";

// Service
import DataAnalyticsService from "../../../store/actions/data_analytics";

const TabelPareto = ({ t, searchText, airport_id, month, year, pending }) => {
  const dispatch = useDispatch();
  let lang = localStorage.getItem('joumpa_language');

  const [dataTablePareto, setDataTablePareto] = useState([]);
  const [dataTotalPareto, setDataTotalPareto] = useState({});
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);

  const numberWithCommas = (x) => {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  
  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = {
        page: page === 0 ? page : page - 1,
        length: 10,
        search_text: searchText,
        airport_id: airport_id ?? "",
        month: month,
        year: year,
      };
      dispatch(DataAnalyticsService.getTablePareto(param, resolve));
    }).then((res) => {
      if(res?.data) {
        setDataTablePareto(res?.data);
        setTotalData(res?.totalCount);
        setMaxPage(Math.ceil(res?.totalCount / 10));
        if (res?.data?.length > 0) {
          handleRefreshTotalPareto();
        }
      }
    });
  };

  const handleRefreshTotalPareto = () => {
    new Promise((resolve) => {
      const param = {
        search_text: searchText,
        airport_id: airport_id ?? "",
        month: month,
        year: year,
      };
      dispatch(DataAnalyticsService.getTotalTablePareto(param, resolve));
    }).then((res) => {
      setDataTotalPareto(res?.data);
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, searchText, airport_id, month, year]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 0));
  }, [searchText, airport_id, month, year]);

  const columns = [
    {
      Header: "NO.",
      accessor: "no",
      colSpanFooter: 3,
      Footer: () => {
        return (
          <div style={{ fontWeight: 800 }}>
            {lang === "id" ? "Total Keseluruhan" : "Total"}
          </div>
        )
      },
    },
    {
      Header: t("field.customer").toUpperCase(),
      accessor: "name",
      Cell: ({ row }) => {
        const { original } = row;
        return original?.name ?? "-";
      },
      hideColFooter: true,
    },
    {
      Header: t("dataAnalytics.lastTransactionDate").toUpperCase(),
      accessor: "last_transaction_date",
      Cell: ({ row }) => {
        const { original } = row;
        return original?.last_transaction_date 
          ? moment(original?.last_transaction_date)?.format("DD/MM/YYYY") 
          : "-";
      },
      hideColFooter: true,
    },
    {
      Header: t("field.sales").toUpperCase(),
      accessor: "grand_total",
      minWidth: 150,
      Cell: ({ row }) => {
        const { original } = row;
        return original?.grand_total ? `Rp ${numberWithCommas(original?.grand_total)}`: "Rp 0";
      },
      Footer: () => {
        return (
          <div style={{ fontWeight: 800 }}>
            {dataTotalPareto?.total_sales 
              ? `Rp ${numberWithCommas(dataTotalPareto.total_sales)}` 
              : 'Rp 0'}
          </div>
        );
      },
    },
    {
      Header: `% ${t("field.sales").toUpperCase()}`,
      accessor: "sales_percentage",
      Cell: ({ row }) => {
        const { original } = row;
        return original?.sales_percentage ? `${original?.sales_percentage}%` : "0%";
      },
      Footer: () => {
        return (
          <div style={{ fontWeight: 800 }}>
            {dataTotalPareto?.sales_percentage 
              ? `${dataTotalPareto?.sales_percentage}%` 
              : '0%'}
          </div>
        );
      },
    },
    {
      Header: `% ${t("dataAnalytics.cumulative").toUpperCase()}`,
      accessor: "cumulative_percentage",
      Cell: ({ row }) => {
        const { original } = row;
        return original?.cumulative_percentage ? `${original?.cumulative_percentage}%` : "0%";
      },
    },
  ];

  return (
    <>
      <Table
        footer
        columns={columns?.filter((item) => item.show !== false)}
        data={dataTablePareto ?? []}
        pagination={{ page, totalData, maxPage, toggle: setPage }}
        isLoading={pending}
      />
    </>
  );
};

const mapStateToProps = ({ data_analytics: { pending, data } }) => {
  return { pending, data };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(TabelPareto));
