import { useRef, useState } from "react";
import DropdownMenu from "../../dropdowns/DropdownMenu";
import account from "../../../assets/images/account/account.png";
import { history } from "../../../utils/History";
import { getUser } from "../../../utils/User";
import { useClickaway } from "../../hooks/ClickawayHooks";
import { toastSuccess } from "../../commons/toast";
import { withTrans } from "../../../i18n/withTrans";

import "./sidebar.scss";
import { Divider } from "@mui/material";
import ChangeLanguage from "./profile_modal/ChangeLanguage";

const Profiles = ({ t }) => {
  const currentUser = getUser();
  const name = currentUser?.user?.name;
  const position = currentUser?.user?.role_name;

  const [show, setShow] = useState(false);
  const [langModal, setLangModal] = useState(false);
  const lang = localStorage.getItem("joumpa_language");

  const logout = () => {
    localStorage.removeItem("user_joumpa");
    localStorage.removeItem("persist:root");
    localStorage.removeItem("joumpa_language");
    history.push(`/`);
    toastSuccess(
      `${lang === "id" ? `Logout Berhasil` : `Logout successfully`}`
    );
  };

  const showLangModal = () => {
    setLangModal(!langModal);
  };

  const dropdownRef = useRef();

  useClickaway(dropdownRef, () => {
    setShow(false);
  });
  return (
    <>
      <div
        className="profile-menu"
        style={{ cursor: "pointer" }}
        onClick={() => setShow(!show)}
      >
        <img src={account} className="profile-image" alt="account" />
        <div className="profile-info hover-show">
          <p className="profile-name text-bold">{name}</p>
          <p className="profile-position">{position}</p>
        </div>
        {show && (
          <div ref={dropdownRef}>
            <DropdownMenu title={name} subtitle={position}>
              <div className="notification-item">
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() => showLangModal()}
                >
                  <div className="notification-detail">
                    <p className="notification-info text-bold">
                      {t("field.change_language")}
                    </p>
                  </div>
                </div>
                <Divider orientation="horizontal" />
              </div>
              <div className="notification-item">
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() => logout()}
                >
                  <div className="notification-detail">
                    <p className="notification-info text-bold">Logout</p>
                  </div>
                </div>
              </div>
            </DropdownMenu>
          </div>
        )}
      </div>
      <ChangeLanguage showModal={langModal} toggle={showLangModal} />
    </>
  );
};

export default withTrans(Profiles);
