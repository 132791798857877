import { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { Row, Col } from "reactstrap";
import moment from "moment";

import DetailPriceRegular from "./DetailPriceRegular";
import Button from "../../../../components/buttons/Button";
import Loader from "../../../../components/commons/Loader";
import CardFlush from "../../../../components/cards/cardFlush";
import Card from "../../../../components/cards/card";
import CardHeader from "../../../../components/cards/cardHeader";
import CardBody from "../../../../components/cards/cardBody";
import Input from "../../../../components/forms/Input";
import Checkbox from "../../../../components/forms/Checkbox";
import InputPrepend from "../../../../components/forms/InputPrepend";
import Select2Multi from "../../../../components/forms/Select2Multi";
import LabelPills from "../../../../components/table/labelPills";
import { history } from "../../../../utils/History";
import { withTrans } from "../../../../i18n/withTrans";

//Service
import AirportService from "../../../../store/actions/master_data/airport";
import RegularService from "../../../../store/actions/product/regular_price";

const Index = ({ pending, t }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [dataAirport, setDataAirport] = useState([]);
  const [dataEditAirport, setDataEditAirport] = useState([]);
  const [indexPrice, setIndexPrice] = useState();
  const [indexCancel, setIndexCancel] = useState(null);
  const [showPageIndex, setShowPageIndex] = useState(true);
  const [showPagePrice, setShowPagePrice] = useState(false);

  const exceptThisSymbols = ['e', 'E', '+', '-', '.'];

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        id,
      };
      if (id) {
        dispatch(RegularService.get(param, resolve));
      }
    }).then((res) => {
      if (res) {
        formikRef?.current?.setFieldValue("name", res?.data[0]?.name);
        formikRef?.current?.setFieldValue("period_list", res?.data[0]?.period_list);
        // setDataEditAirport(res?.data[0]?.airport_list);
        formikRef?.current?.setFieldValue(
          `airport_list`,
          res?.data[0]?.airport_list?.map((x) => x.airport_id)
        );
        const airportTooltips = [];
        res?.data[0]?.airport_list?.map((airport) => {
          const airportFormat = 
            `${airport?.airport_detail?.code} - ${airport?.airport_detail?.city}`;
          airportTooltips?.push(airportFormat);
        });
        formikRef?.current?.setFieldValue("airports", airportTooltips);
        formikRef?.current?.setFieldValue("tax", res?.data[0]?.tax);

        if (res?.data[0]?.tax?.is_include_concession) {
          formikRef?.current?.setFieldValue(
            "tax.is_includes_concession", 
            ["1"]
          );
        }
        if (res?.data[0]?.tax?.is_include_ppn) {
          formikRef?.current?.setFieldValue(
            "tax.is_includes_ppn", 
            ["1"]
          );
        }

        res?.data[0]?.period_list?.map((period, index) => {
          if (period?.product_list?.length) {
            period?.product_list?.map((product) => {
              if (product?.product_name === 1) {
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].is_checked_meet_inter`,
                  ["1"]
                );
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].meet_and_greet_inter`,
                  product
                );
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].meet_and_greet_inter.flight_types`,
                  product?.flight_types?.map((x) => x.flight_type)
                );
              }
              if (product?.product_name === 2) {
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].is_checked_meet_dom`,
                  ["1"]
                );
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].meet_and_greet_dom`,
                  product
                );
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].meet_and_greet_dom.flight_types`,
                  product?.flight_types?.map((x) => x.flight_type)
                );
              }
              if (product?.product_name === 3) {
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].is_checked_fast`,
                  ["1"]
                );
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].fast_track`,
                  product
                );
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].fast_track.flight_types`,
                  product?.flight_types?.map((x) => x.flight_type)
                );
              }
              if (product?.product_name === 4) {
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].is_checked_transfer`,
                  ["1"]
                );
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].transfer`,
                  product
                );
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].transfer.flight_types`,
                  product?.flight_types?.map((x) => x.flight_type)
                );
              }
            });
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 99999,
        is_service: true,
        regularPriceUnpriced: false,
        product_regular_id: id,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      let list = [];
      if (res && res.data) {
        res.data?.map((airport) => (
          list = [...list, {
            ...airport,
            value: airport?.id,
            label: `${airport?.code}-${airport?.city}-${airport?.name}`,
          }]));
      }
      setDataAirport(list);
    });
  }, []);

  const initialValues = {
    id: id ?? "",
    name: "",
    airport_list: "",
    tax: {
      is_includes_concession: null,
      is_includes_ppn: null,
      is_include_concession: false,
      is_include_ppn: false,
    },
    period_list: [],
  };

  const addPrice = (formik, index) => {
    if (index !== null && index !== undefined) {
      setIndexPrice(index);
      setIndexCancel(index);
    } else {
      setIndexPrice(formik?.values?.period_list?.length);
      setIndexCancel(null);
    }
    setShowPagePrice(!showPagePrice);
    setShowPageIndex(!showPageIndex);
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
      >
        {(props) => (
          <Form>
            <>
              {showPagePrice && (
                <DetailPriceRegular
                  formik={props}
                  index={indexPrice}
                  indexCancel={indexCancel}
                  toggle={addPrice}
                />
              )}
              {showPageIndex && ( 
                <div className="menu-container">
                  {pending && <Loader loading={pending} />}
                  <div className="col-md-12 px-5">
                    <div className="d-flex align-items-center">
                      <div className="treatment-title menu-title text-extra-bold pl-0">
                        <div className="treatment-title-icon ">
                          Detail {t("field.regularPrice")}
                        </div>
                      </div>
                      <div className="ml-auto">
                        <Button
                          variant="secondary"
                          rounded
                          leftIcon="close"
                          title={t("commons.back")}
                          onClick={() => 
                            history.push({ pathname: "/product", state: { tab: "regular" } }) }
                        />
                      </div>
                    </div>
                    <Card className="rounded">
                      <CardHeader title={t("field.priceName")} />
                      <CardBody>
                        <Input
                          title={t("field.priceName")}
                          label="name"
                          type="text"
                          errors={props?.errors}
                          touched={props?.touched}
                          disabled
                          {...props?.getFieldProps("name")}
                          value={props.values.name}
                        />
                      </CardBody>
                    </Card>
                    <Card className="rounded">
                      <CardHeader title={t("field.airport")} />
                      <CardBody>
                        <Select2Multi
                          title={t("field.airport")}
                          name="airport_list"
                          options={dataAirport}
                          className="mb-4"
                          errors={props?.errors}
                          touched={props?.touched}
                          value={props?.values?.airport_list}
                          disabled
                          onChange={(name, value) => {
                            let data = value
                            if (!data.length > 0) {
                              data = "";
                            }
                            const airports = [];
                            if(data.length) {
                              data.map((i) => {
                                const index = dataAirport.findIndex(x => x.id === i);
                                const airportFormat = `${dataAirport[index].code} - ${dataAirport[index].city}`;
                                airports.push(airportFormat);
                              })
                            }
                            props?.setFieldValue("airport_list", data);
                            props?.setFieldValue("airports", airports);
                          }}
                          onBlur={props?.setFieldTouched}
                        />
                      </CardBody>
                    </Card>
                    <Card className="rounded">
                      <CardHeader title={t("product.priceTaxInfo")} />
                      <CardBody style={{ paddingTop: '16px' }}>
                        <Checkbox
                          disabled
                          style={{ marginTop: "30px" }}
                          name="tax.is_includes_concession"
                          checked={props?.values?.tax?.is_includes_concession}
                          value={1}
                          label={t("product.includes_concession")}
                        />
                        <Checkbox
                          disabled
                          style={{ marginTop: "30px" }}
                          name="tax.is_includes_ppn"
                          checked={props?.values?.tax?.is_includes_ppn}
                          value={1}
                          label={t("product.includes_ppn")}
                        />
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader title={t("field.price")} />
                      <CardBody>
                        {props?.values?.period_list !== undefined && (
                          <Row
                            style={{
                              fontWeight: 800,
                              fontSize: "16px",
                              color: "rgba(36, 39, 38, 0.3)",
                              paddingBottom: 3,
                            }}
                          >
                            <Col md={7} className="px-0">
                              <Row>
                                <Col md={2} className="px-0">
                                  <div className="text-center">
                                    {t("commons.number")}
                                  </div>
                                </Col>
                                <Col md={5}>
                                  <div>{t("field.product")}</div>
                                </Col>
                                <Col md={5}>
                                  <div>{t("commons.activities")}</div>
                                </Col>
                              </Row>
                            </Col>
                            <Col md={2} className="pr-0">
                              <div className="text-center">
                                {t("promo.startDate")}
                              </div>
                            </Col>
                            <Col md={3} className="px-0">
                              <div className="text-center">
                                {t("promo.endDate")}
                              </div>
                            </Col>
                          </Row>
                        )}
                        {props?.values?.period_list?.length < 1 && (
                          <CardFlush
                            list
                            label={t("commons.no_data")}
                            size={{ label: 12 }}
                          />
                        )}
                        {props?.values?.period_list?.map((item, index) => {
                          const flights = [];
                          const serviceList = [];
                          if (item?.is_checked_fast == 1) {
                            serviceList.push("Fast Track");
                            item?.fast_track?.flight_types?.map((flight) => {
                              if (flight === 5) {
                                flights?.push(t("field.arrival"));
                              }
                              if (flight === 6) {
                                flights?.push(t("field.departure"));
                              }
                            });
                          }
                          if (item?.is_checked_meet_dom == 1) {
                            serviceList.push("Meet and Greet Domestic");
                            item?.meet_and_greet_dom?.flight_types?.map(
                              (flight) => {
                                if (flight === 5) {
                                  flights?.push(t("field.arrival"));
                                }
                                if (flight === 6) {
                                  flights?.push(t("field.departure"));
                                }
                              }
                            );
                          }
                          if (item?.is_checked_meet_inter == 1) {
                            serviceList.push("Meet and Greet International");
                            item?.meet_and_greet_inter?.flight_types?.map(
                              (flight) => {
                                if (flight === 5) {
                                  flights?.push(t("field.arrival"));
                                }
                                if (flight === 6) {
                                  flights?.push(t("field.departure"));
                                }
                              }
                            );
                          }
                          if (item?.is_checked_transfer == 1) {
                            serviceList.push("Transfer");
                            item?.transfer?.flight_types?.map((flight) => {
                              if (flight === 1) {
                                flights?.push("International to International");
                              }
                              if (flight === 2) {
                                flights?.push(t("International to Domestic"));
                              }
                              if (flight === 3) {
                                flights?.push(t("Domestic to International"));
                              }
                              if (flight === 4) {
                                flights?.push(t("Domestic to Domestic"));
                              }
                            });
                          }
                          if (item?.is_checked_lounge == 1) {
                            serviceList.push("Lounge");
                          }

                          const flightList = [...new Set(flights)];

                          return (
                            <Row
                              style={{
                                paddingTop: 8,
                                paddingBottom: 8,
                              }}
                            >
                              <Col md={7} className="px-0">
                                <Row>
                                  <Col md={2} className="px-0">
                                    <div className="text-center">
                                      {index + 1}
                                    </div>
                                  </Col>
                                  <Col md={5}>
                                    <div className="row">
                                      {serviceList?.map((item, i) => (
                                        <LabelPills data={item} />
                                      ))}
                                    </div>
                                  </Col>
                                  <Col md={5}>
                                    <div className="row">
                                      {flightList?.map((item, i) => (
                                        <LabelPills data={item} />
                                      ))}
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={2} className="pr-0">
                                <div className="text-center">
                                  {moment(item?.start_date).format(
                                    "D MMMM YYYY"
                                  )}
                                </div>
                              </Col>
                              <Col md={3} className="px-0">
                                <div className="text-center">
                                  {moment(item?.end_date).format("D MMMM YYYY")}
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      float: "right",
                                    }}
                                    onClick={() => addPrice(props, index)}
                                    className="material-icons-round right-icon"
                                  >
                                    navigate_next
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          );
                        })}
                      </CardBody>
                    </Card>
                  </div>
                </div>  
              )}
            </>
          </Form>
        )}
      </Formik>
    </>   
  );
};

const mapStateToProps = ({ airport }) => {
  return { airport };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Index));
