import React from "react";
import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";

import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import DetailButton from "../../components/table/detailButton";
import Loader from "../../components/commons/Loader";
import Button from "../../components/buttons/Button";
import SearchButton from "../../components/buttons/SearchButton";
import Table from "../../components/table";
import ConfirmationModal from "../../components/modal/ConfirmModal";
import TableStatus from "../../components/table/status";
import SelectFilter from "../../components/forms/SelectFilter";
import Card from "../../components/cards/card";
import CancelReasonModal from "../../components/modal";
import RescheduleBookingModal from "./modal/RescheduleBookingModal";
import ModalFilter from "./modal/modalFilter";
import { toastSuccess, toastError } from "../../components/commons/toast";
import { withTrans } from "../../i18n/withTrans";
import { history } from "../../utils/History";
import { getUser } from "../../utils/User";

// Service
import CorpReservService from "../../store/actions/corp_reservation";
import CorporateService from "../../store/actions/master_data/corporate";
import AirportService from "../../store/actions/master_data/airport";
import BookingService from "../../store/actions/booking";

const CorpPrice = ({ t, pending, error, data_delete }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();
  const position = currentUser?.user?.role_name;

  const [cancelReservationModal, setCancelReservationModal] = useState(false);
  const [dataCorpPrice, setDataCorpPrice] = useState([]);
  const [dataCorpPriceRow, setDataCorpPriceRow] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [corporateFilter, setCorporateFilter] = useState("");
  const [dataCorporate, setDataCorporate] = useState([]);
  const [dataAirport, setDataAirport] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [rescheduleModal, setRescheduleModal] = useState(false);
  const [dataRescheduleBooking, setDataRescheduleBooking] = useState([]);
  const [cancelReasonModal, setCancelReasonModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [paramFilter, setParamFilter] = useState({
    status: "",
    booking_type: "",
    start_date: "",
    end_date: "",
    service_start_date: "",
    service_end_date: "",
    airport_id:
      currentUser?.user.role_code === "admin_bandara" ||
      currentUser?.user.role_code === "supervisor" ||
      currentUser?.user.role_code === "assistant" ||
      currentUser?.user.role_code === "finance_bandara" ||
      currentUser?.user.role_code === "sales_bandara"
        ? currentUser?.user.airport_id
        : "",
  });

  const lang = localStorage.getItem('joumpa_language')

  useEffect(() => {
    const justLogin = localStorage.getItem("just_login");

    if (justLogin) {
      toastSuccess(`${lang === 'id' ? `Selamat Anda berhasil login sebagai ${justLogin}` : `Well done! you have logged in as ${justLogin}`}`);

      localStorage.removeItem("just_login");
    }
  }, []);

  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };
  const handleFilter = (value) => {
    setCorporateFilter(value);
  };

  const handleCorporate = () => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 99999,
      };
      dispatch(CorporateService.get(param, resolve));
    }).then((res) => {
      setDataCorporate(res?.data);
    });
  };

  const corporate_list = dataCorporate?.map((corporate) => ({
    value: corporate?.id,
    label: corporate?.name,
  }));

  const handleAirport = () => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      let list_airports = [];
      if (res) {
        list_airports = res?.data?.map((airport) => ({
          value: airport?.id,
          label: `${airport?.code}-${airport?.city}-${airport?.name} ${airport?.uniform === 'Indonesia' ? "" : "-" + airport?.uniform}`,
        }));
      }
      setDataAirport(list_airports);
    });
  };
  
  useEffect(() => {
    handleAirport();
    handleCorporate();
  }, []);

  const onApplyFilter = (param) => {
    setParamFilter({
      airport_id: param.airport_id,
      status: param?.status,
      booking_type: param?.booking_type,
      start_date: param?.start_date,
      end_date: param?.end_date,
      service_start_date: param?.service_start_date,
      service_end_date: param?.service_end_date
    })
    setShowModalFilter(false);
  };

  useEffect(() => {
    if (error) {
      setLoadingSubmit(false);
    }
  }, [error]);

  useEffect(() => {
    if (data_delete?.data?.status === 200) {
      setLoadingSubmit(false);
    }
  }, [data_delete]);

  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = {
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        id: "",
        corporate_id:
          position === "Corporate"
            ? currentUser?.user?.corporate_id
            : corporateFilter,
        airport_id: paramFilter?.airport_id !== "" ? paramFilter?.airport_id : "",
        status: paramFilter?.status !== "" ? paramFilter?.status : "",
        booking_type: paramFilter?.booking_type !== "" ? paramFilter?.booking_type : "",
        start_date:
          paramFilter?.start_date !== ""
            ? moment(paramFilter?.start_date).format("YYYY-MM-DD")
            : "",
        end_date:
          paramFilter?.end_date !== ""
            ? moment(paramFilter?.end_date).format("YYYY-MM-DD")
            : "",
        service_start_date:
          paramFilter?.service_start_date !== ""
            ? moment(paramFilter?.service_start_date).format("YYYY-MM-DD")
            : "",
        service_end_date: 
          paramFilter?.service_end_date !== ""
            ? moment(paramFilter?.service_end_date).format("YYYY-MM-DD")
            : "",
        search_text: searchText,
      };
      dispatch(CorpReservService.get(param, resolve));
    }).then((res) => {
      setDataCorpPrice(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  const handleFilterModal = () => {
    setShowModalFilter(true);
  };

  useEffect(() => {
    handleRefresh();
  }, [page, searchText, paramFilter, corporateFilter]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [searchText, paramFilter, corporateFilter]);

  const showCancelReservationModal = (data) => {
    setCancelReservationModal(!cancelReservationModal);
    setCancelReason("");
    setDataCorpPriceRow(data ? data : []);
  };

  const handleCancelReservation = () => {
    const callback = () => {
      handleRefresh();
      setCancelReservationModal(!cancelReservationModal);
      setCancelReason("");
    };

    if (!dataCorpPriceRow) {
      return;
    }

    setLoadingSubmit(true);
    const id = dataCorpPriceRow?.id;

    if (cancelReason === "") {
      toastError(`${t('commons.empty_data_message')} ${t("field.reasonCancellation")}`);
      setLoadingSubmit(false);
    } else {
      new Promise((resolve) => {
        dispatch(CorpReservService.del({ order_id: id, cancel_reason: cancelReason }, resolve, callback));
      }).then((res) => {
        setLoadingSubmit(false);
      });
    }
  };

  const showRescheduleModal = (data) => {
    setRescheduleModal(!rescheduleModal);
    setDataRescheduleBooking(data ? data : []);
  };

  const handleRescheduleBooking = (param) => {
    const callback = () => {
      handleRefresh();
      setRescheduleModal(!rescheduleModal);
    };
    dispatch(BookingService.rescheduleBooking(param, callback));
  };

  const exportPDF = (param) => {
    new Promise((resolve) => {
      dispatch(BookingService.exportDetailBooking(
        {order_id: param?.id}, 
        param?.unique_transaction_id, 
        resolve)
      );
    }).then((res) => {});
  };

  const columns = [
    {
      Header: "#ID",
      accessor: "unique_transaction_id",
    },
    {
      Header: t("field.bookingTime").toUpperCase(),
      Cell: ({ row }) => {
        const { original } = row;
        let { booking_date } = original;
        return `${moment(booking_date).format("DD/MM/YYYY")}, ${moment(
          booking_date
        ).format("HH:mm")}`;
      },
    },
    {
      Header: t("report.serviceTime").toUpperCase(),
      Cell: ({ row }) => {
        const { original } = row;
        let date = moment(original?.date)?.format("DD/MM/yyyy");
        let time = original?.time;
        return date && time ? `${date}, ${time}` : '-';
      },
    },
    {
      Header: t("field.flight").toUpperCase(),
      Cell: ({ row }) => {
        const { original } = row;
        if(original.type === 1) {
          return t("field.arrival") + "-" + original?.airplane_code + original?.airplane_number;
        } else if(original.type === 2) {
          return t("field.departure") + "-" + original?.airplane_code + original?.airplane_number;
        } else if(original.type === 3) {
          return t("field.transfer") + "-" + original?.airplane_code + original?.airplane_number;
        }
      },
    },
    {
      Header: t("field.product").toUpperCase(),
      accessor: "product_name_eng",
    },
    {
      Header: t("field.corporate").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return <>{original?.corporate_name ?? "-"}</>;
      },
      show: currentUser.user.role_code !== "corporate",
    },
    {
      Header: t("field.airport").toUpperCase(),
      Cell: ({ row }) => {
        const { original } = row;
        if(original.type === 1) {
          let { airport_city_to, airport_code_to, airport_name_to, } = original;
          return `${airport_code_to}-${airport_city_to}-${airport_name_to}`;
        } else if(original.type === 2) {
          let { airport_city_from, airport_code_from, airport_name_from, } = original;
          return `${airport_code_from}-${airport_city_from}-${airport_name_from}`;
        } else if(original.type === 3) {
          let { airport_city_transfer, airport_code_transfer, airport_name_transfer, } = original;
          return `${airport_code_transfer}-${airport_city_transfer}-${airport_name_transfer}`;
        }
      },
      show: currentUser.user.role_code !== "admin_bandara" 
        && currentUser.user.role_code !== "supervisor"
        && currentUser.user.role_code !== "assistant",
    },
    {
      Header: "STATUS",
      Cell: ({ row }) => {
        const { original } = row;
        let { status } = original;
        return (
          <TableStatus
            status={
              status === 0
                ? t("status.new")
                : status === 1
                  ? t("status.assigned")
                  : status === 2
                    ? t("status.onProgress")
                    : status === 5
                      ? t("status.done")
                      : t("status.cancelled")
            }
            color={
              status === 0
                ? "danger"
                : status === 1 || status === 2
                  ? "warning"
                  : status === 5
                    ? "primary"
                    : "secondary"
            }
          />
        );
      },
      minWidth: 125,
    },
    {
      Header: "",
      accessor: "id",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <DetailButton>
            <MenuItem
              onClick={() =>
                history.push(`/corp_reservation/detail/${original?.id}`)
              }
            >
              <span style={{ fontSize: 14 }}>{t("commons.detail")}</span>
            </MenuItem>
            {original?.status === -1 && (
              <MenuItem onClick={() => {
                setCancelReason(original?.cancel_reason);
                setCancelReasonModal(!cancelReasonModal);
              }}>
                <span style={{ fontSize: 14 }}>
                  {t("field.detailCancellation")}
                </span>
              </MenuItem>
            )}
            {(currentUser?.user?.role_code !== "lead_contact_center" &&
              currentUser?.user?.role_code !== "contact_center" &&
              original?.status !== 5 && original?.status !== -1) && (
              <MenuItem onClick={() => showCancelReservationModal(original)}>
                <span style={{ fontSize: 14 }}>
                  {t("commons.cancelReservation")}
                </span>
              </MenuItem>
            )}
            {(currentUser?.user?.role_code === "lead_contact_center" ||
              currentUser?.user?.role_code === "contact_center") &&
              original?.status !== -1 && (
              <MenuItem onClick={() => showCancelReservationModal(original)}>
                <span style={{ fontSize: 14 }}>
                  {t("commons.cancelReservation")}
                </span>
              </MenuItem>
            )}
            {(currentUser?.user?.role_code === "supervisor" ||
              currentUser?.user?.role_code === "admin_bandara" ||
              currentUser?.user?.role_code === "admin_pusat" ||
              currentUser?.user?.role_code === "lead_contact_center" ||
              currentUser?.user?.role_code === "contact_center") && 
              (original?.status === 0 || original?.status === 1) && (
              <MenuItem
                onClick={() => showRescheduleModal(original)}
              >
                <span style={{ fontSize: 14 }}>Reschedule</span>
              </MenuItem>
            )}
            {(currentUser?.user?.role_code === "admin_pusat" ||
              currentUser?.user?.role_code === "lead_contact_center" ||
              currentUser?.user?.role_code === "contact_center" ||
              currentUser?.user?.role_code === "admin_bandara" ||
              currentUser?.user?.role_code === "supervisor" ||
              currentUser?.user?.role_code === "finance" ||
              currentUser?.user?.role_code === "finance_bandara") && (
              <MenuItem
                onClick={() => exportPDF(original)}
              >
                <span style={{ fontSize: 14 }}>Export</span>
              </MenuItem>
            )}
          </DetailButton>
        );
      },
    },
  ];

  return (
    <>
      {pending && <Loader fullScreen loading={pending} />}
      <HeaderMenu title={t("field.corporateReservation")}>
        <SearchButton
          placeholder={t("commons.enterKeyword")}
          toggle={(value) => handleSearch(value)}
          style={{ marginRight: 20 }}
        />
        {position !== "Corporate" && (
          <SelectFilter
            options={[
              {
                value: "",
                label: `${t("commons.all")} ${t("field.corporation")}`,
              },
              ...corporate_list,
            ]}
            placeholder={t("field.corporate")}
            value={corporateFilter}
            onChange={(value) => handleFilter(value.value)}
            toggle={(value) => handleFilter(value)}
          />
        )}
        <Button
          title="Filter"
          size="sm"
          rounded={true}
          variant="button-light btn-lg"
          onClick={handleFilterModal}
        />

        {(currentUser?.user?.role_code === "lead_contact_center" ||
          currentUser?.user?.role_code === "contact_center" ||
          currentUser?.user?.role_code === "corporate" ||
          currentUser?.user?.role_code === "admin_bandara" ||
          currentUser?.user?.role_code === "supervisor" ||
          currentUser?.user?.role_code === "assistant") ? (
          <>
            <div className="nav-separator mr-3 ml-3" />
            <Button
              title={t("field.newReservation")}
              leftIcon="add"
              rounded={true}
              variant="primary"
              onClick={() => history.push("corp_reservation/new-reservation")}
            />
          </>
        ) : (
          ""
        )}
      </HeaderMenu>
      <div className="content-container-side">
        <div className="overflow">
          <Table
            columns={columns.filter((item) => item.show !== false)}
            data={dataCorpPrice ?? []}
            pagination={{ page, totalData, maxPage, toggle: setPage }}
          />
        </div>
      </div>
      <ConfirmationModal
        show={cancelReservationModal}
        toggle={showCancelReservationModal}
        confirm={handleCancelReservation}
        messageData={`` + dataCorpPriceRow?.unique_transaction_id}
        reason={
          <div className="form-group" style={{ width: "100%" }}>
            <label className="form-label">
              {t("field.reasonCancellation")}
            </label>
            <textarea
              className="form-control"
              value={cancelReason}
              placeholder={`${t("commons.input")} ${t("field.reasonCancellation")}`}
              style={{
                background: 'white 0% 0% no-repeat padding-box',
                borderRadius: 6,
                paddingLeft: 12,
              }}
              rows={3}
              onChange={(e) => setCancelReason(e?.target?.value)}
            />
          </div>
        }
        icon="info"
        message={`${t("commons.areYouSureCancel")} ${t("field.reservation")} `}
        isDelete
        disabledConfirm={loadingSubmit}
      />
      <CancelReasonModal
        show={cancelReasonModal}
        toggle={() => setCancelReasonModal(!cancelReasonModal)}
        title={t("field.detailCancellation")}
        content={
          <Card className="w-100 rounded p-4">
            <div className="d-flex">
              <i
                className="material-icons-outlined icon-modal"
                style={{ marginRight: 10, marginTop: 0 }}
              >
                info
              </i>
              <div className="flex flex-column">
                <p style={{ fontSize: "16px", marginBottom: '0.5rem' }}>
                  <strong>{t("field.reasonCancellation")}</strong>
                </p>
                <p style={{ fontSize: "16px" }}>
                  {cancelReason}
                </p>
              </div>
            </div>
          </Card>
        }
      />
      <RescheduleBookingModal
        show={rescheduleModal}
        toggle={showRescheduleModal}
        data={dataRescheduleBooking}
        action={handleRescheduleBooking}
      />
      <ModalFilter
        show={showModalFilter}
        toggle={() => setShowModalFilter(false)}
        airport={dataAirport}
        filter={paramFilter}
        toggleFilter={(props) => {
          setParamFilter({
            status: props.status,
            booking_type: props.booking_type,
            start_date: props.start_date,
            end_date: props.end_date,
            service_start_date: props.service_start_date,
            service_end_date: props.service_end_date,
            airport_id: props.airport_id,
          });
        }}
        onApplyFilter={onApplyFilter}
      />
    </>
  );
};

const mapStateToProps = ({ corp_reserv: { error, pending, data_delete } }) => {
  return { error, pending, data_delete };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(CorpPrice)
);
