import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import React from "react";
import ButtonModal from "../../../components/modal/ButtonModal";
import CardFlush from "../../../components/cards/cardFlush";
import UserModal from "./FormUserModal";

import { withTrans } from "../../../i18n/withTrans";
import { getUser } from "../../../utils/User";

//Service
import UserService from "../../../store/actions/master_data/user";

const DetailUserModal = ({
  t,
  show,
  toggle,
  data,
  detailUser,
  detailModal,
  refresh,
}) => {
  const dispatch = useDispatch();
  const currentUser = getUser();

  const [editModal, setEditModal] = useState(false);

  const showEditModal = () => {
    setEditModal(!editModal);
  };
  const handleEdit = (param) => {
    const callback = () => {
      refresh();
      setEditModal(!editModal);
      detailModal(!detailModal);
    };
    dispatch(UserService.put(param, callback));
  };

  return (
    <>
      <Modal isOpen={show}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {`Detail`}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Container className="px-3">
          <ModalBody className="px-4">
            <div className="h5 pb-30 text-extra-bold">{data?.name}</div>
            <div className="wrapper-detail-card">
              {
                (detailUser = [
                  { label: "Email", desc: data?.email ?? "-" },
                  { label: "Role", desc: data?.role_name ?? "-" },
                ].map((detailUser, index) => (
                  <CardFlush
                    list
                    key={index}
                    label={detailUser.label}
                    desc={detailUser.desc}
                  />
                )))
              }
              {data?.role_code === "admin_bandara" && (
                <CardFlush
                  list
                  label={"Bandara"}
                  desc={`${data?.airport_code}-${data?.airport_city}-${data?.airport_name}` ?? " - "}
                />
              )}
              {data?.role_code === "supervisor" && (
                <CardFlush
                  list
                  label={"Bandara"}
                  desc={`${data?.airport_code}-${data?.airport_city}-${data?.airport_name}` ?? " - "}
                />
              )}
              {data?.role_code === "corporate" && (
                <CardFlush
                  list
                  label={t("field.corporate")}
                  desc={data?.corporate_name ?? " - "}
                />
              )}
            </div>
          </ModalBody>
          {(currentUser.user.role_code !== "contact_center" &&
            currentUser.user.role_code !== "lead_contact_center") && (
            <ModalFooter>
              <ButtonModal
                toggle={toggle}
                cancelTitle={t("commons.close")}
                confirmTitle="Edit"
                confirm={showEditModal}
              />
            </ModalFooter>
          )}
        </Container>
      </Modal>

      <UserModal
        show={editModal}
        toggle={showEditModal}
        action={handleEdit}
        data={data}
      />
    </>
  );
};

export default withTrans(DetailUserModal);
