import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";

import CorporateLoungeModal from "./CorporateLoungeModal";
import Button from "../../../../components/buttons/Button";
import InputPrepend from "../../../../components/forms/InputPrepend";
import Select2Multi from "../../../../components/forms/Select2Multi";
import Select2 from "../../../../components/forms/Select2";
import DynamicForm from "../../../../components/forms/DynamicForm";
import Checkbox from "../../../../components/forms/Checkbox";
import Card from "../../../../components/cards/card";
import CardHeader from "../../../../components/cards/cardHeader";
import CardBody from "../../../../components/cards/cardBody";
import CardFlush from "../../../../components/cards/cardFlush";
import { withTrans } from "../../../../i18n/withTrans";
import { toastError } from "../../../../components/commons/toast";
import { flightTypeList, flightTypeTransferList, CarList } from "../../../../utils/Constants";

import AirportService from "../../../../store/actions/master_data/airport";

const ShowTabProduct = ({ t, active, props, idType, index, dataAirport, toggle }) => {
  const dispatch = useDispatch();
  const [modalCorpLounge, setModalCorpLounge] = useState(false);
  const [indexCorpLounge, setIndexCorpLounge] = useState();
  const [loungesList, setLoungesList] = useState([]);
  const [indexCancelCorpLounge, setIndexCancelCorpLounge] = useState();
  const [carsList, setCarsList] = useState(CarList);
  const [showGroup, setShowGroup] = useState(0);

  let name, value, checked, nameChecked, labelChecked;
  let corporateType = props?.values?.type_list[idType]?.corporate_type;
  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  if (active === "0") {
    name = `type_list[${idType}].period_list[${index}].meet_and_greet_dom`;
    value = props?.values?.type_list[idType]?.period_list[index]?.meet_and_greet_dom;
    nameChecked = `type_list[${idType}].period_list[${index}].is_checked_meet_dom`;
    checked = props?.values?.type_list[idType]?.period_list[index]?.is_checked_meet_dom;
    labelChecked = `${t("product.activate_meet")}`;
  } else if (active === "1") {
    name = `type_list[${idType}].period_list[${index}].meet_and_greet_inter`;
    value = props?.values?.type_list[idType]?.period_list[index]?.meet_and_greet_inter;
    nameChecked = `type_list[${idType}].period_list[${index}].is_checked_meet_inter`;
    checked = props?.values?.type_list[idType]?.period_list[index]?.is_checked_meet_inter;
    labelChecked = `${t("product.activate_meet_inter")}`;
  } else if (active === "2") {
    name = `type_list[${idType}].period_list[${index}].fast_track`;
    value = props?.values?.type_list[idType]?.period_list[index]?.fast_track;
    nameChecked = `type_list[${idType}].period_list[${index}].is_checked_fast`;
    checked = props?.values?.type_list[idType]?.period_list[index]?.is_checked_fast;
    labelChecked = `${t("product.activate_fast")}`;
  } else if (active === "3") {
    name = `type_list[${idType}].period_list[${index}].transfer`;
    value = props?.values?.type_list[idType]?.period_list[index]?.transfer;
    nameChecked = `type_list[${idType}].period_list[${index}].is_checked_transfer`;
    checked = props?.values?.type_list[idType]?.period_list[index]?.is_checked_transfer;
    labelChecked = `${t("product.activate_transfer")}`;
  } else if (active === "4") {
    name = `type_list[${idType}].period_list[${index}].lounge`;
    value = props?.values?.type_list[idType]?.period_list[index]?.lounge;
    nameChecked = `type_list[${idType}].period_list[${index}].is_checked_lounge`;
    checked = props?.values?.type_list[idType]?.period_list[index]?.is_checked_lounge;
    labelChecked = `${t("product.activate_lounge")}`;
  } else if (active === "5") {
    name = `type_list[${idType}].period_list[${index}].transport`;
    value = props?.values?.type_list[idType]?.period_list[index]?.transport;
    nameChecked = `type_list[${idType}].period_list[${index}].is_checked_transport`;
    checked = props?.values?.type_list[idType]?.period_list[index]?.is_checked_transport;
    labelChecked = `${t("product.activate_transport")}`;
  }

  useEffect(() => {
    if (active === "4" && value?.airport_list?.length) {
      new Promise((resolve) => {
        let param = {
          length: 99999,
          airport_ids: value?.airport_list,
        };
        dispatch(AirportService.getListLounge(param, resolve));
      }).then((res) => {
        let list = [];
        if (res && res.data) {
          res.data?.map(
            (item) =>
              (list = [
                ...list,
                {
                  ...item,
                  value: item?.id,
                  label: `${item?.code}-${item?.name} (${
                    item?.route_type?.charAt(0).toUpperCase() + item?.route_type?.slice(1)
                  })`,
                },
              ])
          );
        }
        setLoungesList(list);
      });
    }
  }, [value?.airport_list]);

  const handleSelectCar = (nameSelected, valueSelected) => {
    let list = value?.transport_list;
    let i = list?.findIndex((x) => x?.car_name === valueSelected);
    if (i === -1) {
      props.setFieldValue(nameSelected, valueSelected);
    } else {
      toastError("Data sudah ditambahkan");
    }
  };

  const deleteListCar = (item, i) => {
    if (value?.transport_list && item && (i !== undefined || i !== null)) {
      if (value?.transport_list?.length === 1) {
        value.transport_list = [
          { car_name: "", price: "", capacity: "" }
        ];
        props?.setFieldValue(name, value);
      } else {
        let prices = value?.transport_list;
        value?.transport_list?.splice(i, 1);
        value.transport_list = prices;
        props?.setFieldValue(name, value);
      }
    }
  };

  const handleAddCar = () => {
    let transport_list = value?.transport_list;
    let last_element =
      transport_list.length > 0
        ? transport_list[transport_list.length - 1]
        : null;
    if (last_element) {
      if (
        last_element.car_name &&
        last_element.capacity
      ) {
        transport_list = [
          ...transport_list,
          { car_name: "", price: "", capacity: "" },
        ];
      } else {
        toastError(t("commons.empty_data_message"));
      }
    } else {
      transport_list = [
        ...transport_list,
        { car_name: "", price: "", capacity: "" },
      ];
    }

    value.transport_list = transport_list;
    props?.setFieldValue(name, value);
  };

  const handleSelectLounge = (nameSelected, valueSelected, indexLounge) => {
    let list = value?.lounge_list;
    let i = list?.findIndex((x) => x?.lounge_id === valueSelected);
    const indexSelected = loungesList
      ?.map(function (e) {
        return e.id;
      })
      .indexOf(valueSelected);
    if (i === -1) {
      props.setFieldValue(nameSelected, valueSelected);
      props.setFieldValue(
        `${name}.lounge_list[${indexLounge}].airport_id`,
        loungesList[indexSelected]?.airport_id
      );
    } else {
      toastError("Data sudah ditambahkan");
    }
  };

  const deleteListLounge = (item, i) => {
    if (value?.lounge_list && item && (i !== undefined || i !== null)) {
      if (value?.lounge_list?.length === 1) {
        value.lounge_list = [
          {
            lounge_id: "",
            adult_price: "",
            child_price: "",
            infant_price: "",
          }
        ];
        props?.setFieldValue(name, value);
      } else {
        let prices = value?.lounge_list;
        value?.lounge_list?.splice(i, 1);
        value.lounge_list = prices;
        props?.setFieldValue(name, value);
      }
    }
  };

  const handleAddLounge = () => {
    let lounge_list = value?.lounge_list;
    let last_element =
      lounge_list?.length > 0 ? lounge_list[lounge_list?.length - 1] : null;
    if (last_element) {
      if (
        last_element?.lounge_id
      ) {
        lounge_list = [
          ...lounge_list,
          {
            lounge_id: "",
            adult_price: "",
            child_price: "",
            infant_price: "",
          },
        ];
      } else {
        toastError(`${t("commons.empty_data_message")} ${t("field.lounge")}!`);
      }
    } else {
      lounge_list = [
        ...lounge_list,
        { lounge_id: "", adult_price: "", child_price: "", infant_price: "" },
      ];
    }

    value.lounge_list = lounge_list;
    props?.setFieldValue(name, value);
  };

  const addCorporateLounge = (index) => {
    if (index !== null && index !== undefined) {
      setIndexCorpLounge(index);
      setIndexCancelCorpLounge(index);
    } else {
      if (value?.corporate_lounge_list === undefined) {
        value.corporate_lounge_list = [];
      }
      let corporate_lounge_list = value?.corporate_lounge_list;
      props?.setFieldValue(`${name}.corporate_lounge_list`, [
        ...corporate_lounge_list,
        {
          name: "",
          route_type: "",
          adult_price: "",
          child_price: "",
          infant_price: "",
        },
      ]);
      setIndexCorpLounge(value?.corporate_lounge_list?.length);
      setIndexCancelCorpLounge(null);
    }
    setModalCorpLounge(true);
  }

  const handleCancelCorpLounge = () => {
    let i = value?.corporate_lounge_list?.length - 1;
    if (indexCancelCorpLounge === null || indexCancelCorpLounge === undefined) {
      let corporate_lounge_list = value?.corporate_lounge_list;
      corporate_lounge_list.splice(i, 1);
      value.corporate_lounge_list = corporate_lounge_list;
      props?.setFieldValue(name, value);
      setModalCorpLounge(false);
    } else {
      setModalCorpLounge(false);
    }
  }

  const handleDeleteCorpLounge = () => {
    let corporate_lounge_list = value?.corporate_lounge_list;
    corporate_lounge_list.splice(indexCorpLounge, 1);
    value.corporate_lounge_list = corporate_lounge_list;
    props?.setFieldValue(name, value);
    setModalCorpLounge(false);
  }

  const multipleListInputPrice = (
    nameFieldAdult,
    valueFieldAdult,
    nameFieldChild,
    valueFieldChild,
    nameFieldInfant,
    valueFieldInfant
  ) => {
    return [
      {
        prefix: "Rp ",
        isCurrency: true,
        thousandSeparator: true,
        placeholder: `${t("commons.adult")}`,
        name: nameFieldAdult,
        value: valueFieldAdult,
        onChange: (data) => {
          const val = data.target.value;
          const price_adult = Number(val.replace(/[^0-9\.-]+/g, ""));
          props?.setFieldValue(nameFieldAdult, price_adult);
        },
        onKeyDown: (e) => exceptThisSymbols.includes(e.key) && e.preventDefault()
      },
      {
        prefix: "Rp ",
        isCurrency: true,
        thousandSeparator: true,
        placeholder: `${t("commons.child")}`,
        name: nameFieldChild,
        value: valueFieldChild,
        onChange: (data) => {
          const val = data.target.value;
          const price_adult = Number(val.replace(/[^0-9\.-]+/g, ""));
          props?.setFieldValue(nameFieldChild, price_adult);
        },
        onKeyDown: (e) => exceptThisSymbols.includes(e.key) && e.preventDefault()
      },
      {
        prefix: "Rp ",
        isCurrency: true,
        thousandSeparator: true,
        placeholder: `${t("commons.infantLabel")}`,
        name: nameFieldInfant,
        value: valueFieldInfant,
        onChange: (data) => {
          const val = data.target.value;
          const price_adult = Number(val.replace(/[^0-9\.-]+/g, ""));
          props?.setFieldValue(nameFieldInfant, price_adult);
        },
        onKeyDown: (e) => exceptThisSymbols.includes(e.key) && e.preventDefault()
      },
    ]
  }

  return (
    <>
      <div className="checkbox-wrapper">
        <Checkbox
          style={{ marginTop: "30px" }}
          name={nameChecked}
          checked={checked}
          onChange={(e) => {
            if (checked) {
              props?.setFieldValue(nameChecked, null);
              props?.setFieldValue(name, {});
            } else {
              props?.setFieldValue(nameChecked, ["1"]);
              props?.setFieldValue(
                `${name}.group_list`,
                props?.values?.type_list[idType]?.group_list
              );
              if (active === "4") {
                props?.setFieldValue(`${name}.lounge_list`, [
                  {
                    lounge_id: "",
                    adult_price: "",
                    child_price: "",
                    infant_price: "",
                  },
                ]);
              }
              if (active === "5") {
                props?.setFieldValue(`${name}.transport_list`, [
                  { car_name: "", price: "", capacity: "" },
                ]);
              }
            }
          }}
          value={1}
          label={labelChecked}
        />
      </div>
      {checked == 1 && (
        <>
          <Select2Multi
            title={t("field.airport")}
            name={`${name}.airport_list`}
            options={[
              {
                label: `${t("commons.all")} ${t("field.airport")}`,
                value: "all",
              },
              ...dataAirport,
            ]}
            className="mb-4"
            errors={props?.errors}
            touched={props?.touched}
            value={value?.airport_list}
            onChange={(x, val) => {
              let data;
              let airport_code_list = [];
              if (val.includes("all")) {
                data = dataAirport?.map((item, index) => {
                  return item?.value;
                });
                dataAirport?.map((item, index) => {
                  airport_code_list?.push({
                    id: item?.id,
                    name: item?.code
                  });
                });
              } else {
                data = val;
                dataAirport?.map((itemDataAirport) => {
                  val?.map((itemVal) => {
                    if (itemDataAirport?.value === itemVal) {
                      airport_code_list?.push({
                        id: itemDataAirport?.id,
                        name: itemDataAirport?.code
                      });
                    }
                  });
                });
              }
              if (data.length < 1) {
                data = "";
              }
              props?.setFieldValue(`${name}.airport_list`, data);
              props?.setFieldValue(
                `${name}.airport_code_list`,
                airport_code_list
              );
            }}
            onBlur={props?.setFieldTouched}
          />
          {active !== "4" && active !== "5" && (
            <Select2Multi
              title={t("commons.activities")}
              name={`${name}.flight_list`}
              options={
                active === "3" ? flightTypeTransferList : flightTypeList(t)
              }
              className="mb-4"
              errors={props?.errors}
              touched={props?.touched}
              value={value?.flight_list}
              onChange={(x, val) => {
                let data = val;
                if (!data.length > 0) {
                  data = "";
                }
                props?.setFieldValue(`${name}.flight_list`, data);
              }}
              onBlur={props?.setFieldTouched}
            />
          )}
          <Card className="rounded">
            <CardHeader title={t("field.price")} />
            <CardBody style={{ paddingTop: 0 }}>
              {active === "5" ? (
                <div>
                  <div className="row" style={{ marginTop: 20 }}>
                    <div className="col-md-4" style={{ paddingRight: "0px" }}>
                      <div className="form-group" style={{ marginBottom: 0 }}>
                        <label className="form-label">
                          {t("field.carType")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4" style={{ paddingLeft: "0px" }}>
                      <div className="form-group" style={{ marginBottom: 0 }}>
                        <label className="form-label">
                          {t("field.capacity")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4" style={{ paddingLeft: "0px" }}>
                      <div className="form-group" style={{ marginBottom: 0 }}>
                        <label className="form-label">
                          {t("field.price")}
                        </label>
                      </div>
                    </div>
                  </div>
                  {value?.transport_list?.map((item, i) => {
                    return (
                      <DynamicForm
                        pbRow="2"
                        nameLeft={`${name}.transport_list[${i}].car_name`}
                        name={`${name}.transport_list[${i}].price`}
                        nameCenter={`${name}.transport_list[${i}].capacity`}
                        placeholderLeft={t("field.carType")}
                        placeholder={t("field.price")}
                        placeholderCenter={t("field.capacity")}
                        optionLeft={carsList}
                        valueLeft={value?.transport_list[i]?.car_name ?? ""}
                        formik={props}
                        type="number"
                        typeCenter="number"
                        currency
                        handleOptionLeft={(name, value) =>
                          handleSelectCar(name, value)
                        }
                        size={{ title: 4, center: 4, value: 4 }}
                        toggle={() => deleteListCar(item, i)}
                        onChangeRight={(e) => {
                          const val = e.target.value;
                          const price = Number(
                            val.replace(/[^0-9\.-]+/g, "")
                          );
                          props?.setFieldValue(
                            `${name}.transport_list[${i}].price`,
                            price
                          );
                        }}
                      />
                    );
                  })}
                  <div className="mb-5" style={{ textAlign: "center" }}>
                    <Button
                      variant="button secondary"
                      rounded
                      title={t("commons.add") + " " + t("commons.car")}
                      onClick={() => handleAddCar()}
                      type="Button"
                      style={{ padding: "8px 20px" }}
                    />
                  </div>
                </div>
              ) : active === "4" ? (
                <div>
                  <div className="row" style={{ marginTop: 20 }}>
                    <div className="col-md-3" style={{ paddingRight: "0px" }}>
                      <div className="form-group" style={{ marginBottom: 0 }}>
                        <label className="form-label">
                          {t("field.lounge")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                      <div className="form-group" style={{ marginBottom: 0 }}>
                        <label className="form-label">
                          {t("commons.adult")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                      <div className="form-group" style={{ marginBottom: 0 }}>
                        <label className="form-label">
                          {t("commons.child")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                      <div className="form-group" style={{ marginBottom: 0 }}>
                        <label className="form-label">
                          {t("commons.infantLabel")}
                        </label>
                      </div>
                    </div>
                  </div>
                  {value?.lounge_list?.map((item, i) => {
                    return (
                      <div>
                        <Row style={{ marginBottom: 0 }}>
                          <Col md={3} style={{ padding: 0 }}>
                            <Select2
                              name={`${name}.lounge_list[${i}].lounge_id`}
                              options={loungesList}
                              errors={props?.errors}
                              touched={props?.touched}
                              value={value?.lounge_list[i]?.lounge_id ?? ""}
                              onChange={(name, value) =>
                                handleSelectLounge(name, value, i)
                              }
                              onBlur={props?.setFieldTouched}
                            />
                          </Col>
                          <Col md={9} style={{ padding: 0 }}>
                            <InputPrepend
                              errors={props?.errors}
                              touched={props?.touched}
                              multiple={multipleListInputPrice(
                                `${name}.lounge_list[${i}].adult_price`,
                                value?.lounge_list[i]?.adult_price,
                                `${name}.lounge_list[${i}].child_price`,
                                value?.lounge_list[i]?.child_price,
                                `${name}.lounge_list[${i}].infant_price`,
                                value?.lounge_list[i]?.infant_price
                              )}
                            />
                            <div style={{ position: "absolute", right: 15, top: 15 }}>
                              <span
                                className="material-icons-round"
                                style={{
                                  cursor: toggle ? "pointer" : "default",
                                  opacity: toggle ? "0.8" : "0.1",
                                }}
                                onClick={() => deleteListLounge(item, i)}
                              >
                                close
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                  <div style={{ textAlign: "center" }}>
                    <Button
                      variant="button secondary"
                      rounded
                      title={t("commons.add") + " " + t("field.lounge")}
                      onClick={() => handleAddLounge()}
                      type="Button"
                      style={{ padding: "8px 20px" }}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  {value?.group_list?.map((item, index) => {
                    return item?.property_list?.length ? (
                          <>
                            {corporateType === "Group" && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  paddingTop: 15,
                                  fontSize: 14,
                                }}
                              >
                                <div className="text-extra-bold">
                                  {item?.group_type}
                                </div>
                                <div
                                  onClick={() =>
                                    setShowGroup(
                                      showGroup === index ? null : index
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <span className="material-icons-round left-icon">
                                    expand_more
                                  </span>
                                </div>
                              </div>
                            )}
                            {showGroup === index && (
                              <div>
                                <div className="row">
                                  <div className="col-md-3"></div>
                                  <div className="col-md-3">
                                    <div className="form-group" style={{ marginBottom: 0 }}>
                                      <label className="form-label mb-1">
                                        {t("commons.adult")}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                    <div className="form-group" style={{ marginBottom: 0 }}>
                                      <label className="form-label mb-1">
                                        {t("commons.child")}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                    <div className="form-group" style={{ marginBottom: 0 }}>
                                      <label className="form-label mb-1">
                                        {t("commons.infantLabel")}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {item?.property_list?.map((property, i) => {
                                  return (
                                    <>
                                      {corporateType === "MOB" ? (
                                        <>
                                          <InputPrepend
                                            mb="2"
                                            errors={props?.errors}
                                            touched={props?.touched}
                                            title={`${t("field.price")} Individu`}
                                            multiple={multipleListInputPrice(
                                              `${name}.group_list[${index}].property_list[${i}].prices.adult_price`,
                                              value?.group_list[index]?.property_list[i]?.prices?.adult_price,
                                              `${name}.group_list[${index}].property_list[${i}].prices.child_price`,
                                              value?.group_list[index]?.property_list[i]?.prices?.child_price,
                                              `${name}.group_list[${index}].property_list[${i}].prices.infant_price`,
                                              value?.group_list[index]?.property_list[i]?.prices?.infant_price
                                            )}
                                          />
                                          <InputPrepend
                                            mb="2"
                                            errors={props?.errors}
                                            touched={props?.touched}
                                            title={`${t("field.price")} MOB`}
                                            prefix="Rp "
                                            isCurrency={true}
                                            thousandSeparator={true}
                                            name={`${name}.group_list[${index}].property_list[${i}].prices.price`}
                                            value={value?.group_list[index]?.property_list[i]?.prices?.price}
                                            placeholder={`${t("field.price")}`}
                                            onChange={(data) => {
                                              const val = data.target.value;
                                              const price_mob = Number(val.replace(/[^0-9\.-]+/g, ""));
                                              props?.setFieldValue(
                                                `${name}.group_list[${index}].property_list[${i}].prices.price`,
                                                price_mob
                                              );
                                            }}
                                            onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                          />
                                        </>
                                      ) : corporateType === "Package" ? (
                                        <div className="checkbox-wrapper">
                                          <Checkbox
                                            style={{ marginTop: "30px" }}
                                            name={`${name}.group_list[${index}].property_list[${i}].prices.infant_is_free`}
                                            checked={value?.group_list[index]?.property_list[i]?.prices?.infant_is_free}
                                            onChange={props?.handleChange}
                                            value={1}
                                            label={t("product.infantIsFree")}
                                          />
                                          <InputPrepend
                                            mb="2"
                                            errors={props?.errors}
                                            touched={props?.touched}
                                            title={`${property?.min_pax} - ${property?.max_pax} pax`}
                                            prefix="Rp "
                                            isCurrency={true}
                                            thousandSeparator={true}
                                            name={`${name}.group_list[${index}].property_list[${i}].prices.price`}
                                            value={value?.group_list[index]?.property_list[i]?.prices?.price}
                                            placeholder={`${t("field.price")}`}
                                            onChange={(data) => {
                                              const val = data.target.value;
                                              const price = Number(val.replace(/[^0-9\.-]+/g, ""));
                                              props?.setFieldValue(
                                                `${name}.group_list[${index}].property_list[${i}].prices.price`,
                                                price
                                              );
                                            }}
                                            onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                          />
                                        </div>
                                      ) : (
                                        <>
                                          <InputPrepend
                                            mb="2"
                                            mt="2"
                                            errors={props?.errors}
                                            touched={props?.touched}
                                            title={
                                              corporateType === "Group" 
                                                ? `${property?.min_pax} - ${property?.max_pax} pax` 
                                                : `FIT ${t("field.price")}`
                                            }
                                            multiple={multipleListInputPrice(
                                              `${name}.group_list[${index}].property_list[${i}].prices.adult_price`,
                                              value?.group_list[index]?.property_list[i]?.prices?.adult_price,
                                              `${name}.group_list[${index}].property_list[${i}].prices.child_price`,
                                              value?.group_list[index]?.property_list[i]?.prices?.child_price,
                                              `${name}.group_list[${index}].property_list[${i}].prices.infant_price`,
                                              value?.group_list[index]?.property_list[i]?.prices?.infant_price
                                            )}
                                          />
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                              </div>
                            )}
                          </>
                    ) : (
                      <div></div>
                    )
                  })}
                </div>
              )}
            </CardBody>
          </Card>
          {active === "4" && (
            <>
              <Card className="rounded">
                <CardHeader title={`${t("field.price")} Corporate Lounge`} />
                <CardBody style={{ paddingTop: 0 }}>
                  {value?.corporate_lounge_list?.length > 0 && 
                    value?.corporate_lounge_list?.map((item, index) => {
                    return (
                      <CardFlush
                        list
                        label={
                          `${item?.name} ${item?.route_type 
                            ? `(${item?.route_type?.charAt(0).toUpperCase() + item?.route_type?.slice(1)})` 
                            : ''}`
                        }
                        button={
                          <div
                            className="d-flex justify-content-end"
                            style={{ cursor: "pointer" }}
                            onClick={() => addCorporateLounge(index)}
                          >
                            <span className="material-icons-round right-icon">
                              navigate_next
                            </span>
                          </div>
                        }
                        key={index}
                        size={{ label: 7, desc: 5 }}
                      />
                    )
                  })}
                  <div
                    style={{
                      color: "#63ae5c",
                      paddingTop: "20px",
                      display: "flex",
                      cursor: "pointer",
                    }}
                    onClick={() => addCorporateLounge()}
                  >
                    <div
                      style={{
                        borderRadius: "50%",
                        border: "solid 1px #63ae5c",
                        width: 30,
                        height: 30,
                        paddingTop: 2,
                        marginRight: 20,
                        textAlign: "center",
                      }}
                    >
                      <span className="material-icons-round">add</span>
                    </div>
                    <p
                      className="text-bold"
                      style={{
                        fontSize: 14,
                        margin: 0,
                        alignSelf: "center",
                      }}
                    >
                      {t("commons.add") + " Corporate Lounge"}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </>
          )}
          {active !== "4" && active !== "5" && corporateType === "MOB" && (
            <Card className="rounded">
              <CardHeader title={t("field.priceUnderQuota")} />
              <CardBody style={{ paddingTop: 0 }}>
                {value?.group_list?.map((item, index) => {
                  return (
                    <>
                      <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-3">
                          <div className="form-group" style={{ marginBottom: 0 }}>
                            <label className="form-label mb-1">
                              {t("commons.adult")}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                          <div className="form-group" style={{ marginBottom: 0 }}>
                            <label className="form-label mb-1">
                              {t("commons.child")}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                          <div className="form-group" style={{ marginBottom: 0 }}>
                            <label className="form-label mb-1">
                              {t("commons.infantLabel")}
                            </label>
                          </div>
                        </div>
                      </div>
                      {item?.property_list?.length && item?.property_list?.map((property, i) => {
                        return (
                          <> 
                          <InputPrepend
                            mb="2"
                            errors={props?.errors}
                            touched={props?.touched}
                            title={t("field.price")}
                            multiple={multipleListInputPrice(
                              `${name}.group_list[${index}].property_list[${i}].prices.adult_price_under_quota`,
                              value?.group_list[index]?.property_list[i]?.prices?.adult_price_under_quota,
                              `${name}.group_list[${index}].property_list[${i}].prices.child_price_under_quota`,
                              value?.group_list[index]?.property_list[i]?.prices?.child_price_under_quota,
                              `${name}.group_list[${index}].property_list[${i}].prices.infant_price_under_quota`,
                              value?.group_list[index]?.property_list[i]?.prices?.infant_price_under_quota
                            )}
                          />
                          </>
                        )
                      })}
                    </>
                  )
                })}

              </CardBody>
            </Card>
          )}
          <Card className="rounded">
            <CardHeader title={t("product.priceTaxInfo")} />
            <CardBody style={{ paddingTop: '16px' }}>
              <Checkbox
                name={`${name}.is_includes_concession`}
                checked={value?.is_includes_concession}
                onChange={props?.handleChange}
                value={1}
                label={t("product.includes_concession")}
              />
              <Checkbox
                name={`${name}.is_includes_ppn`}
                checked={value?.is_includes_ppn}
                onChange={props?.handleChange}
                value={1}
                label={t("product.includes_ppn")}
              />
            </CardBody>
          </Card>
        </>
      )}
      <CorporateLoungeModal
        show={modalCorpLounge}
        formik={props}
        index={indexCorpLounge}
        value={value}
        name={name}
        toggle={handleCancelCorpLounge}
        reset={handleDeleteCorpLounge}
        confirm={() => setModalCorpLounge(false)}
      />
    </>
  );
};

export default withTrans(ShowTabProduct);