import React, { useState } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import { withTrans } from "../../../../i18n/withTrans";
import { useTranslation } from "react-i18next";
import CardBody from "../../../../components/cards/cardBody";
import DynamicButton from "../../../forms/DynamicButton";
import ButtonModal from "../../../modal/ButtonModal"

const ChangeLanguage = ({ t, showModal, toggle }) => {
    const { i18n } = useTranslation()

    let [activeID, setActiveID] = useState(false)
    let [activeEN, setActiveEN] = useState(false)

    let joumpa_language = "joumpa_language"
    let lang = localStorage.getItem('joumpa_language')
    if (lang === 'id') {
        activeID = true
    } else {
        activeEN = true
    }

    const handleConfirm = () => {
        if (lang === 'id') {
            i18n.changeLanguage('id');
            window.location.reload()
            toggle()
        } else {
            i18n.changeLanguage('en');
            window.location.reload()
            toggle()
        }
    }

    const handleIDLanguage = (e) => {
        if (e.target.checked) {
            localStorage.setItem(joumpa_language, 'id')
            setActiveID(true)
            setActiveEN(false)
        }
        if (!e.target.checked) {
            localStorage.removeItem(joumpa_language)
            setActiveID(false)
        }
    }
    const handleENLanguage = (e) => {
        if (e.target.checked) {
            localStorage.setItem(joumpa_language, 'en')
            setActiveEN(true)
            setActiveID(false)
        }
        if (!e.target.checked) {
            localStorage.removeItem(joumpa_language)
            setActiveEN(false)
        }
    }
    return (
        <>
            <Modal
                isOpen={showModal}
                modalTransition={{ timeout: 700 }}
                backdropTransition={{ timeout: 1300 }}
            >
                <ModalHeader className="text-extra-bold pt-5"> {" "}{t("field.change_language")}{" "}
                    <span className="close clickable" onClick={toggle}>
                        &times;
                    </span>
                </ModalHeader>
                <ModalBody>
                    <CardBody>
                        <DynamicButton
                            checkbox
                            name="lang_indonesia"
                            label="Bahasa Indonesia"
                            styleInput={{ margin: '8px 0', borderRadius: '20px' }}
                            value={'id'}
                            checked={localStorage.getItem(joumpa_language)}
                            onChange={(value) => {
                                handleIDLanguage(value)
                            }}
                            active={activeID}
                        />
                        <DynamicButton
                            checkbox
                            name="lang_english"
                            label="English"
                            styleInput={{ margin: '8px 0', borderRadius: '20px' }}
                            value={'en'}
                            checked={localStorage.getItem(joumpa_language)}
                            onChange={(value) => {
                                handleENLanguage(value)
                            }}
                            active={activeEN}
                        />
                    </CardBody>
                </ModalBody>
                <ModalFooter>
                    <ButtonModal
                        confirm={handleConfirm}
                        toggle={toggle}
                        confirmTitle={t("commons.save")}
                        cancelTitle={t("commons.cancel")}
                    />
                </ModalFooter>
            </Modal>
        </>
    );
};

export default withTrans(ChangeLanguage);
