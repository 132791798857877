import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Divider } from "@mui/material";
import { Row, Col } from "reactstrap";

import Loader from "../../../components/commons/Loader";
import Card from "../../../components/cards/card";
import CardFlush from "../../../components/cards/cardFlush";
import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import Button from "../../../components/buttons/Button";
import LabelPills from "../../../components/table/labelPills";
import EditModal from "./MasterDataModal";
import { withTrans } from "../../../i18n/withTrans";
import { history } from "../../../utils/History";
import { getUser } from "../../../utils/User";

import AirportsService from "../../../store/actions/master_data/airport";

const DetailAirport = ({ match, pending, t }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();

  const [airport, setAirport] = useState([]);
  const [showZone, setShowZone] = useState(null);
  const [action, setAction] = useState(false);
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    new Promise((resolve) => {
      const param = {
        draw: 1,
        length: 1,
        page: 1,
        id: match?.params?.id,
      };
      dispatch(AirportsService.get(param, resolve));
    }).then((res) => {
      setAirport(res?.data[0]);
    });
  }, [action]);

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const showEditModal = () => {
    setEditModal(!editModal);
  };

  const handleEdit = (param) => {
    const callback = () => {
      setEditModal(!editModal);
      history.push({ pathname: "/data-master", state: { tab: "airport" } });
    };

    if (param) {
      dispatch(AirportsService.put(param, callback));
    }
  };

  return (
    <div className="menu-container">
      {pending && <Loader loading={pending} />}
      <div className="grid-header">
        <div className="side-menu">
          <div className="menu-title text-extra-bold">
            <div className="side-menu-back-icon ">
              <span
                className="material-icons clickable"
                onClick={() =>
                  history.push({
                    pathname: "/data-master",
                    state: { tab: "airport" },
                  })
                }
              >
                arrow_back
              </span>
            </div>
            {`Detail ${t("field.airport")}`}
          </div>
          <div style={{ padding: "0px 15px 30px 10px" }}>
            <Card>
              <CardFlush
                sideMenu
                title={t("field.airportCode")}
                desc={airport?.code}
                leftIcon="check_circle"
              />
              <CardFlush
                sideMenu
                title={t("field.city")}
                desc={airport?.city}
                leftIcon="confirmation_number"
              />
            </Card>
            {currentUser?.user.role_code !== "contact_center" && 
              currentUser?.user.role_code !== "lead_contact_center" && (
              <Button
                title={`${t("commons.edit")} ${t("field.airport")}`}
                type="Button"
                variant="button secondary"
                rounded
                style={{ width: "100%", textAlign: "center", marginBottom: 25 }}
                onClick={() => showEditModal()}
              />
            )}
          </div>
        </div>
        <div>
          <HeaderMenu title="Detail" />
          <div className="content-container-side">
            <div className="wrapper-detail-card">
              <CardFlush
                list
                label={t("field.airportName")}
                desc={airport?.name}
                size={{ label: 4, desc: 8 }}
              />
              <CardFlush
                list
                label="Email"
                desc={airport?.email}
                size={{ label: 4, desc: 8 }}
                last={true}
              />
            </div>
            <div className="wrapper-detail-card">
              <div
                className="text-extra-bold"
                style={{ padding: 15, opacity: 0.5 }}
              >
                Shift
              </div>
              <Divider orientation="horizontal" />
              {airport?.shifts?.length ? (
                <div className="pb-2">
                  <Row className="py-2"
                    style={{ fontSize: 14, padding: "15px 25px" }}>
                    <Col md={4} className="text-extra-bold"
                      style={{ color: "#000" }}>
                        {t("field.name")}
                    </Col>
                    <Col md={8} className="text-extra-bold"
                      style={{ color: "#000" }}>
                        Shift
                    </Col>
                  </Row>
                  {airport?.shifts?.map((item, i) => {
                    return (
                      <Row className="py-2"
                        style={{ fontSize: 14, padding: "15px 25px" }}>
                        <Col md={4}>
                          {item?.name ?? "-"}
                        </Col>
                        <Col md={8}>
                          <LabelPills data={`${item?.start_time} - ${item?.end_time}`}/>
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              ) : (
                <Row className="py-2"
                  style={{ fontSize: 14, padding: "15px 25px", justifyContent: "center" }}>
                  {t("commons.dataNotFound")}
                </Row>
              )}
            </div>
            <div className="wrapper-detail-card">
              <div
                className="text-extra-bold"
                style={{ padding: 15, opacity: 0.5 }}
              >
                {t("field.lounge")}
              </div>
              <Divider orientation="horizontal" />
              {airport?.lounges?.length ? (
                <div className="pb-2">
                  <Row className="py-2"
                    style={{ fontSize: 14, padding: "15px 25px" }}>
                    <Col md={3} className="text-extra-bold"
                      style={{ color: "#000" }}>
                        {t("field.lounge")}
                    </Col>
                    <Col md={3} className="text-extra-bold"
                      style={{ color: "#000" }}>
                        {t("field.routeType")}
                    </Col>
                    <Col md={6} className="text-extra-bold"
                      style={{ color: "#000", padding: 0 }}>
                        <Row>
                          <Col md={4} className="text-extra-bold"
                            style={{ color: "#000" }}>
                              {t("commons.adult")}
                          </Col>
                          <Col md={4} className="text-extra-bold"
                            style={{ color: "#000" }}>
                              {t("commons.child")}
                          </Col>
                          <Col md={4} className="text-extra-bold"
                            style={{ color: "#000" }}>
                              {t("commons.infantLabel")}
                          </Col>
                        </Row>
                    </Col>
                  </Row>
                  {airport?.lounges?.map((item, i) => {
                    return (
                      <Row className="py-2"
                        style={{ fontSize: 14, padding: "15px 25px" }}>
                        <Col md={3}>
                          {item?.name ?? "-"}
                        </Col>
                        <Col md={3}>
                          {item?.route_type?.charAt(0).toUpperCase() + item?.route_type?.slice(1) ?? "-"}
                        </Col>
                        <Col md={6} style={{ padding: 0 }}>
                          <Row>
                            <Col md={4}>
                              {item?.price_adult ? "Rp. " + numberWithCommas(item?.price_adult) : "-"}
                            </Col>
                            <Col md={4}>
                              {item?.price_child ? "Rp. " + numberWithCommas(item?.price_child) : "-"}
                            </Col>
                            <Col md={4}>
                              {item?.price_infant ? "Rp. " + numberWithCommas(item?.price_infant) : "-"}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              ) : (
                  <Row className="py-2"
                    style={{ fontSize: 14, padding: "15px 25px", justifyContent: "center" }}>
                    {t("commons.dataNotFound")}
                  </Row>
                )
              }
            </div>
            <div className="wrapper-detail-card">
              <div
                className="text-extra-bold"
                style={{ padding: 15, opacity: 0.5 }}
              >
                {t("field.transportation")}
              </div>
              <Divider orientation="horizontal" />
              {airport?.transports?.length ?
                airport?.transports?.map((item, index) => {
                  if(airport?.transports !== null) {
                    let region = [];
                    region = item?.region_list?.split(",")
                    region = region?.map(element => {
                      return element.charAt(0).toUpperCase() + element.slice(1);
                    });

                    return (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: 15,
                            fontSize: 14,
                          }}
                        >
                          <div className="text-extra-bold">{item?.zone_name}</div>
                          <div
                            onClick={() =>
                              setShowZone(showZone === item?.id ? null : item?.id)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <span className="material-icons-round left-icon">
                              expand_more
                            </span>
                          </div>
                        </div>
                        {showZone === item?.id && (
                          <div style={{ paddingBottom: 15 }}>
                            <CardFlush
                              list
                              label={t("field.region")}
                              desc={region ? region.join(', ') : '-'}
                              style={{ background: "#F7F7FA" }}
                              size={{ label: 4, desc: 8 }}
                            />
                            {item?.prices?.map((price, i) => {
                              return (
                                <div className="d-flex py-2"
                                  style={{ fontSize: 14, paddingLeft: 15, paddingRight: 15 }}>
                                  <Divider orientation="horizontal" />
                                  <div className="col-md-4">
                                    <div>
                                        {price?.car_name}
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div>
                                        {price?.capacity ? `${price.capacity} orang`: '-'}
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div>
                                        {price?.price
                                          ? "Rp. " + numberWithCommas(price?.price)
                                          : "-"}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </>
                    );
                  }
                })
                : (
                  <Row className="py-2"
                    style={{ fontSize: 14, padding: "15px 25px", justifyContent: "center" }}>
                    {t("commons.dataNotFound")}
                  </Row>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <EditModal
        show={editModal}
        toggle={showEditModal}
        action={handleEdit}
        data={airport}
      />
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(DetailAirport)
);
