import React, { useState, useRef } from "react";
import { Formik, Form } from "formik";
import { withTrans } from "../../i18n/withTrans";
import { connect, useDispatch } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import Input from "../../components/forms/Input";
import Button from "../../components/buttons/Button";
import * as Yup from "yup";

// ACTION REDUX
import AuthService from "../../store/actions/auth";

const FillPassword = ({ t, loggingIn }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const [isMatch, setIsMatch] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [matchMessage, setMatchMessage] = useState(null);
  const [checkValue, setCheckValue] = useState(null);
  const [checkChar, setCheckChar] = useState(null);
  const [visibility, setVisibility] = useState(false);
  const [visibilityConfirmPass, setVisibilityConfirmPass] = useState(false);
  const alphanumeric = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
  const alphaspecial = /[a-zA-Z]+[(@!#\$%\^\&*\)\(+=._-]{1,}/;
  const numericspecial = /[0-9]+[(@!#\$%\^\&*\)\(+=._-]{1,}/;

  const { verification_code } = useParams();

  function checkPassword() {
    const values = formikRef?.current?.values;

    if (values?.new_password?.length < 8) {
      setCheckValue(`- ${t("auth.check8Character")}`);
      setIsValid(false);
    } else {
      setCheckValue(null);
      setIsValid(true);
    }

    if (
      values?.new_password?.match(alphanumeric) === null &&
      values?.new_password.match(alphaspecial) === null &&
      values?.new_password.match(numericspecial) === null
    ) {
      setCheckChar(`- ${t("auth.checkCombinationChar")}`);
      setIsValid(false);
    } else {
      setCheckChar(null);
      setIsValid(true);
    }
  }

  function confirmationPassword() {
    const values = formikRef?.current?.values;

    if (values?.new_password?.length > 7 && values?.confirmation_password) {
      if (values?.new_password === values?.confirmation_password) {
        setIsMatch(true);
        setMatchMessage(`${t("auth.checkisMatch")}`);
      } else {
        setIsMatch(false);
        setMatchMessage(`${t("auth.checkisnMatch")}`);
      }
    } else {
      setIsMatch(false);
      setMatchMessage(null);
    }
  }

  const validation = Yup.object().shape({
    new_password: Yup.string()
      .required(t("profile.newPassword") + " " + t("commons.required"))
      .nullable(),
    confirmation_password: Yup.string()
      .required(t("profile.confirmationPassword") + " " + t("commons.required"))
      .nullable(),
  });

  const title = t("auth.titleFillPassword");
  const subtitle = t("auth.subtitleFillPassword");
  return (
    <div className="main-wrapper">
      <div className="login-container">
        <div className="content-wrapper">
          <p className="super-title text-extra-bold">{title}</p>
          <p className="subtitle text-medium" style={{ marginBottom: 50 }}>
            {subtitle}
          </p>
          <div className="form-wrapper">
            <Formik
              innerRef={formikRef}
              initialValues={{
                new_password: "",
                confirmation_password: "",
              }}
              validationSchema={validation}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                setStatus();
                const param = {
                  new_password: values.new_password,
                  verification_code: verification_code,
                };
                dispatch(AuthService.fillPassword(param));
              }}
            >
              {(props) => (
                <Form>
                  <Input
                    title={t("profile.newPassword")}
                    name="new_password"
                    type={visibility ? "text" : "password"}
                    placeholder={`${t("commons.input")} ${t("profile.newPassword")}`}
                    righticon={visibility ? "visibility_off" : "visibility"}
                    errors={props.errors}
                    touched={props.touched}
                    onKeyUp={() => checkPassword()}
                    toggleicon={() => setVisibility(!visibility)}
                    {...props.getFieldProps("new_password")}
                  />
                  {checkValue && (
                    <div
                      className="subtitle text-medium"
                      style={{
                        fontSize: 13,
                        marginTop: "-5px",
                        marginBottom: 10,
                      }}
                    >
                      {checkValue}
                    </div>
                  )}
                  {checkChar && (
                    <div
                      className="subtitle text-medium"
                      style={{
                        fontSize: 13,
                        marginTop: "-5px",
                        marginBottom: 10,
                      }}
                    >
                      {checkChar}
                    </div>
                  )}
                  <Input
                    title={t("auth.confirmPassword")}
                    name="confirmation_password"
                    type={visibilityConfirmPass ? "text" : "password"}
                    placeholder={`${t("commons.input")} ${t("auth.confirmPassword")}`}
                    righticon={visibilityConfirmPass ? "visibility_off" : "visibility"}
                    errors={props.errors}
                    touched={props.touched}
                    onKeyUp={() => confirmationPassword()}
                    toggleicon={() => setVisibilityConfirmPass(!visibilityConfirmPass)}
                    {...props.getFieldProps("confirmation_password")}
                  />
                  {matchMessage && (
                    <div
                      className="text-medium"
                      style={{
                        fontSize: 13,
                        color: isMatch ? "#4894FE" : "#E01640",
                        marginTop: "-5px",
                        marginBottom: 10,
                      }}
                    >
                      {matchMessage}
                    </div>
                  )}
                  <div className="button-wrapper" style={{ marginTop: "30px" }}>
                    <Button
                      title={title}
                      rounded
                      type="submit"
                      variant="button primary"
                      style={{ width: "100%", textAlign: "center" }}
                      disabled={!isMatch || !isValid || loggingIn}
                    />
                  </div>
                </Form>
              )}
            </Formik>
            <div
              style={{ marginTop: 100, position: "absolute", bottom: 10 }}
              className="subtitle"
            />
          </div>
        </div>
        <div className="side-login"/>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, auth: { loggingIn } }) => {
  return { user, loggingIn };
};

const mapDispatchToProps = () => {
  return {};
};
export default withRouter(withTrans(connect(mapStateToProps, mapDispatchToProps)(FillPassword)));