import React from "react";
import { useState, useEffect } from "react";
import { withTrans } from "../../../i18n/withTrans";
import { connect, useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";

import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import DetailButton from "../../../components/table/detailButton";
import Loader from "../../../components/commons/Loader";
import SearchButton from "../../../components/buttons/SearchButton";
import Table from "../../../components/table";

// Service
import UserService from "../../../store/actions/master_data/user";
import DetailModal from "./DetailModal";

const Customer = ({ t, pending }) => {
  const dispatch = useDispatch();

  const [detailModal, setDetailModal] = useState(false);
  const [dataCustomer, setDataCustomer] = useState([]);
  const [dataCustomerRow, setDataCustomerRow] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };
  
  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = {
        role_code: 'customer',
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        search_text: searchText,
      };
      dispatch(UserService.get(param, resolve));
    }).then((res) => {
      setDataCustomer(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, searchText]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [searchText]);

  const showDetailModal = (data) => {
    setDetailModal(!detailModal);
    setDataCustomerRow(data ? data : []);
  };

  const columns = [
    {
      Header: "NO.",
      accessor: "no",
    },
    {
      Header: `ID ${t("field.customer").toUpperCase()}`,
      accessor: "customer_id",
      Cell: ({ row }) => {
        const { original } = row;
        return original?.customer_id ?? "-";
      },
    },
    {
      Header: t("field.name").toUpperCase(),
      accessor: "name",
      Cell: ({ row }) => {
        const { original } = row;
        return original?.name ?? "-";
      },
    },
    {
      Header: t("field.phone").toUpperCase(),
      accessor: "phone",
      Cell: ({ row }) => {
        const { original } = row;
        const phone = original?.handphone && original?.country_code
          ? `${original?.country_code}${original?.handphone}`
          : original?.handphone;
        return phone ?? "-";
      },
    },
    {
      Header: "EMAIL",
      accessor: "email",
      Cell: ({ row }) => {
        const { original } = row;
        return original?.email ?? "-";
      },
    },
    {
      Header: "",
      accessor: "id",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <DetailButton>
            <MenuItem onClick={() => showDetailModal(original)}>
              <span style={{ fontSize: 14 }}>{t("commons.detail")}</span>
            </MenuItem>
          </DetailButton>
        );
      },
    },
  ];

  return (
    <>
      {pending && <Loader sideContent loading={pending} />}
      <HeaderMenu title={t("field.customer")}>
        <SearchButton
          placeholder={t("commons.enterKeyword")}
          toggle={(value) => handleSearch(value)}
        />
      </HeaderMenu>
      <div className="content-container-side">
        <div className="overflow">
          <Table
            columns={columns?.filter((item) => item.show !== false)}
            data={dataCustomer ?? []}
            pagination={{ page, totalData, maxPage, toggle: setPage }}
          />
        </div>
      </div>
      <DetailModal
        show={detailModal}
        toggle={showDetailModal}
        data={dataCustomerRow}
      />
    </>
  );
};

const mapStateToProps = ({ user: { pending, data } }) => {
  return { pending, data };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Customer));
