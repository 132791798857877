import {React, useEffect, useState} from "react";
import {Formik, Form} from "formik";
import {withTrans} from "../../../i18n/withTrans";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import {connect, useDispatch} from "react-redux";
import {getUser} from "../../../utils/User";
import Select2 from "../../../components/forms/Select2";
import ButtonModal from "../../../components/modal/ButtonModal";
import DynamicButton from "../../../components/forms/DynamicButton";
import DatePicker from "../../../components/forms/DateRangePicker";
import moment from "moment";

//Service

function ModalFilter({t, show, airport, product, toggle, filter, toggleFilter, isDelete, onApplyFilter}) {
  const currentUser = getUser();
  const dispatch = useDispatch();
  const [haveAirport, setHaveAirport] = useState(
      currentUser?.user.role_code === "admin_bandara" 
          ? true 
      : currentUser?.user.role_code === "supervisor" 
          ? true
          : false
  );

  const handleReset = (formik) => {
    formik.setFieldValue("status", "");
    formik.setFieldValue("booking_type", "");
    formik.setFieldValue("start_date", "");
    formik.setFieldValue("end_date", "");
    formik.setFieldValue("airport_id", "");
    formik.setFieldValue("product_id", "");
    toggleFilter({
      status: "",
      booking_type: "",
      start_date: "",
      end_date: "",
      airport_id: "",
      product_id: "",
    });
    toggle();
  };

  const status = [
    {
      label: t("status.new"),
      value: 0
    },
    {
      label: t("status.assigned"),
      value: 1
    },
    {
      label: t("status.onProgress"),
      value: 2
    },
    {
      label: t("status.done"),
      value: 5
    }
  ];

  return (
    <>
      <Modal isOpen={show} modalTransition={{timeout: 200}}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Filter{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody style={{margin: "0px 20px"}}>
            <DynamicButton
              titleLeft={t("field.chooseFilter")}
              iconLeft="info"
            />
          <Formik
            initialValues={{
              airport_id: filter?.airport_id ?? "",
              product_id: filter?.product_id ?? "",
              start_date: filter?.start_date ?? "",
              end_date: filter?.end_date ?? "",
              status: filter?.status ?? "",
              booking_type: filter?.booking_type ?? "",
            }}
            enableReinitialize
            onSubmit={(values) => {
              onApplyFilter({
                start_date: values.start_date ? values.start_date : "",
                end_date: values.end_date ? values.end_date : "",
                airport_id:
                  currentUser?.user.role_code === "admin_bandara" ||
                  currentUser?.user.role_code === "supervisor"
                      ? currentUser?.user.airport_id
                      : values.airport_id,
                product_id: values.product_id,
                status: values.status,
                booking_type: values?.booking_type,
              });
              toggle();
            }}
          >
            {(props) => (
              <Form style={{marginTop: 30}}>
                <DatePicker
                  title={t("report.serviceTime")}
                  name="start_date"
                  handlechange={(startDate, endDate) => {
                      props.setFieldValue("start_date", startDate);
                      props.setFieldValue("end_date", endDate);
                  }}
                  format="DD MMMM YYYY"
                  startDate={filter ? filter?.start_date : props.values?.start_date}
                  endDate={filter ? filter?.end_date : props.values?.end_date}
                  className="mx-auto"
                  errors={props.errors}
                  touched={props.touched}
                />
                {haveAirport === false && (
                  <Select2
                    title={t("field.airport")}
                    name="airport_id"
                    options={[
                      {
                        value: "",
                        label: `${t("commons.all")} ${t("field.airport")}`,
                      },
                      ...airport,
                    ]}
                    errors={props.errors}
                    touched={props.touched}
                    value={props.values?.airport_id}
                    onChange={(name, value) => {
                      props.setFieldValue("airport_id", value);
                    }}
                    onBlur={props.setFieldTouched}
                  />
                )}
                <Select2
                  title={t("field.product")}
                  name="product_id"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t("field.product")}`,
                    },
                    ...product,
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.product_id}
                  onChange={(name, value) => {
                    props.setFieldValue("product_id", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title="Status"
                  name="status"
                  options={[
                    {
                      value: "",
                      label: `${t("schedule.all_status")}`,
                    },
                    ...status,
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.status}
                  onChange={(name, value) => {
                    props.setFieldValue("status", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title={t("field.bookingType")}
                  name="booking_type"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t(
                        "field.bookingType"
                      )}`,
                    },
                    {
                      value: "mobile",
                      label: t("field.mobileApp"),
                    },
                    {
                      value: "web-public",
                      label: t("field.webPublic"),
                    },
                    {
                      value: "web-admin",
                      label: t("field.webAdmin"),
                    },
                    {
                      value: "api",
                      label: "API",
                    },
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.booking_type}
                  onChange={(name, value) => {
                    props.setFieldValue("booking_type", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <div style={{marginTop: 50, marginBottom: 30}}>
                  <ButtonModal
                      toggle={toggle}
                      reset={() => handleReset(props)}
                      deleted={isDelete}
                      confirmTitle={t("field.apply")}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = ({city}) => {
    return {city};
};

const mapDispatchToProps = () => {
    return {};
};

export default withTrans(
    connect(mapStateToProps, mapDispatchToProps)(ModalFilter)
);
