import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import { connect, useDispatch  } from "react-redux";
import { Divider } from "@mui/material";
import moment from "moment";

import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import Card from "../../../components/cards/card";
import CardHeader from "../../../components/cards/cardHeader";
import CardBody from "../../../components/cards/cardBody";
import ProductionCard from "../../../components/cards/ProductionCard";
import DatePicker from "../../../components/forms/DatePicker";
import Table from "../../../components/table";
import { withTrans } from "../../../i18n/withTrans";

// Service
import DataAnalyticsService from "../../../store/actions/data_analytics";
import CorporateService from "../../../store/actions/corp_reservation"

const Index = ({ t, pending, pendingCorp }) => {
  const dispatch = useDispatch();
  const [filterYear, setFilterYear] = useState(moment().format("yyyy"));
  const [filterMonth, setFilterMonth] = useState(new Date().getMonth() + 1);
  const [monthYearPicker, setMonthYearPicker] = useState(new Date());
  const [nonActiveUser, setNonActiveUser] = useState(0);
  const [activeUser, setActiveUser] = useState(0);
  const [dataNewCorporate, setDataNewCorporate] = useState([]);

  const handleGetTotalUser = () => {
    new Promise((resolve) => {
      let param = {
        month: filterMonth,
        year: filterYear,
      };
      dispatch(DataAnalyticsService.getTotalUser(param, resolve));
    }).then((res) => {
      if (res?.data) {
        setNonActiveUser(res?.data?.non_active_user);
        setActiveUser(res?.data?.active_user);
      }
    });
  };

  useEffect(() => {
    handleGetTotalUser();
  }, [filterMonth, filterYear]);

  const handleGetNewCorporate = () => {
    new Promise((resolve) => {
      const param = {
        month: filterMonth,
        year: filterYear,
      };
      dispatch(CorporateService.getNewCorporate(param, resolve));
    }).then((res) => {
      if (res?.data) {
        setDataNewCorporate(res?.data);
      }
    });
  };

  useEffect(() => {
    handleGetNewCorporate();
  }, [filterMonth, filterYear]);

  const column = [
    {
      Header: "NO",
      accessor: "no",
    },
    {
      Header: t("field.pullBranch").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.airport ?? "-";
      },
    },
    {
      Header: t("dataAnalytics.newCorporate").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const new_corporate_list = original?.new_corporate?.map((item) => (
          <div className="mb-3">
            {item}
          </div>
        ));
        return new_corporate_list ?? "-";
      },
    },
    {
      Header: `TOTAL ${t("dataAnalytics.newCorporate").toUpperCase()}`,
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <div className="text-center">
            {original?.total_corporate ?? "0"}
          </div>
        );
      },
      textAlign: 'center'
    },
    {
      Header: t("dataAnalytics.renewalCorporate").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const renewal_corporate_list = original?.renewal?.map((item) => (
          <div className="mb-3">
            {item}
          </div>
        ));
        return renewal_corporate_list ?? "-";
      },
    },
    {
      Header: `TOTAL ${t("dataAnalytics.renewalCorporate").toUpperCase()}`,
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <div className="text-center">
            {original?.total_renewal ?? "0"}
          </div>
        );
      },
      textAlign: 'center'
    },
  ];

  return (
    <>
      <HeaderMenu title={t("dataAnalytics.customer")} />
      <div className="content-container-side">
        <div className="overflow">
          <div className="d-flex justify-content-end align-items-center">
            <DatePicker
              isLocale
              useCustomInput
              value={monthYearPicker}
              onChange={(date) => {
                setMonthYearPicker(date);
                setFilterMonth(new Date(date).getMonth() + 1);
                setFilterYear(moment(date).format("yyyy"));
              }}
              showMonthYearPicker
            />
          </div>
          <br />
          <Card rounded className="col-md">
            <CardHeader
              isDashboard
              title={`Total ${t("dataAnalytics.customer")}`}
              style={{
                color: '#24243D !important'
              }}
            />
            <CardBody>
              <Row className="justify-content-between">
                <ProductionCard
                  className="col-md"
                  color="hijau"
                  title={t("dataAnalytics.activeCustomer")}
                  total={activeUser}
                  icon="done"
                  pending={pending}
                />
                <ProductionCard
                  className="col-md"
                  color="merah"
                  title={t("dataAnalytics.nonActiveCustomer")}
                  total={nonActiveUser}
                  icon="cancel"
                  pending={pending}
                />
              </Row>
            </CardBody>
          </Card>
          <Divider className="my-5" />
          <Table
            columns={column.filter((item) => item.show !== false)}
            data={dataNewCorporate ?? []}
            isLoading={pendingCorp}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ data_analytics: { pending, data }, corp_reserv: { pending: pendingCorp } }) => {
  return { pending, data, pendingCorp };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Index));
