import Loader from "../commons/Loader";
import React from "react";

const Index = ({color, price, title, total, icon, pending}) => {
    return (
        <>
            {pending && <Loader cardContent loading={pending}/>}
            <div className="d-flex align-items-center">
                <div className={`square-icon ${color || "biru"}`}>
                    <span className="material-icons">{icon}</span>
                </div>
                <div className="ml-2">
                    <div className="mb-1">
                        <span className="normal-title">{title || "-"}</span>
                    </div>
                    {price && (
                        <div className="d-flex justify-content-between">
                            <div className="border-dashboard-right">
                                <span className="title text-bold">{total || "0"}</span>
                            </div>
                            <div>
                                <span className="title text-bold">{price || "0"}</span>
                            </div>
                        </div>
                    )}
                    {!price && (
                        <div>
                            <span className="title text-bold">{total || "0"}</span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Index;
