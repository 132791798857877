import React from "react";
import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";

import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import DetailButton from "../../components/table/detailButton";
import Loader from "../../components/commons/Loader";
import Button from "../../components/buttons/Button";
import Card from "../../components/cards/card";
import SearchButton from "../../components/buttons/SearchButton";
import Table from "../../components/table";
import CancelReasonModal from "../../components/modal";
import ConfirmationModal from "../../components/modal/ConfirmModal";
import RescheduleBookingModal from "./modal/RescheduleBookingModal";
import ModalFilter from "./modal/modalFilter";
import TableStatus from "../../components/table/status";
import { toastSuccess, toastError } from "../../components/commons/toast";
import { history } from "../../utils/History";
import { getUser } from "../../utils/User";
import { numberWithCommas } from "../../utils/Constants";
import { withTrans } from "../../i18n/withTrans";

// Service
import BookingService from "../../store/actions/booking";
import AirportService from "../../store/actions/master_data/airport";

const Booking = ({ match, t, pending, error, data_delete }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();
  const lang = localStorage.getItem('joumpa_language');
  const currentUrl = match?.url?.substring(1);

  const [cancelReservationModal, setCancelReservationModal] = useState(false);
  const [dataBooking, setDataBooking] = useState([]);
  const [dataBookingRow, setDataBookingRow] = useState([]);
  const [dataAirport, setDataAirport] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [rescheduleModal, setRescheduleModal] = useState(false);
  const [dataRescheduleBooking, setDataRescheduleBooking] = useState([]);
  const [cancelReasonModal, setCancelReasonModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [paramFilter, setParamFilter] = useState({
    status: "",
    start_date: "",
    end_date: "",
    service_start_date: "",
    service_end_date: "",
    payment_status: "",
    booking_type: "",
    airport_id:
      currentUser?.user.role_code === "admin_bandara" ||
      currentUser?.user.role_code === "supervisor" ||
      currentUser?.user.role_code === "assistant" ||
      currentUser?.user.role_code === "finance_bandara" ||
      currentUser?.user.role_code === "sales_bandara"
        ? currentUser?.user.airport_id
        : "",
  });

  useEffect(() => {
    const justLogin = localStorage.getItem("just_login");

    if (justLogin) {
      toastSuccess(
        `${lang === 'id' 
          ? `Selamat Anda berhasil login sebagai ${justLogin}` 
          : `Well done! you have logged in as ${justLogin}`}`
      );

      localStorage.removeItem("just_login");
    }
  }, []);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      let list_airports = [];
      if (res) {
        list_airports = res?.data?.map((airport) => ({
          value: airport?.id,
          label: `${airport?.code}-${airport?.city}-${airport?.name} ${
            airport?.uniform === 'Indonesia' ? "" : "-" + airport?.uniform
          }`,
        }));
      }
      setDataAirport(list_airports);
    });
  }, []);

  useEffect(() => {
    if (error) {
      setLoadingSubmit(false);
    }
  }, [error]);

  useEffect(() => {
    if (data_delete?.data?.status === 200) {
      setLoadingSubmit(false);
    }
  }, [data_delete]);

  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };
  
  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = {
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        contact_center: currentUrl === 'booking-contact-center' ? true : false,
        id: null,
        search_text: searchText,
        airport_id: paramFilter?.airport_id !== "" ? paramFilter?.airport_id : "",
        payment_status: paramFilter?.payment_status !== "" ? paramFilter?.payment_status : "",
        booking_type: paramFilter?.booking_type !== "" ? paramFilter?.booking_type : "",
        status: paramFilter?.status !== "" ? paramFilter?.status : "",
        start_date:
          paramFilter?.start_date !== ""
            ? moment(paramFilter?.start_date).format("YYYY-MM-DD")
            : "",
        end_date:
          paramFilter?.end_date !== ""
            ? moment(paramFilter?.end_date).format("YYYY-MM-DD")
            : "",
        service_start_date:
          paramFilter?.service_start_date !== ""
            ? moment(paramFilter?.service_start_date).format("YYYY-MM-DD")
            : "",
        service_end_date: 
          paramFilter?.service_end_date !== ""
            ? moment(paramFilter?.service_end_date).format("YYYY-MM-DD")
            : "",
      };
      dispatch(BookingService.get(param, resolve));
    }).then((res) => {
      if (res) {
        setDataBooking(res?.data ?? []);
        setTotalData(res?.totalCount);
        setMaxPage(Math.ceil(res?.totalCount / 10));
      }
    });
  };

  const onApplyFilter = (param) => {
    setParamFilter({
      airport_id: param.airport_id,
      status: param?.status,
      payment_status: param?.payment_status,
      booking_type: 
        currentUrl === 'booking-contact-center' 
          ? null 
          : param?.booking_type,
      start_date: param?.start_date,
      end_date: param?.end_date,
      service_start_date: param?.service_start_date,
      service_end_date: param?.service_end_date
    })
    setShowModalFilter(false);
  };

  const handleFilterModal = () => {
    setShowModalFilter(true);
  };

  useEffect(() => {
    handleRefresh();
  }, [page, searchText, paramFilter, currentUrl]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [searchText, paramFilter]);

  const showCancelReservationModal = (data) => {
    setCancelReservationModal(!cancelReservationModal);
    setCancelReason("");
    setDataBookingRow(data ? data : []);
  };

  const handleCancelReservation = () => {
    const callback = () => {
      handleRefresh();
      setCancelReservationModal(!cancelReservationModal);
      setCancelReason("");
    };

    if (!dataBookingRow) {
      return;
    }

    setLoadingSubmit(true);
    const id = dataBookingRow?.id;

    if (cancelReason === "") {
      toastError(`${t('commons.empty_data_message')} ${t("field.reasonCancellation")}`);
      setLoadingSubmit(false);
    } else {
      new Promise((resolve) => {
        dispatch(BookingService.del({ order_id: id, cancel_reason: cancelReason }, resolve, callback));
      }).then((res) => {
        setLoadingSubmit(false);
      });
    }
  };

  const showRescheduleModal = (data) => {
    setRescheduleModal(!rescheduleModal);
    setDataRescheduleBooking(data ? data : []);
  };

  const handleRescheduleBooking = (param) => {
    const callback = () => {
      handleRefresh();
      setRescheduleModal(!rescheduleModal);
    };
    dispatch(BookingService.rescheduleBooking(param, callback));
  };

  const exportPDF = (param) => {
    new Promise((resolve) => {
      dispatch(BookingService.exportDetailBooking(
        {order_id: param?.id}, 
        param?.unique_transaction_id, 
        resolve)
      );
    }).then((res) => {});
  };

  const columns = [
    {
      Header: "#ID",
      accessor: "unique_transaction_id",
    },
    {
      Header: t("field.bookingTime").toUpperCase(),
      Cell: ({ row }) => {
        const { original } = row;
        let { booking_date } = original;
        let date = moment(booking_date)?.format("DD/MM/yyyy");
        let time = moment(booking_date)?.format("HH:mm");
        return date && time ? `${date}, ${time}` : '-';
      },
    },
    {
      Header: t("report.serviceTime").toUpperCase(),
      Cell: ({ row }) => {
        const { original } = row;
        let date = moment(original?.date)?.format("DD/MM/yyyy");
        let time = original?.time;
        return date && time ? `${date}, ${time}` : '-';
      },
    },
    {
      Header: t("field.customer").toUpperCase(),
      Cell: ({ row }) => {
        const { original } = row;
        let customer_name =
          original.customer_name === null
            ? original.list_order_passengers.length > 0
              ? original.list_order_passengers[0].name
              : "-"
            : original.customer_name;

        return `${customer_name}`;
      },
    },
    {
      Header: t("field.booker").toUpperCase(),
      Cell: ({ row }) => {
        const { original } = row;

        return original?.bookers_email || "-";
      },
    },
    {
      Header: t("field.orderType").toUpperCase(),
      accessor: "passenger_type",
    },
    {
      Header: t("field.flight").toUpperCase(),
      Cell: ({ row }) => {
        const { original } = row;
        if(original?.type === 1) {
          return t("field.arrival") + "-" + original?.airplane_code + original?.airplane_number;
        } else if(original?.type === 2) {
          return t("field.departure") + "-" + original?.airplane_code + original?.airplane_number;
        } else if(original?.type === 3) {
          return t("field.transfer") + "-" + original?.airplane_code + original?.airplane_number;
        }
      },
      minWidth: 150,
    },
    {
      Header: t("field.product").toUpperCase(),
      accessor: "product_name_eng",
      minWidth: 200,
    },
    {
      Header: t("field.corporate").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return <>{original?.corporate_name ?? "-"}</>;
      },
      minWidth: 150,
      show:
        currentUser.user.role_code !== "corporate" &&
        currentUrl !== "booking-contact-center",
    },
    {
      Header: t("field.airport").toUpperCase(),
      Cell: ({ row }) => {
        const { original } = row;
        if(original.type_label === t("field.arrival")) {
          let { airport_city_to, airport_code_to, airport_name_to, } = original;
          return `${airport_code_to}-${airport_city_to}-${airport_name_to}`;
        } else if(original.type_label === t("field.departure")) {
          let { airport_city_from, airport_code_from, airport_name_from, } = original;
          return `${airport_code_from}-${airport_city_from}-${airport_name_from}`;
        } else if(original.type_label === "Transfer") {
          let { airport_city_transfer, airport_code_transfer, airport_name_transfer, } = original;
          return `${airport_code_transfer}-${airport_city_transfer}-${airport_name_transfer}`;
        }
      },
      minWidth: 250,
      show:
        currentUrl === "booking-contact-center"
          ? true
          : currentUser.user.role_code !== "admin_bandara" && 
            currentUser.user.role_code !== "supervisor" && 
            currentUser.user.role_code !== "assistant",
    },
    {
      Header: "GRAND TOTAL",
      accessor: "total_price",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return <>{"Rp " + numberWithCommas(original?.total_price)}</>;
      },
      minWidth: 150,
    },
    {
      Header: `${t("field.payment").toUpperCase()}`,
      Cell: ({ row }) => {
        const { original } = row;
        let { is_paid } = original;
        return (
          <TableStatus
            status={
              is_paid === false
                ? t("status.unpaid")
                : original?.corporate_id === null
                  ? t("status.paid")
                  : t("status.invoicePayment")
            }
            color={
              is_paid === false
                ? "danger"
                : "primary"
            }
          />
        );
      },
      minWidth: 150,
    },
    {
      Header: "STATUS",
      Cell: ({ row }) => {
        const { original } = row;
        let { status, refund_img_url } = original;
        return (
          <TableStatus
            status={
              status === 0
                ? t("status.new")
                : status === 1
                ? t("status.assigned")
                : status === 2
                ? t("status.onProgress")
                : status === 5
                ? t("status.done")
                : status === 6 && refund_img_url === null
                ? t("status.refund")
                : status === 7
                ? t("status.reschedule")
                : status === 6 && refund_img_url !== null
                ? t("status.cancelled")
                : t("status.cancelled")
            }
            color={
              status === 0
                ? "danger"
                : status === 1 || status === 2 || status === 7
                ? "warning"
                : status === 6 && refund_img_url === null
                ? "info"
                : status === 5
                ? "primary"
                : status === 6 && refund_img_url !== null
                ? "secondary"
                : "secondary"
            }
          />
        );
      },
      minWidth: 125,
    },
    {
      Header: "",
      accessor: "id",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <DetailButton>
            <MenuItem
              onClick={() => history.push(`/${currentUrl}/detail/${original?.id}`)}
            >
              <span style={{ fontSize: 14 }}>{t("commons.detail")}</span>
            </MenuItem>
            {(currentUser?.user?.role_code === "lead_contact_center" ||
              currentUser?.user?.role_code === "contact_center") &&
              original?.status !== -1 && (
              <MenuItem onClick={() => showCancelReservationModal(original)}>
                <span style={{ fontSize: 14 }}>
                  {t("commons.cancelReservation")}
                </span>
              </MenuItem>
            )}
            {original?.status === -1 && (
              <MenuItem onClick={() => {
                setCancelReason(original?.cancel_reason);
                setCancelReasonModal(!cancelReasonModal);
              }}>
                <span style={{ fontSize: 14 }}>
                  {t("field.detailCancellation")}
                </span>
              </MenuItem>
            )}
            {(currentUser?.user?.role_code === "supervisor" ||
              currentUser?.user?.role_code === "admin_bandara" ||
              currentUser?.user?.role_code === "admin_pusat" ||
              currentUser?.user?.role_code === "lead_contact_center" ||
              currentUser?.user?.role_code === "contact_center") && 
              (original?.status === 0 || original?.status === 1) && (
              <MenuItem
                onClick={() => showRescheduleModal(original)}
              >
                <span style={{ fontSize: 14 }}>Reschedule</span>
              </MenuItem>
            )}
            {(currentUser?.user?.role_code === "admin_pusat" ||
              currentUser?.user?.role_code === "lead_contact_center" ||
              currentUser?.user?.role_code === "contact_center" ||
              currentUser?.user?.role_code === "admin_bandara" ||
              currentUser?.user?.role_code === "supervisor" ||
              currentUser?.user?.role_code === "finance" ||
              currentUser?.user?.role_code === "finance_bandara") && (
              <MenuItem
                onClick={() => exportPDF(original)}
              >
                <span style={{ fontSize: 14 }}>Export</span>
              </MenuItem>
            )}
          </DetailButton>
        );
      },
    },
  ];

  return (
    <>
      {pending && <Loader fullScreen loading={pending} />}
      <HeaderMenu
        title={
          currentUrl === 'booking-contact-center'
            ? `${lang !== "id" ? "Booking By Contact Center" : "Pesanan Contact Center"}`
            : t("field.booking")
        }
      >
        <SearchButton
          placeholder={t("commons.enterKeyword")}
          toggle={(value) => handleSearch(value)}
        />
        <Button
          title="Filter"
          size="sm"
          rounded={true}
          variant="button-light btn-lg ml-3"
          onClick={handleFilterModal}
        />

        <div className="nav-separator mr-3 ml-3" />

        {(currentUser?.user?.role_code !== "sales" &&
        currentUser?.user?.role_code !== "finance" &&
        currentUser?.user?.role_code !== "sales_bandara" &&
        currentUser?.user?.role_code !== "finance_bandara") && (
          <Button
            title={`${t("commons.add")} ${t("field.booking")}`}
            leftIcon="add"
            rounded={true}
            variant="primary"
            onClick={() => history.push(`${currentUrl}/new-booking`)}
          />
        )}
      </HeaderMenu>
      <div className="content-container-side">
        <div className="overflow">
          <Table
            columns={columns.filter((item) => item.show !== false)}
            data={dataBooking ?? []}
            pagination={{ page, totalData, maxPage, toggle: setPage }}
          />
        </div>
      </div>
      <ConfirmationModal
        show={cancelReservationModal}
        toggle={showCancelReservationModal}
        confirm={handleCancelReservation}
        messageData={`` + dataBookingRow?.unique_transaction_id}
        reason={
          <div className="form-group" style={{ width: "100%" }}>
            <label className="form-label">
              {t("field.reasonCancellation")}
            </label>
            <textarea
              className="form-control"
              value={cancelReason}
              placeholder={`${t("commons.input")} ${t("field.reasonCancellation")}`}
              style={{
                background: 'white 0% 0% no-repeat padding-box',
                borderRadius: 6,
                paddingLeft: 12,
              }}
              rows={3}
              onChange={(e) => setCancelReason(e?.target?.value)}
            />
          </div>
        }
        icon="info"
        message={`${t("commons.areYouSureCancel")} ${t("field.booking")} `}
        isDelete
        disabledConfirm={loadingSubmit}
      />
      <CancelReasonModal
        show={cancelReasonModal}
        toggle={() => setCancelReasonModal(!cancelReasonModal)}
        title={t("field.detailCancellation")}
        content={
          <Card className="w-100 rounded p-4">
            <div className="d-flex">
              <i
                className="material-icons-outlined icon-modal"
                style={{ marginRight: 10, marginTop: 0 }}
              >
                info
              </i>
              <div className="flex flex-column">
                <p style={{ fontSize: "16px", marginBottom: '0.5rem' }}>
                  <strong>{t("field.reasonCancellation")}</strong>
                </p>
                <p style={{ fontSize: "16px" }}>
                  {cancelReason}
                </p>
              </div>
            </div>
          </Card>
        }
      />
      <RescheduleBookingModal
        show={rescheduleModal}
        toggle={showRescheduleModal}
        data={dataRescheduleBooking}
        action={handleRescheduleBooking}
      />
      <ModalFilter
        show={showModalFilter}
        toggle={() => setShowModalFilter(false)}
        airport={dataAirport}
        filter={paramFilter}
        toggleFilter={(props) => {
          setParamFilter({
            status: props.status,
            payment_status: props.payment_status,
            booking_type: props.booking_type,
            start_date: props.start_date,
            end_date: props.end_date,
            service_start_date: props.service_start_date,
            service_end_date: props.service_end_date,
            airport_id: props.airport_id,
          });
        }}
        onApplyFilter={onApplyFilter}
      />
    </>
  );
};

const mapStateToProps = ({ booking: { error, pending, data, data_delete } }) => {
  return { error, pending, data, data_delete };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Booking));
