import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { withTrans } from "../../i18n/withTrans";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import {getUser} from "../../utils/User";
import Select2 from "../../components/forms/Select2";
import ButtonModal from "../../components/modal/ButtonModal";
import DynamicButton from "../../components/forms/DynamicButton";
import DatePicker from "../../components/forms/DateRangePicker";

function ModalFilter({
  t,
  show,
  toggle,
  filter,
  toggleFilter,
  isDelete,
  onApplyFilter,
  dataAirport,
  dataProduct
}) {
  const dispatch = useDispatch();
  const currentUser = getUser();
  const [haveAirport, setHaveAirport] = useState(
    currentUser?.user.role_code === "admin_bandara"
      ? true
      : currentUser?.user.role_code === "supervisor"
      ? true
      : false
  );

  const airport_list = dataAirport?.map((airport) => ({
    value: airport?.id,
    label: `${airport?.code}-${airport?.city}-${airport?.name}`,
  }));

  const product_list = dataProduct.map((product) => ({
    value: product?.id,
    label: product?.name_eng,
  }));

  const type_list = [
    {
      value: "",
      label: `${t("commons.all")} Type`,
    },
    {
      value: "specific",
      label: t("promo.special"),
    },
    {
      value: "blast",
      label: "Blast",
    },
  ];


  const handleReset = (formik) => {
    formik.setFieldValue("status", "");
    formik.setFieldValue("start_date", "");
    formik.setFieldValue("end_date", "");
    formik.setFieldValue(
      "airport_id",
      currentUser?.user.role_code === "admin_bandara" ||
        currentUser?.user.role_code === "supervisor"
        ? currentUser?.user.airport_id
        : ""
    );
    formik.setFieldValue("product_id", "");
    formik.setFieldValue("type", "");
    toggleFilter({
      status: "",
      start_date: "",
      end_date: "",
      airport_id: currentUser?.user.role_code === "admin_bandara" ||
        currentUser?.user.role_code === "supervisor"
        ? currentUser?.user.airport_id
        : "",
      product_id: "",
      type: ""
    });
    toggle();
  };

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Filter{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody style={{ margin: "0px 20px" }}>
          <DynamicButton titleLeft={t("field.chooseFilter")} iconLeft="info" />
          <Formik
            initialValues={{
              airport_id: filter?.airport_id ?? "",
              product_id: filter?.product_id ?? "",
              start_date: filter?.start_date ?? "",
              end_date: filter?.end_date ?? "",
              status: filter?.status ?? "",
              type: filter?.type ?? "",
            }}
            enableReinitialize
            onSubmit={(values) => {
              onApplyFilter({
                start_date: values.start_date ? values.start_date : "",
                end_date: values.end_date ? values.end_date : "",
                airport_id:
                  currentUser?.user.role_code === "admin_bandara" ||
                  currentUser?.user.role_code === "supervisor"
                      ? currentUser?.user.airport_id
                      : values.airport_id,
                product_id: values.product_id,
                status: values.status,
                type: values.type,
              });
              toggle();
            }}
          >
            {(props) => (
              <Form style={{ marginTop: 30 }}>
                <DatePicker
                  title={t("field.date")}
                  name="start_date"
                  handlechange={(startDate, endDate) => {
                    props.setFieldValue("start_date", startDate);
                    props.setFieldValue("end_date", endDate);
                  }}
                  format="DD MMMM YYYY"
                  startDate={filter ? filter?.start_date : props.values?.start_date}
                  endDate={filter ? filter?.end_date : props.values?.end_date}
                  className="mx-auto"
                  errors={props.errors}
                  touched={props.touched}
                />
                {haveAirport === false && (
                  <Select2
                    title={t("field.airport")}
                    name="airport_id"
                    options={[
                      {
                        value: "",
                        label: `${t("commons.all")} ${t("field.airport")}`,
                      },
                      ...airport_list,
                    ]}
                    errors={props.errors}
                    touched={props.touched}
                    value={props.values?.airport_id}
                    onChange={(name, value) => {
                      props.setFieldValue("airport_id", value);
                    }}
                    onBlur={props.setFieldTouched}
                  />
                )}
                <Select2
                  title={t("field.product")}
                  name="product_id"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t("field.product")}`,
                    },
                    ...product_list,
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.product_id}
                  onChange={(name, value) => {
                    props.setFieldValue("product_id", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title={t("promo.promoType")}
                  name="type"
                  options={
                    !haveAirport ? [
                      ...type_list,
                      {
                        value: "cross-selling",
                        label: t("field.cross_selling"),
                      },
                    ] : type_list
                  }
                  type="text"
                  errors={props?.errors}
                  touched={props?.touched}
                  value={props.values?.type}
                  onChange={(name, value) => {
                    props?.setFieldValue("type", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title="Status"
                  name="status"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} Status`,
                    },
                    {
                      value: true,
                      label: t("promo.active"),
                    },
                    {
                      value: false,
                      label: t("promo.nonactive"),
                    },
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.status}
                  placeholder="Pilih status"
                  onChange={(name, value) => {
                    props.setFieldValue("status", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <div style={{ marginTop: 50, marginBottom: 30 }}>
                  <ButtonModal
                    toggle={toggle}
                    reset={() => handleReset(props)}
                    deleted={isDelete}
                    confirmTitle={t("field.apply")}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = ({ masterDataRegion }) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(ModalFilter)
);
