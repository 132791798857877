import React from "react";
import { Formik, Form } from "formik";
import { withTrans } from "../../i18n/withTrans";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import Select2 from "../../components/forms/Select2";
import ButtonModal from "../../components/modal/ButtonModal";
import DynamicButton from "../../components/forms/DynamicButton";

function ModalFilter({
    t,
    show,
    toggle,
    filter,
    pending,
    isDelete,
    onApplyFilter,
}) {
    const handleReset = (formik) => {
        formik.setFieldValue("year", "");
        formik.setFieldValue("type", null);
        onApplyFilter({ year: "", type: null });
        toggle();
    };

    let yearOptions = [];
    for (let i = new Date().getFullYear(); i >= 2022; i--) {
        yearOptions = [...yearOptions, { label: i, value: i }];
    }

    return (
        <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
            <ModalHeader className="text-extra-bold pt-5">
                {" "}
                Filter{" "}
                <span className="close clickable" onClick={toggle}>
                    &times;
                </span>
            </ModalHeader>
            <ModalBody style={{ margin: "0px 20px" }}>
                <DynamicButton titleLeft={t("field.chooseFilter")} iconLeft="info" />
                <Formik
                    initialValues={{
                        year: filter?.year ?? "",
                        type: filter?.type ?? "",
                    }}
                    onSubmit={(values) => {
                        onApplyFilter({
                            year: values.year,
                            type: values.type,
                        });
                        toggle();
                    }}
                >
                    {(props) => (
                        <Form style={{ marginTop: 30 }}>
                            <Select2
                                title={t('field.year')}
                                name="year"
                                options={[
                                    {
                                        value: "",
                                        label: `${t("commons.all")} ${t("field.year")}`,
                                    },
                                    ...yearOptions,
                                ]}
                                errors={props.errors}
                                touched={props.touched}
                                value={props.values?.year}
                                placeholder="Pilih tahun"
                                onChange={(name, value) => {
                                    props.setFieldValue("year", value);
                                }}
                                onBlur={props.setFieldTouched}
                            />
                            <Select2
                                title={`${t('commons.type')}`}
                                name="type"
                                options={[
                                    {
                                        value: 'Individual',
                                        label: 'Individual',
                                    },
                                    {
                                        value: 'Group',
                                        label: 'Group (Charter, Hajj, Umrah)',
                                    },
                                    {
                                        value: 'Other',
                                        label: `Group ${t('masterData.others')}`,
                                    },
                                ]}
                                errors={props.errors}
                                touched={props.touched}
                                value={props.values?.type}
                                placeholder={t('commons.type')}
                                onChange={(name, value) => {
                                    props.setFieldValue("type", value);
                                }}
                                onBlur={props.setFieldTouched}
                            />
                            <div style={{ marginTop: 50, marginBottom: 30 }}>
                                <ButtonModal
                                    toggle={toggle}
                                    reset={() => handleReset(props)}
                                    deleted={isDelete}
                                    confirmTitle={t("field.apply")}
                                    disabledConfirm={pending}
                                />
                            </div>

                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    )
}

const mapStateToProps = ({ masterDataRegion }) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default withTrans(
    connect(mapStateToProps, mapDispatchToProps)(ModalFilter)
);