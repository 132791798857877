import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import debounce from "lodash.debounce";

import Card from "../../../components/cards/card";
import CardHeader from "../../../components/cards/cardHeader";
import CardBody from "../../../components/cards/cardBody";
import Input from "../../../components/forms/Input";
import InputPrepend from "../../../components/forms/InputPrepend";
import Select2 from "../../../components/forms/Select2";
import Checkbox from "../../../components/forms/Checkbox";
import DynamicButton from "../../../components/forms/DynamicButton";
import { getUser } from "../../../utils/User";
import { withTrans } from "../../../i18n/withTrans";
import { toastError } from "../../../components/commons/toast";

//SERVICE
import CorpService from "../../../store/actions/product/corporate_price";
import AirportService from "../../../store/actions/master_data/airport";
import AirlineService from "../../../store/actions/master_data/airline";
import ProductService from "../../../store/actions/master_data/product";

const FlightReservation = ({
  formik,
  t,
  button,
}) => {
  const dispatch = useDispatch();
  const currentUser = getUser();
  const position = currentUser?.user?.role_name;
  const role = currentUser?.user?.role_code;
  const corporateId = currentUser?.user?.corporate_id;
  const airportId = currentUser?.user?.airport_id;

  const [dataAirportArrival, setDataAirportArrival] = useState([]);
  const [dataAirportDeparture, setDataAirportDeparture] = useState([]);
  const [dataAdminBandara, setDataAdminBandara] = useState([]);
  const [dataCorp, setDataCorp] = useState([]);
  const [dataAirline, setDataAirline] = useState([]);
  const [dataAirlineTransfer, setDataAirlineTransfer] = useState([]);
  const [dataProduct, setDataProduct] = useState([]);

  const [corporateType, setCorporateType] = useState("");
  const [searchAirportArrival, setSearchAirportArrival] = useState([]);
  const [searchAirportDeparture, setSearchAirportDeparture] = useState([]);
  const [searchTextAirline, setSearchTextAirline] = useState([]);
  const [searchTextAirlineTransfer, setSearchTextAirlineTransfer] = useState([]);
  const [haveAirport, setHaveAirport] = useState(
    role === "admin_bandara" 
      ? true 
    : role === "supervisor" 
      ? true
    : role === "assistant" 
      ? true
      : false
  );
  const minDate =
    role === "admin_pusat"
      ? moment().add(1, "days").format("YYYY-MM-DD")
      : (role === "contact_center" || role === "lead_contact_center")
      ? null
      : moment().subtract(1, 'days').format("YYYY-MM-DD");

  useEffect(() => {
    // get airport data arrival
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchAirportArrival,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      if (res.data) {
        let data = res.data;
        setDataAirportArrival(data);
      }
    });
  }, [searchAirportArrival]);

  useEffect(() => {
    // get airport data departure
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchAirportDeparture,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      if (res.data) {
        let data = res.data;
        setDataAirportDeparture(data);
      }
    });
  }, [searchAirportDeparture]);

  useEffect(() => {
    // get airport service
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
        id: airportId,
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      let list = [];
      if (res && res.data) {
        res.data?.map(
          (airport) =>
            (list = [
              ...list,
              {
                ...airport,
                value: airport?.id,
                label: `${airport?.code}-${airport?.city}-${airport?.name}`,
              },
            ])
        );
      }
      if (airportId) {
        setDataAdminBandara(list);
      } else {
        formik.setFieldValue("airport_list_indo", list);
      }
    });
  }, [formik?.values.flight_type]);

  useEffect(() => {
    //get airline data
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchTextAirline,
      };
      dispatch(AirlineService.get(param, resolve));
    }).then((res) => {
      if (res.data) {
        let data = res.data;
        const airlineList = data.map((airline) => ({
          value: airline?.name,
          code: airline?.code,
          label: `${airline?.code}-${airline?.name}`,
        }));

        if (formik?.values?.selectedAirline === undefined) {
          return;
        }

        const indexSelectedAirline = airlineList.findIndex(
          (x) => x.value == formik?.values?.selectedAirline.value
        );
        if (indexSelectedAirline === -1) {
          airlineList.push(formik?.values?.selectedAirline);
        }
        setDataAirline(airlineList);
      }
    });
  }, [searchTextAirline]);

  useEffect(() => {
    //get airline transfer
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchTextAirlineTransfer,
      };
      dispatch(AirlineService.get(param, resolve));
    }).then((res) => {
      if (res.data) {
        let data = res.data;
        const airlineTransfer = data.map((airline) => ({
          value: airline?.name,
          code: airline?.code,
          label: `${airline?.code}-${airline?.name}`,
        }));

        if (formik?.values?.selectedAirlineTransfer === undefined) {
          return;
        }
        
        const indexSelectedAirlineTransfer = airlineTransfer.findIndex(
          (x) => x.value == formik?.values?.selectedAirlineTransfer.value
        );
        if (indexSelectedAirlineTransfer === -1) {
          airlineTransfer.push(formik?.values?.selectedAirlineTransfer);
        }
        setDataAirlineTransfer(airlineTransfer);
      }
    });
  }, [searchTextAirlineTransfer]);

  useEffect(() => {
    // get product data
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 99999,
      };
      dispatch(ProductService.get(param, resolve));
    }).then((res) => {
      setDataProduct(res?.data ?? []);
    });

    // get corporate data
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 99999,
      };
      dispatch(CorpService.getActive(param, resolve));
    }).then((res) => {
      if (res?.data) {
        let data = res?.data;
        let list = data.map((corp) => ({
          ...corp,
          value: corp?.id,
          label: `${corp?.name}`,
        }));
        setDataCorp(list);
      }
    });
  }, []);

  useEffect(() => {
    if (dataCorp.length > 0 && position === "Corporate") {
      formik.setFieldValue("corporate_id", corporateId);
      setCorporateType(corporateId);
    }
  }, [dataCorp]);

  useEffect(() => {
    new Promise((resolve) => {
      if (formik.values?.corporate_id) {
        let param = {
          id: formik?.values?.corporate_id
        };
        dispatch(CorpService.getActive(param, resolve));
      }
    }).then((res) => {
      if (res) {
        const typeMOB = res?.data[0]?.type_list?.filter((item) => item?.corporate_type === "MOB");
        if (typeMOB?.length) {
          formik.setFieldValue("isTypeMOBExist", true);
        } else {
          formik.setFieldValue("isTypeMOBExist", false);
        }
      }
    });
  }, [formik.values?.corporate_id]);


  useEffect(() => {
    if (
      formik?.values.flight_type >= 0 &&
      formik?.values?.airport_id_from &&
      formik?.values?.airport_id_to
    ) {
      let isInternational =
        formik?.values?.airport_uniform_from !== "Indonesia" ||
        formik?.values?.airport_uniform_to !== "Indonesia";
      let services = [];

      if (
        formik?.values.flight_type === 2 ||
        !dataProduct ||
        dataProduct.length < 1
      ) {
        return;
      }

      if (isInternational) {
        services = [
          ...services,
          // {
          //   label: "FAST TRACK",
          //   name: "Fast Track",
          //   type: 3,
          // },
          {
            label: "MEET & GREET INTERNASIONAL",
            name: "Meet and Greet International",
            type: 1,
          },
        ];
      } else {
        services = [
          ...services,
          {
            label: "MEET & GREET DOMESTIC",
            name: "Meet and Greet Domestic",
            type: 2,
          },
        ];
      }
      formik.setFieldValue("list_available_services", services);
    }
  }, [
    formik?.values?.airport_id_from,
    formik?.values?.airport_id_to,
    formik?.values.flight_type,
  ]);

  useEffect(() => {
    if (formik?.values?.product_type <= 0) {
      return;
    }

    const serviceInAvailableServices = 
      formik?.values?.list_available_services?.find(
        (item) => item?.type === formik?.values?.product_type
      );
    
    if (!serviceInAvailableServices) {
      formik.setFieldValue("product_type", 0);
    }
  }, [formik?.values?.list_available_services]);

  useEffect(() => {
    if (formik?.values?.flight_type === 0) {
      formik.setFieldValue("flight_type_code", 5);
    } else if (formik?.values?.flight_type === 1) {
      formik.setFieldValue("flight_type_code", 6);
    } else if (formik?.values?.flight_type === 2) {
      if (
        formik?.values?.airport_uniform_from !== "Indonesia" &&
        formik?.values?.airport_uniform_to !== "Indonesia"
      ) {
        formik.setFieldValue("flight_type_code", 1);
      } else if (
        formik?.values?.airport_uniform_from !== "Indonesia" &&
        formik?.values?.airport_uniform_to === "Indonesia"
      ) {
        formik.setFieldValue("flight_type_code", 2);
      } else if (
        formik?.values?.airport_uniform_from === "Indonesia" &&
        formik?.values?.airport_uniform_to !== "Indonesia"
      ) {
        formik.setFieldValue("flight_type_code", 3);
      } else if (
        formik?.values?.airport_uniform_from === "Indonesia" &&
        formik?.values?.airport_uniform_to === "Indonesia"
      ) {
        formik.setFieldValue("flight_type_code", 4);
      }
    }
  }, [
    formik?.values?.flight_type,
    formik?.values?.airport_uniform_from,
    formik?.values?.airport_uniform_to,
  ]);

  useEffect(() => {
    let date = moment().format("YYYY-MM-DD");
    let time = moment(
      `${date}, ${moment().format("HH:mm")}`
    ).diff(moment().startOf("day"), "minutes");
    let datepick =
      formik?.values?.date !== null &&
      formik?.values?.date !== "" &&
      formik?.values?.date !== undefined
        ? formik?.values?.date
        : moment().format("YYYY-MM-DD");
    let timepick = moment(`${date}, ${formik?.values?.time}`).diff(
      moment().startOf("day"),
      "minutes"
    );

    if (date === datepick) {
      if (time >= timepick) {
        formik.setFieldValue("isGoShow", true);
      } else {
        formik.setFieldValue("isGoShow", false);
      }
    } else {
      formik.setFieldValue("isGoShow", false);
    }
  }, [
    formik?.values?.date,
    formik?.values?.time,
  ]);

  const handleFlightType = (value) => {
    formik.setFieldValue("product_type", 0);
    formik.setFieldValue("airplane_code", null);
    formik.setFieldValue("airplane_code_transfer", null);
    formik.setFieldValue("airplane_name", null);
    formik.setFieldValue("airplane_number", null);
    formik.setFieldValue("date", null);
    formik.setFieldValue("time", null);
    setSearchAirportArrival("");
    setSearchAirportDeparture("");
    setSearchTextAirline("");
    setSearchTextAirlineTransfer("");
    switch (value) {
      case "arrival":
        formik.setFieldValue("list_available_services", []);
        formik.setFieldValue("flight_type", 0);
        formik.setFieldValue("airport_id_from", null);
        formik.setFieldValue("airport_name_from", null);
        formik.setFieldValue("airport_uniform_from", null);
        if (haveAirport) {
          formik.setFieldValue("airport_id_to", dataAdminBandara[0]?.id);
          formik.setFieldValue(
            "airport_name_to",
            `${dataAdminBandara[0]?.code} - ${
              dataAdminBandara[0]?.city} - ${
              dataAdminBandara[0]?.name}`
          );
          formik.setFieldValue(
            "airport_uniform_to",
            dataAdminBandara[0]?.uniform
          );
        } else {
          formik.setFieldValue("airport_id_to", null);
          formik.setFieldValue("airport_name_to", null);
          formik.setFieldValue("airport_uniform_to", null);
        }
        break;
      case "departure":
        formik.setFieldValue("list_available_services", []);
        formik.setFieldValue("flight_type", 1);
        formik.setFieldValue("airport_id_to", null);
        formik.setFieldValue("airport_name_to", null);
        formik.setFieldValue("airport_uniform_to", null);
        if (haveAirport) {
          formik.setFieldValue("airport_id_from", dataAdminBandara[0]?.id);
          formik.setFieldValue(
            "airport_name_from",
            `${dataAdminBandara[0]?.code} - ${
              dataAdminBandara[0]?.city} - ${
              dataAdminBandara[0]?.name}`
          );
          formik.setFieldValue(
            "airport_uniform_from",
            dataAdminBandara[0]?.uniform
          );
        } else {
          formik.setFieldValue("airport_id_from", null);
          formik.setFieldValue("airport_name_from", null);
          formik.setFieldValue("airport_uniform_from", null);
        }
        break;
      case "transfer":
        formik.setFieldValue("list_available_services", [
          {
            name: "Transfer",
            label: "TRANSFER",
            type: 4,
          },
        ]);
        formik.setFieldValue("product_name", "Transfer");
        formik.setFieldValue("flight_type", 2);
        formik.setFieldValue("airport_id_transfer", null);
        formik.setFieldValue("airport_uniform_transfer_to", null);
        formik.setFieldValue("airport_id_from", null);
        formik.setFieldValue("airport_name_from", null);
        formik.setFieldValue("airport_uniform_from", null);
        formik.setFieldValue("airport_id_to", null);
        formik.setFieldValue("airport_name_to", null);
        formik.setFieldValue("airport_uniform_to", null);
        if (haveAirport) {
          formik.setFieldValue("airport_id_transfer", dataAdminBandara[0]?.id);
          formik.setFieldValue(
            "airport_name_transfer",
            `${dataAdminBandara[0]?.code} - ${
              dataAdminBandara[0]?.city} - ${
              dataAdminBandara[0]?.name}`
          );
          formik.setFieldValue(
            "airport_uniform_transfer",
            dataAdminBandara[0]?.uniform
          );
        } else {
          formik.setFieldValue("airport_id_transfer", null);
          formik.setFieldValue("airport_name_transfer", null);
          formik.setFieldValue("airport_uniform_transfer", null);
        }
        break;
    }
  };

  const handleProductSelected = (item) => {
    let selectedType = item?.type;
    let selectedName = item?.name;
    let productType = formik.values.product_type;

    if (selectedType && productType === selectedType) {
      selectedType = null;
      selectedName = null;
    }
    formik.setFieldValue("product_type", selectedType);
    formik.setFieldValue("product_name", selectedName);
  };

  const debouncedAirports = debounce((value, type) => {
    if (value === "") {
      return;
    }

    if (type === "arrival") {
      setSearchAirportArrival(value);
    }
    if (type === "departure") {
      setSearchAirportDeparture(value);
    }
  }, 500);

  let airportsArrival = [];
  dataAirportArrival.map((airport) => {
    airportsArrival.push({
      value: airport?.id,
      label: `${airport?.code}-${airport?.city}-${airport?.name} ${
        airport?.uniform === "Indonesia" ? "" : "-" + airport?.uniform
      }`,
    });
  });
  //if airportsArrival does not have the same value as selectedAirportArrival, 
  //push selectedAirportArrival in list airportsArrival
  //so airport search results can still appear in the dropdown 
  const indexSameAirportArrival = airportsArrival.findIndex(
    (x) => x.value == formik?.values?.selectedAirportArrival.value
  );
  if (indexSameAirportArrival === -1) {
    airportsArrival.push(formik?.values?.selectedAirportArrival);
  }

  let airportsDeparture = [];
  dataAirportDeparture.map((airport) => {
    airportsDeparture.push({
      value: airport?.id,
      label: `${airport?.code}-${airport?.city}-${airport?.name} ${
        airport?.uniform === "Indonesia" ? "" : "-" + airport?.uniform
      }`,
    });
  });
  //if airportsDeparture does not have the same value as selectedAirportDeparture, 
  //push selectedAirportDeparture in list airportsDeparture
  //so airport search results can still appear in the dropdown 
  const indexSameAirportDeparture = airportsDeparture.findIndex(
    (x) => x.value == formik?.values?.selectedAirportDeparture.value
  );
  if (indexSameAirportDeparture === -1) {
    airportsDeparture.push(formik?.values?.selectedAirportDeparture);
  };

  return (
    <Row>
      <Col md={10}>
        <Card rounded>
          <CardHeader title={t("field.corporate")} />
          <CardBody>
            <Select2
              title={t("field.corporate")}
              name="corporate_id"
              options={dataCorp}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values?.corporate_id}
              onChange={(name, value) => {
                formik.setFieldValue("corporate_id", value);
                setCorporateType(value);
              }}
              disabled={position === "Corporate"}
            />
            <br />
            {formik?.values?.isTypeMOBExist && (
              <Checkbox
                name="corporateType"
                checked={formik?.values?.corporateType}
                onChange={(e) => {
                  if (formik?.values?.corporateType === "MOB") {
                    formik?.setFieldValue("corporateType", null);
                    formik.setFieldValue("dataCorporate", "");
                    formik.setFieldValue("corporate_type_id", "");
                  } else {
                    formik.setFieldValue("corporateType", "MOB");
                  }
                }}
                value="MOB"
                label="Select MOB as the cooperation type"
              />
            )}
          </CardBody>
        </Card>
        <Card rounded>
          <CardHeader title={t("field.flightType")} />
          <CardBody>
            <div className="d-flex justify-content-left">
              <DynamicButton
                titleLeft={t("field.arrival")}
                iconLeft="flight_land"
                active={formik.values.flight_type === 0}
                toggle={() => handleFlightType("arrival")}
              />
              <DynamicButton
                titleLeft={t("field.departure")}
                iconLeft="flight_takeoff"
                active={formik.values.flight_type === 1}
                toggle={() => handleFlightType("departure")}
              />
              <DynamicButton
                titleLeft="Transfer"
                iconLeft="transfer_within_a_station"
                active={formik.values.flight_type === 2}
                toggle={() => handleFlightType("transfer")}
              />
            </div>
          </CardBody>
        </Card>
        <Card rounded>
          <CardHeader
            title={
              formik?.values?.flight_type === 2 
                ? t("field.arrivalInformation") 
                : t("field.flightInformation")
            }
          />
          <CardBody>
            <div className="d-flex justify-content-between">
              <div className="text-bold">{t("field.origin")}</div>
              <Col md={8}>
                <Select2
                  name="airport_id_from"
                  options={
                    formik?.values?.flight_type !== 1
                      ? airportsDeparture
                      : haveAirport
                        ? dataAdminBandara
                        : formik.values?.airport_list_indo
                  }
                  errors={formik.errors}
                  touched={formik.touched}
                  value={formik.values?.airport_id_from ?? ""}
                  onInputChange={(keyword) => {
                    debouncedAirports(keyword, "departure");
                  }}
                  onChange={(name, value) => {
                    const isSameAirportTo = formik.values?.airport_id_to === value;
                    const isSameAirportTransfer =
                      formik.values.flight_type === 2
                        ? formik.values?.airport_id_transfer === value
                        : false;
                    const list_airports = 
                      formik?.values?.flight_type !== 1
                        ? dataAirportDeparture
                        : haveAirport
                          ? dataAdminBandara
                          : formik?.values?.airport_list_indo;
                    const index = list_airports.findIndex(
                      (x) => x.id === value
                    );

                    if (isSameAirportTo || isSameAirportTransfer) {
                      toastError(`${t("field.cantSameAirport")}!`);
                      return;
                    }

                    formik.setFieldValue("airport_id_from", value);
                    formik.setFieldValue(
                      "airport_name_from",
                      `${list_airports[index]?.code} - ${
                        list_airports[index]?.city} - ${
                        list_airports[index]?.name}`
                    );
                    formik.setFieldValue(
                      "airport_uniform_from",
                      list_airports[index]?.uniform
                    );
                    formik.setFieldValue(
                      "selectedAirportDeparture", 
                      {
                        value: value,
                        label:
                          `${list_airports[index]?.code} - ${
                            list_airports[index]?.city} - ${
                            list_airports[index]?.name} ${
                            list_airports[index]?.uniform === "Indonesia"
                              ? ""
                              : `- ${list_airports[index]?.uniform}`
                          }`,
                      }
                    );
                  }}
                  onBlur={formik.setFieldTouched}
                />
              </Col>
            </div>
            <div className="d-flex justify-content-between">
              <div className="text-bold">
                {formik.values?.flight_type !== 2
                  ? t("field.destination")
                  : "Transfer"}
              </div>
              <Col md={8}>
                <Select2
                  name={
                    formik.values?.flight_type !== 2
                      ? "airport_id_to"
                      : "airport_id_transfer"
                  }
                  errors={formik.errors}
                  options={
                    formik.values?.flight_type === 1
                      ? airportsArrival
                      : haveAirport
                        ? dataAdminBandara
                        : formik?.values?.airport_list_indo
                  }
                  touched={formik.touched}
                  value={
                    formik.values?.flight_type !== 2
                      ? formik.values?.airport_id_to
                      : formik.values?.airport_id_transfer
                  }
                  onInputChange={(keyword) => {
                    formik.values?.flight_type !== 2 &&
                      debouncedAirports(keyword, "arrival");
                  }}
                  onChange={(name, value) => {
                    const isSameAirportTo =
                      formik.values?.airport_id_from === value;
                    const isSameAirportFrom =
                      formik.values?.airport_id_from === value;
                    const isNotTransfer = formik.values?.flight_type !== 2;
                    const list_airports =
                      formik.values?.flight_type === 1
                        ? dataAirportArrival
                        : haveAirport
                          ? dataAdminBandara
                          : formik?.values?.airport_list_indo
                    const index = list_airports.findIndex(
                      (x) => x.id === value
                    );

                    if (
                      isNotTransfer
                        ? isSameAirportFrom
                        : isSameAirportFrom || isSameAirportTo
                    ) {
                      toastError(`${t("field.cantSameAirport")}!`);
                      return;
                    }
                    
                    formik.setFieldValue("lounge", "");
                    formik.setFieldValue("transportation", "");
                    formik.setFieldValue("car_type", "");

                    if (isNotTransfer) {
                      formik.setFieldValue("airport_id_to", value);
                      formik.setFieldValue(
                        "airport_name_to",
                        `${list_airports[index]?.code} - ${
                          list_airports[index]?.city} - ${
                          list_airports[index]?.name}`
                      );
                      formik.setFieldValue(
                        "airport_uniform_to",
                        list_airports[index]?.uniform
                      );
                      formik.setFieldValue(
                        "selectedAirportArrival", 
                        {
                          value: value,
                          label: 
                            `${list_airports[index]?.code} - ${
                              list_airports[index]?.city} - ${
                              list_airports[index]?.name} ${
                              list_airports[index]?.uniform === "Indonesia"
                                ? ""
                                : `- ${list_airports[index]?.uniform}`
                            }`,
                        }
                      );
                    } else {
                      formik.setFieldValue("airport_id_transfer", value);
                      formik.setFieldValue(
                        "airport_name_transfer",
                        `${list_airports[index]?.code} - ${
                          list_airports[index]?.city} - ${
                          list_airports[index]?.name}`
                      );
                      formik.setFieldValue(
                        "airport_uniform_transfer",
                        list_airports[index]?.uniform
                      );
                    }
                  }}
                  onBlur={formik.setFieldTouched}
                />
              </Col>
            </div>
          </CardBody>
          <hr />
          <CardBody>
            <div className="row">
              <Col md={6}>
                <Select2
                  title={t("field.airline")}
                  name="airplane_name"
                  options={dataAirline}
                  errors={formik.errors}
                  touched={formik.touched}
                  value={formik.values?.airplane_name}
                  onInputChange={(keyword) => {
                    if (keyword !== "") {
                      setSearchTextAirline(keyword);
                    }
                  }}
                  onChange={(name, value) => {
                    formik.setFieldValue("airplane_name", value);
                    let airplaneCode = dataAirline.filter(function (el) {
                      return el.value == value;
                    });
                    formik.setFieldValue("airplane_code", airplaneCode[0]?.code);
                    formik.setFieldValue("selectedAirline", {
                      value: value,
                      code: airplaneCode[0]?.code,
                      label: `${airplaneCode[0]?.code} - ${value}`,
                    });
                  }}
                  onBlur={formik.setFieldTouched}
                />
              </Col>
              <Col md={6}>
                <div className="form-group" style={{ marginBottom: "0" }}>
                  <label className="form-label" style={{ marginBottom: "5px" }}>
                    {t("field.flightNumber")}
                  </label>
                </div>
                <InputPrepend
                  name="airplane_number"
                  label={t("field.flightNumber")}
                  title={formik.values.airplane_code}
                  type="text"
                  width="25%"
                  mt="2"
                  touched={formik.touched}
                  {...formik.getFieldProps("airplane_number")}
                />
              </Col>
              <Col md={6}>
                <Input
                  title={
                    formik.values.flight_type !== 1
                      ? t("field.arrivalDate")
                      : t("field.departureDate")
                  }
                  name="date"
                  min={minDate}
                  errors={formik.errors}
                  touched={formik.touched}
                  {...formik.getFieldProps("date")}
                  onChange={(e) => {
                    let value = e.target.value;
                    // const period = formik?.values?.dataCorporate?.period_list;
                    // const idxPeriod = period?.findIndex((item) => {
                    //   const start = moment(item?.start_date, "YYYY-MM-DD").subtract(1, "days");
                    //   const end = moment(item?.end_date, "YYYY-MM-DD").add(1, "days");

                    //   return moment(value).isBetween(start, end);
                    // });
                    // if (idxPeriod == -1) {
                    //   toastError(t("commons.servicePeriodInfo"));
                    // } else {
                      formik.setFieldValue("date", value);
                    // }
                  }}
                  type="date"
                />
              </Col>
              <Col md={6}>
                <Input
                  title={
                    formik.values.flight_type !== 1
                      ? t("field.arrivalTime")
                      : t("field.departureTime")
                  }
                  name="time"
                  errors={formik.errors}
                  touched={formik.touched}
                  value={formik.values.time}
                  onChange={(e) => {
                    let value = e.target.value;
                    formik.setFieldValue("time", value);
                  }}
                  type="time"
                />
              </Col>
            </div>
          </CardBody>
          {formik?.values?.flight_type === 2 && (
            <React.Fragment>
              <hr />
              <CardHeader title={t("field.departureInformation")} />
              <CardBody>
                <div className="d-flex justify-content-between">
                  <div className="text-bold">{t("field.destination")}</div>
                  <Col md={8}>
                    <Select2
                      name="airport_id_to"
                      options={airportsArrival}
                      errors={formik.errors}
                      touched={formik.touched}
                      value={formik.values?.airport_id_to}
                      onInputChange={(keyword) => {
                        debouncedAirports(keyword, "arrival");
                      }}
                      onChange={(name, value) => {
                        if (
                          formik.values?.airport_id_from === value ||
                          formik.values?.airport_id_transfer === value
                        ) {
                          toastError(`${t("field.cantSameAirport")}!`);
                          return;
                        }

                        const index = dataAirportArrival
                          ?.map(function (e) {
                            return e.id;
                          })
                          .indexOf(value);

                        formik.setFieldValue("airport_id_to", value);
                        formik.setFieldValue(
                          "airport_name_to",
                          `${dataAirportArrival[index]?.code} - ${
                            dataAirportArrival[index]?.city} - ${
                            dataAirportArrival[index]?.name}`
                        );
                        formik.setFieldValue(
                          "airport_uniform_to",
                          dataAirportArrival[index]?.uniform
                        );
                        formik.setFieldValue(
                          "selectedAirportArrival", 
                          {
                            value: value,
                            label:
                              `${dataAirportArrival[index]?.code} - ${
                                dataAirportArrival[index]?.city} - ${
                                dataAirportArrival[index]?.name} ${
                                dataAirportArrival[index]?.uniform === "Indonesia"
                                  ? ""
                                  : `- ${dataAirportArrival[index]?.uniform}`
                              }`,
                          }
                        );
                      }}
                      onBlur={formik.setFieldTouched}
                    />
                  </Col>
                </div>
              </CardBody>
              <hr />
              <CardBody>
                <div className="row">
                  <Col md={6}>
                    <Select2
                      title={t("field.airline")}
                      name="airplane_name_transfer_to"
                      options={dataAirlineTransfer}
                      errors={formik.errors}
                      touched={formik.touched}
                      value={formik.values?.airplane_name_transfer_to}
                      onInputChange={(keyword) => {
                        if (keyword !== "") {
                          setSearchTextAirlineTransfer(keyword);
                        }
                      }}
                      onChange={(name, value) => {
                        formik.setFieldValue(
                          "airplane_name_transfer_to",
                          value
                        );
                        let airplaneTransferCode = dataAirlineTransfer.filter(
                          function (el) {
                            return el.value == value;
                          }
                        );
                        formik.setFieldValue(
                          "airplane_code_transfer",
                          airplaneTransferCode[0]?.code
                        );
                        formik.setFieldValue("selectedAirlineTransfer", {
                          value: value,
                          code: airplaneTransferCode[0]?.code,
                          label: `${airplaneTransferCode[0]?.code} - ${value}`,
                        });
                      }}
                      onBlur={formik.setFieldTouched}
                    />
                  </Col>
                  <Col md={6}>
                    <div className="form-group" style={{ marginBottom: "0" }}>
                      <label
                        className="form-label"
                        style={{ marginBottom: "5px" }}
                      >
                        {t("field.flightNumber")}
                      </label>
                    </div>
                    <InputPrepend
                      name="airplane_number_transfer_to"
                      label={t("field.flightNumber")}
                      title={formik.values?.airplane_code_transfer}
                      type="text"
                      width="25%"
                      mt="2"
                      errors={formik.errors}
                      touched={formik.touched}
                      {...formik.getFieldProps("airplane_number_transfer_to")}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      title={t("field.departureDate")}
                      name="date_transfer_to"
                      errors={formik.errors}
                      min={formik.values?.date}
                      touched={formik.touched}
                      value={formik.values?.date_transfer_to}
                      {...formik.getFieldProps("date_transfer_to")}
                      type="date"
                      onChange={(e) => {
                        let value = e.target.value;
                        formik.setFieldValue("date_transfer_to", value);
                      }}
                      disabled={!formik.values.date || !formik.values.time}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      title={t("field.departureTime")}
                      name="time_transfer_to"
                      errors={formik.errors}
                      touched={formik.touched}
                      value={formik.values?.time_transfer_to}
                      onChange={(e) => {
                        let value = e.target.value;
                        formik.setFieldValue("time_transfer_to", value);
                      }}
                      disabled={
                        formik.values.date_transfer_to === null ||
                        formik.values.date_transfer_to === "" ||
                        formik.values.date_transfer_to === undefined
                      }
                      type="time"
                    />
                  </Col>
                </div>
              </CardBody>
            </React.Fragment>
          )}
        </Card>
        <Card rounded>
          <CardHeader title={t("field.product")} />
          <CardBody>
            {formik?.values?.list_available_services?.map((item, index) => {
              return (
                <React.Fragment key={`service-${index}`}>
                  <div
                    onClick={() => handleProductSelected(item)}
                    style={{ cursor: "pointer" }}
                  >
                    <Card rounded>
                      <CardBody
                        isActive={item?.type === formik?.values.product_type}
                      >
                        <div>
                          <span
                            className={
                              item?.type === formik?.values.product_type
                                ? "card-header"
                                : "card-header-inactive"
                            }
                          >
                            <span
                              style={{
                                borderRadius: "50%",
                                backgroundColor: "#63AE5C",
                                borderColor: "#63AE5C",
                                marginRight: "25px",
                                height: "100%",
                                color: "#FFFF",
                              }}
                              className="material-icons-round"
                            >
                              {item?.type === formik?.values.product_type
                                ? "done"
                                : "info"}
                            </span>
                            <div>
                              {item?.label}
                              <div
                                style={{
                                  fontSize: 12,
                                  display: "block",
                                  lineHeight: "1rem",
                                  paddingTop: 10,
                                }}
                              >
                                {t(
                                  `product.${
                                    item?.type === 3
                                      ? "detailFTDesc"
                                      : item?.type === 4
                                      ? "detailTransferDesc"
                                      : "detailMnGDesc"
                                  }`
                                )}
                              </div>
                            </div>
                          </span>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </React.Fragment>
              );
            })}
            {(!formik?.values?.list_available_services ||
              formik?.values?.list_available_services?.length < 1) && (
              <Card rounded>
                <CardBody isActive={false}>
                  <span className="card-header-inactive">
                    <span
                      style={{
                        borderRadius: "50%",
                        backgroundColor: "#FFFF",
                        borderColor: "#A5A5C0",
                        marginRight: "25px",
                        height: "100%",
                        color: "#A5A5C0",
                      }}
                      className="material-icons-round"
                    >
                      info
                    </span>
                    {t("field.notAvailable")}
                  </span>
                </CardBody>
              </Card>
            )}
          </CardBody>
        </Card>
        {button(corporateType)}
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ airport, corporate, airline, corp_price }) => {
  return { airport, corporate, airline, corp_price };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(FlightReservation)
);
