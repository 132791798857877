import React, { useState } from "react";
import { Formik, Form } from "formik";
import { withTrans } from "../../../i18n/withTrans";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import {getUser} from "../../../utils/User";
import Select2 from "../../../components/forms/Select2";
import ButtonModal from "../../../components/modal/ButtonModal";
import DynamicButton from "../../../components/forms/DynamicButton";
import DatePicker from "../../../components/forms/DateRangePicker";

function ModalFilter({
  t,
  show,
  toggle,
  airport,
  filter,
  toggleFilter,
  isDelete,
  onApplyFilter,
}) {
  const currentUser = getUser();
  const handleReset = (formik) => {
    formik.setFieldValue("status", "");
    formik.setFieldValue("payment_status", "");
    formik.setFieldValue("booking_type", "");
    formik.setFieldValue("start_date", "");
    formik.setFieldValue("end_date", "");
    formik.setFieldValue("service_start_date", "");
    formik.setFieldValue("service_end_date", "");
    formik.setFieldValue("airport_id", "");
    toggleFilter({
      status: "",
      payment_status: "",
      booking_type: "",
      start_date: "",
      end_date: "",
      service_start_date: "",
      service_end_date: "",
      airport_id: "",
    });
    toggle();
  };
  const [haveAirport, setHaveAirport] = useState(
    currentUser?.user.role_code === "admin_bandara" 
      ? true 
    : currentUser?.user.role_code === "supervisor" 
      ? true
    : currentUser?.user.role_code === "finance_bandara" 
      ? true
    : currentUser?.user.role_code === "sales_bandara" 
      ? true
    : currentUser?.user.role_code === "assistant" 
      ? true
      : false
  );

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Filter{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody style={{ margin: "0px 20px" }}>
          <DynamicButton titleLeft={t("field.chooseFilter")} iconLeft="info" />
          <Formik
            initialValues={{
              status: filter?.status ?? "",
              payment_status: filter?.payment_status ?? "",
              booking_type: filter?.booking_type ?? "",
              start_date: filter?.start_date ?? "",
              end_date: filter?.end_date ?? "",
              service_start_date: filter?.service_start_date ?? "",
              service_end_date: filter?.service_end_date ?? "",
              airport_id: filter?.airport_id ?? "",
            }}
            onSubmit={(values) => {
              onApplyFilter(
                {
                  airport_id: haveAirport ? currentUser?.user.airport_id : values.airport_id,
                  status: values.status, 
                  payment_status: values.payment_status,
                  booking_type: values.booking_type,
                  start_date: values.start_date,
                  end_date: values.end_date,
                  service_start_date: values.service_start_date,
                  service_end_date: values.service_end_date,
                }
              );
              toggle();
            }}
          >
            {(props) => (
              <Form style={{ marginTop: 30 }}>
                <DatePicker
                  title={t("field.bookingTime")}
                  name="start_date"
                  handlechange={(startDate, endDate) => {
                    props.setFieldValue("start_date", startDate);
                    props.setFieldValue("end_date", endDate);
                  }}
                  format="DD MMMM YYYY"
                  startDate={filter ? filter?.start_date : props.values?.start_date}
                  endDate={filter ? filter?.end_date : props.values?.end_date}
                  className="mx-auto"
                  errors={props.errors}
                  touched={props.touched}
                />
                <DatePicker
                  title={t("report.serviceTime")}
                  name="service_start_date"
                  handlechange={(startDate, endDate) => {
                    props.setFieldValue("service_start_date", startDate);
                    props.setFieldValue("service_end_date", endDate);
                  }}
                  format="DD MMMM YYYY"
                  startDate={filter ? filter?.service_start_date : props.values?.service_start_date}
                  endDate={filter ? filter?.service_end_date : props.values?.service_end_date}
                  className="mx-auto"
                  errors={props.errors}
                  touched={props.touched}
                />
                {haveAirport === false && (
                  <Select2
                    title={t("field.airport")}
                    name="airport_id"
                    options={[
                      {
                        value: "",
                        label: `${t("commons.all")} ${t("field.airport")}`,
                      },
                      ...airport,
                    ]}
                    errors={props.errors}
                    touched={props.touched}
                    value={props.values?.airport_id}
                    onChange={(name, value) => {
                      props.setFieldValue("airport_id", value);
                    }}
                    onBlur={props.setFieldTouched}
                  />
                )}
                <Select2
                  title="Status"
                  name="status"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} Status`,
                    },
                    {
                      value: -1,
                      label: t("status.cancelled"),
                    },
                    {
                      value: 0,
                      label: t("status.new"),
                    },
                    {
                      value: 1,
                      label: t("status.assigned"),
                    },
                    {
                      value: 2,
                      label: t("status.onProgress"),
                    },
                    {
                      value: 5,
                      label: t("status.done"),
                    },
                    // {
                    //   value: 6,
                    //   label: t("status.refund"),
                    // },
                    {
                      value: 7,
                      label: t("status.reschedule"),
                    }
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.status}
                  placeholder="Pilih status"
                  onChange={(name, value) => {
                    props.setFieldValue("status", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title={t("field.paymentStatus")}
                  name="payment_status"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} Status`,
                    },
                    {
                      value: "paid",
                      label: t("status.paid"),
                    },
                    {
                      value: "unpaid",
                      label: t("status.unpaid"),
                    },
                    {
                      value: "invoice",
                      label: t("status.invoicePayment"),
                    },
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.payment_status}
                  placeholder="Pilih status pembayaran"
                  onChange={(name, value) => {
                    props.setFieldValue("payment_status", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title={t("field.bookingType")}
                  name="booking_type"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t(
                        "field.bookingType"
                      )}`,
                    },
                    {
                      value: "mobile",
                      label: t("field.mobileApp"),
                    },
                    {
                      value: "web-public",
                      label: t("field.webPublic"),
                    },
                    {
                      value: "web-admin",
                      label: t("field.webAdmin"),
                    },
                    {
                      value: "api",
                      label: "API",
                    },
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.booking_type}
                  onChange={(name, value) => {
                    props.setFieldValue("booking_type", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <div style={{ marginTop: 50, marginBottom: 30 }}>
                  <ButtonModal
                    toggle={toggle}
                    reset={() => handleReset(props)}
                    deleted={isDelete}
                    confirmTitle={t("field.apply")}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = ({ masterDataRegion }) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(ModalFilter)
);
