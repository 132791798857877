import { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { Container, Row, Col } from "reactstrap";
import * as Yup from "yup";
import moment from "moment";

import Button from "../../../components/buttons/Button";
import Loader from "../../../components/commons/Loader";
import ButtonModal from "../../../components/modal/ButtonModal";
import SideMenu from "../../../components/commons/menu/SideMenu";
import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import ConfirmationModal from "../../../components/modal/ConfirmModal";
import Checkout from "./Checkout";
import FlightReservation from "./FlightReservation";
import PassengerReservation from "./PassengerReservation";
import AdtServiceReservation from "./AdtServiceReservation";
import { getUser } from "../../../utils/User";
import { history } from "../../../utils/History";
import { withTrans } from "../../../i18n/withTrans";
import { toastError } from "../../../components/commons/toast";

//Service
import Booking from "../../../store/actions/booking";

const Index = ({ match, pending, t, error_message }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const currentUser = getUser();
  const currentUrl = match?.url?.split("/")?.[1];
  const position = currentUser?.user?.role_name;
  const role = currentUser?.user?.role_code;
  const minDate =
    currentUrl === 'booking-contact-center'
      ? moment().format("YYYY-MM-DD")
        : role === "admin_pusat"
          ? moment().add(1, "days").format("YYYY-MM-DD")
          : (role === "contact_center" || role === "lead_contact_center")
          ? null
          : moment().subtract(1, 'days').format("YYYY-MM-DD");

  const [show, setShow] = useState("flight-reservation");
  const [next, setNext] = useState("passenger-reservation");
  const [back, setBack] = useState(null);
  const [sequence, setSequence] = useState(1);
  const [cancelModal, setCancelModal] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const showCancelModal = () => {
    setCancelModal(!cancelModal);
  };
  const handleCancel = () => {
    history.push("/booking");
  };

  const handleChangeForm = (tab) => {
    document.documentElement.scrollTop = 0;
    switch (tab) {
      case "flight-reservation":
        setShow("flight-reservation");
        setBack(null);
        setNext("passenger-reservation");
        setSequence(1);
        break;
      case "passenger-reservation":
        setShow("passenger-reservation");
        setBack("flight-reservation");
        setNext("adt-service-reservation");
        setSequence(2);
        break;
      case "adt-service-reservation":
        setShow("adt-service-reservation");
        setBack("passenger-reservation");
        setNext("checkout");
        setSequence(3);
        break;
      case "checkout":
        setShow("checkout");
        setBack("adt-service-reservation");
        setNext(null);
        setSequence(4);
        break;
    }
  };

  const findAvailabilityPrice = (tab) => {
    let values = formikRef.current.values;
    let airport_selected =
      values.flight_type === 0
        ? values.airport_id_to
        : values.flight_type === 1
        ? values.airport_id_from
        : values.airport_id_transfer;
    const adult_total = 
      values?.passenger_list?.filter(
        (item) => item?.title === "Mrs." || item?.title === "Mr." || item?.title === "Ms."
      )?.length;
    const child_total =
      values?.passenger_list?.filter(
        (item) => item?.title === "Child"
      )?.length;
    const infant_total =
      values?.passenger_list?.filter(
        (item) => item?.title === "Infant"
      )?.length;

    new Promise((resolve) => {
      let param = {
        airport_service_id: airport_selected,
        flight_type: values?.flight_type_code,
        service_date: values?.date,
        product_name: values?.product_type,
        adult_total: values?.passenger_list?.length
          ? adult_total
          : null,
        child_total: values?.passenger_list?.length
          ? child_total
          : null,
        infant_total: values?.passenger_list?.length
          ? infant_total
          : null,
      };
      if (
        airport_selected &&
        values?.flight_type_code &&
        values?.date &&
        values?.product_type
      ) {
        dispatch(Booking.findAvailPrice(param, resolve));
      }
    }).then((res) => {
      if (res) {
        handleChangeForm(tab);

        if (!res?.data) {
          return;
        }

        const product_list = res?.data?.period_list[0]?.product_list[0];

        formikRef.current.setFieldValue(
          "period_id",
          res?.data?.period_list[0]?.id
        );
        formikRef.current.setFieldValue(
          "product_list", 
          product_list
        );
        formikRef.current.setFieldValue(
          "product_list_id", 
          product_list?.id
        );

        if (product_list?.group_prices?.length) {
          formikRef.current.setFieldValue(
            "group_price_id",
            product_list?.group_prices[0]?.id
          );
          formikRef.current.setFieldValue("price_id", null);
        } else {
          formikRef.current.setFieldValue(
            "price_id",
            product_list?.prices?.id
          );
          formikRef.current.setFieldValue("group_price_id", null);
        }
      }
    });
  };

  const handleNextClick = (tab) => {
    let values = formikRef?.current?.values;

    formikRef.current.validateForm().then(() => {
      if (tab === "passenger-reservation") {
        if ((role === "admin_pusat") 
          && values?.isGoShow
        ) {
          toastError(`${position} ${t("booking.notAllowedGoShow")}!`);
          return;
        }
        if (values.flight_type < 0) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.flightType")}!`
          );
          return;
        }
        if (
          values.airport_id_from === "" ||
          values.airport_id_from === null
        ) {
          toastError(`${t("commons.empty_data_message")} ${"field.origin"}!`);
          return;
        }
        if (
          values.airport_id_to === "" ||
          values.airport_id_to === null
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.destination")}!`
          );
          return;
        }
        if (
          values.airplane_name === "" ||
          values.airplane_name === null
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.airline")}!`
          );
          return;
        }
        if (
          values.airplane_number === "" ||
          values.airplane_number === null
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.flightNumber")}!`
          );
          return;
        }
        if (
          values.date === "" ||
          values.date === null
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.date")}!`
          );
          return;
        } else if (
          values.date !== "" &&
          values.date !== null
        ) {
          let dateTomorrow = moment().add(1, "days").format("YYYY-MM-DD");
          if (
            values?.is_cross_selling == 1 &&
            values?.date < dateTomorrow
          ) {
            toastError(
              `${t("commons.orderDateHandle")} ${moment(dateTomorrow).format("DD/MM/YYYY")}!`
            );
            return;
          } else if (
            values?.is_cross_selling != 1 &&
            values?.date < minDate
          ) {
            toastError(
              `${t("commons.orderDateHandle")} ${moment(minDate).format("DD/MM/YYYY")}!`
            );
            return;
          }
        }
        if (
          values.time === "" ||
          values.time === null
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t("commons.time")}!`
          );
          return;
        }

        if (
          currentUrl === 'booking-contact-center' &&
          values.time !== "" &&
          values.time !== null
        ) {
          let time = moment(`${minDate}, ${moment().format("HH:mm")}`).diff(
            moment().startOf("day"),
            "minutes"
          );
          let datepick = values.date;
          let timepick = moment(
            `${datepick}, ${values.time}`
          ).diff(moment().startOf("day"), "minutes");

          if (
            minDate === datepick &&
            (time >= timepick)
          ) {
            toastError(
              `${
                values.flight_type !== 1
                  ? t("field.arrivalTime")
                  : t("field.departureTime")
              } ${t("commons.orderTimeHandle")}`
            );
            return;
          }
        }

        if (values.flight_type === 2) {
          let timeArrival = moment(
            `${values?.date}, ${values?.time}`,
            "YYYY-MM-DD HH:mm"
          )?.diff(moment().startOf("day"), "minutes");

          let timeDeparture = moment(
            `${values?.date_transfer_to}, ${values?.time_transfer_to}`,
            "YYYY-MM-DD HH:mm"
          )?.diff(moment().startOf("day"), "minutes");

          if (timeArrival > timeDeparture) {
            toastError(t("commons.transfer_message"));
            return;
          }

          if (
            values.airport_id_transfer === "" ||
            values.airport_id_transfer === null
          ) {
            toastError(`${t("commons.empty_data_message")} Transfer!`);
            return;
          }
          if (values.airplane_name_transfer_to === "") {
            toastError(
              `${t("commons.empty_data_message")} ${t("field.airline")} !`
            );
            return;
          }
          if (values.airplane_number_transfer_to === "") {
            toastError(
              `${t("commons.empty_data_message")} ${t(
                "field.flightNumber"
              )} !`
            );
            return;
          }
          if (values.date_transfer_to === "") {
            toastError(
              `${t("commons.empty_data_message")} ${t(
                "field.departureDate"
              )} !`
            );
            return;
          } else if (
            values.date_transfer_to !== "" &&
            values.date_transfer_to !== null &&
            values?.date_transfer_to < minDate
          ) {
            toastError(
              `${t("commons.orderDateHandle")} ${moment(minDate).format(
                "DD/MM/YYYY"
              )}!`
            );
            return;
          }
          if (values.time_transfer_to === "") {
            toastError(
              `${t("commons.empty_data_message")} ${t(
                "field.departureTime"
              )} !`
            );
            return;
          }
        }
        if (
          values?.product_type === null ||
          values?.product_type === 0
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.product")}!`
          );
          return;
        }

        if (formikRef.current.isValid) {
          findAvailabilityPrice(tab);
        }
      } else if (tab === "adt-service-reservation") {
        if (
          !values?.paging_name &&
          !values?.paging_logo
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.pagingInfo")}!`
          );
          return;
        }
        if (!values?.passenger_list.length > 0) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.passenger")}!`
          );
          return;
        }
        if (
          !(values?.bookers_title &&
            values?.bookers_name &&
            values?.bookers_nationality &&
            values?.bookers_email)
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t(
              "field.bookersInfo"
            )}!`
          );
          return;
        } else if (
          values?.bookers_title &&
          values?.bookers_name &&
          values?.bookers_nationality &&
          values?.bookers_email &&
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
            values?.bookers_email
          )
        ) {
          toastError(`${t("commons.wrong_email_format")} ${t("field.booker")}!`);
          return;
        } else if (
          values?.bookers !== "admin" &&
          (values?.bookers_phone === "" ||
          values?.bookers_phone.includes("undefined") ||
          values?.bookers_phone === values?.bookers_country_code)
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t(
              "field.bookersInfo"
            )}!`
          );
          return;
        }

        let passengerNotValid = false;
        values?.passenger_list?.forEach((item) => {
          if (item?.name === "" || item?.name === undefined) {
            toastError(
              `${t("commons.empty_data_message")} ${t("field.name")} ${t(
                "field.passenger"
              )}!`
            );
            passengerNotValid = true;
          } else if (
            item?.passenger_nationality === "" ||
            item?.passenger_nationality === undefined
          ) {
            toastError(
              `${t("commons.empty_data_message")} ${t(
                "field.nationality"
              )} ${t("field.passenger")}!`
            );
            passengerNotValid = true;
          } else if (item?.title === "" || item?.title === undefined) {
            toastError(
              `${t("commons.empty_data_message")} ${t("field.title")} ${t(
                "field.passenger"
              )}!`
            );
            passengerNotValid = true;
          } else if (
            item?.title !== "" &&
            item?.title !== "Mr." &&
            item?.title !== "Mrs." &&
            item?.title !== "Ms." &&
            item?.title !== "Infant" &&
            item?.title !== "Child"
          ) {
            toastError(`${t("commons.invalidTitlePassenger")}!`);
            passengerNotValid = true;
          } else if (item?.title === "Infant") {
            if (
              item?.passenger_date_of_birth === "" ||
              item?.passenger_date_of_birth === undefined
            ) {
              toastError(`${t("commons.complete_birth_date_message")}!`);
              passengerNotValid = true;
            } else {
              const yearDiff = moment().diff(
                item?.passenger_date_of_birth,
                "year"
              );
              if (yearDiff > 2) {
                toastError(`${t("commons.birth_date_less_than_message")}!`);
                passengerNotValid = true;
              }
            }
          } else if (item?.title !== "Infant" && item?.title !== "Child") {
            if (item?.email === "" || item?.email === undefined) {
              toastError(
                `${t("commons.empty_data_message")} Email ${t(
                  "field.passenger"
                )}!`
              );
              passengerNotValid = true;
            } else if (
              item?.phoneNumber === "" ||
              item?.phoneNumber === undefined ||
              item?.phoneNumber.includes("undefined") ||
              item?.phoneNumber === item?.countryCode
            ) {
              toastError(
                `${t("commons.empty_data_message")} ${t("field.phone")} ${t(
                  "field.passenger"
                )}!`
              );
              passengerNotValid = true;
            }
          }
        });

        if (passengerNotValid) {
          return;
        }

        if (formikRef.current.isValid) {
          findAvailabilityPrice(tab);
        }
      } else if (tab === "checkout") {
        const countAdult = values?.passenger_list?.filter(
          (item) =>
            item?.title === "Mr." ||
            item?.title === "Mrs." ||
            item?.title === "Ms."
        ).length;

        const countChild = values?.passenger_list?.filter(
          (item) => item?.title === "Child"
        ).length;

        const countInfant = values?.passenger_list?.filter(
          (item) => item?.title === "Infant"
        ).length;

        if (values?.lounge) {
          if (values?.lounge_adult_number > countAdult) {
            toastError(`${t("booking.handleAdultLounge")}!`);
            return;
          }
          if (values?.lounge_child_number > countChild) {
            toastError(`${t("booking.handleChildLounge")}!`);
            return;
          }
          if (values?.lounge_infant_number > countInfant) {
            toastError(`${t("booking.handleInfantLounge")}!`);
            return;
          }
        } else {
          formikRef?.current?.setFieldValue("lounge_adult_number", 0);
          formikRef?.current?.setFieldValue("lounge_child_number", 0);
          formikRef?.current?.setFieldValue("lounge_infant_number", 0);
        }

        if (values?.region) {
          if (
            !(
              values?.car_type &&
              values?.total_unit
            )
          ) {
            toastError(
              `${t("commons.empty_data_message")} ${t("field.transportation")}!`
            );
            return;
          }
        } else {
          formikRef?.current?.setFieldValue("total_unit", 0);
        }

        if (
          (
            !values?.is_cross_selling && 
            (values?.additional_service_description ||
            values?.additional_service_concession_percentage ||
            values?.additional_service_ppn_percentage ||
            values?.additional_service_price)
          ) &&
          !(
            values?.additional_service_description !== "" &&
            (
              values?.additional_service_concession_percentage !== null &&
              values?.additional_service_concession_percentage !== ""
            ) &&
            (
              values?.additional_service_ppn_percentage !== null &&
              values?.additional_service_ppn_percentage !== ""
            ) &&
            values?.additional_service_price > 0
          )
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t(
              "field.additionalService"
            )} ${t("masterData.others")}!`
          );
          return;
        }

        if (formikRef.current.isValid) {
          formikRef.current.setFieldValue("promo_id", null);
          formikRef.current.setFieldValue("promo_id_cross_selling", null);
          handleChangeForm(tab);
        }
      }
    });
  };

  const handleChangeTab = (tab, number) => {
    if (number > sequence) {
      formikRef.current.validateForm().then(() => {
        if (formikRef.current.isValid) {
          handleChangeForm(tab);
        }
      });
    } else {
      handleChangeForm(tab);
    }
  };

  const validation = {
    "flight-reservation": Yup.lazy((value) => {
      if (value.flight_type === -1) {
        return Yup.object().shape({
          airport_id_from: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airport_id_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_name: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_number: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          time: Yup.string().required(t("commons.required")).nullable(),
          date: Yup.string().required(t("commons.required")).nullable(),
        });
      }
      if (value.flight_type === 0) {
        return Yup.object().shape({
          airport_id_from: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airport_id_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_name: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_number: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          time: Yup.string().required(t("commons.required")).nullable(),
          date: Yup.string().required(t("commons.required")).nullable(),
        });
      }
      if (value.flight_type === 1) {
        return Yup.object().shape({
          airport_id_from: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airport_id_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_name: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_number: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          time: Yup.string().required(t("commons.required")).nullable(),
          date: Yup.string().required(t("commons.required")).nullable(),
        });
      }
      if (value.flight_type === 2) {
        return Yup.object().shape({
          airport_id_from: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airport_id_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airport_id_transfer: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_name: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_name_transfer_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_number: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_number_transfer_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          time: Yup.string().required(t("commons.required")).nullable(),
          time_transfer_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          date: Yup.string().required(t("commons.required")).nullable(),
          date_transfer_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
        });
      }
      return Yup.mixed().notRequired();
    }),
    "passenger-reservation": Yup.object().shape({}),
    "adt-service-reservation": Yup.lazy((value) => {
      if (value.transportation !== undefined) {
        return Yup.object().shape({
          transportation: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          car_type: Yup.string().required(t("commons.required")).nullable(),
        });
      }
      return Yup.mixed().notRequired();
    }),
  };

  const initialValues = {
    id: "",
    car_type: "",
    airplane_name: "",
    airplane_name_transfer_to: "",
    airplane_number: "",
    airplane_number_transfer_to: "",
    airport_id_from: "",
    airport_id_to: "",
    airport_id_transfer: "",
    is_cross_selling: null,
    available_service: "",
    corporate_id:
      position === "Corporate" ? currentUser?.user?.corporate_id : "",
    customer_id: "",
    assistant_id: null,
    assistant_list: null,
    date: "",
    date_transfer_to: "",
    payment_type: "",
    file: null,
    dataPrice: 0,
    dataPriceMOB: 0,
    dataPriceFIT: 0,
    dataPriceMOBIndividual: 0,
    dataPriceGroup: [],
    flight_type: -1,
    isOverMOB: false,
    lounge: "",
    lounge_adult_number: 0,
    lounge_child_number: 0,
    lounge_infant_number: 0,
    total_lounge_adult: 0,
    total_lounge_child: 0,
    total_lounge_infant: 0,
    total_lounges: 0,
    orderExtras: {
      lounge: { name: "", price: "", id: "" },
      transport: { name: "", price: "", id: "" },
      car: { name: "", price: "", id: "" },
    },
    order_extra_list: [],
    passenger_list: [],
    passenger_notes: "",
    product_id: null,
    product_list_id: null,
    product_type: 0,
    promo_id: null,
    promo_id_cross_selling: null,
    time: "",
    time_transfer_to: "",
    transportation: "",
    region: "",
    type: null,
    groupPrice: 0,
    grandTotalPrice: 0,
    loungePrice: 0,
    transportPrice: 0,
    isPriceNotSet: true,
    midtrans_token_id: null,
    isGoShow: false,
    passenger_as_booker: null,
    bookers_title: "",
    bookers_name: "",
    bookers_nationality: "",
    bookers_email: "",
    bookers_phone: "",
    selectedAirport: {},
    selectedAirportTransfer: {},
    selectedAirportDomestic: {},
    selectedAirline: {},
    selectedAirlineTransfer: {},
    imagePagingInfo: {
      title: t("field.uploadPagingInfo"),
      subtitle: "File",
    },
    additional_service_description: "",
    additional_service_concession_percentage: null,
    additional_service_ppn_percentage: null,
    additional_service_price: null,
    additional_service_dpp: null,
    additional_service_is_include_concession: null,
    additional_service_is_include_ppn: null,
  };

  const handleSubmit = (values) => {
    let isNotValid = false;

    values.type = values.flight_type;
    values.customer_id = null;
    if (
      values.date <= moment().format("YYYY-MM-DD") &&
      values?.is_cross_selling != 1 &&
      currentUrl !== 'booking-contact-center'
    ) {
      // values.product_id = values.data_product_list[3].id;
      if (
        values?.assistant_list === undefined ||
        values?.assistant_list === null
      ) {
        toastError(
          `${t("commons.empty_data_message")} ${t("field.assistant")}!`
        );
        isNotValid = true;
        return;
      }
    }
    if (values?.payment_type !== "" && values?.file === null) {
      toastError(
        `${t("commons.empty_data_message")} ${t("booking.paymentProof")}!`
      );
      isNotValid = true;
      return;
    }
    if (values?.payment_type === "" && values?.file !== null) {
      toastError(
        `${t("commons.empty_data_message")} ${t("booking.paymentType")}!`
      );
      isNotValid = true;
      return;
    }
    if (values?.is_cross_selling == 1 && values?.promo_id_cross_selling == null) {
      toastError(
        `${t("commons.validationCrossSelling")}!`
      );
      isNotValid = true;
      return;
    }

    let formData = new FormData();
    if (values?.additional_service_description !== "") {
      formData.append(
        "additional_service_description",
        values?.additional_service_description
      );
    }
    if (values?.additional_service_concession_percentage !== null) {
      formData.append(
        "additional_service_concession_percentage",
        parseFloat(values?.additional_service_concession_percentage)
      );
    }
    if (values?.additional_service_is_include_concession !== undefined) {
      formData.append(
        "additional_service_is_include_concession",
        values?.additional_service_is_include_concession == 1 ? true : false
      );
    }
    if (values?.additional_service_is_include_ppn !== undefined) {
      formData.append(
        "additional_service_is_include_ppn",
        values?.additional_service_is_include_ppn == 1 ? true : false
      );
    }
    if (values?.additional_service_ppn_percentage !== null) {
      formData.append(
        "additional_service_ppn_percentage",
        parseFloat(values?.additional_service_ppn_percentage)
      );
    }
    if (
      values?.additional_service_price !== null &&
      values?.additional_service_price !== 0
    ) {
      formData.append(
        "additional_service_price",
        values?.additional_service_price
      );
    }
    if(
      values?.additional_service_dpp !== null && 
      values?.additional_service_dpp !== 0
    ) {
      formData.append(
        "additional_service_dpp", 
        values?.additional_service_dpp
      );
    }
    formData.append("airplane_name", values?.airplane_name);
    formData.append(
      "airplane_name_transfer_to",
      values?.airplane_name_transfer_to
    );
    formData.append("airplane_number", values?.airplane_number);
    formData.append(
      "airplane_number_transfer_to",
      values?.airplane_number_transfer_to
    );
    if (values?.airport_id_from !== "") {
      formData.append("airport_id_from", values?.airport_id_from);
    }
    if (values?.airport_id_to !== "") {
      formData.append("airport_id_to", values?.airport_id_to);
    }
    if (values?.airport_id_transfer !== "") {
      formData.append("airport_id_transfer", values?.airport_id_transfer);
    }
    if (
      values?.assistant_list !== null &&
      values?.assistant_list !== undefined
    ) {
      values.assistant_list.forEach((key, x) => {
        formData.append(
          `assistant_list[${[x]}].assistant_id`,
          key.assistant_id ? key.assistant_id : ""
        );
        formData.append(
          `assistant_list[${[x]}].is_main`,
          key.is_main ? key.is_main : ""
        );
      });
    }
    formData.append("bookers_title", values?.bookers_title);
    formData.append("bookers_name", values?.bookers_name);
    formData.append("bookers_nationality", values?.bookers_nationality);
    formData.append("bookers_email", values?.bookers_email);
    if (
      values?.bookers_phone !== "" &&
      values?.bookers_phone !== undefined &&
      values?.bookers_phone !== null &&
      !values?.bookers_phone.includes("undefined") &&
      values?.bookers_phone !== values?.bookers_country_code
    ) {
      const bookerPhone = values?.bookers_phone?.replace(values?.bookers_country_code, "");
      formData.append(
        'bookers_phone',
        bookerPhone.charAt(0) == 0
          ? bookerPhone?.substring(1)
          : bookerPhone
      );
      formData.append(
        `bookers_country_code`,
        `+${values?.bookers_country_code}`
      );
      formData.append(
        `bookers_country_id`,
        values?.bookers_country_id
      );
    }
    if (values?.customer_id !== null) {
      formData.append("customer_id", values?.customer_id);
    }
    formData.append("date", values?.date);
    if (values?.date_transfer_to !== "") {
      formData.append("date_transfer_to", values?.date_transfer_to);
    }
    if (values?.file !== null) {
      formData.append("file", values?.file);
    }
    formData.append("is_cross_selling", values?.is_cross_selling == 1 ? true : false);
    if (values?.lounge !== "") {
      formData.append("airport_lounge_id", values?.lounge);
    }
    if (values?.lounge_adult_number === "") {
      formData.append("lounge_adult_number", 0);
    } else {
      formData.append("lounge_adult_number", values?.lounge_adult_number);
    }
    if (values?.lounge_child_number === "") {
      formData.append("lounge_child_number", 0);
    } else {
      formData.append("lounge_child_number", values?.lounge_child_number);
    }
    if (values?.lounge_infant_number === "") {
      formData.append("lounge_infant_number", 0);
    } else {
      formData.append("lounge_infant_number", values?.lounge_infant_number);
    }
    if (values?.midtrans_token_id !== null) {
      formData.append("midtrans_token_id", values?.midtrans_token_id);
    }
    if (values?.paging_name !== "" && values?.paging_name !== undefined) {
      formData.append("paging_name", values?.paging_name);
    }
    if (values?.paging_logo !== null && values?.paging_logo !== undefined) {
      formData.append("paging_logo", values?.paging_logo);
    }
    values.passenger_list.forEach((key, x) => {
      formData.append(
        `passenger_list[${[x]}].email`,
        key.email ? key.email : ""
      );
      formData.append(`passenger_list[${[x]}].name`, key.name ? key.name : "");
      if (
        key.phoneNumber !== "" &&
        key.phoneNumber !== undefined &&
        key.phoneNumber !== null &&
        !key?.phoneNumber.includes("undefined") &&
        key?.phoneNumber !== key?.countryCode
      ) {
        const phone = key?.phoneNumber?.replace(key?.countryCode, "");
        formData.append(
          `passenger_list[${[x]}].phoneNumber`,
          phone.charAt(0) == 0
            ? phone?.substring(1)
            : phone
        );
        formData.append(
          `passenger_list[${[x]}].countryCode`,
          key.countryCode ? `+${key.countryCode}` : ""
        );
        formData.append(
          `passenger_list[${[x]}].countryId`,
          key.countryId ? key.countryId : ""
        );
      }
      formData.append(
        `passenger_list[${[x]}].title`,
        key.title ? key.title : ""
      );
      formData.append(
        `passenger_list[${[x]}].passenger_nationality`,
        key.passenger_nationality ? key.passenger_nationality : ""
      );
      formData.append(
        `passenger_list[${[x]}].passenger_date_of_birth`,
        key.passenger_date_of_birth ? key.passenger_date_of_birth : ""
      );
    });
    if (values?.passenger_notes !== "") {
      formData.append("passenger_notes", values?.passenger_notes);
    }
    if (values?.payment_type !== "") {
      formData.append("payment_type", values?.payment_type);
    }
    formData.append("period_id", values?.period_id);
    if (values?.price_id !== undefined && values?.price_id !== null) {
      formData.append("price_id", values?.price_id);
    }
    if (
      values?.group_price_id !== undefined &&
      values?.group_price_id !== null
    ) {
      formData.append("group_price_id", values?.group_price_id);
    }
    formData.append("product_list_id", values?.product_list_id);
    formData.append("product_name", values?.product_type);
    if (values?.promo_id !== null) {
      formData.append("promo_id", values?.promo_id);
    }
    if (values?.promo_id_cross_selling !== null) {
      formData.append("promo_id_cross_selling", values?.promo_id_cross_selling);
    }
    if (values?.region !== "") {
      formData.append("region", values?.region);
    }
    if (values?.show_joumpa_logo == 1) {
      formData.append("show_joumpa_logo", true);
    }
    formData.append("type", values?.flight_type + 1);
    formData.append("time", values?.time);
    if (values?.time_transfer_to !== "") {
      formData.append("time_transfer_to", values?.time_transfer_to);
    }
    if (values?.total_unit !== "" && values?.total_unit !== undefined) {
      formData.append("transport_number", values?.total_unit);
    }
    if (values?.orderExtras?.car?.id) {
      formData.append("transport_price_id", values?.orderExtras?.car?.id);
    }

    if (isNotValid) {
      return;
    }

    new Promise((resolve) => {
      setLoadingSubmit(true);
      if (currentUrl !== 'booking-contact-center') {
        const callback = () => {
          history.push("/booking");
        };
        dispatch(Booking.post(formData, resolve, callback));
      } else {
        dispatch(Booking.postContactCenter(formData, resolve));
      }
    }).then((res) => {
      if (currentUrl === 'booking-contact-center' && res) {
        window.open(`${res?.data?.redirect_payment_url}`, `_blank`);
        history.push("/booking-contact-center");
      };
      setLoadingSubmit(false);
    });
  };

  useEffect(() => {
    if (error_message) {
      setLoadingSubmit(false);
    }
  }, [error_message]);

  const button = (param = null, data = null) => {
    return (
      <div style={{ float: "right" }}>
        {next && (
          <ButtonModal
            cancelTitle={back ? t("commons.back") : t("commons.cancel")}
            confirmTitle={t("commons.next")}
            typeConfirm={"Button"}
            toggle={
              back ? () => handleChangeForm(back) : () => showCancelModal()
            }
            confirm={next ? () => handleNextClick(next) : null}
          />
        )}
        {next === null && (
          <ButtonModal
            cancelTitle={back ? t("commons.back") : t("commons.cancel")}
            confirmTitle={t("commons.save")}
            typeConfirm={"Submit"}
            toggle={back ? () => handleChangeForm(back) : ""}
            disabledConfirm={loadingSubmit}
          />
        )}
      </div>
    );
  };

  return (
    <div className="menu-container">
      {pending && <Loader loading={pending} />}
      <div className="grid-header">
        <div className="side-menu-no-border">
          <div className="treatment-title menu-title text-extra-bold">
            <div className="treatment-title-icon ">
              {t("field.newReservation")}
            </div>
          </div>
          <SideMenu
            title={t("field.flight")}
            toggle={() => handleChangeTab("flight-reservation")}
            checked={sequence > 1}
            checkbox
          />
          <SideMenu
            title={t("field.passenger")}
            toggle={() => handleChangeTab("passenger-reservation", 2)}
            checked={sequence > 2}
            checkbox
          />
          <SideMenu
            title={t("field.additionalService")}
            toggle={() => handleChangeTab("adt-service-reservation", 3)}
            checked={sequence > 3}
            checkbox
          />
          <SideMenu
            title="Check Out"
            toggle={() => handleChangeTab("checkout", 4)}
            checked={sequence > 4}
            checkbox
          />
        </div>
        <div className="side-menu-detail">
          <Container>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              onSubmit={(values) => {
                if (next === null) {
                  handleSubmit(values);
                }
              }}
            >
              {(props) => (
                <Form>
                  {show === "flight-reservation" && (
                    <>
                      <HeaderMenu
                        children={
                          <>
                            <Button
                              variant="secondary"
                              rounded
                              leftIcon="close"
                              title={t("commons.cancel")}
                              onClick={() => showCancelModal()}
                            />
                          </>
                        }
                      />
                      <FlightReservation formik={props} button={button} currentUrl={currentUrl} />
                    </>
                  )}
                  {show === "passenger-reservation" && (
                    <>
                      <HeaderMenu
                        children={
                          <>
                            <Button
                              variant="secondary"
                              rounded
                              leftIcon="close"
                              title={t("commons.cancel")}
                              onClick={() => showCancelModal()}
                            />
                          </>
                        }
                      />
                      <PassengerReservation formik={props} button={button} currentUrl={currentUrl} />
                    </>
                  )}
                  {show === "adt-service-reservation" && (
                    <>
                      <HeaderMenu
                        children={
                          <>
                            <Button
                              variant="secondary"
                              rounded
                              leftIcon="close"
                              title={t("commons.cancel")}
                              onClick={() => showCancelModal()}
                            />
                          </>
                        }
                      />
                      <AdtServiceReservation formik={props} button={button} currentUrl={currentUrl} />
                    </>
                  )}
                  {show === "checkout" && (
                    <>
                      <HeaderMenu
                        children={
                          <>
                            <Button
                              variant="secondary"
                              rounded
                              leftIcon="close"
                              title={t("commons.cancel")}
                              onClick={() => showCancelModal()}
                            />
                          </>
                        }
                      />
                      <Checkout formik={props} button={button} currentUrl={currentUrl} />
                    </>
                  )}
                </Form>
              )}
            </Formik>
            <ConfirmationModal
              show={cancelModal}
              toggle={showCancelModal}
              confirm={handleCancel}
              icon="info"
              isDelete
              isCancel={t("commons.back")}
              isConfirm={t("commons.yesCancel")}
            >
              <Col md={8}>
                <div className="normal-title">
                  {t("commons.areYouSureCancel")} <strong>Booking</strong> ?
                </div>
              </Col>
            </ConfirmationModal>
          </Container>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ booking: { error_message } }) => {
  return { error_message };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Index));
