import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Compressor from "compressorjs";

import Input from "../../../components/forms/Input";
import DynamicButton from "../../../components/forms/DynamicButton";
import Checkbox from "../../../components/forms/Checkbox";
import ButtonModal from "../../../components/modal/ButtonModal";
import PagingLogoModal from "../../../components/modal";
import { withTrans } from "../../../i18n/withTrans";
import { toastError } from "../../../components/commons/toast";

import BookingService from "../../../store/actions/booking";

const ModalEditPaging = ({
  t,
  show,
  toggle,
  data,
  callback
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const imageRef = useRef();
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  
  const [showPagingLogo, setShowPagingLogo] = useState(false);
  const [pagingLogoUrl, setPagingLogoUrl] = useState(null);
  const [pagingLogoName, setPagingLogoName] = useState("");
  const [fileImage, setFileImage] = useState();

  async function getFileFromUrl(url, defaultName){
    const response = await fetch(url);
    const data = await response.blob();
    return setFileImage(new File([data], defaultName, { type: 'image/png' }));
  };

  useEffect(() => {
    if (data?.paging_logo) {
      const imgUrl = 
        process.env.REACT_APP_API_URL + 
        data?.paging_logo?.substr(
          1,
          data?.paging_logo?.length
        );
      getFileFromUrl(imgUrl, imgUrl.split('/').pop());
      setPagingLogoUrl(imgUrl);
      setPagingLogoName(imgUrl.split('/').pop())
    }
  }, [data?.paging_logo]);

  const initial = {
    order_id: data?.id,
    paging_name: data?.paging_name,
    show_joumpa_logo: data?.show_joumpa_logo ? ["1"] : null,
    paging_logo: data?.paging_logo ? fileImage : null,
    imagePagingInfo: data?.paging_logo ? {
      title: pagingLogoName,
      subtitle: "File",
    } : {
      title: t("field.uploadPagingInfo"),
      subtitle: "File",
    },
  }

  const validation = Yup.object().shape({});

  const openFileDialogImage = () => {
    imageRef.current.click();
  };

  const handleChangeImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const image = e.target.files[0];
      const imageName = image.name;
      new Compressor(image, {
        quality: 0.6,
        success: (res) => {
          setPagingLogoUrl(URL.createObjectURL(res));
          const file = new File([res], imageName);
          formikRef?.current?.setFieldValue("paging_logo", file);
          formikRef?.current?.setFieldValue("imagePagingInfo", {
            title: imageName,
            subtitle: (Number(image.size) * 0.001).toFixed(2) + " KB",
          });
        },
        error(err) {
          console.log(err.message);
        },
      });
    }
  };

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {`Edit ${t("field.pagingInfo")}`}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={initial}
          validationSchema={validation}
          onSubmit={async (values, { setStatus, setSubmitting }) => {
            if (!values?.paging_name && !values?.paging_logo) {
              toastError(
                `${t("commons.empty_data_message")} ${t(
                  "field.pagingInfo"
                )}!`
              );
            } else {
              let formData = new FormData();
              formData.append("order_id", values?.order_id);
              formData.append("paging_name", values?.paging_name);
              formData.append("paging_logo", values?.paging_logo);
              if (values?.show_joumpa_logo == 1){
                formData.append("show_joumpa_logo", true);
              } else {
                formData.append("show_joumpa_logo", false);
              }
              
              setStatus();
              dispatch(BookingService.editPaging(formData, callback));
              await delay(3000);
              setSubmitting(false);
            }
          }}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Container>
                  <Input
                    name="paging_name"
                    errors={props.errors}
                    touched={props.touched}
                    value={props?.values?.paging_name ?? ""}
                    onChange={(e) => {
                      let value = e.target.value;
                      props?.setFieldValue(`paging_name`, value);
                    }}
                    title={t("field.pagingName")}
                    type="text"
                  />
                  <div className="d-flex align-items-center">
                    <div className="form-label">{t("field.pagingImage")}</div>
                    {props?.values?.paging_logo && (
                      <div className="d-flex align-items-center ml-auto">
                        <i
                          className="material-icons-round text-primary"
                          style={{ fontSize: 20 }}
                          onClick={() => setShowPagingLogo(!showPagingLogo)}
                        >
                          visibility
                        </i>
                        <div
                          style={{
                            borderRight: "1px solid #A9A9A9",
                            height: 20,
                            margin: 5,
                          }}
                        ></div>
                        <i
                          className="material-icons-round text-danger"
                          style={{ fontSize: 19 }}
                          onClick={() => {
                            imageRef.current.value = "";
                            setPagingLogoUrl(null);
                            props.setFieldValue("paging_logo", null);
                            props.setFieldValue("imagePagingInfo", {
                              title: t("field.uploadPagingInfo"),
                              subtitle: "File",
                            });
                          }}
                        >
                          delete_outline
                        </i>
                      </div>
                    )}
                  </div>
                  <DynamicButton
                    titleLeft={props?.values?.imagePagingInfo?.title}
                    subtitleLeft={props?.values?.imagePagingInfo?.subtitle}
                    iconLeft="add_photo_alternate"
                    toggle={openFileDialogImage}
                    error={props?.values?.imagePagingInfo?.message_error}
                    marginButton="0 0 20px 0"
                  />
                  <input
                    type="file"
                    ref={imageRef}
                    style={{ display: "none" }}
                    onChange={handleChangeImage}
                    accept="image/png, image/jpeg"
                  />
                  <Checkbox
                    style={{ marginTop: "30px" }}
                    name="show_joumpa_logo"
                    checked={props?.values?.show_joumpa_logo}
                    onChange={(e) => {
                      if (props?.values?.show_joumpa_logo) {
                        props?.setFieldValue("show_joumpa_logo", null);
                      } else {
                        props?.setFieldValue("show_joumpa_logo", ["1"]);
                      }
                    }}
                    value={1}
                    label={t("field.includeLogoJoumpa")}
                  />
                </Container>
              </ModalBody>
              <ModalFooter>
                <ButtonModal
                  toggle={toggle}
                  confirmTitle={t("commons.save")}
                  disabledConfirm={props.isSubmitting}
                  icon
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
      <PagingLogoModal
        show={showPagingLogo}
        title={t("field.pagingImage")}
        toggle={() => setShowPagingLogo(!showPagingLogo)}
        content={
          <div>
            {pagingLogoUrl ? (
              <img
                src={pagingLogoUrl}
                alt={t("field.pagingImage")}
                className="img img-fluid"
                width="100%"
                style={{ objectFit: "cover", width: "100%" }}
              />
            ) : (
              <p>Dokumen tidak ditemukan</p>
            )}
          </div>
        }
      />
    </>
  );
};

export default withTrans(ModalEditPaging);
