import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Divider } from "@mui/material";
import { Row, Col } from "reactstrap";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import moment from "moment";

import Loader from "../../../components/commons/Loader";
import Card from "../../../components/cards/card";
import CardFlush from "../../../components/cards/cardFlush";
import CardFlushButtonSide from "../../../components/cards/cardFlushButtonSide";
import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import Table from "../../../components/table";
import ConfirmationModal from "../../../components/modal/ConfirmModal";
import AssignAssistantModal from "./AssignAssistantModal";
import EvidenceAssistantModal from "./EvidenceAssistantModal";
import PaymentProofModal from "./PaymentProofModal";
import EditPassengerModal from "./ModalEditPassenger"; 
import AssistantHistoryModal from "./AssistantHistory";
import AdditionalServiceModal from "./AdditionalServiceModal";
import EditNotesModal from "../../../components/modal";
import { toastError } from "../../../components/commons/toast";
import { withTrans } from "../../../i18n/withTrans";
import { history } from "../../../utils/History";
import { getUser } from "../../../utils/User";

import OperationalService from "../../../store/actions/schedule/operational";
import UserAssistant from "../../../store/actions/schedule/operational";
import BookingService from "../../../store/actions/booking";
import Corp_Reservation from "../../../store/actions/corp_reservation";
import AirportService from "../../../store/actions/master_data/airport";

const DetailOperational = ({ data, error, error_delete, t, pending, match }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();
  const numberWithCommas = (x) => {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const [action, setAction] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [evidenceAssistant, setEvidenceAssistant] = useState(false);
  const [paymentProof, setPaymentProof] = useState(false);
  const [dataDetail, setDataDetail] = useState("");
  const [dataLounge, setDataLounge] = useState([]);
  const [dataTransports, setDataTransports] = useState([]);
  const [dataOther, setDataOther] = useState([]);
  const [listActionLounge, setListActionLounge] =  useState([]);
  const [listActionTransports, setListActionTransports] =  useState([]);
  const [listActionOther, setListActionOther] =  useState([]);
  const [editPassengerModal, setEditPassengerModal] = useState(false);
  const [dataPassengerRow, setDataPassengerRow] = useState(null);
  const [assistant, setAssistant] = useState([]);
  const [addAdditionalServiceModal, setAddAdditionalServiceModal] = useState(false);
  const [productLounge, setProductLounge] = useState([]);
  const [airportLounge, setAirportLounge] = useState([]);
  const [productTransports, setProductTransports] = useState([]);
  const [serviceName, setServiceName] = useState("");
  const [airportCrossSelling, setAirportCrossSelling] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteServiceName, setDeleteServiceName] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [showEditNotes, setShowEditNotes] = useState(false);
  const [passengerNotes, setPassengerNotes] = useState("");
  const [showConfirmEditNotes, setShowConfirmEditNotes] = useState(false);

  const handleGetDetailAirportCrossSelling = (idAirport) => {
    new Promise((resolve) => {
      let param = {
        id: idAirport,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      if (res) {
        setAirportCrossSelling(
          res?.data?.map((airport) => `${airport?.code}-${airport?.city}-${airport?.name} ${airport?.uniform === 'Indonesia' ? "" : "-" + airport?.uniform}`)[0]
        );
      }
    });
  };
  
  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        draw: 1,
        length: 1,
        page: 1,
        id: match?.params?.id,
      };
      dispatch(OperationalService.get(param, resolve));
    }).then((res) => {
      setDataDetail(res?.data[0]);
      setDataLounge(
        res?.data[0]?.list_order_extras?.filter(
          (item) => item?.type === "Lounge"
        )
      );
      setDataTransports(
        res?.data[0]?.list_order_extras?.filter(
          (item) => item?.type === "Transport"
        )
      );
      setDataOther(
        res?.data[0]?.list_order_extras?.filter(
          (item) => item?.type === "Other"
        )
      );
      
      if(res?.data[0]?.is_cross_selling && res?.data[0]?.airport_id_cross_selling) {
        handleGetDetailAirportCrossSelling(res?.data[0]?.airport_id_cross_selling)
      }
    });
  };

  useEffect(() => {
    handleRefresh()
  }, [action]);
  
  useEffect(() => {
    if (error) {
      setLoadingSubmit(false);
    }
  }, [error]);

  useEffect(() => {
    if (error_delete) {
      setLoadingSubmit(false);
    }
  }, [error_delete]);

  useEffect(() => {
    if (dataDetail) {
      if (dataLounge?.length) {
        setListActionLounge([
          {
            name: t("commons.change") + " " + t("field.lounge"),
            action: () => addAdditionalService("lounge"),
          },
          {
            name: t("commons.delete") + " " + t("field.lounge"),
            action: () => showConfirmModal("lounge"),
          },
        ]);
      } else {
        setListActionLounge([
          {
            name: t("commons.change") + " " + t("field.lounge"),
            action: () => addAdditionalService("lounge"),
          },
        ]);
      }

      if (dataTransports?.length) {
        setListActionTransports([
          {
            name: t("commons.change") + " " + t("field.transportation"),
            action: () => addAdditionalService("transport"),
          },
          {
            name: t("commons.delete") + " " + t("field.transportation"),
            action: () => showConfirmModal("transport"),
          },
        ]);
      } else {
        setListActionTransports([
          {
            name: t("commons.change") + " " + t("field.transportation"),
            action: () => addAdditionalService("transport"),
          },
        ]);
      }

      if (dataOther?.length) {
        setListActionOther([
          {
            name: t("commons.change") + " " + t("masterData.others"),
            action: () => addAdditionalService("other"),
          },
          {
            name: t("commons.delete") + " " + t("masterData.others"),
            action: () => showConfirmModal("other"),
          },
        ]);
      } else {
        setListActionOther([
          {
            name: t("commons.change") + " " + t("masterData.others"),
            action: () => addAdditionalService("other"),
          },
        ]);
      }
    }
  }, [dataDetail, dataLounge, dataTransports, dataOther]);

  const showAssignModal = () => {
    setAssignModal(!assignModal);
  };

  const showHistoryModal = () => {
    setHistoryModal(!historyModal);
  };

  const showUploadModal = () => {
    setUploadModal(!uploadModal);
  };

  const showEvidenceAssistant = () => {
    setEvidenceAssistant(!evidenceAssistant);
  };

  const showPaymentProof = () => {
    setPaymentProof(!paymentProof);
  };

  const handleRefreshHistory = () => {
    new Promise((resolve) => {
      let param = {
        length: 9999,
        order_id: match?.params?.id,
      };
      dispatch(UserAssistant.getHistory(param, resolve));
    }).then((res) => {
      setAssistant(res?.data);
    });
  };

  const handleGetAirportLounge = () => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 99999,
        id:
          dataDetail?.type === 1
            ? dataDetail?.airport_id_to
            : dataDetail?.type === 2
            ? dataDetail?.airport_id_from
            : dataDetail?.airport_id_transfer,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      let airport_lounge_list;
      if (dataDetail?.type === 1) {
        if (dataDetail?.airport_country_from !== "Indonesia") {
          airport_lounge_list = res?.data[0]?.lounges?.filter((item) => item?.route_type === "international");
        } else {
          airport_lounge_list = res?.data[0]?.lounges?.filter((item) => item?.route_type === "domestic");
        }
      } else if (dataDetail?.type === 2) {
        if (dataDetail?.airport_country_to !== "Indonesia") {
          airport_lounge_list = res?.data[0]?.lounges?.filter((item) => item?.route_type === "international");
        } else {
          airport_lounge_list = res?.data[0]?.lounges?.filter((item) => item?.route_type === "domestic");
        }
      } else if (dataDetail?.type === 3) {
        if (
          dataDetail?.airport_country_from !== "Indonesia" ||
          dataDetail?.airport_country_to !== "Indonesia"
        ) {
          airport_lounge_list = res?.data[0]?.lounges?.filter((item) => item?.route_type === "international");
        } else {
          airport_lounge_list = res?.data[0]?.lounges?.filter((item) => item?.route_type === "domestic");
        }
      }
      setAirportLounge(airport_lounge_list.map((item) => ({
        label: (
          <>
            <div className="normal-title">
              {`${item.lounge_name ?? item.name} (${item?.route_type?.charAt(0).toUpperCase() + item?.route_type?.slice(1)})`}
            </div>
            <div className="d-flex justify-content-between">
              <div className="col-md-4">
                <div className="d-flex flex-wrap justify-content-between normal-title">
                  {t("commons.adult")}
                  <div className="text-primary">
                    {item?.price_adult == null
                      ? " Rp 0"
                      : " Rp " + numberWithCommas(item.price_adult)}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex flex-wrap justify-content-between normal-title">
                  {t("commons.child")}
                  <div className="text-primary">
                    {item?.price_child == null
                      ? " Rp 0"
                      : " Rp " + numberWithCommas(item.price_child)}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex flex-wrap justify-content-between normal-title">
                  {t("commons.infantLabel")}
                  <div className="text-primary">
                    {item?.price_infant == null
                      ? " Rp 0"
                      : " Rp " + numberWithCommas(item.price_infant)}
                  </div>
                </div>
              </div>
            </div>
          </>
        ),
        value: item.id,
        name: `${item?.name} (${item?.route_type})`,
        adult_price: item.price_adult,
        child_price: item.price_child,
        infant_price: item.price_infant,
      })));
    });
  };

  const showConfirmModal = (service) => {
    setDeleteServiceName(service);
    setConfirmModal(!confirmModal);
  };

  const deleteAdditionalService = () => {
    setLoadingSubmit(true);
    const id = deleteServiceName === 'lounge'
      ? dataLounge[0]?.id
      : deleteServiceName === 'transport'
      ? dataTransports[0]?.id
      : dataOther[0]?.id;

    const callback = () => {
      setConfirmModal(!confirmModal);
      handleRefresh();
    };

    if(id) {
      new Promise((resolve) => {
        dispatch(Corp_Reservation.deleteExtraOrder({ id: id }, resolve, callback));
      }).then((res) => {
        setLoadingSubmit(false);
      });
    }
  };

  const addAdditionalService = (service) => {
    let flight_type, airport_service_id, product_name;
    if (dataDetail?.type === 1) {
      airport_service_id = dataDetail?.airport_id_to;
      flight_type = 5;
    } else if (dataDetail?.type === 2) {
      airport_service_id = dataDetail?.airport_id_from;
      flight_type = 6;
    } else if (dataDetail?.type === 3) {
      airport_service_id = dataDetail?.airport_id_transfer;
      if (
        dataDetail?.airport_country_from !== "Indonesia" &&
        dataDetail?.airport_country_from !== "Indonesia"
      ) {
        flight_type = 1;
      } else if (
        dataDetail?.airport_country_from !== "Indonesia" &&
        dataDetail?.airport_country_from === "Indonesia"
      ) {
        flight_type = 2;
      } else if (
        dataDetail?.airport_country_from === "Indonesia" &&
        dataDetail?.airport_country_from !== "Indonesia"
      ) {
        flight_type = 3;
      } else if (
        dataDetail?.airport_country_from === "Indonesia" &&
        dataDetail?.airport_country_from === "Indonesia"
      ) {
        flight_type = 4;
      }
    }

    if (dataDetail?.product_name_eng === "Meet and Greet International") {
      product_name = 1;
    } else if (dataDetail?.product_name_eng === "Meet and Greet Domestic") {
      product_name = 2;
    } else if (dataDetail?.product_name_eng === "Fast Track") {
      product_name = 3;
    } else if (dataDetail?.product_name_eng === "Transfer") {
      product_name = 4;
    }

    const param = {
      airport_service_id,
      flight_type,
      product_name,
      corporate_id: dataDetail?.corporate_id,
      service_date: dataDetail?.date,
      is_mob: dataDetail?.corporate_group_type === "MOB" ? true : false,
      adult_total: dataDetail?.list_order_passengers?.filter(
        (item) =>
          item?.title === "Mr." ||
          item?.title === "Mrs." ||
          item?.title === "Ms."
      )?.length,
      child_total: dataDetail?.list_order_passengers?.filter(
        (item) => item?.title === "Child"
      )?.length,
      infant_total: dataDetail?.list_order_passengers?.filter(
        (item) => item?.title === "Infant"
      )?.length,
    };

    new Promise((resolve) => {
      dispatch(Corp_Reservation.findAvailPrice(param, resolve));
    }).then((res) => {
      if (res?.data) {
        const corporate_type = res?.data?.type_list?.find(
          (item) => item?.corporate_type === dataDetail?.corporate_group_type
        );
        const lounge =
          corporate_type?.period_list[0]?.product_list?.find(
            (item) => item?.product_name === 5
          );
        if (lounge) {
          let lounge_list;
          if (lounge?.corporate_lounge_list?.length) {
            lounge_list = lounge?.corporate_lounge_list;
          } else if (lounge?.lounge_list?.length) {
            lounge_list = lounge?.lounge_list?.filter((item) => item?.airport_id === airport_service_id);
          } 

          if (dataDetail?.type === 1) {
            if (dataDetail?.airport_country_from !== "Indonesia") {
              lounge_list = lounge_list?.filter((item) => item?.route_type === "international");
            } else {
              lounge_list = lounge_list?.filter((item) => item?.route_type === "domestic");
            }
          } else if (dataDetail?.type === 2) {
            if (dataDetail?.airport_country_to !== "Indonesia") {
              lounge_list = lounge_list?.filter((item) => item?.route_type === "international");
            } else {
              lounge_list = lounge_list?.filter((item) => item?.route_type === "domestic");
            }
          } else if (dataDetail?.type === 3) {
            if (
              dataDetail?.airport_country_from !== "Indonesia" ||
              dataDetail?.airport_country_to !== "Indonesia"
            ) {
              lounge_list = lounge_list?.filter((item) => item?.route_type === "international");
            } else {
              lounge_list = lounge_list?.filter((item) => item?.route_type === "domestic");
            }
          }

          setProductLounge(lounge_list.map((item) => ({
            label: (
              <>
                <div className="normal-title">
                  {`${item.lounge_name ?? item.name} (${item?.route_type?.charAt(0).toUpperCase() + item?.route_type?.slice(1)})`}
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-md-4">
                    <div className="d-flex flex-wrap justify-content-between normal-title">
                      {t("commons.adult")}
                      <div className="text-primary">
                        {item?.adult_price == null
                          ? " Rp 0"
                          : " Rp " + numberWithCommas(item.adult_price)}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex flex-wrap justify-content-between normal-title">
                      {t("commons.child")}
                      <div className="text-primary">
                        {item?.child_price == null
                          ? " Rp 0"
                          : " Rp " + numberWithCommas(item.child_price)}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex flex-wrap justify-content-between normal-title">
                      {t("commons.infantLabel")}
                      <div className="text-primary">
                        {item?.infant_price == null
                          ? " Rp 0"
                          : " Rp " + numberWithCommas(item.infant_price)}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ),
            value: item.id,
            name: `${item?.name} (${item?.route_type})`,
            adult_price: item.adult_price,
            child_price: item.child_price,
            infant_price: item.infant_price,
          })));
        } else {
          handleGetAirportLounge();
        }

        const transport =
          corporate_type?.period_list[0]?.product_list?.find(
            (item) => item?.product_name === 6
          );
        if (transport) {
          setProductTransports(
            transport?.transport_list.map((item) => ({
              label: (
                <>
                  <div className="d-flex justify-content-between">
                    <div className="normal-title">{item?.car_name}</div>
                    <div className="normal-title text-extra-bold">
                      {`${item?.capacity} ${t("field.person")}`}
                    </div>
                    <div className="normal-title text-extra-bold">
                      {item?.price == null
                        ? "Rp 0"
                        : "Rp " + numberWithCommas(item?.price)}
                    </div>
                  </div>
                </>
              ),
              value: item?.id,
              name: item?.car_name,
              price: item?.price,
            }))
          );
        }
      }
    });
    setServiceName(service);
    setAddAdditionalServiceModal(!addAdditionalServiceModal);
  };

  const handleEdit = (param) => {
    const callback = () => {
      setAssignModal(!assignModal);
      handleRefresh();
      handleRefreshHistory();
    };
    if (param) {
      dispatch(OperationalService.change_assistant(param, callback));
    }
  };

  const handleEditNotes = () => {
    setLoadingSubmit(true);
    const callback = () => {
      handleRefresh();
      setShowConfirmEditNotes(!showConfirmEditNotes)
      setShowEditNotes(!showEditNotes);
      setPassengerNotes("");
    };

    const param ={
      order_id: dataDetail?.id, 
      notes: passengerNotes
    }

    if (passengerNotes === "") {
      toastError(`${t('commons.empty_data_message')} ${t("field.passengerNotes")}`);
      setLoadingSubmit(false);
    } else {
      new Promise((resolve) => {
        dispatch(BookingService.editPassengerNotes(param, resolve, callback));
      }).then((res) => {
        setLoadingSubmit(false);
      });
    }
  };

  const showEditPassengerModal = (data) => {
    setEditPassengerModal(!editPassengerModal);
    if (data !== null) {
      setDataPassengerRow(data);
    } else {
      setDataPassengerRow(null);
    }
  };

  const handleEditPassenger = (param) => {
    const callback = () => {
      setEditPassengerModal(!editPassengerModal);
      handleRefresh();
    };

    if (param.title === "Infant") {
      if(
        param.passenger_date_of_birth === "" ||
        param.passenger_date_of_birth === undefined
      ) {
        toastError(`${t("commons.empty_data_message")} ${t("field.birth_date")}!`);
        return;
      } else {
        const yearDiff = moment().diff(param.passenger_date_of_birth, "year");
        if(yearDiff > 2) {
          toastError(`${t("commons.birth_date_less_than_message")}!`);
          return;
        }
      }
    }
    if(param.passenger_nationality === "") {
      toastError(`${t("commons.empty_data_message")} ${t("field.nationality")}!`);
      return;
    }
    if (param.name === "") {
      toastError(
        `${t("commons.empty_data_message")} ${t("field.name")}!`
      );
      return;
    }
    if (
      param?.email &&
      !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        param?.email
      ))
    ) {
      toastError(`${t("commons.wrong_email_format")}!`);
      return;
    }
    if (
      param?.title !== "Infant" &&
      param?.title !== "Child"
    ) {
      if (param.email === "") {
        toastError(`${t("commons.empty_data_message")} Email!`);
        return;
      } else if (
        param.phoneNumber === "" ||
        param.phoneNumber.includes("undefined") ||
        param.phoneNumber === param.countryCode
      ) {
        toastError(
          `${t("commons.empty_data_message")} ${t("field.phone")}!`
        );
        return;
      }
    }
    
    if (
      param.phoneNumber !== "" &&
      param.phoneNumber !== undefined &&
      param.phoneNumber !== null &&
      !param.phoneNumber.includes("undefined") &&
      param.phoneNumber !== param.countryCode
    ) {
      const phone = param.phoneNumber
        .replace("+", "")
        .replace(param?.countryCode, "");

      param.phoneNumber = 
        phone.charAt(0) == 0
          ? phone?.substring(1)
          : phone;
      param.countryCode = `+${param.countryCode}`;
    } else {
      param.phoneNumber = ""
      param.countryCode = ""
    }

    if (param?.id) {
      dispatch(BookingService.editActualPax(param, callback));
    } else {
      dispatch(BookingService.addActualPax({order_id : match?.params?.id, ...param}, callback));
    }
  };

  const handleDeletePassenger = (param) => {
    const callback = () => {
      setEditPassengerModal(!editPassengerModal);
      handleRefresh();
    };

    if (param) {
      dispatch(BookingService.delActualPax({ id: param?.id }, callback));
    }
  };

  const columnPassengers = [
    {
      Header: "No",
      Cell: ({ row }) => {
        return row?.index + 1;
      },
      width: 10,
    },
    {
      Header: t("field.passenger"),
      Cell: ({ row }) => {
        const { original } = row;
        return original?.name ?? "-";
      },
    },
    {
      Header: "",
      accessor: "actions",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <div
            className="d-flex justify-content-end"
            onClick={() => showEditPassengerModal(original)}
          >
            <span className="material-icons-round right-icon">
              navigate_next
            </span>
          </div>
        );
      },
      show: 
        (currentUser.user.role_code === "supervisor" || 
        currentUser.user.role_code === "admin_bandara") &&
        currentUser.user.airport_id !== dataDetail?.airport_id_cross_selling,
    },
  ];

  return (
    <div className="menu-container">
      {pending && <Loader loading={pending} />}
      <div className="grid-header">
        <div className="side-menu">
          <div className="menu-title text-extra-bold">
            <div className="side-menu-back-icon ">
              <span
                className="material-icons clickable"
                onClick={() =>
                  history.push({
                    pathname: "/schedule",
                    state: { tab: "operational" },
                  })
                }
              >
                arrow_back
              </span>
            </div>
            {`Detail ${t("field.schedule")}`}
          </div>
          <div style={{ padding: "0px 15px 30px 10px" }}>
            <Card className="rounded">
              <CardFlush
                sideMenu
                title={t("field.product")}
                desc={
                  <>
                    <div>{dataDetail?.product_name_eng}</div>
                    {dataDetail?.status_go_show !== null && (
                      <div className="normal-text text-primary mt-2">
                        {dataDetail?.status_go_show === 1
                          ? "Go Show"
                          : "Go Show On The Spot"}
                      </div>
                    )}
                  </>
                }
                leftIcon="check_circle"
              />
              <CardFlush
                sideMenu
                title={t("field.bookingId")}
                desc={dataDetail?.unique_transaction_id}
                leftIcon="confirmation_number"
              />
              {dataDetail?.booking_no_from_partner && (
                <CardFlush
                  sideMenu
                  title={`${t("field.bookingId")} ${t("field.partner")}`}
                  desc={dataDetail?.booking_no_from_partner}
                  leftIcon="confirmation_number"
                />
              )}
              <CardFlush
                sideMenu
                title="SDF"
                desc={dataDetail?.sdf ? dataDetail?.sdf : "-"}
                leftIcon="book_online"
              />
              <CardFlush
                sideMenu
                title={t("field.flightType")}
                desc={
                  localStorage.getItem("joumpa_language") === "en" ||
                  localStorage.getItem("joumpa_language") === "en-US"
                    ? dataDetail?.type_label_eng
                    : dataDetail?.type_label
                }
                leftIcon="flight"
              />
              <CardFlush
                sideMenu
                title={t('commons.addedBy')}
                desc={dataDetail?.adder_name 
                  ? dataDetail?.adder_name
                  : '-'}
                leftIcon="group_add"
              />
              <CardFlushButtonSide
                sideMenu
                title={t("field.assistant")}
                desc={
                  dataDetail?.list_assistants 
                    ? dataDetail?.list_assistants
                      ?.map((item) => {
                        return item?.is_main
                          ? `${item?.assistant_name} (main)`
                          : item?.assistant_name;
                      })
                      .join(", ")
                    : dataDetail?.assistant_name === null 
                      ? "-" 
                      : dataDetail?.assistant_name
                }
                leftIcon="assignment_ind"
                listMenu={
                  (currentUser.user.role_code !== "contact_center" &&
                  currentUser.user.role_code !== "lead_contact_center") &&
                  currentUser.user.airport_id !== dataDetail?.airport_id_cross_selling
                  ? dataDetail?.status === 1
                    ? [
                      {
                        name: t("schedule.change_assistant"),
                        action: () => showAssignModal(),
                      },
                      {
                        name: t("schedule.replacement_history"),
                        action: () => showHistoryModal(),
                      },
                    ]
                    : [
                      {
                        name: t("schedule.replacement_history"),
                        action: () => showHistoryModal(),
                      },
                    ]
                  : null
                }
                last
              />
            </Card>
            <Card className="rounded">
              <CardFlush
                sideMenu
                last
                title={"Status"}
                desc={
                  dataDetail?.status === 0
                    ? t("status.new")
                    : dataDetail?.status === 1
                      ? t("status.assigned")
                      : dataDetail?.status === 2
                        ? t("status.onProgress")
                        : dataDetail?.status === 5
                          ? t("status.done")
                          : t("status.cancelled")
                }
                leftIcon={dataDetail?.status === 5 ? "done" : "info"}
                iconColor={
                  dataDetail?.status === 1
                    ? "#f0ad4e"
                    : dataDetail?.status === 2
                      ? "#f0ad4e"
                      : dataDetail?.status === 5
                        ? "#5cb85c"
                        : "#D10D0D"
                }
              />
            </Card>
          </div>
        </div>
        <div>
          <HeaderMenu title={t("commons.details")} />
          <div style={{ padding: "0 40px" }}>
            {dataDetail?.status === 2 || dataDetail?.status === 5 ? (
              <div>
                <Stepper Linear activeStep={-1} alternativeLabel
                style={{
                  width:
                    dataDetail?.list_order_milestones?.length > 7
                      ? 150 * dataDetail?.list_order_milestones?.length
                      : "100%",
                }}
                  // style={{ width: 150 * dataDetail?.list_order_milestones?.length }}
                >
                  {dataDetail?.list_order_milestones?.map((label, index) => (
                    <Step key={label.step} completed={label.status}>
                      <StepButton color="inherit">
                        {localStorage.getItem("joumpa_language") === "en" ||
                        localStorage.getItem("joumpa_language") === "en-US"
                          ? label.name_eng
                          : label.name}
                        <br />
                        {label.status === 1 && label.updated_at !== null
                          ? moment(label.updated_at).format("HH:mm")
                          : ""}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              </div>
            ) : (
              <></>
            )}
            <div className="wrapper-detail-card">
              {
                (data = [
                  {
                    label: t("field.customer"),
                    desc:
                      dataDetail?.customer_name === null
                        ? dataDetail?.list_order_passengers[0]?.name
                        : dataDetail?.customer_name,
                  },
                  {
                    label: t("field.passengerType"),
                    desc: dataDetail?.passenger_type,
                  },
                  {
                    label: t("field.contact"),
                    desc:
                      dataDetail?.customer_handphone === null &&
                      dataDetail?.customer_email === null
                        ? `${dataDetail?.list_order_passengers[0]?.email} / ${
                          dataDetail?.list_order_passengers[0]?.phone_number &&
                          dataDetail?.list_order_passengers[0]?.country_code
                            ? `${dataDetail?.list_order_passengers[0]?.country_code}${dataDetail?.list_order_passengers[0]?.phone_number}`
                            : dataDetail?.list_order_passengers[0]?.phone_number
                          }`
                        : dataDetail?.customer_handphone !== null &&
                          dataDetail?.customer_email !== null
                        ? `${dataDetail?.customer_email} / ${
                          dataDetail?.customer_handphone &&
                          dataDetail?.customer_country_code
                            ? `${dataDetail?.customer_country_code}${dataDetail?.customer_handphone}`
                            : dataDetail?.customer_handphone
                          }`
                        : dataDetail?.customer_email === null
                        ? `${
                          dataDetail?.customer_handphone &&
                          dataDetail?.customer_country_code
                            ? `${dataDetail?.customer_country_code}${dataDetail?.customer_handphone}`
                            : dataDetail?.customer_handphone
                          }`
                        : dataDetail?.customer_email,
                  },
                  {
                    label: t('field.corporate'),
                    desc: dataDetail?.corporate_name,
                  },
                  {
                    label: t('field.cooperationType'),
                    desc: dataDetail?.corporate_group_type === 'Group' 
                      ? (t("field.group") + " " + (
                        dataDetail?.corporate_group_type_name ? 
                          dataDetail?.corporate_group_type_name 
                          : ""
                        ))
                      : dataDetail?.corporate_group_type === 'FIT' 
                        || dataDetail?.corporate_group_type === 'MOB'
                      ? dataDetail?.corporate_group_type
                      : "-",
                  },  
                  {
                    label: t("field.bookingTime"),
                    desc: `${moment(dataDetail?.booking_date).format(
                      "DD/MM/YYYY"
                    )}, ${moment(dataDetail?.booking_date).format("HH:mm")}`,
                  },
                ].map((item, i) => {
                  let isCorporate = dataDetail?.corporate_name
                  if(item.label !== t('field.corporate') && item.label !== t('field.cooperationType')) {
                    return (
                      <CardFlush
                        list
                        label={item.label}
                        desc={item.desc}
                        size={{ label: 3, desc: 8 }}
                      />
                    )
                  }
                  else{
                    if(isCorporate){
                      return (
                        <CardFlush
                          list
                          label={item.label}
                          desc={item.desc}
                          size={{ label: 3, desc: 8 }}
                        />
                      )
                    }
                  }
              }))}
              <CardFlush
                list
                label={t("field.airline")}
                desc={`${dataDetail?.airplane_name} / ${dataDetail?.airplane_code}${dataDetail?.airplane_number}`}
                size={{ label: 3, desc: 8 }}
              />
              {dataDetail?.is_cross_selling && (
                <CardFlush
                  list
                  label={t("field.cross_selling")}
                  desc={airportCrossSelling}
                  size={{ label: 3, desc: 8 }}
                />
              )}
              {dataDetail?.type_label === "Transfer" ? (
                (data = [
                  {
                    label: t("field.origin"),
                    desc: `${dataDetail?.airport_code_from} - ${dataDetail?.airport_city_from} - ${dataDetail?.airport_name_from}  ${dataDetail?.airport_country_from !== "Indonesia" && dataDetail?.airport_country_from !== undefined  ? '-' + dataDetail?.airport_country_from : '' }`,
                  },
                  {
                    label: `${t("field.transfer")}`,
                    desc: `${dataDetail?.airport_code_transfer} - ${dataDetail?.airport_city_transfer} - ${dataDetail?.airport_name_transfer}  ${dataDetail?.airport_country_transfer && dataDetail?.airport_country_transfer !== "Indonesia" ? '-' + dataDetail?.airport_country_transfer : '' }`,
                  },
                  {
                    label: t("field.destination"),
                    desc: `${dataDetail?.airport_code_to} - ${dataDetail?.airport_city_to} - ${dataDetail?.airport_name_to} ${dataDetail?.airport_country_to && dataDetail?.airport_country_to !== "Indonesia" ? '-' + dataDetail?.airport_country_to : '' }`,
                  },
                  {
                    label: t("field.arrivalTime"),
                    desc:
                      moment(dataDetail?.date_transfer_to).format(
                        "DD/MM/YYYY"
                      ) +
                      " " +
                      dataDetail?.time,
                  },
                  {
                    label: "Estimate Time Departure",
                    desc: `${moment(dataDetail?.date).format("DD/MM/YYYY")}, ${dataDetail?.time
                      }`,
                  },
                ].map((item, i) => (
                  <CardFlush
                    list
                    label={item.label}
                    desc={item.desc}
                    size={{ label: 3, desc: 8 }}
                  />
                )))
              ) : (
                <>
                  <CardFlush
                    list
                    label={t("field.origin")}
                    desc={`${dataDetail?.airport_code_from} - ${dataDetail?.airport_city_from} - ${dataDetail?.airport_name_from}  ${dataDetail?.airport_country_from && dataDetail?.airport_country_from !== "Indonesia" ? '-' + dataDetail?.airport_country_from : '' }`}
                    size={{ label: 3, desc: 8 }}
                  />
                  <CardFlush
                    list
                    label={t("field.destination")}
                    desc={`${dataDetail?.airport_code_to} - ${dataDetail?.airport_city_to} - ${dataDetail?.airport_name_to} ${dataDetail?.airport_country_to && dataDetail?.airport_country_to !== "Indonesia" ? '-' + dataDetail?.airport_country_to : '' }`}
                    size={{ label: 3, desc: 8 }}
                  />
                  <CardFlush
                    list
                    label={
                      dataDetail?.type_label_eng === "Departure"
                        ? t("field.departureTime")
                        : t("field.arrivalTime")
                    }
                    desc={`${moment(dataDetail?.date).format("DD/MM/YYYY")}, ${dataDetail?.time
                      }`}
                    size={{ label: 3, desc: 8 }}
                  />
                </>
              )}
              <CardFlush
                list
                label={t("field.note")}
                desc={(
                  <div className="d-flex justify-content-between">
                    {dataDetail?.passenger_notes ?? "-"}
                    {(currentUser.user.role_code === "admin_bandara" ||
                      currentUser.user.role_code === "supervisor" ||
                      currentUser.user.role_code === "assistant") &&
                      currentUser.user.airport_id !==
                        dataDetail?.airport_id_cross_selling && (
                      <div
                        onClick={() => {
                          setShowEditNotes(!showEditNotes);
                          setPassengerNotes(dataDetail?.passenger_notes ?? "")
                        }}
                        className="ml-auto text-right left-icon float-left rounded-fill"
                        style={{ padding: "4px 6px 1px 6px" }}
                      >
                        <span
                          className="material-icons clickable"
                          style={{ fontSize: "15px", color: "#63ae5c" }}
                        >
                          edit
                        </span>
                      </div>
                    )}
                  </div>
                )}
                size={{ label: 3, desc: 9 }}
                last
              />
            </div>
            <div className="wrapper-detail-card">
              <div
                className="text-extra-bold"
                style={{ padding: 15, opacity: 0.5 }}
              >
                {t("field.additionalService")}
              </div>
              <Divider orientation="horizontal" />
                <CardFlush
                  addtService
                  isLounge={dataLounge?.length > 0}
                  isTransport={dataTransports?.length > 0}
                  isOther={dataOther?.length > 0}
                  nameLounge={
                    dataLounge?.length
                      ? dataLounge[0]?.name
                      : t("commons.none")
                  }
                  nameTransport={
                    dataTransports?.length
                      ? dataTransports[0]?.region !== null 
                          ? dataTransports[0]?.region 
                            + " - "
                            + dataTransports[0]?.name 
                            + " ( " 
                            + (
                                dataTransports[0]?.total_unit ?
                                dataTransports[0]?.total_unit :
                                "1" 
                              ) 
                            + " unit)"
                          : dataTransports[0]?.name 
                            + " ( " 
                            + (
                                dataTransports[0]?.total_unit ?
                                dataTransports[0]?.total_unit :
                                "1" 
                              ) 
                            + " unit)"
                      : t("commons.none")
                  }
                  descOther={
                    dataOther?.length
                      ? dataOther[0]?.name
                      : t("commons.none")
                  }
                  actionsLounge={
                    dataDetail?.passenger_type_label_eng === "Corporate" &&
                    (currentUser.user.role_code === "supervisor" ||
                    currentUser.user.role_code === "admin_bandara") &&
                    currentUser.user.airport_id !== dataDetail?.airport_id_cross_selling
                      ? listActionLounge
                      : null
                  }
                  actionsTransport={
                    dataDetail?.passenger_type_label_eng === "Corporate" &&
                    (currentUser.user.role_code === "supervisor" ||
                    currentUser.user.role_code === "admin_bandara") &&
                    currentUser.user.airport_id !== dataDetail?.airport_id_cross_selling
                      ? listActionTransports
                      : null
                  }
                  actionsOther={
                    dataDetail?.passenger_type_label_eng === "Corporate" &&
                    (currentUser.user.role_code === "supervisor" ||
                    currentUser.user.role_code === "admin_bandara") &&
                    currentUser.user.airport_id !== dataDetail?.airport_id_cross_selling
                      ? listActionOther
                      : null
                  }
                />
            </div>
            <div className="wrapper-detail-card">
              <div
                className="text-extra-bold"
                style={{ padding: 15, opacity: 0.5 }}
              >
                <Row>
                  <Col md={3}>{t("field.passenger")}</Col>
                  <Col md={9}>
                    <div className="text-medium d-flex justify-content-end">
                      <div className="text-medium">
                        {dataDetail?.list_order_passengers?.length > 0
                          ? dataDetail?.list_order_passengers[0]?.email
                          : dataDetail?.customer_email}
                      </div>
                      <div className="vertical-divider" />
                      <div className="text-medium">
                        {dataDetail?.list_order_passengers?.length > 0
                          ? `${
                            dataDetail?.list_order_passengers[0]?.phone_number &&
                            dataDetail?.list_order_passengers[0]?.country_code
                              ? `${dataDetail?.list_order_passengers[0]?.country_code}${dataDetail?.list_order_passengers[0]?.phone_number}`
                              : dataDetail?.list_order_passengers[0]?.phone_number
                            }`
                          : dataDetail?.customer_handphone}
                      </div>
                      <div
                        className="ml-3 p-1 border rounded material-icons"
                        style={{ marginTop: -5 }}
                      >
                        call
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <Table
                columns={columnPassengers.filter((item) => item.show !== false)}
                data={dataDetail?.list_order_passengers ?? []}
                minHeight="100%"
              />
              <Divider orientation="horizontal" />
              <div
                className="d-flex align-items-center mt-4"
                style={{ paddingLeft: 15, paddingRight: 15 }}>
                <p
                  className="normal-title text-extra-bold"
                  style={{ opacity: 0.5 }}
                >
                  Total PAX
                </p>
                <div className="ml-auto">
                  <p
                    className="normal-title text-bold"
                  >
                    {`${
                      dataDetail?.list_order_passengers?.filter(
                        (item) => item?.title === "Mrs." || item?.title === "Mr." || item?.title === "Ms."
                      )?.length
                    } ${t("commons.adult")}, ${
                      dataDetail?.list_order_passengers?.filter(
                        (item) => item?.title === "Child"
                      )?.length
                    } ${t("commons.child")}, ${
                      dataDetail?.list_order_passengers?.filter(
                        (item) => item?.title === "Infant"
                      )?.length
                    } ${t("commons.infantLabel")}`}
                  </p>
                </div>
              </div>
              <Divider orientation="horizontal" />
              {(currentUser.user.role_code === "supervisor" || 
                currentUser.user.role_code === "admin_bandara") &&
                currentUser.user.airport_id !== dataDetail?.airport_id_cross_selling && (
                  <div
                    className="d-flex justify-content-start p-3"
                    onClick={() => showEditPassengerModal(null)}
                  >
                    <div className="left-icon float-left rounded-fill" style={{ padding: "5px 8px" }}>
                      <span
                        className="material-icons clickable"
                        style={{ fontSize: 25, color: "#63ae5c" }}
                      >
                        person
                      </span>
                    </div>
                    <div
                      className="clickable normal-title text-extra-bold pt-3 pl-3"
                      style={{ color: "#63ae5c" }}
                    >
                      {t("field.addPassenger")}
                    </div>
                  </div>
                )}
            </div>
            <div className="wrapper-detail-card">
              <div
                className="ml-3 text-extra-bold"
                style={{ padding: 15, opacity: 0.5 }}
              >
                <Row>
                  <Col md={3}>{t("field.booker")}</Col>
                </Row>
              </div>
              <Divider orientation="horizontal" />
              <Row className="py-3"
                style={{ fontSize: 14, padding: "15px 25px" }}>
                <Col md={3} className="text-extra-bold"
                  style={{ color: "#000" }}>
                    {t("field.booker")}
                </Col>
                <Col md={3} className="text-extra-bold"
                  style={{ color: "#000" }}>
                    {t("field.nationality")}
                </Col>
                <Col md={3} className="text-extra-bold"
                  style={{ color: "#000" }}>
                    {t("field.email")}
                </Col>
                <Col md={3} className="text-extra-bold"
                  style={{ color: "#000" }}>
                    {t("field.phone")}
                </Col>
              </Row>
              <Divider orientation="horizontal" />
              <Row className="py-3"
                style={{ fontSize: 14, padding: "15px 25px" }}>
                <Col md={3}>
                  {dataDetail?.bookers_name ?? ""}
                </Col>
                <Col md={3}>
                  {dataDetail?.bookers_nationality ?? ""}
                </Col>
                <Col md={3}>
                  {dataDetail?.bookers_email ?? ""}
                </Col>
                <Col md={3}>
                  {dataDetail?.bookers_phone && dataDetail?.bookers_country_code
                    ? `${dataDetail?.bookers_country_code}${dataDetail?.bookers_phone}` 
                    : dataDetail?.bookers_phone && !dataDetail?.bookers_country_code
                    ? dataDetail?.bookers_phone
                    : ""}
                </Col>
              </Row>
            </div>
            {dataDetail?.payment_proof !== null && (
              <div className="wrapper-detail-card">
                <div
                  className="text-extra-bold"
                  style={{ 
                    paddingTop: 15, 
                    paddingBottom: 15, 
                    paddingLeft: 25, 
                    paddingRight: 25, 
                  }}
                >
                  <Row>
                    <Col md={6} 
                      style={{ opacity: 0.5 }}
                    >
                      {t("booking.paymentProof")}
                    </Col>
                    <Col md={6}>
                      <div className="d-flex justify-content-end">
                        <a className="link text-bold" 
                          style={{ 
                            fontSize: 14, 
                            opacity: 1,
                            cursor: "pointer"
                          }}
                          onClick={showPaymentProof}
                        >
                          {t("booking.openPaymentProof")}
                        </a>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Divider orientation="horizontal" />
                <CardFlush
                  list
                  label={t("booking.paymentType")}
                  desc={
                    dataDetail?.payment_type ? 
                    dataDetail?.payment_type : 
                    "-"
                  }
                  size={{ label: 6, desc: 6 }}
                  descEnd
                />
              </div>
            )}
            {(dataDetail?.status === 5 &&
              dataDetail?.evidences?.length) ? (
              <div className="wrapper-detail-card">
                <div
                  className="text-extra-bold"
                  style={{ 
                    paddingTop: 15, 
                    paddingBottom: 15, 
                    paddingLeft: 25, 
                    paddingRight: 25, 
                  }}
                >
                  <Row>
                    <Col md={3} 
                      style={{ opacity: 0.5 }}
                    >
                      {t("booking.evidenceAssistant")}
                    </Col>
                    <Col md={9}>
                      <div className="d-flex justify-content-end">
                        <a className="link text-bold" 
                          style={{ 
                            fontSize: 14, 
                            opacity: 1,
                            cursor: "pointer"
                          }}
                          onClick={showEvidenceAssistant}
                        >
                          {t("booking.openEvidenceAssistant")}
                        </a>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            ) : <></>}
          </div>
        </div>
      </div>
      <AdditionalServiceModal
        show={addAdditionalServiceModal}
        toggle={() => {
          setAddAdditionalServiceModal(!addAdditionalServiceModal);
          handleRefresh();
        }}
        listPassegers={dataDetail?.list_order_passengers}
        listLounge={productLounge?.length ? productLounge : airportLounge}
        isCorporateLounge={productLounge?.length ? true : false}
        listTransportProduct={productTransports}
        order_id={dataDetail?.id}
        isChangeLounge={serviceName === "lounge" ? true : false}
        isChangeTransport={serviceName === "transport" ? true : false}
        isChangeOther={serviceName === "other" ? true : false}
      />
      <AssignAssistantModal
        show={assignModal}
        toggle={showAssignModal}
        action={handleEdit}
        id={match?.params?.id}
        data={dataDetail}
      />
      <AssistantHistoryModal
        show={historyModal}
        toggle={showHistoryModal}
        action={handleEdit}
        id={match?.params?.id}
        data={assistant}
      />
      <EvidenceAssistantModal
        show={evidenceAssistant}
        title={t("booking.evidenceAssistant")}
        toggle={() => setEvidenceAssistant(!evidenceAssistant)}
        images={dataDetail?.evidences}
      />
      <PaymentProofModal
        show={paymentProof}
        toggle={() => setPaymentProof(!paymentProof)}
        image={
          process.env.REACT_APP_API_URL +
          dataDetail?.payment_proof?.substr(
            1,
            dataDetail?.payment_proof?.length
          )
        }
      />
      <EditPassengerModal
        reset={handleDeletePassenger}
        show={editPassengerModal}
        toggle={() => showEditPassengerModal(null)}
        action={handleEditPassenger}
        data={dataPassengerRow}
        listPassengersLength={dataDetail?.list_order_passengers?.length}
      />
      <ConfirmationModal
        show={confirmModal}
        toggle={()=> setConfirmModal(!confirmModal)}
        confirm={deleteAdditionalService}
        icon="info"
        message={`${t("confirm.deleteAdditionalService")} `}
        disabledConfirm={loadingSubmit}
      />
       <EditNotesModal
        show={showEditNotes}
        toggle={() => {
          setShowEditNotes(!showEditNotes);
          setPassengerNotes("")
        }}
        title={`Edit ${t("field.passengerNotes")}`}
        confirmTitle={t("commons.save")}
        confirm={() => setShowConfirmEditNotes(!showConfirmEditNotes)}
        content={
          <div className="form-group" style={{ width: "100%" }}>
            <label className="form-label">
              {t("field.passengerNotes")}
            </label>
            <textarea
              className="form-control"
              value={passengerNotes}
              placeholder={`${t("commons.input")} ${t("field.note")}`}
              style={{
                background: 'white 0% 0% no-repeat padding-box',
                borderRadius: 6,
                paddingLeft: 12,
              }}
              rows={3}
              onChange={(e) => setPassengerNotes(e?.target?.value)}
            />
          </div>
        }
      />
      <ConfirmationModal
        show={showConfirmEditNotes}
        toggle={() => setShowConfirmEditNotes(!showConfirmEditNotes)}
        confirm={handleEditNotes}
        icon="info"
        message={`${t("commons.saveConfirm")}`}
        isDelete
        disabledConfirm={loadingSubmit}
      />
    </div>
  );
};

const mapStateToProps = ({ booking: { error }, corp_reserv: { error_delete } }) => {
  return { error, error_delete };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(DetailOperational)
);
