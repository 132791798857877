import { history } from "../../../../utils/History";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";

import { toastSuccess, toastError } from "../../../../components/commons/toast";

import API from "../../API";
import { handleResponse } from "../../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_CORPORATE_PENDING = "GET_CORPORATE_PENDING";
export const GET_CORPORATE_SUCCESS = "GET_CORPORATE_SUCCESS";
export const GET_CORPORATE_ERROR = "GET_CORPORATE_ERROR";
export const GET_ALL_CORPORATE_PENDING = "GET_ALL_CORPORATE_PENDING";
export const GET_ALL_CORPORATE_SUCCESS = "GET_ALL_CORPORATE_SUCCESS";
export const GET_ALL_CORPORATE_ERROR = "GET_ALL_CORPORATE_ERROR";
export const GET_PRICE_CORPORATE_PENDING = "GET_PRICE_CORPORATE_PENDING";
export const GET_PRICE_CORPORATE_SUCCESS = "GET_PRICE_CORPORATE_SUCCESS";
export const GET_PRICE_CORPORATE_ERROR = "GET_PRICE_CORPORATE_ERROR";
export const POST_CORPORATE_PENDING = "POST_CORPORATE_PENDING";
export const POST_CORPORATE_SUCCESS = "POST_CORPORATE_SUCCESS";
export const POST_CORPORATE_ERROR = "POST_CORPORATE_ERROR";
export const PUT_CORPORATE_PENDING = "POST_CORPORATE_PENDING";
export const PUT_CORPORATE_SUCCESS = "POST_CORPORATE_SUCCESS";
export const PUT_CORPORATE_ERROR = "POST_CORPORATE_ERROR";
export const DELETE_CORPORATE_PENDING = "DELETE_CORPORATE_PENDING";
export const DELETE_CORPORATE_SUCCESS = "DELETE_CORPORATE_SUCCESS";
export const DELETE_CORPORATE_ERROR = "DELETE_CORPORATE_ERROR";

// URL: URL_{URL}
const CORPORATE_URL = `v2/master_data/corporate/`;
const CORPORATE_PRICE_URL = `v1/master_data/corporate/detail_price`;

const lang = localStorage.getItem('joumpa_language')

const get = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(GET_CORPORATE_PENDING));
    API.get(CORPORATE_URL, { params: param })
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_CORPORATE_SUCCESS, res));
            if (resolve) {
                const records_total = res.data.total;
                let data = res.data.data.map((item, i) => ({
                    ...item,
                    no: i + 1 + (param?.page - 1) * param?.length,
                }));
                resolve({
                    data: data,
                    page: param?.page - 1,
                    totalCount: records_total,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(GET_CORPORATE_ERROR));
            let not_logout = handleResponse(err?.response);
            if (not_logout) {
                if (lang === 'id') {
                  toastError(err?.response?.data?.message);
                } else if (lang === "en-US" || lang === "en") {
                  toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
                }
            }
        });
};

const getAll = (param) => (dispatch) => {
    dispatch(actionPending(GET_ALL_CORPORATE_PENDING));
    API.get(CORPORATE_URL, { params: param })
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_ALL_CORPORATE_SUCCESS, res));
        })
        .catch((err) => {
            dispatch(actionError(GET_ALL_CORPORATE_ERROR));
            let not_logout = handleResponse(err?.response);
            if (not_logout) {
                if (lang === 'id') {
                  toastError(err?.response?.data?.message);
                } else if (lang === "en-US" || lang === "en") {
                  toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
                }
            }
        });
};

const getPrice = (param) => (dispatch) => {
    dispatch(actionPending(GET_PRICE_CORPORATE_PENDING));
    API.get(CORPORATE_PRICE_URL, { params: param })
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_PRICE_CORPORATE_SUCCESS, res));
        })
        .catch((err) => {
            dispatch(actionError(GET_PRICE_CORPORATE_ERROR));
            let not_logout = handleResponse(err?.response);
            if (not_logout) {
                if (lang === 'id') {
                  toastError(err?.response?.data?.message);
                } else if (lang === "en-US" || lang === "en") {
                  toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
                }
            }
        });
};

const post = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(POST_CORPORATE_PENDING));
    API.post(CORPORATE_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(POST_CORPORATE_SUCCESS, res));
            toastSuccess(lang === 'id' ? "Berhasil Tambah Data Korporasi" : "Corporate Data Added Succesfully");
            if (resolve) {
                resolve({
                    data: res.data.data,
                    message: res.data.message,
                    status: res.data.status,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(POST_CORPORATE_ERROR));
            if (lang === 'id') {
              toastError(err?.response?.data?.message);
            } else if (lang === "en-US" || lang === "en") {
              toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
            }
        });
};

const put = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(PUT_CORPORATE_PENDING));
    API.put(CORPORATE_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(PUT_CORPORATE_SUCCESS, res));
            toastSuccess(lang === 'id' ? "Berhasil Ubah Data Korporasi" : "Corporate Data Changed Succesfully");
            if (resolve) {
                resolve({
                    data: res.data.data,
                    message: res.data.message,
                    status: res.data.status,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(PUT_CORPORATE_ERROR));
            if (lang === 'id') {
              toastError(err?.response?.data?.message);
            } else if (lang === "en-US" || lang === "en") {
              toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
            }
        });
};

const deleted = (param, callback, name) => (dispatch) => {
    dispatch(actionPending(DELETE_CORPORATE_PENDING));
    API.delete(CORPORATE_URL + `?id=${param}`)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(DELETE_CORPORATE_SUCCESS, res));
            toastSuccess(lang === 'id' ? `Berhasil Hapus Data Korporasi ${name}` : `Corporate  ${name} Removed Successfully`);
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(DELETE_CORPORATE_ERROR));
            if (lang === 'id') {
              toastError(err?.response?.data?.message);
            } else if (lang === "en-US" || lang === "en") {
              toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
            }
        });
};

const corporate = {
    get,
    getAll,
    post,
    put,
    deleted,
    getPrice,
};
export default corporate;
