import React from "react";
import { Formik, Form } from "formik";
import { withTrans } from "../../../i18n/withTrans";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import Select2 from "../../../components/forms/Select2";
import ButtonModal from "../../../components/modal/ButtonModal";
import DynamicButton from "../../../components/forms/DynamicButton";
import DatePicker from "../../../components/forms/DateRangePicker";
import { Role } from "../../../utils/Constants";
import { getUser } from "../../../utils/User";

function ModalFilter({
  t,
  show,
  toggle,
  dataAirport,
  filter,
  toggleFilter,
  isDelete,
  onApplyFilter,
}) {
  const currentUser = getUser();

  const handleReset = (formik) => {
    formik.setFieldValue("airport_id", "");
    formik.setFieldValue("status_assistant", "");
    toggleFilter({ status_assistant: "", airport_id: "" });
    toggle();
  };

  const airport_list = dataAirport.map((airport) => ({
    value: airport?.id,
    label: `${airport?.code}-${airport?.city}-${airport?.name} ${
      airport?.uniform === "Indonesia" ? "" : "-" + airport?.uniform
    }`,
  }));

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Filter{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody style={{ margin: "0px 20px" }}>
          <DynamicButton titleLeft={t("field.chooseFilter")} iconLeft="info" />
          <Formik
            initialValues={{
              status_assistant: filter?.status_assistant ?? "",
              airport_id: filter?.airport_id ?? "",
            }}
            enableReinitialize
            onSubmit={(values) => {
              onApplyFilter({
                status_assistant: values?.status_assistant,
                airport_id:
                  currentUser?.user?.role_code === Role.Sub_admin ||
                  currentUser?.user?.role_code === Role.Supervisor
                    ? currentUser?.user?.airport_id
                    : values?.airport_id,
              });
              toggle();
            }}
          >
            {(props) => (
              <Form style={{ marginTop: 30 }}>
                <Select2
                  title={t("field.airport")}
                  name="airport_id"
                  options={
                    currentUser?.user?.role_code === Role.Sub_admin ||
                    currentUser?.user?.role_code === Role.Supervisor
                      ? [
                          {
                            value: currentUser?.user?.airport_id,
                            label: `${currentUser?.user?.airport_code} - ${currentUser?.user?.airport_city}`,
                          },
                        ]
                      : airport_list
                  }
                  errors={props.errors}
                  touched={props.touched}
                  value={
                    currentUser?.user?.role_code === Role.Sub_admin ||
                    currentUser?.user?.role_code === Role.Supervisor
                      ? currentUser?.user?.airport_id
                      : props?.values?.airport_id
                  }
                  onChange={(name, value) => {
                    props.setFieldValue("airport_id", value);
                  }}
                  onBlur={props.setFieldTouched}
                  disabled={
                    currentUser?.user?.role_code === Role.Sub_admin ||
                    currentUser?.user?.role_code === Role.Supervisor
                  }
                />
                <Select2
                  title="Status"
                  name="status_assistant"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} Status`,
                    },
                    {
                      value: "Inactive",
                      label: t("promo.nonactive"),
                    },
                    {
                      value: "Active",
                      label: t("promo.active"),
                    },
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.status_assistant}
                  onChange={(name, value) => {
                    props.setFieldValue("status_assistant", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <div style={{ marginTop: 50, marginBottom: 30 }}>
                  <ButtonModal
                    toggle={toggle}
                    reset={() => handleReset(props)}
                    deleted={isDelete}
                    confirmTitle={t("field.apply")}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = ({ masterDataRegion }) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(ModalFilter)
);
