import { useState, useEffect } from "react";
import { Container, Col } from "reactstrap";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import ReactTooltip from "react-tooltip";
import { Row, } from "reactstrap";

import GroupPriceModal from "./GroupPriceModal";
import HeaderMenu from "../../../../components/commons/menu/HeaderMenu";
import ButtonModal from "../../../../components/modal/ButtonModal";
import CardFlush from "../../../../components/cards/cardFlush";
import Card from "../../../../components/cards/card";
import CardHeader from "../../../../components/cards/cardHeader";
import CardBody from "../../../../components/cards/cardBody";
import Input from "../../../../components/forms/Input";
import InputPrepend from "../../../../components/forms/InputPrepend";
import Select2Multi from "../../../../components/forms/Select2Multi";
import Checkbox from "../../../../components/forms/Checkbox";
import { flightTypeList, flightTypeTransferList } from "../../../../utils/Constants";
import { toastError } from "../../../../components/commons/toast";
import { withTrans } from "../../../../i18n/withTrans";

const AddPriceRegular = ({ formik, toggle, index, indexCancel, t, lastPriceValue }) => {
  const [activeTab, setActiveTab] = useState("0");
  const [indexGroupPrice, setIndexGroupPrice] = useState();
  const [indexCancelGroupPrice, setIndexCancelGroupPrice] = useState();
  const [modalGroupPrice, setModalGroupPrice] = useState(false);
  
  const exceptThisSymbols = ['e', 'E', '+', '-', '.'];
  let airportsTip = formik?.values?.airports
    ? [...formik?.values?.airports]
    : [];
  if (airportsTip?.length > 1) {
    airportsTip?.shift();
  }

  //compare two arrays to find element of array 2 in array 1
  const compare = (a, b) => {
    const indexFinded = a?.find((e) => b?.indexOf(e) !== -1);

    return indexFinded ? true : false;
  };

  const handleAddPrice = (formik) => {
    let product_name;
    let isNotValid = false;
    let period = formik?.values?.period_list[index];

    const handleCheckProductMandatory = (product) => {
      if (!product?.flight_types?.length) {
        toastError(
          `${t("commons.empty_data_message")} ${t("commons.activities")}!`
        );
        isNotValid = true;
        return;
      } else if (product?.group_prices?.length > 1) {
        const last_element = product?.group_prices[product?.group_prices?.length - 1];
        if (!(last_element?.min_pax && last_element?.max_pax)) {
          toastError(
            `${t("commons.delete_last_data_message")} ${t("field.groupPrice")}!`
          );
          isNotValid = true;
          return;
        }
      }

      product?.group_prices?.forEach((item, i) => {
        if (item?.min_pax && !item?.max_pax) {
          item.max_pax = item?.min_pax;
        }
        if (item?.max_pax && !item?.min_pax) {
          item.min_pax = item?.max_pax;
        }

        if (!item.reg_adult_price) {
          item.reg_adult_price = 0;
        }
        if (!item.reg_child_price) {
          item.reg_child_price = 0;
        }
        if (!item.reg_infant_price) {
          item.reg_infant_price = 0;
        }
        if (!item.goshow_adult_price) {
          item.goshow_adult_price = 0;
        }
        if (!item.goshow_child_price) {
          item.goshow_child_price = 0;
        }
        if (!item.goshow_infant_price) {
          item.goshow_infant_price = 0;
        }

        if (
          i >= 1 && 
          item?.min_pax <= product?.group_prices[i-1]?.max_pax
        ) {
          toastError(
            `${t("commons.handleMinGroupPax")}!`
          );
          isNotValid = true;
          return;
        }
        if (parseInt(item?.max_pax) < parseInt(item?.min_pax)) {
          toastError(
            `${t("commons.handleMaxGroupPax")}!`
          );
          isNotValid = true;
          return;
        }
        item.min_pax = parseInt(item?.min_pax);
        item.max_pax = parseInt(item?.max_pax);
      })
    }

    if (period?.is_checked_meet_dom == 1) {
      product_name = period?.meet_and_greet_dom;
      handleCheckProductMandatory(product_name);
    }
    if (period?.is_checked_meet_inter == 1) {
      product_name = period?.meet_and_greet_inter;
      handleCheckProductMandatory(product_name);
    }
    if (period?.is_checked_fast == 1) {
      product_name = period?.fast_track;
      handleCheckProductMandatory(product_name);
    }
    if (period?.is_checked_transfer == 1) {
      product_name = period?.transfer;
      handleCheckProductMandatory(product_name);
    }

    if ((period?.start_date && period?.end_date) && period?.start_date > period?.end_date) {
      toastError(
        `${t("product.startMoreThanEnd")}!`
      );
      isNotValid = true;
      return;
    }
    if (!period?.start_date) {
      toastError(
        `${t("commons.empty_data_message")} ${t("promo.startDate")}!`
      );
      isNotValid = true;
      return;
    }
    if (!period?.end_date) {
      toastError(
        `${t("commons.empty_data_message")} ${t("promo.endDate")}!`
      );
      isNotValid = true;
      return;
    }

    const existingPeriod = formik?.values?.period_list?.filter(
      (el, i) => i !== index
    );
    const comparedExistingPeriodWithNewPeriod = 
      existingPeriod?.filter((existing) => {
        if (
          period?.end_date <= existing?.start_date && 
          period?.start_date >= existing?.end_date
        ) {
          return;
        }

        let meet_and_greet_dom = true,
          meet_and_greet_inter = true,
          fast_track = true,
          transfer = true;
        if (
          existing?.hasOwnProperty("meet_and_greet_dom") &&
          period?.hasOwnProperty("meet_and_greet_dom")
        ) {
          meet_and_greet_dom = compare(
            existing?.meet_and_greet_dom?.flight_types,
            period?.meet_and_greet_dom?.flight_types
          );
        }

        if (
          existing?.hasOwnProperty("meet_and_greet_inter") &&
          period?.hasOwnProperty("meet_and_greet_inter")
        ) {
          meet_and_greet_inter = compare(
            existing?.meet_and_greet_dom?.flight_types,
            period?.meet_and_greet_dom?.flight_types
          );
        }

        if (
          existing?.hasOwnProperty("fast_track") &&
          period?.hasOwnProperty("fast_track")
        ) {
          fast_track = compare(
            existing?.fast_track?.flight_types,
            period?.fast_track?.flight_types
          );
        }

        if (
          existing?.hasOwnProperty("transfer") &&
          period?.hasOwnProperty("transfer")
        ) {
          transfer = compare(
            existing?.transfer?.flight_types, 
            period?.transfer?.flight_types
          );
        }
        return meet_and_greet_dom && meet_and_greet_inter && transfer && fast_track;
      });
    
    if (comparedExistingPeriodWithNewPeriod?.length) {
      toastError(`${t("product.sameServicePeriod")}!`);
      isNotValid = true;
      return;
    }
    if (isNotValid) {
      return;
    }
    
    if (!product_name?.prices) {
      product_name.prices = {};
    }
    if (!product_name?.prices?.reg_adult_price) {
      product_name.prices.reg_adult_price = 0;
    }
    if (!product_name?.prices?.reg_child_price) {
      product_name.prices.reg_child_price = 0;
    }
    if (!product_name?.prices?.reg_infant_price) {
      product_name.prices.reg_infant_price = 0;
    }
    if (!product_name?.prices?.goshow_adult_price) {
      product_name.prices.goshow_adult_price = 0;
    }
    if (!product_name?.prices?.goshow_child_price) {
      product_name.prices.goshow_child_price = 0;
    }
    if (!product_name?.prices?.goshow_infant_price) {
      product_name.prices.goshow_infant_price = 0;
    }
    formik?.setFieldValue("period_list", formik?.values?.period_list);
    toggle();
  };

  const handleCancelPrice = (formik) => {
    let i = formik?.values?.period_list?.length - 1;
    let period = formik?.values?.period_list[index];

    if (
      (indexCancel === null || indexCancel === undefined)
      && (period?.start_date
        || period?.end_date
        || period?.is_checked_meet_dom
        || period?.is_checked_meet_inter
        || period?.is_checked_fast
        || period?.is_checked_transfer
        || period?.meet_and_greet_dom
        || period?.meet_and_greet_inter
        || period?.fast_track
        || period?.transfer)
    ) {
      let period_list = formik?.values?.period_list;
      period_list.splice(i, 1);
      formik?.setFieldValue("period_list", period_list);
      toggle();
    } else if (
      indexCancel === null
      && period?.start_date === undefined
      && period?.end_date === undefined
      && period?.is_checked_meet_dom === undefined
      && period?.is_checked_meet_inter === undefined
      && period?.is_checked_fast === undefined
      && period?.is_checked_transfer === undefined
      && period?.meet_and_greet_dom === undefined
      && period?.meet_and_greet_inter === undefined
      && period?.fast_track === undefined
      && period?.transfer === undefined
    ) {
      toggle();
    } else {
      formik?.setFieldValue("period_list", lastPriceValue);
      toggle();
    }
  };

  const handleDeletePrice = (props, index) => {
    let period_list = props?.values?.period_list;
    period_list.splice(index, 1);
    props?.setFieldValue("period_list", period_list);
    toggle();
  };

  const ShowTab = (active, props) => {
    let name, value, checked, nameChecked, labelChecked;

    if (active === "0") {
      name = `period_list[${index}].meet_and_greet_dom`;
      value = props?.values?.period_list[index]?.meet_and_greet_dom;
      nameChecked = `period_list[${index}].is_checked_meet_dom`;
      checked = props?.values?.period_list[index]?.is_checked_meet_dom;
      labelChecked = `${t("product.activate_meet")}`;
    } else if (active === "1") {
      name = `period_list[${index}].meet_and_greet_inter`;
      value = props?.values?.period_list[index]?.meet_and_greet_inter;
      nameChecked = `period_list[${index}].is_checked_meet_inter`;
      checked = props?.values?.period_list[index]?.is_checked_meet_inter;
      labelChecked = `${t("product.activate_meet_inter")}`;
    } else if (active === "2") {
      name = `period_list[${index}].fast_track`;
      value = props?.values?.period_list[index]?.fast_track;
      nameChecked = `period_list[${index}].is_checked_fast`;
      checked = props?.values?.period_list[index]?.is_checked_fast;
      labelChecked = `${t("product.activate_fast")}`;
    } else if (active === "3") {
      name = `period_list[${index}].transfer`;
      value = props?.values?.period_list[index]?.transfer;
      nameChecked = `period_list[${index}].is_checked_transfer`;
      checked = props?.values?.period_list[index]?.is_checked_transfer;
      labelChecked = `${t("product.activate_transfer")}`;
    }

    const handleAddGroupPrice = (index) => {
      if (index !== null && index !== undefined) {
        setIndexGroupPrice(index);
        setIndexCancelGroupPrice(index);
      } else {
        if (value?.group_prices === undefined) {
          value.group_prices = [];
        }
        let group_prices = value?.group_prices;
        props?.setFieldValue(`${name}.group_prices`, [
          ...group_prices,
          {
            min_pax: "",
            max_pax: "",
            goshow_adult_price: "",
            goshow_child_price: "",
            goshow_infant_price: "",
            reg_adult_price: "",
            reg_child_price: "",
            reg_infant_price: ""
          },
        ]);
        setIndexGroupPrice(value?.group_prices?.length);
        setIndexCancelGroupPrice(null);
      }
      setModalGroupPrice(true);
    }

    const cancelAddGroupPrice = () => {
      let i = value?.group_prices?.length - 1;
      if (indexCancelGroupPrice === null || indexCancelGroupPrice === undefined) {
        let group_prices = value?.group_prices;
        group_prices.splice(i, 1);
        value.group_prices = group_prices;
        props?.setFieldValue(name, value);
        setModalGroupPrice(false);
      } else {
        setModalGroupPrice(false);
      }
    }

    const deleteListGroupPrice = () => {
      let group_prices = value?.group_prices;
      group_prices.splice(indexGroupPrice, 1);
      value.group_prices = group_prices;
      props?.setFieldValue(name, value);
      setModalGroupPrice(false);
    }

    return (
      <>
        <div className="checkbox-wrapper">
          <Checkbox
            style={{ marginTop: "30px" }}
            name={nameChecked}
            checked={checked}
            onChange={(e) => {
              if (checked) {
                props?.setFieldValue(nameChecked, null);
              } else {
                props?.setFieldValue(nameChecked, ["1"]);
                props?.setFieldValue(`${name}.group_prices`, []);
              }
            }}
            value={1}
            label={labelChecked}
          />
        </div>
        {checked == 1 && (
          <>
            <Select2Multi
              title={t("commons.activities")}
              name={`${name}.flight_types`}
              options={
                active === "3" ? flightTypeTransferList : flightTypeList(t)
              }
              className="mb-4"
              errors={props?.errors}
              touched={props?.touched}
              value={value?.flight_types}
              onChange={(x, value) => {
                let data = value;
                if (!data.length > 0) {
                  data = "";
                }
                props?.setFieldValue(
                  `${name}.flight_types`,
                  data
                );
              }}
              onBlur={props?.setFieldTouched}
            />
            <Card className="rounded">
              <CardHeader title={t("field.individualPrice")} />
              <CardBody style={{ paddingTop: 0 }}>
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-3">
                    <div className="form-group" style={{ marginBottom: 0 }}>
                      <label className="form-label mb-1">
                        {t("commons.adult")}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                    <div className="form-group" style={{ marginBottom: 0 }}>
                      <label className="form-label mb-1">
                        {t("commons.child")}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                    <div className="form-group" style={{ marginBottom: 0 }}>
                      <label className="form-label mb-1">
                        {t("commons.infantLabel")}
                      </label>
                    </div>
                  </div>
                </div>
                <InputPrepend
                  mb="0"
                  errors={props?.errors}
                  touched={props?.touched}
                  title={t("field.regular")}
                  multiple={[
                    {
                      prefix: "Rp ",
                      isCurrency: true,
                      thousandSeparator: true,
                      name: `${name}.prices.reg_adult_price`,
                      value: value?.prices?.reg_adult_price,
                      placeholder: `${t("commons.adult")}`,
                      onKeyDown: (e) => exceptThisSymbols.includes(e.key) && e.preventDefault(),
                      onChange: (data) => {
                        const val = data.target.value;
                        const regular_adult = Number(val.replace(/[^0-9\.-]+/g, ""));
                        // let regular_adult = raw > 0 ? raw : "";
                        props?.setFieldValue(
                          `${name}.prices.reg_adult_price`,
                          regular_adult
                        );
                      },
                    },
                    {
                      prefix: "Rp ",
                      isCurrency: true,
                      thousandSeparator: true,
                      name: `${name}.prices.reg_child_price`,
                      value: value?.prices?.reg_child_price,
                      placeholder: `${t("commons.child")}`,
                      onKeyDown: (e) => exceptThisSymbols.includes(e.key) && e.preventDefault(),
                      onChange: (data) => {
                        const val = data.target.value;
                        const regular_child = Number(val.replace(/[^0-9\.-]+/g, ""));
                        // let regular_child = raw > 0 ? raw : "";
                        props?.setFieldValue(
                          `${name}.prices.reg_child_price`,
                          regular_child
                        );
                      },
                    },
                    {
                      prefix: "Rp ",
                      isCurrency: true,
                      thousandSeparator: true,
                      name: `${name}.prices.reg_infant_price`,
                      value: value?.prices?.reg_infant_price,
                      placeholder: `${t("commons.infantLabel")}`,
                      onKeyDown: (e) => exceptThisSymbols.includes(e.key) && e.preventDefault(),
                      onChange: (data) => {
                        const val = data.target.value;
                        const regular_infant = Number(val.replace(/[^0-9\.-]+/g, ""));
                        // let regular_infant = raw > 0 ? raw : "";
                        props?.setFieldValue(
                          `${name}.prices.reg_infant_price`,
                          regular_infant
                        );
                      },
                    },
                  ]}
                />
                <InputPrepend
                  mb="0"
                  errors={props?.errors}
                  touched={props?.touched}
                  title="Go Show"
                  multiple={[
                    {
                      prefix: "Rp ",
                      isCurrency: true,
                      thousandSeparator: true,
                      name: `${name}.prices.goshow_adult_price`,
                      value: value?.prices?.goshow_adult_price,
                      placeholder: `${t("commons.adult")}`,
                      onKeyDown: (e) => exceptThisSymbols.includes(e.key) && e.preventDefault(),
                      onChange: (data) => {
                        const val = data.target.value;
                        const goshow_adult = Number(val.replace(/[^0-9\.-]+/g, ""));
                        // let goshow_adult = raw > 0 ? raw : "";
                        props?.setFieldValue(
                          `${name}.prices.goshow_adult_price`,
                          goshow_adult
                        );
                      },
                    },
                    {
                      prefix: "Rp ",
                      isCurrency: true,
                      thousandSeparator: true,
                      name: `${name}.prices.goshow_child_price`,
                      value: value?.prices?.goshow_child_price,
                      placeholder: `${t("commons.child")}`,
                      onKeyDown: (e) => exceptThisSymbols.includes(e.key) && e.preventDefault(),
                      onChange: (data) => {
                        const val = data.target.value;
                        const goshow_child = Number(val.replace(/[^0-9\.-]+/g, ""));
                        // let goshow_child = raw > 0 ? raw : "";
                        props?.setFieldValue(
                          `${name}.prices.goshow_child_price`,
                          goshow_child
                        );
                      },
                    },
                    {
                      prefix: "Rp ",
                      isCurrency: true,
                      thousandSeparator: true,
                      name: `${name}.prices.goshow_infant_price`,
                      value: value?.prices?.goshow_infant_price,
                      placeholder: `${t("commons.infantLabel")}`,
                      onKeyDown: (e) => exceptThisSymbols.includes(e.key) && e.preventDefault(),
                      onChange: (data) => {
                        const val = data.target.value;
                        const goshow_infant = Number(val.replace(/[^0-9\.-]+/g, ""));
                        // let goshow_infant = raw > 0 ? raw : "";
                        props?.setFieldValue(
                          `${name}.prices.goshow_infant_price`,
                          goshow_infant
                        );
                      },
                    },
                  ]}
                />
              </CardBody>
            </Card>
            <Card className="rounded">
              <CardHeader title={t("field.groupPrice")} />
              <CardBody style={{ paddingTop: 0 }}>
                {value?.group_prices?.length > 0 && (
                  <>
                    <Row
                      style={{
                        fontWeight: 800,
                        fontSize: "16px",
                        color: "rgba(36, 39, 38, 0.3)",
                        paddingBottom: 3,
                      }}
                    >
                      <Col md={2}>
                        <div className="text-center">
                          Min Pax
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="text-center">
                          Max Pax
                        </div>
                      </Col>
                      <Col md={8}>
                        <div></div>
                      </Col>
                    </Row>
                    {value?.group_prices?.map((item, index) => {
                      return (
                        <>
                          <Row
                            style={{
                              paddingTop: 8,
                              paddingBottom: 8,
                            }}
                          >
                            <Col md={2}>
                              <div className="text-center">
                                {item?.min_pax ?? item?.max_pax}
                              </div>
                            </Col>
                            <Col md={2}>
                              <div className="text-center">
                                {item?.max_pax ?? item?.min_pax}
                              </div>
                            </Col>
                            <Col md={8}>
                              <div
                                className="d-flex justify-content-end"
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleAddGroupPrice(index)}
                              >
                                <span className="material-icons-round right-icon">
                                  navigate_next
                                </span>
                              </div>
                            </Col>
                          </Row>
                          <hr style={{ marginTop: '0.3rem', marginBottom: '0.3rem' }} />
                        </>
                      )
                    })}
                  </>
                )}
                <div
                  style={{
                    color: "#63ae5c",
                    paddingTop: "20px",
                    display: "flex",
                    cursor: "pointer",
                  }}
                  onClick={() => handleAddGroupPrice()}
                >
                  <div
                    style={{
                      borderRadius: "50%",
                      border: "solid 1px #63ae5c",
                      width: 30,
                      height: 30,
                      paddingTop: 2,
                      marginRight: 20,
                      textAlign: "center",
                    }}
                  >
                    <span className="material-icons-round">add</span>
                  </div>
                  <p
                    className="text-bold"
                    style={{
                      fontSize: 14,
                      margin: 0,
                      alignSelf: "center",
                    }}
                  >
                    {t("commons.add") + " " + t("field.group")}
                  </p>
                </div>
              </CardBody>
            </Card>
          </>
        )}
        <GroupPriceModal
          show={modalGroupPrice}
          formik={props}
          index={indexGroupPrice}
          value={value}
          name={name}
          toggle={cancelAddGroupPrice}
          reset={deleteListGroupPrice}
          confirm={() => setModalGroupPrice(false)}
        />
      </>
    )
  };

  return (
    <div className="menu-container">
      <div className="grid-header">
        <div className="side-menu">
          <div className="menu-title text-extra-bold">
            <div className="side-menu-back-icon ">
              <span
                className="material-icons clickable"
                onClick={() => handleCancelPrice(formik)}
              >
                arrow_back
              </span>
            </div>
            {indexCancel !== null
              ? `${t("commons.edit")} ${t("field.price")}`
              : `${t("commons.set")} ${t("field.price")}`
            }
          </div>
          <div style={{ padding: "0px 15px 30px 10px" }}>
            <Card className="rounded">
              <CardFlush
                sideMenu
                title={t("field.priceName")}
                desc={formik?.values?.name}
                leftIcon="assignment_ind"
              />
              <CardFlush
                sideMenu
                title={t("field.airport")}
                leftIcon="flight"
                desc={
                  <div className="row">
                    <div className="col-8">
                      {formik?.values?.airports
                        ? formik?.values?.airports[0]
                        : ""}
                    </div>
                    {formik?.values?.airports?.length > 1 && (
                      <div className="col-4">
                        <p
                          className="button-tooltip"
                          data-tip
                          data-for="airportsTip"
                        >
                          {`+${formik?.values?.airports?.length - 1}`}
                        </p>
                        <ReactTooltip
                          id="airportsTip"
                          place="bottom"
                          effect="solid"
                        >
                          <p style={{ whiteSpace: "pre-line" }}>
                            {airportsTip.join(",\n")}
                          </p>
                        </ReactTooltip>
                      </div>
                    )}
                  </div>
                }
              />
            </Card>
          </div>
        </div>
        <div className="side-menu-detail">
          <Container>
            <HeaderMenu
              title={t("commons.details")}
            />
            <Card className="rounded mx-3">
              <CardHeader title={t("product.period")} />
              <CardBody>
                <div className="d-flex justify-content-between">
                  <div className="text-bold">{t("promo.startDate")}</div>
                  <Col md={8}>
                    <Input
                      name={`period_list[${index}].start_date`}
                      type="date"
                      // min={moment().format("YYYY-MM-DD")}
                      errors={formik.errors}
                      touched={formik.touched}
                      value={formik?.values?.period_list[index]?.start_date ?? ""}
                      onChange={(e) => {
                        let value = e.target.value;
                        formik?.setFieldValue(
                          `period_list[${index}].start_date`,
                          value
                        );
                      }}
                    />
                  </Col>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="text-bold">{t("promo.endDate")}</div>
                  <Col md={8}>
                    <Input
                      name={`period_list[${index}].end_date`}
                      type="date"
                      // min={moment().format("YYYY-MM-DD")}
                      errors={formik.errors}
                      touched={formik.touched}
                      value={formik?.values?.period_list[index]?.end_date ?? ""}
                      onChange={(e) => {
                        let value = e.target.value;
                        formik?.setFieldValue(
                          `period_list[${index}].end_date`,
                          value
                        );
                      }}
                    />
                  </Col>
                </div>
              </CardBody>
            </Card>
            <Card className="rounded mx-3">
              <CardHeader title={t("field.product")} />
              <CardBody>
                <div
                  className="content-container-side"
                  style={{ padding: "0" }}
                >
                  <div className="tab-menu overflow">
                    <div style={{ width: "fit-content" }}>
                      <Tabs
                        scrollButtons="auto"
                        variant="scrollable"
                        value={activeTab}
                        textColor="primary"
                        indicatorColor="primary"
                        onChange={(event, newValue) => {
                          setActiveTab(newValue);
                        }}
                      >
                        <Tab
                          label="Meet and Greet Domestic"
                          value="0"
                        />
                        <Tab
                          label="Meet and Greet International"
                          value="1"
                        />
                        <Tab label="Fast Track" value="2" />
                        <Tab label="Transfer" value="3" />
                      </Tabs>
                    </div>
                  </div>
                  <div>{ShowTab(activeTab, formik)}</div>
                </div>
              </CardBody>
            </Card>
            <div className="px-3">
              <ButtonModal
                reset={() => handleDeletePrice(formik, index)}
                toggle={() => handleCancelPrice(formik)}
                confirm={() => handleAddPrice(formik)}
                resetTitle={t("commons.delete")}
                cancelTitle={t("commons.cancel")}
                confirmTitle={t("commons.save")}
                typeConfirm="Button"
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default withTrans(AddPriceRegular);
