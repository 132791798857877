import React, { useRef } from "react";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";
import { withTrans } from "../../i18n/withTrans";
import { connect, useDispatch } from "react-redux";
import Input from "../../components/forms/Input";
import Button from "../../components/buttons/Button";
import * as Yup from "yup";

// ACTION REDUX
import AuthService from "../../store/actions/auth";

const ForgotPassword = ({ t, history }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();

  const validation = Yup.object().shape({
    email: Yup.string()
      .email(t("commons.invalidEmail"))
      .required(`Email ${t("commons.required")}`)
      .nullable(),
  });

  return (
    <div className="main-wrapper">
      <div className="login-container">
        <div className="content-wrapper">
          <div className="title-icon" onClick={() => history.push("/")}>
            <span className="material-icons">arrow_back</span>
          </div>
          <p className="super-title mt-4 text-extra-bold">{t("auth.forgotPassword")}</p>
          <p className="subtitle text-medium">
            {t("auth.subtitleForgotPassword")}
          </p>
          <div className="form-wrapper">
            <Formik
              innerRef={formikRef}
              initialValues={{
                email: "",
              }}
              validationSchema={validation}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                setStatus();
                dispatch(AuthService.forgotPassword(values));
              }}
            >
              {(props) => (
                <Form>
                  <Input
                    title={t("field.email")}
                    name="email"
                    type="text"
                    placeholder={t("commons.input") + "Email"}
                    errors={props.errors}
                    touched={props.touched}
                    {...props.getFieldProps("email")}
                  />
                  <div className="button-wrapper" style={{ marginTop: "30px" }}>
                    <Button
                      title={t("auth.send")}
                      rounded
                      type="Submit"
                      variant="primary text-white"
                      style={{
                        width: "100%",
                        textAlign: "center",
                        borderRadius: "48px",
                        padding: "10px 0",
                      }}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="side-login">
          <div className="side-login-title text-extra-bold">
            Airport Service for Your Journey Companion
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};

const mapDispatchToProps = () => {
  return {};
};
export default withRouter(
  withTrans(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword))
);
