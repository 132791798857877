import React, { useState, useRef, useEffect } from 'react';
import Chart from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import _uniqueId from 'lodash/uniqueId';
import Loader from "../commons/Loader";

let chart;

const PieChart = ({
  labels = ["Green", "Pink"],
  options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    }
  },
  datasets = [
    {
      label: "Dataset 1",
      data: [86, 67],
    }
  ],
  pending_chart,
}) => {

  const chartRef = useRef(null);
  const [id] = useState(_uniqueId('piechart-'));

  useEffect(() => {
    buildChart();
  });

  const buildChart = () => {
    if (typeof chart !== "undefined") chart.destroy();
    const pieChart = chartRef.current.getContext("2d");

    chart = new Chart(pieChart, {
      plugins: [ChartDataLabels],
      type: "pie",
      data: {
        //Bring in data
        labels: labels,
        datasets: datasets
      },
      options: {
        responsive: true,
        animation: {
          animateRotate: pending_chart,
        },
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              label: (context) => {
                let label = context.label || "";

                if (label) {
                  label += ": ";
                }

                if (context.parsed !== null) {
                  if (context.dataset.nonParsed) {
                    label += context.raw
                  } else {
                    label += context.parsed+'%'
                  }
                }

                return [label];
              },
            },
          },
          datalabels: {
            formatter: (value, ctx) => {
              // let sum = 0;
              // let dataArr = ctx.chart.data.datasets[0].data;
              // dataArr.map(data => {
              //     sum += data;
              // });
              // let percentage = (value*100 / sum).toFixed(2)+"%";
              let percentage = value+"%";
              return percentage;
            },
            color: '#fff',
          }
        }
      }
    });
  };

  return (
    <div>
      {pending_chart && <Loader loading={pending_chart} />}
      <canvas
        id={id}
        ref={chartRef}
      />
    </div>
  );
};

export default PieChart;
