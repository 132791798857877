import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import { Formik, Form } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import * as Yup from "yup";

import { withTrans } from "../../../i18n/withTrans";
import { getUser } from "../../../utils/User";
import { toastError } from "../../../components/commons/toast";
import Input from "../../../components/forms/Input";
import Select2 from "../../../components/forms/Select2";
import ButtonModal from "../../../components/modal/ButtonModal";

const UserAssistantModal = ({
  data,
  show,
  toggle,
  action,
  t,
  confirm,
  dataAirport,
}) => {
  const currentUser = getUser();
  const title =
    (data ? t("commons.edit") : t("commons.add")) + " " + t("field.assistant");
  const initial = data
    ? {
      id: data?.id,
      assistant_id: data?.assistant_id,
      name: data?.name,
      gender: data?.gender,
      email: data?.email,
      handphone: data?.country_code 
        ? `${data?.country_code.replace("+", "")}${data?.handphone}` 
        : data?.handphone,
      country_code: data?.country_code?.includes("+") 
        ? data?.country_code.replace("+", "") 
        : data?.country_code,
      country_id: data?.country_id,
      airport_id: data?.airport_id,
    }
    : {
      id: data?.id,
      assistant_id: "",
      name: "",
      gender: "",
      email: "",
      handphone: "",
      airport_id:
        currentUser?.user?.role_code === "admin_bandara"
          ? currentUser?.user?.airport_id :
          "",
    };
  // const phoneRegExp = /^[0]{1}[8]{1}[0-9]{8}/;

  const validation = Yup.object().shape({
    airport_id: Yup.string()
      .required(t("field.airport") + " " + t("commons.required"))
      .nullable(),
    assistant_id: Yup.string()
      .required("ID" + " " + t("commons.required"))
      .nullable(),
    name: Yup.string()
      .required(t("field.name") + " " + t("commons.required"))
      .nullable(),
    gender: Yup.string()
      .required(t("field.gender") + " " + t("commons.required"))
      .nullable(),
    email: Yup.string()
      .email(t("commons.invalidEmail"))
      .required(t("field.email") + " " + t("commons.required"))
      .nullable(),
    // handphone: Yup.string()
    //   .matches(phoneRegExp, t("commons.invalidPhone"))
    //   .required(t("field.phone") + " " + t("commons.required"))
    //   .nullable(),
  });

  const gender = [
    { value: 1, label: `${t("commons.men")}` },
    { value: 0, label: `${t("commons.woman")}` },
  ];

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {title}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          initialValues={initial}
          validationSchema={validation}
          onSubmit={ async (values) => {
            if (
              values.handphone !== "" &&
              values.handphone !== undefined &&
              values.handphone !== null &&
              !values.handphone.includes("undefined") &&
              values.handphone !== values.country_code
            ) {
              const phone = values.handphone
                .replace("+", "")
                .replace(values?.country_code, "");
        
              values.handphone = 
                phone.charAt(0) == 0
                  ? phone?.substring(1)
                  : phone;
              values.country_code = `+${values.country_code}`
            } else {
              toastError(`${t("commons.empty_data_message")} ${t("field.phone")}!`);
              return;
            }

            action(values);
            await delay(3000);
          }}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Container>
                  <Input
                    name="assistant_id"
                    errors={props.errors}
                    touched={props.touched}
                    {...props.getFieldProps("assistant_id")}
                    title="ID"
                    type="text"
                  />
                  <Input
                    name="name"
                    errors={props.errors}
                    touched={props.touched}
                    {...props.getFieldProps("name")}
                    title={t("field.name")}
                    type="text"
                  />
                  <Select2
                    name="gender"
                    type="text"
                    errors={props.errors}
                    touched={props.touched}
                    {...props.getFieldProps("gender")}
                    title={t("field.gender")}
                    options={gender}
                    value={props?.values?.gender}
                    onChange={(name, value) => {
                      props?.setFieldValue("gender", value);
                    }}
                    onBlur={props?.setFieldTouched}
                  />
                  <Input
                    name="email"
                    type="email"
                    errors={props.errors}
                    touched={props.touched}
                    {...props.getFieldProps("email")}
                    title={t("field.email")}
                  />
                  {/* <Input
                    name="handphone"
                    type="text"
                    errors={props.errors}
                    touched={props.touched}
                    value={props.values.handphone}
                    {...props.getFieldProps("handphone")}
                    title={t("field.phone")}
                  /> */}
                  <div className="form-group">
                    <div className="form-label">{t("field.phone")}</div>
                    <div className="input-wrapper w-100">
                      <PhoneInput
                        enableTerritories={true}
                        countryCodeEditable={false}
                        placeholder={t("field.placeHolderPhoneNumber")}
                        country={props?.values?.country_id ?? "id"}
                        inputStyle={{ width: '100%' }}
                        enableSearch={true}
                        value={props?.values?.handphone}
                        onChange={(phone, country) => {
                          props?.setFieldValue("country_code", country?.dialCode);
                          props?.setFieldValue("handphone", phone);
                          props?.setFieldValue("country_id", country?.countryCode);
                        }}
                      />
                    </div>
                  </div>
                  {currentUser?.user?.role_code === "admin_bandara" ? (
                    <Select2
                      name="airport_id"
                      type="text"
                      options={[
                        {
                          label: `${currentUser?.user?.airport_code} - ${currentUser?.user?.airport_city}`,
                          value: currentUser?.user?.airport_id,
                        },
                      ]}
                      errors={props.errors}
                      touched={props.touched}
                      value={currentUser?.user?.airport_id}
                      disabled
                      title={t("field.airport")}
                    />
                  ) : (
                    <Select2
                      name="airport_id"
                      type="text"
                      options={dataAirport}
                      errors={props.errors}
                      touched={props.touched}
                      {...props.getFieldProps("airport_id")}
                      value={props?.values?.airport_id}
                      onChange={(name, value) => {
                        props?.setFieldValue("airport_id", value);
                      }}
                      onBlur={props?.setFieldTouched}
                      title={t("field.airport")}
                    />
                  )}
                </Container>
              </ModalBody>
              <ModalFooter>
                <ButtonModal
                  toggle={toggle}
                  confirm={confirm}
                  confirmTitle={t("commons.save")}
                  disabledConfirm={props.isSubmitting}
                  icon
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default withTrans(UserAssistantModal);
