import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Container, Col } from "reactstrap";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { withTrans } from "../../../../i18n/withTrans";
import HeaderMenu from "../../../../components/commons/menu/HeaderMenu";
import ButtonModal from "../../../../components/modal/ButtonModal";
import CardFlush from "../../../../components/cards/cardFlush";
import Card from "../../../../components/cards/card";
import CardHeader from "../../../../components/cards/cardHeader";
import CardBody from "../../../../components/cards/cardBody";
import Input from "../../../../components/forms/Input";
import Checkbox from "../../../../components/forms/Checkbox";
import { toastError } from "../../../../components/commons/toast";
import ShowTabProduct from "./ShowTabProduct";

import AirportService from "../../../../store/actions/master_data/airport";

const AddPriceCorporate = ({
  formik,
  toggle,
  idTypeList,
  index,
  indexCancel,
  lastPriceValue,
  t,
}) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("0");
  const [dataAirport, setDataAirport] = useState([]);
  const { id } = useParams();

  const idType = formik?.values?.type_list
    .map((item) => {
      return item?.id;
    })
    .indexOf(idTypeList);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 99999,
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      let list = [];
      if (res && res.data) {
        res.data?.map(
          (airport) =>
            (list = [
              ...list,
              {
                ...airport,
                value: airport?.id,
                label: `${airport?.code}-${airport?.city}-${airport?.name}`,
              },
            ])
        );
      }
      setDataAirport(list);
    });
  }, []);

  //compare two arrays to find element of array 2 in array 1
  const compare = function (a, b) {
    const indexFinded = a?.find((e) => b?.indexOf(e) !== -1);

    return indexFinded ? true : false;
  };

  const handleAddPrice = (formik) => {
    let type_list = formik?.values?.type_list[idType];
    let period = type_list?.period_list[index];
    let product_name;
    let isNotValid = false;

    const handleCheckProductMandatory = (product) => {
      if (!product?.airport_list?.length) {
        toastError(`${t("commons.empty_data_message")} ${t("field.airport")}!`);
        isNotValid = true;
        return;
      }
      if (product !== period?.lounge && product !== period?.transport) {
        if (!product?.flight_list?.length) {
          toastError(
            `${t("commons.empty_data_message")} ${t("commons.activities")}!`
          );
          isNotValid = true;
          return;
        }
      }
      if (product === period?.lounge) {
        let emptyLoungeAirport = false;
        let emptyLoungeCorporate = false;
        let invalidListLounge = false;

        if (product_name?.lounge_list?.length) {
          const loungeListAirportIds = [];
          product_name?.lounge_list?.forEach((item) => {
            if (item?.lounge_id !== "") {
              if (!item?.adult_price) {
                item.adult_price = 0;
              }
              if (!item?.child_price) {
                item.child_price = 0;
              }
              if (!item?.infant_price) {
                item.infant_price = 0;
              }
              loungeListAirportIds?.push(item?.airport_id);
            } else {
              product_name.lounge_list = [];
              emptyLoungeAirport = true;
            }
          });

          const filteredAirportIds = product_name?.airport_list?.every(
            (airport_listId) =>
              loungeListAirportIds.find(
                (lounge_airport_ids) => lounge_airport_ids === airport_listId
              )
          );
          if (!filteredAirportIds) {
            invalidListLounge = true;
          }
        }

        if (!product_name?.corporate_lounge_list?.length) {
          emptyLoungeCorporate = true;
        }

        if (emptyLoungeAirport && emptyLoungeCorporate) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.lounge")}!`
          );
          isNotValid = true;
          return;
        } else if (!emptyLoungeAirport && invalidListLounge) {
          toastError(`${t("product.airportNotAllIncluded")}!`);
          isNotValid = true;
          return;
        }
      }
      if (product === period?.transport) {
        if (product?.transport_list?.length) {
          product?.transport_list?.map((item) => {
            if (item?.car_name === "" || item?.capacity === "") {
              toastError(`${t("commons.empty_data_message")} transport!`);
              isNotValid = true;
              return;
            }
            if (item?.price === null || item?.price === "") {
              item.price = 0;
            }
          });
        }
      }
    };

    const handleCheckProductPrice = (product) => {
      product?.group_list?.map((item, index) => {
        if (item?.property_list?.length) {
          item?.property_list?.map((property, i) => {
            if (item?.group_type === "MOB") {
              if (!property?.prices) {
                property.prices = {};
              }
              if (!property?.prices?.price) {
                property.prices.price = 0;
              }
              if (!property?.prices?.adult_price) {
                property.prices.adult_price = 0;
              }
              if (!property?.prices?.child_price) {
                property.prices.child_price = 0;
              }
              if (!property?.prices?.infant_price) {
                property.prices.infant_price = 0;
              }
            } else if (item?.group_type === "Package") {
              if (!property?.prices) {
                property.prices = {};
                property.prices.price = 0;
              }
            } else {
              if (!property?.prices) {
                property.prices = {};
              }
              if (!property?.prices?.adult_price) {
                property.prices.adult_price = 0;
              }
              if (!property?.prices?.child_price) {
                property.prices.child_price = 0;
              }
              if (!property?.prices?.infant_price) {
                property.prices.infant_price = 0;
              }
            }
          });
        }
      });
    };

    if (
      (period?.start_date && period?.end_date &&
      period?.start_date > period?.end_date) ||
      (period?.renewal_start_date && period?.renewal_end_date &&
      period?.renewal_start_date > period?.renewal_end_date)
    ) {
      toastError(`${t("product.startMoreThanEnd")}!`);
      isNotValid = true;
      return;
    }
    if (!period?.start_date || (period?.activate_renewal == 1 && !period?.renewal_start_date)) {
      toastError(`${t("commons.empty_data_message")} ${t("promo.startDate")}!`);
      isNotValid = true;
      return;
    }
    if (!period?.end_date || (period?.activate_renewal == 1 && !period?.renewal_end_date)) {
      toastError(`${t("commons.empty_data_message")} ${t("promo.endDate")}!`);
      isNotValid = true;
      return;
    }
    if (
      period?.is_checked_meet_dom != 1 &&
      period?.is_checked_meet_inter != 1 &&
      period?.is_checked_fast != 1 &&
      period?.is_checked_transfer != 1
    ) {
      toastError(`${t("product.noProductChecked")}!`);
      isNotValid = true;
      return;
    }

    if (period?.is_checked_meet_dom == 1) {
      product_name = period?.meet_and_greet_dom;
      handleCheckProductMandatory(product_name);
      handleCheckProductPrice(product_name);
    }
    if (period?.is_checked_meet_inter == 1) {
      product_name = period?.meet_and_greet_inter;
      handleCheckProductMandatory(product_name);
      handleCheckProductPrice(product_name);
    }
    if (period?.is_checked_fast == 1) {
      product_name = period?.fast_track;
      handleCheckProductMandatory(product_name);
      handleCheckProductPrice(product_name);
    }
    if (period?.is_checked_transfer == 1) {
      product_name = period?.transfer;
      handleCheckProductMandatory(product_name);
      handleCheckProductPrice(product_name);
    }
    if (period?.is_checked_lounge == 1) {
      product_name = period?.lounge;
      handleCheckProductMandatory(product_name);
    }
    if (period?.is_checked_transport == 1) {
      product_name = period?.transport;
      handleCheckProductMandatory(product_name);
    };

    const existingPeriod = type_list?.period_list?.filter(
      (el, i) => i !== index
    );
    const comparedExistingPeriodWithNewPeriod = existingPeriod?.filter(
      (existing) => {
        if (
          (period?.end_date >= existing?.start_date &&
            period?.start_date <= existing?.end_date) ||
          (period?.activate_renewal == 1 &&
            period?.renewal_end_date >= existing?.start_date &&
            period?.renewal_start_date <= existing?.end_date)
        ) {
          let meet_and_greet_dom = true,
            meet_and_greet_inter = true,
            fast_track = true,
            transfer = true,
            lounge = true,
            transport = true;
          if (
            existing.hasOwnProperty("meet_and_greet_dom") &&
            period.hasOwnProperty("meet_and_greet_dom")
          ) {
            let comparedAirportMNGDom, comparedFlightsMNGDom;
            comparedAirportMNGDom = compare(
              existing.meet_and_greet_dom.airport_list,
              period.meet_and_greet_dom.airport_list
            );

            comparedFlightsMNGDom = compare(
              existing.meet_and_greet_dom.flight_list,
              period.meet_and_greet_dom.flight_list
            );
            meet_and_greet_dom = comparedAirportMNGDom && comparedFlightsMNGDom;
          } else if (
            !existing.hasOwnProperty("meet_and_greet_dom") &&
            period.hasOwnProperty("meet_and_greet_dom")
          ) {
            meet_and_greet_dom = false;
          }

          if (
            existing.hasOwnProperty("meet_and_greet_inter") &&
            period.hasOwnProperty("meet_and_greet_inter")
          ) {
            let comparedAirportMNGInter, comparedFlightsMNGInter;
            comparedAirportMNGInter = compare(
              existing.meet_and_greet_inter.airport_list,
              period.meet_and_greet_inter.airport_list
            );

            comparedFlightsMNGInter = compare(
              existing.meet_and_greet_inter.flight_list,
              period.meet_and_greet_inter.flight_list
            );
            meet_and_greet_inter =
              comparedAirportMNGInter && comparedFlightsMNGInter;
          } else if (
            !existing.hasOwnProperty("meet_and_greet_inter") &&
            period.hasOwnProperty("meet_and_greet_inter")
          ) {
            meet_and_greet_inter = false;
          }

          if (
            existing.hasOwnProperty("fast_track") &&
            period.hasOwnProperty("fast_track")
          ) {
            let comparedAirportFastTrack, comparedFlightsFastTrack;
            comparedAirportFastTrack = compare(
              existing.fast_track.airport_list,
              period.fast_track.airport_list
            );

            comparedFlightsFastTrack = compare(
              existing.fast_track.flight_list,
              period.fast_track.flight_list
            );
            fast_track = comparedAirportFastTrack && comparedFlightsFastTrack;
          } else if (
            !existing.hasOwnProperty("fast_track") &&
            period.hasOwnProperty("fast_track")
          ) {
            fast_track = false;
          }

          if (
            existing.hasOwnProperty("lounge") &&
            period.hasOwnProperty("lounge")
          ) {
            let comparedAirportLounge = compare(
              existing.lounge.airport_list,
              period.lounge.airport_list
            );
            lounge = comparedAirportLounge;
          } else if (
            !existing.hasOwnProperty("lounge") &&
            period.hasOwnProperty("lounge")
          ) {
            lounge = false;
          }

          if (
            existing.hasOwnProperty("transport") &&
            period.hasOwnProperty("transport")
          ) {
            let comparedAirportTransport = compare(
              existing.transport.airport_list,
              period.transport.airport_list
            );
            transport = comparedAirportTransport;
          } else if (
            !existing.hasOwnProperty("transport") &&
            period.hasOwnProperty("transport")
          ) {
            transport = false;
          }

          if (
            existing.hasOwnProperty("transfer") &&
            period.hasOwnProperty("transfer")
          ) {
            let comparedAirportTransfer, comparedFlightsTransfer;
            comparedAirportTransfer = compare(
              existing.transfer.airport_list,
              period.transfer.airport_list
            );

            comparedFlightsTransfer = compare(
              existing.transfer.flight_list,
              period.transfer.flight_list
            );
            transfer = comparedAirportTransfer && comparedFlightsTransfer;
          } else if (
            !existing.hasOwnProperty("transfer") &&
            period.hasOwnProperty("transfer")
          ) {
            transfer = false;
          }

          return (
            meet_and_greet_dom &&
            meet_and_greet_inter &&
            transfer &&
            lounge &&
            fast_track &&
            transport
          );
        }
      }
    );
    if (comparedExistingPeriodWithNewPeriod?.length) {
      toastError(`${t("product.sameServicePeriod")}!`);
      isNotValid = true;
      return;
    }

    if (!isNotValid) {
      formik?.setFieldValue(
        `type_list[${idType}].period_list`,
        type_list?.period_list
      );
      toggle(toggle);
    }
  };

  const handleCancelPrice = (formik) => {
    let i = formik?.values?.type_list[idType]?.period_list?.length - 1;
    if (
      (indexCancel === null || indexCancel === undefined) &&
      (
        formik?.values?.type_list[idType]?.period_list[index]?.start_date ||
        formik?.values?.type_list[idType]?.period_list[index]?.end_date ||
        formik?.values?.type_list[idType]?.period_list[index]?.activate_renewal ||
        formik?.values?.type_list[idType]?.period_list[index]?.renewal_start_date ||
        formik?.values?.type_list[idType]?.period_list[index]?.renewal_end_date ||
        formik?.values?.type_list[idType]?.period_list[index]?.is_checked_meet_dom ||
        formik?.values?.type_list[idType]?.period_list[index]?.is_checked_meet_inter ||
        formik?.values?.type_list[idType]?.period_list[index]?.is_checked_fast ||
        formik?.values?.type_list[idType]?.period_list[index]?.is_checked_transfer ||
        formik?.values?.type_list[idType]?.period_list[index]?.is_checked_lounge ||
        formik?.values?.type_list[idType]?.period_list[index]?.is_checked_transport
      )
    ) {
      let period_list = formik?.values?.type_list[idType]?.period_list;
      period_list.splice(i, 1);
      formik?.setFieldValue(`type_list[${idType}].period_list`, period_list);
      toggle();
    } else if (
      indexCancel === null
      && formik?.values?.type_list[idType]?.period_list[index]?.start_date === undefined
      && formik?.values?.type_list[idType]?.period_list[index]?.end_date === undefined
      && formik?.values?.type_list[idType]?.period_list[index]?.activate_renewal === undefined
      && formik?.values?.type_list[idType]?.period_list[index]?.renewal_start_date === undefined
      && formik?.values?.type_list[idType]?.period_list[index]?.renewal_end_date === undefined
      && formik?.values?.type_list[idType]?.period_list[index]?.is_checked_meet_dom === undefined
      && formik?.values?.type_list[idType]?.period_list[index]?.is_checked_meet_inter === undefined
      && formik?.values?.type_list[idType]?.period_list[index]?.is_checked_fast === undefined
      && formik?.values?.type_list[idType]?.period_list[index]?.is_checked_transfer === undefined
      && formik?.values?.type_list[idType]?.period_list[index]?.is_checked_lounge === undefined
      && formik?.values?.type_list[idType]?.period_list[index]?.is_checked_transport === undefined
    ) {
      toggle();
    } else {
      formik?.setFieldValue(`type_list[${idType}].period_list`, lastPriceValue);
      toggle();
    }
  };

  return (
    <>
      <div className="menu-container">
        <div className="grid-header">
          <div className="side-menu" style={{ width: 300 }}>
            <div className="menu-title text-extra-bold">
              <div className="side-menu-back-icon ">
                <span
                  className="material-icons clickable"
                  onClick={() => handleCancelPrice(formik)}
                >
                  arrow_back
                </span>
              </div>
              {indexCancel !== null
                ? `${t("commons.edit")} ${t("field.price")}`
                : `${t("commons.set")} ${t("field.price")}`}
            </div>
            <div style={{ padding: "0px 15px 30px 10px" }}>
              <Card className="rounded">
                <CardFlush
                  sideMenu
                  title={t("field.corporateName")}
                  desc={formik?.values?.name}
                  leftIcon="assignment_ind"
                />
                <CardFlush
                  sideMenu
                  title={t("field.cooperationType")}
                  desc={formik?.values?.type_list[idType]?.corporate_type}
                  leftIcon="swap_horiz"
                />
              </Card>
            </div>
          </div>
          <div className="side-menu-detail">
            <Container>
              <HeaderMenu
                title={t("commons.details")}
              />
              <Card className="rounded mx-3">
                <CardHeader title={t("product.period")} />
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-bold">{t("promo.startDate")}</div>
                    <Col md={8}>
                      <Input
                        disabled={formik?.values?.type_list[idType]?.period_list[index]?.activate_renewal == 1}
                        name={`type_list[${idType}].period_list[${index}].start_date`}
                        type="date"
                        errors={formik.errors}
                        touched={formik.touched}
                        value={
                          formik?.values?.type_list[idType]?.period_list[index]?.start_date ?? ""
                        }
                        onChange={(e) => {
                          let value = e.target.value;
                          formik?.setFieldValue(
                            `type_list[${idType}].period_list[${index}].start_date`,
                            value
                          );
                        }}
                      />
                    </Col>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-bold">{t("promo.endDate")}</div>
                    <Col md={8}>
                      <Input
                        disabled={formik?.values?.type_list[idType]?.period_list[index]?.activate_renewal == 1}
                        name={`type_list[${idType}].period_list[${index}].end_date`}
                        type="date"
                        errors={formik.errors}
                        touched={formik.touched}
                        value={
                          formik?.values?.type_list[idType]?.period_list[index]?.end_date ?? ""
                        }
                        onChange={(e) => {
                          let value = e.target.value;
                          formik?.setFieldValue(
                            `type_list[${idType}].period_list[${index}].end_date`,
                            value
                          );
                        }}
                      />
                    </Col>
                  </div>
                </CardBody>
              </Card>
              {id && (
                <Card className="rounded mx-3">
                  <CardHeader title={t("product.renewal")} />
                  <CardBody>
                    <div className="checkbox-wrapper mt-0">
                      <Checkbox
                        name={`type_list[${idType}].period_list[${index}].activate_renewal`}
                        checked={formik?.values?.type_list[idType].period_list[index]?.activate_renewal}
                        onChange={(e) => {
                          if (formik?.values?.type_list[idType].period_list[index]?.activate_renewal) {
                            formik?.setFieldValue(
                              `type_list[${idType}].period_list[${index}].activate_renewal`, 
                              null
                            );
                            formik?.setFieldValue(
                              `type_list[${idType}].period_list[${index}].is_renewal`, 
                              false
                            );
                          } else {
                            formik?.setFieldValue(
                              `type_list[${idType}].period_list[${index}].activate_renewal`, 
                              ["1"]
                            );
                            formik?.setFieldValue(
                              `type_list[${idType}].period_list[${index}].is_renewal`, 
                              true
                            );
                          }
                        }}
                        value={1}
                        label={t("product.activate_renewal")}
                      />
                    </div>
                    {formik?.values?.type_list[idType]?.period_list[index]?.activate_renewal == 1 && (
                      <>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="text-bold">{t("promo.startDate")}</div>
                        <Col md={8} className="pr-0">
                          <Input
                            name={`type_list[${idType}].period_list[${index}].renewal_start_date`}
                            type="date"
                            errors={formik.errors}
                            touched={formik.touched}
                            value={
                              formik?.values?.type_list[idType]?.period_list[index]?.renewal_start_date ?? ""
                            }
                            onChange={(e) => {
                              let value = e.target.value;
                              formik?.setFieldValue(
                                `type_list[${idType}].period_list[${index}].renewal_start_date`,
                                value
                              );
                            }}
                          />
                        </Col>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="text-bold">{t("promo.endDate")}</div>
                        <Col md={8} className="pr-0">
                          <Input
                            name={`type_list[${idType}].period_list[${index}].renewal_end_date`}
                            type="date"
                            errors={formik.errors}
                            touched={formik.touched}
                            value={
                              formik?.values?.type_list[idType]?.period_list[index]?.renewal_end_date ?? ""
                            }
                            onChange={(e) => {
                              let value = e.target.value;
                              formik?.setFieldValue(
                                `type_list[${idType}].period_list[${index}].renewal_end_date`,
                                value
                              );
                            }}
                          />
                        </Col>
                      </div>
                      </>
                    )}
                  </CardBody>
                </Card>
              )}
              <Card className="rounded mx-3">
                <CardHeader title={t("field.product")} />
                <CardBody>
                  <div
                    className="content-container-side"
                    style={{ padding: "0" }}
                  >
                    <div className="tab-menu overflow">
                      <div style={{ width: "fit-content" }}>
                        <Tabs
                          scrollButtons="auto"
                          variant="scrollable"
                          value={activeTab}
                          textColor="primary"
                          indicatorColor="primary"
                          onChange={(event, newValue) => {
                            setActiveTab(newValue);
                          }}
                        >
                          <Tab
                            label={`${t("product.meetandgreet")} Domestic`}
                            value="0"
                          />
                          <Tab
                            label={`${t("product.meetandgreet")} International`}
                            value="1"
                          />
                          <Tab label={t("product.fasttrack")} value="2" />
                          <Tab label="Transfer" value="3" />
                          <Tab label="Lounge" value="4" />
                          <Tab label="Transport" value="5" />
                        </Tabs>
                      </div>
                    </div>
                    <ShowTabProduct
                      props={formik}
                      active={activeTab}
                      idType={idType}
                      index={index}
                      toggle={toggle}
                      dataAirport={dataAirport}
                    />
                  </div>
                </CardBody>
              </Card>
              <div className="px-3">
                <ButtonModal
                  toggle={() => handleCancelPrice(formik)}
                  confirm={() => handleAddPrice(formik)}
                  cancelTitle={t("commons.cancel")}
                  confirmTitle={t("commons.save")}
                  typeConfirm="Button"
                />
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTrans(AddPriceCorporate);
