import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUser } from "../../utils/User";
import {
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import moment from "moment";
import ButtonModal from "../../components/modal/ButtonModal";
import CardFlush from "../../components/cards/cardFlush";
import PromoModal from "./FormPromoModal";
import SideMenu from "../../components/modal/SideModal";
import ModalImage from "./ModalImage";
import ModalUsage from "./ModalUsage";
import { toRupiah } from "../../utils/Helper";
import { withTrans } from "../../i18n/withTrans";

//Service
import PromoService from "../../store/actions/promo";
import ProductService from "../../store/actions/master_data/product";

const DetailPromoModal = ({
  t,
  show,
  toggle,
  data,
  dataAirport,
  dataProduct,
  detailPromo,
  detailProduct = [],
  refresh,
}) => {
  const dispatch = useDispatch();
  const currentUser = getUser();

  const [editModal, setEditModal] = useState(false);
  const [showModalImage, setShowModalImage] = useState(false);
  const [showModalUsage, setShowModalUsage] = useState(false);
  const [haveAirport, setHaveAirport] = useState(
    currentUser?.user.role_code === "admin_bandara" 
      ? true 
    : currentUser?.user.role_code === "supervisor" 
      ? true
    : currentUser?.user.role_code === "finance_bandara" 
      ? true
    : currentUser?.user.role_code === "sales_bandara" 
      ? true
    : currentUser?.user.role_code === "assistant" 
      ? true
      : false
  );

  const showEditModal = () => {
    setEditModal(!editModal);
  };

  const handleEdit = (param) => {
    const callback = () => {
      refresh();
      setEditModal(!editModal);
      toggle();
    };
    dispatch(PromoService.put(param, callback));
  };

  const handleModalImage = () => {
    setShowModalImage(true);
  };

  const handleModalUsage = () => {
    setShowModalUsage(true);
  };

  const arrayAirport = [];
  const arrayAirportCrossSelling = [];
  const arrayProduct = [];

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const airport_list = data?.list_airports_detail?.map((airport) => ({
    value: airport?.id,
    label: `${airport?.name}`,
    name: `${airport?.name}`,
  }));

  const product_list = dataProduct.map((product) => ({
    value: product?.id,
    label: product?.name_eng,
    name: product?.name_eng
  }));

  return (
    <>
      <Modal isOpen={show}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {`Detail`}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Container className="px-3">
          <ModalBody className="px-4">
            <div className="h5 pb-30 text-extra-bold">{data?.name}</div>
            {data?.type === "specific" ? (
              <div className="wrapper-detail-card">
                {
                  (detailPromo = [
                    {
                      label: t("promo.promoType"),
                      desc: t("promo.special"),
                    },
                    {
                      label: t("promo.typePeriodPromo"),
                      desc: data?.is_service_date_period ? t("report.serviceDate") : t("report.bookingDate"),
                    },
                    {
                      label: data?.is_service_date_period ? t("promo.serviceStartDate") : t("promo.bookingStartDate"),
                      desc: moment(data?.period_start).format("DD/MM/YYYY"),
                    },
                    {
                      label: data?.is_service_date_period ? t("promo.serviceEndDate") : t("promo.bookingEndDate"),
                      desc: moment(data?.period_end).format("DD/MM/YYYY"),
                    },
                    {
                      label: t("promo.promoValue"),
                        desc:
                        data?.discount_price > 0
                          ? "Rp " + numberWithCommas(data?.discount_price)
                          : data?.discount_percent + "%",
                    },
                    {
                      label: t("promo.promoCode"),
                      desc: data?.code,
                    },
                    {
                      label: t("promo.numberOfPromo"),
                      desc: data?.quantity,
                    },
                  ].map((detailUser, index) => (
                    <CardFlush
                      list
                      key={index}
                      label={detailUser.label}
                      desc={detailUser.desc}
                      last={index === 4}
                    />
                  )))
                }
              </div>
            ) : (
              <div className="wrapper-detail-card">
                {
                  (detailPromo = [
                    {
                      label: t("promo.promoType"),
                      desc:
                        data?.type === "blast"
                          ? data?.type?.charAt(0).toUpperCase() + data?.type?.slice(1)
                          : t("field.cross_selling"),
                    },
                    {
                      label: t("promo.typePeriodPromo"),
                      desc: data?.is_service_date_period ? t("report.serviceDate") : t("report.bookingDate"),
                    },
                    {
                      label: data?.is_service_date_period ? t("promo.serviceStartDate") : t("promo.bookingStartDate"),
                      desc: moment(data?.period_start).format("DD/MM/YYYY"),
                    },
                    {
                      label: data?.is_service_date_period ? t("promo.serviceEndDate") : t("promo.bookingEndDate"),
                      desc: moment(data?.period_end).format("DD/MM/YYYY"),
                    },
                    {
                      label:
                        data?.type === "cross-selling" 
                          ? t("promo.labelPromoValueService") 
                          : t("promo.promoValue"),
                      desc:
                        data?.discount_price > 0
                          ? "Rp " + numberWithCommas(data?.discount_price)
                          : data?.discount_percent + "%",
                    },
                    {
                      label: t("promo.promoCode"),
                      desc: data?.code,
                    },
                  ].map((detailUser, index) => (
                    <CardFlush
                      list
                      key={index}
                      label={detailUser.label}
                      desc={detailUser.desc}
                      last={index === 5}
                    />
                  )))
                }
              </div>
            )}

            <div className="wrapper-detail-card">
              {data.list_products
                ? data.list_products.map((item, index) => {
                  const idx = product_list
                    ?.map(function (e) {
                      return e.value;
                    })
                    .indexOf(item);
                  if (idx > -1) {
                    arrayProduct.push(product_list[idx]);
                  }
                })
                : ""}
              {
                (detailPromo = [
                  {
                    label: t("field.product"),
                    desc: `${arrayProduct.label}` ?? " ",
                  },
                ].map((detailPromo, index) => (
                  <CardFlush
                    multipleList={arrayProduct}
                    key={index}
                    label={detailPromo.label}
                    desc={detailPromo.desc}
                  />
                )))
              }
            </div>
            {data?.type === "cross-selling" && (
              <div className="wrapper-detail-card">
                {data.list_airport_cross_selling
                  ? data.list_airport_cross_selling.forEach((item, index) => {
                    arrayAirportCrossSelling.push({
                      value: item?.airport_id,
                      label: `${item?.airport_detail?.code}-${item?.airport_detail?.city}-${item?.airport_detail?.name}`,
                      name: `${item?.airport_detail?.code}-${item?.airport_detail?.city}-${item?.airport_detail?.name}`,
                    });
                  })
                  : ""}
                {
                  (detailPromo = [
                    {
                      label: t("promo.airportCrossSelling"),
                      desc: `${arrayAirportCrossSelling.label}` ?? " ",
                    },
                  ].map((detailPromo, index) => (
                    <CardFlush
                      multipleList={arrayAirportCrossSelling}
                      key={index}
                      label={detailPromo.label}
                      desc={detailPromo.desc}
                    />
                  )))
                }
              </div>

            )}
            <div className="wrapper-detail-card">
              {data.list_airports_detail
                ? data.list_airports_detail?.map((item, index) => {
                  const idx = airport_list
                    ?.map(function (e) {
                      return e.value;
                    })
                    .indexOf(item?.id);
                  if (idx > -1) {
                    arrayAirport.push(airport_list[idx]);
                  }
                })
                : ""}
              {
                (detailPromo = [
                  {
                    label: t("field.airport"),
                    desc: `${arrayAirport.label}` ?? " ",
                  },
                ].map((detailPromo, index) => (
                  <CardFlush
                    multipleList={arrayAirport}
                    key={index}
                    label={detailPromo.label}
                    desc={detailPromo.desc}
                  />
                )))
              }
            </div>
            <div className="wrapper-detail-card">
              {
                (detailPromo = [
                  {
                    label: t("promo.description"),
                    desc: data?.description,
                  },
                ].map((detailPromo, index) => (
                  <CardFlush
                    descList
                    key={index}
                    label={detailPromo.label}
                    desc={detailPromo.desc}
                  />
                )))
              }
            </div>

            <div className="wrapper-detail-card">
              {data.list_details
                ? data.list_details.map((item, index) => {
                  detailProduct.push({
                    label: item.product_name_eng,
                    desc: toRupiah(item.price),
                  });
                })
                : ""}
              {detailProduct.map((item, index) => (
                <CardFlush
                  list
                  key={index}
                  label={item.label}
                  desc={item.desc}
                />
              ))}
            </div>
            <SideMenu
              title={t("promo.showBanner")}
              icon="image"
              toggle={() => handleModalImage()}
            />
            <SideMenu
              title={t("promo.showUsage")}
              icon="local_offer"
              toggle={() => handleModalUsage()}
            />
          </ModalBody>
          <ModalFooter>
            {haveAirport && data?.type === "cross-selling" ? (
              <></>
            ) : currentUser?.user?.role_code !== "contact_center" &&
              currentUser?.user?.role_code !== "lead_contact_center" &&
              currentUser?.user?.role_code !== "supervisor" ? (
              <ButtonModal
                toggle={toggle}
                cancelTitle={t("commons.close")}
                confirmTitle="Edit"
                confirm={showEditModal}
              />
            ) : (
              <></>
            )}
          </ModalFooter>
        </Container>
      </Modal>
      <PromoModal
        show={editModal}
        toggle={showEditModal}
        action={handleEdit}
        data={data}
        dataAirport={dataAirport}
        dataProduct={dataProduct}
      />
      <ModalImage
        show={showModalImage}
        toggle={() => setShowModalImage(false)}
        image={
          data.banner !== null
          ? process.env.REACT_APP_API_URL + data.banner?.substr(1, data.banner?.length)
          : null
        }
      />
      <ModalUsage
        show={showModalUsage}
        toggle={() => setShowModalUsage(false)}
        data={data?.list_orders}
        title={t("promo.usage")}
      />
    </>
  );
};

export default withTrans(DetailPromoModal);
