import { actionPending, actionSuccess, actionError } from "../../actionTypes";

import { toastSuccess, toastError } from "../../../../components/commons/toast";

import API from "../../API";
import { handleResponse } from "../../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_COMMISSION_PENDING = "GET_COMMISSION_PENDING";
export const GET_COMMISSION_SUCCESS = "GET_COMMISSION_SUCCESS";
export const GET_COMMISSION_ERROR = "GET_COMMISSION_ERROR";
export const POST_COMMISSION_PENDING = "POST_COMMISSION_PENDING";
export const POST_COMMISSION_SUCCESS = "POST_COMMISSION_SUCCESS";
export const POST_COMMISSION_ERROR = "POST_COMMISSION_ERROR";
export const PUT_COMMISSION_PENDING = "PUT_COMMISSION_PENDING";
export const PUT_COMMISSION_SUCCESS = "PUT_COMMISSION_SUCCESS";
export const PUT_COMMISSION_ERROR = "PUT_COMMISSION_ERROR";
export const DELETE_COMMISSION_PENDING = "DELETE_COMMISSION_PENDING";
export const DELETE_COMMISSION_SUCCESS = "DELETE_COMMISSION_SUCCESS";
export const DELETE_COMMISSION_ERROR = "DELETE_COMMISSION_ERROR";

// URL: URL_{URL}
const COMMISSION_URL = `v2/master_data/commission`;

const lang = localStorage.getItem("joumpa_language");

const get = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(GET_COMMISSION_PENDING));
    API.get(COMMISSION_URL, { params: param })
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_COMMISSION_SUCCESS, res));
            if (resolve) {
                const records_total = res.data.total;
                let data = res.data.data.map((item, i) => ({
                    ...item,
                    no: i + 1 + (param?.page - 1) * param?.length,
                    passenger_type:
                        lang === "id"
                            ? item?.passenger_type_label
                            : item?.passenger_type_label_eng,
                    type_label:
                        lang === "id"
                            ? item?.type_label
                            : item?.type_label_eng,
                }));
                resolve({
                    data: data,
                    page: param?.page - 1,
                    totalCount: records_total,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(GET_COMMISSION_ERROR));
            let not_logout = handleResponse(err?.response);
            if (not_logout) {
                if (lang === 'id') {
                  toastError(err?.response?.data?.message);
                } else if (lang === "en-US" || lang === "en") {
                  toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
                }
            }
        });
};

const post = (param, resolve, callback) => (dispatch) => {
    dispatch(actionPending(POST_COMMISSION_PENDING));
    API.post(COMMISSION_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(POST_COMMISSION_SUCCESS, res));
            toastSuccess(
                lang === "id" ? "Berhasil Tambah Komisi" : "Add Commission Successful"
            );
            if (resolve) {
                resolve({
                    data: res.data.data,
                    message: res.data.message,
                    status: res.data.status,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(POST_COMMISSION_ERROR));
            if (lang === 'id') {
              toastError(err?.response?.data?.message);
            } else if (lang === "en-US" || lang === "en") {
              toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
            }
        });
};


const put = (param, resolve, callback) => (dispatch) => {
    dispatch(actionPending(PUT_COMMISSION_PENDING));
    API.put(COMMISSION_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(PUT_COMMISSION_SUCCESS, res));
            toastSuccess(
                lang === "id"
                    ? "Berhasil Ubah Komisi"
                    : "Change Commission Data Successful"
            );
            if (resolve) {
                resolve({
                    data: res.data.data,
                    message: res.data.message,
                    status: res.data.status,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(PUT_COMMISSION_ERROR));
            if (lang === 'id') {
              toastError(err?.response?.data?.message);
            } else if (lang === "en-US" || lang === "en") {
              toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
            }
        });
};

const del = (param, callback) => (dispatch) => {
    dispatch(actionPending(DELETE_COMMISSION_PENDING));
    API.post(COMMISSION_URL + `?id=${param.id}`)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(DELETE_COMMISSION_SUCCESS, res));
            toastSuccess(
                lang === "id"
                    ? `Komisi telah dihapus`
                    : "Commission has been deleted"
            );
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(DELETE_COMMISSION_ERROR));
            if (lang === 'id') {
              toastError(err?.response?.data?.message);
            } else if (lang === "en-US" || lang === "en") {
              toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
            }
        });
};

const commission = {
    get,
    post,
    put,
    del,
};
export default commission;
