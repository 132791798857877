import { useState, useEffect, useRef } from "react";
import { Row, Col } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import NumberFormat from "react-number-format";
import { Divider } from "@mui/material";
import * as XLSX from "xlsx";
import Compressor from "compressorjs";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import Card from "../../../components/cards/card";
import CardHeader from "../../../components/cards/cardHeader";
import CardBody from "../../../components/cards/cardBody";
import Checkbox from "../../../components/forms/Checkbox";
import Input from "../../../components/forms/Input";
import Select2 from "../../../components/forms/Select2";
import RadioButton from "../../../components/forms/RadioButton";
import Button from "../../../components/buttons/Button";
import CardFlush from "../../../components/cards/cardFlush";
import ConfirmationModal from "../../../components/modal/ConfirmModal";
import ConfirmDeleteImport from "../../../components/modal/ConfirmModal";
import PagingLogoModal from "./modal/PagingLogoModal";
import DynamicButton from "../../../components/forms/DynamicButton";
import { toastError } from "../../../components/commons/toast";
import PassengerModal from "./modal/PassengerModal";

import { withTrans } from "../../../i18n/withTrans";
import { getUser } from "../../../utils/User";
import { countries } from "../../../utils/Constants";
import { EXPORT_FILE_BASE64 } from "../../../utils/FormatExcelPassenger";

import Corp_Reservation from "../../../store/actions/corp_reservation";

const PassengerReservation = ({
  formik,
  button,
  setErrorAvailabilityPrice,
  t,
  error_message,
  error_message_eng,
}) => {
  const dispatch = useDispatch();
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmModalDeleteImport, setConfirmModalDeleteImport] = useState(false);
  const [showPagingLogo, setShowPagingLogo] = useState(false);
  const [modalPass, setModalPass] = useState(false);
  const [indexPassenger, setIndexPassenger] = useState(null);
  const [dataCountries, setDataCountries] = useState(
    countries?.map((item) => ({
      label: item.name,
      value: item.name,
      countryCode: item.code?.toLowerCase(),
    }))
  );
  const fileRef = useRef();
  const imageRef = useRef();
  const currentUser = getUser();
  const lang = localStorage.getItem("joumpa_language");

  const passengersFromImport = formik?.values?.passenger_list?.filter(
    (item) => item?.isFromExcel === true
  );
  const passengersFromInput = formik?.values?.passenger_list?.filter(
    (item) => item?.isFromExcel !== true
  );

  let isValid = formik?.values?.passenger_list?.length;

  let airport_selected =
    formik.values.flight_type === 0
      ? formik.values.airport_id_to
      : formik.values.flight_type === 1
      ? formik.values.airport_id_from
      : formik.values.airport_id_transfer;

  useEffect(() => {
    new Promise((resolve) => {
      // const adult_total =
      //   formik?.values?.passenger_list?.filter(
      //     (item) => item?.title === "Mrs." || item?.title === "Mr." || item?.title === "Ms."
      //   )?.length;
      // const child_total =
      //   formik?.values?.passenger_list?.filter((item) => item?.title === "Child")?.length;
      // const infant_total =
      //   formik?.values?.passenger_list?.filter((item) => item?.title === "Infant")?.length;
      let param = {
        corporate_id: formik.values?.corporate_id,
        airport_service_id: airport_selected,
        flight_type: formik.values?.flight_type_code,
        service_date: formik?.values?.date,
        product_name: formik?.values?.product_type,
        is_mob: formik?.values?.corporateType === "MOB" ? true : false,
        adult_total: formik?.values?.total_passenger?.adult,
        child_total: formik?.values?.total_passenger?.child,
        infant_total: formik?.values?.total_passenger?.infant,
      };
      if (
        formik?.values?.passenger_list?.length &&
        formik.values?.corporate_id &&
        airport_selected &&
        formik.values?.flight_type_code &&
        formik?.values?.date &&
        formik?.values?.product_type
      ) {
        dispatch(Corp_Reservation.findAvailPrice(param, resolve));
      }
    })
    .then((res) => {
      if (res && res?.data) {
        setErrorAvailabilityPrice(null);
        formik.setFieldValue(
          "corporate_id",
          res?.data?.id
        );

        if (res?.data?.type_list?.length < 2) {
          formik.setFieldValue(
            "property_list",
            []
          );
          formik.setFieldValue(
            "corporate_type_id",
            res?.data?.type_list[0]?.id
          );
          formik.setFieldValue(
            "corporateType",
            res?.data?.type_list[0]?.corporate_type
          );
          formik.setFieldValue(
            "price_period_id",
            res?.data?.type_list[0]?.period_list[0]?.id
          );
          
          const product =
            res?.data?.type_list[0]?.period_list[0]?.product_list?.find(
              (item) => item?.product_name === formik?.values?.product_type
            );
          formik?.setFieldValue("product_list_id", product?.id);
          formik?.setFieldValue("product_code", product?.product_name);

          const lounge =
            res?.data?.type_list[0]?.period_list[0]?.product_list?.find(
              (item) => item?.product_name === 5
            );
          if (lounge) {
            if (lounge?.corporate_lounge_list?.length) {
              formik?.setFieldValue(
                "loungePrice",
                lounge?.corporate_lounge_list
              );
    
            } else if (lounge?.lounge_list?.length) {
              formik?.setFieldValue(
                "loungePrice",
                lounge?.lounge_list?.filter((item) => item?.airport_id === airport_selected)
              );
            }      
          } else {
            formik?.setFieldValue("loungePrice", null);
          }

          const transport =
            res?.data?.type_list[0]?.period_list[0]?.product_list?.find(
              (item) => item?.product_name === 6
            );
          if (transport) {
            formik?.setFieldValue(
              "productTransports",
              transport?.transport_list
            );
          } else {
            formik?.setFieldValue("productTransports", null);
          }

          if (res?.data?.type_list[0]?.corporate_type !== "Group") {
            formik.setFieldValue(
              "corporate_type_group_id",
              product?.group_list[0]?.id
            );
            formik.setFieldValue(
              "corporate_type_group_property_id",
              product?.group_list[0]?.property_list[0]?.id
            );
            formik.setFieldValue(
              "corporate_price_id",
              product?.group_list[0]?.property_list[0]?.prices?.id
            );
            formik.setFieldValue(
              "group_list",
              []
            );
          } else {
            formik.setFieldValue(
              "corporate_type_group_id",
              ""
            );
            formik.setFieldValue(
              "group_list",
              product?.group_list?.map((item) => ({
                ...item,
                value: item?.id,
                label: `${item?.group_type}`,
              }))
            );
          }
        } else {
          formik.setFieldValue("group_list", []);

          const dataProperty = [];
          let loungePrice;

          res?.data?.type_list?.forEach((item) => {
            const product =
              item?.period_list[0]?.product_list?.find(
                (product) => product?.product_name === formik?.values?.product_type
              );
            const lounge =
              item?.period_list[0]?.product_list?.find(
                (product) => product?.product_name === 5
              );
            const transport =
              item?.period_list[0]?.product_list?.find(
                (product) => product?.product_name === 6
              );
            if (lounge) {
              if (lounge?.corporate_lounge_list?.length) {
                loungePrice = lounge?.corporate_lounge_list.sort((a, b) => a.name.localeCompare(b.name));
              } else if (lounge?.lounge_list?.length) {
                loungePrice = lounge?.lounge_list?.filter(
                  (x) => x?.airport_id === airport_selected
                ).sort((a, b) => a.name.localeCompare(b.name));;
              }      
            }

            product?.group_list?.forEach((group) => {
              dataProperty?.push(
                {
                  key: group?.group_type,
                  value: group?.id,
                  corporate_type_id: item?.id,
                  corporate_type: item?.corporate_type,
                  price_period_id: item?.period_list[0]?.id,
                  product_list_id: product?.id,
                  product_code: product?.product_name,
                  corporate_type_group_property_id: group?.property_list[0]?.id,
                  corporate_price_id: group?.property_list[0]?.prices?.id,
                  productTransports: transport 
                    ? transport?.transport_list.sort((a, b) => a.car_name.localeCompare(b.car_name))
                    : [],
                  loungePrice : lounge ? loungePrice : [],
                  label: item?.corporate_type === "Package" 
                    ? `${item?.corporate_type} - (${group?.property_list[0]?.min_pax} - ${group?.property_list[0]?.max_pax} pax)`
                    : `${group?.group_type} - (${group?.property_list[0]?.min_pax} - ${group?.property_list[0]?.max_pax} pax)`
                }
              )
            });
          });

          if (
            JSON.stringify(formik?.values?.property_list) !== 
              JSON.stringify(dataProperty.sort((a, b) => a.key.localeCompare(b.key)))
          ) {
            formik.setFieldValue("corporate_type_group_id", "");
            formik.setFieldValue(
              "property_list",
              dataProperty.sort((a, b) => a.key.localeCompare(b.key))
            );
          } 
        }
      }
    });
  }, [
    formik?.values?.corporateType !== undefined,
    formik?.values?.date,
    formik?.values?.product_type !== undefined,
    formik?.values?.flight_type_code,
    formik?.values?.total_passenger,
    airport_selected,
  ]);

  useEffect(() => {
    if (error_message || error_message_eng) {
      formik.setFieldValue(
        "property_list",
        []
      );
      formik.setFieldValue(
        "group_list",
        []
      );
      setErrorAvailabilityPrice(lang === "id" ? error_message : error_message_eng);
    }
  }, [error_message, error_message_eng]);

  useEffect (() => {
    if (formik?.values?.bookers !== "firstPassenger") {
      return;
    }

    if (formik?.values?.passenger_list?.length === 0) {
      formik?.setFieldValue("bookers_title", "");
      formik?.setFieldValue("bookers_name", "");
      formik?.setFieldValue("bookers_nationality", "");
      formik?.setFieldValue("bookers_email", "");
      formik?.setFieldValue("bookers_phone", "");
    } else {
      formik?.setFieldValue(
        "bookers_title",
        formik?.values?.passenger_list[0]?.title
      );
      formik?.setFieldValue(
        "bookers_name",
        formik?.values?.passenger_list[0]?.name
      );
      formik?.setFieldValue(
        "bookers_nationality",
        formik?.values?.passenger_list[0]
          ?.passenger_nationality
      );
      formik?.setFieldValue(
        "bookers_email",
        formik?.values?.passenger_list[0]?.email
      );
      formik?.setFieldValue(
        "bookers_phone",
        formik?.values?.passenger_list[0]?.phoneNumber
      );
      formik?.setFieldValue(
        "bookers_country_code",
        formik?.values?.passenger_list[0]?.countryCode
      );
      formik?.setFieldValue(
        "bookers_country_id",
        formik?.values?.passenger_list[0]?.countryId
      );
    }
  }, [
    formik?.values?.bookers,
    formik?.values?.passenger_list[0],
    formik?.values?.passenger_list?.length
  ]);

  const showModalPass = () => {
    setModalPass(false);
  };

  const handleAddPassenger = (index, categoryPass) => {
    if (index !== null && index !== undefined) {
      if (categoryPass === "input") {
        const idxPassengerInput = formik?.values?.passenger_list?.findIndex(
          (item) => item === passengersFromInput?.find((item, x) => x === index)
        );
        setIndexPassenger(idxPassengerInput);
      } else {
        const idxPassengerImport = formik?.values?.passenger_list?.findIndex(
          (item) =>
            item === passengersFromImport?.find((item, x) => x === index)
        );
        setIndexPassenger(idxPassengerImport);
      }
    } else {
      let passenger_list = formik?.values?.passenger_list ?? [];
      formik?.setFieldValue("passenger_list", [
        ...passenger_list,
        {
          email: "",
          name: "",
          phoneNumber: "",
          title: "",
          passenger_nationality: "",
          passenger_date_of_birth: "",
        },
      ]);
      setIndexPassenger(null);
    }
    setModalPass(true);
  };

  const openFileDialog = () => {
    fileRef.current.value = "";
    fileRef.current.click();
  };

  const readExcel = (file) => {
    let headerNotValid = false;
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const workbook = XLSX.read(bufferArray, { type: "buffer" });
        const worksheet = workbook.Sheets["Passengers"];
        const header = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          defval: ""
        })?.[0];
        
        if (!header?.includes('Country Code')) {
          headerNotValid = true;
        }

        const data = XLSX.utils.sheet_to_json(worksheet, {
          raw: false,
          dateNF: "yyyy-mm-dd",
        });

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      if (data?.length && !headerNotValid) {
        const convertedKeysData = data.map(el => 
          Object.fromEntries(Object.entries(el).map(([key, value]) => ([
            key.replace(/\s+/g, ""),
            value
          ])))
        );

        const list_passengers = convertedKeysData?.map((item) => {
          return {
            isFromExcel: true,
            title: item?.Title,
            name: item?.Name,
            passenger_date_of_birth: item?.Birthdate ? item?.Birthdate : "",
            passenger_nationality: item?.Nationality,
            email: item?.Email,
            countryId: 
              item?.CountryCode 
                ? item?.CountryCode?.split(" ")[1]?.replace("(", "").toLowerCase() 
                : "id",
            countryCode: 
              item?.CountryCode 
                ? item?.CountryCode?.split(" ")[0] 
                : 62,
            phoneNumber: 
              item?.CountryCode 
                ? `${item?.CountryCode?.split(" ")[0]}${
                    item?.Phone.charAt(0) == 0 ? item?.Phone?.substring(1) : item?.Phone
                  }` 
                : `62${item?.Phone}`,
          };
        });
        passengersFromInput?.forEach((item) => {
          list_passengers?.push(item);
        });
        const adult_total =
          list_passengers?.filter(
            (item) => item?.title === "Mrs." || item?.title === "Mr." || item?.title === "Ms."
          )?.length;
        const child_total =
          list_passengers?.filter((item) => item?.title === "Child")?.length;
        const infant_total =
          list_passengers?.filter((item) => item?.title === "Infant")?.length;

        formik?.setFieldValue("passenger_list", list_passengers);
        formik?.setFieldValue(
          "total_passenger",
          {
            adult: adult_total,
            child: child_total,
            infant: infant_total
          }
        );
      } else {
        toastError(t("booking.errorFormatFilePass"));
      }
    });
  };

  const handleDownloadExcel = () => {
    let sliceSize = 1024;
    let byteCharacters = atob(EXPORT_FILE_BASE64);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    const FileSaver = require("file-saver");
    FileSaver.saveAs(
      new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
      "Format Excel Passenger List.xlsx"
    );
  };

  const showConfirmaDelete = () => {
    setConfirmModal(!confirmModal);
  };

  const handleDeletePassengers = () => {
    const deleteAllPassengers = formik?.values?.passenger_list?.filter(
      (item) => item?.isFromExcel === true
    );
    const adult_total =
      deleteAllPassengers?.filter(
        (item) => item?.title === "Mrs." || item?.title === "Mr." || item?.title === "Ms."
      )?.length;
    const child_total =
      deleteAllPassengers?.filter((item) => item?.title === "Child")?.length;
    const infant_total =
      deleteAllPassengers?.filter((item) => item?.title === "Infant")?.length;

    formik?.setFieldValue("passenger_list", deleteAllPassengers);
    formik?.setFieldValue(
      "total_passenger",
      {
        adult: adult_total,
        child: child_total,
        infant: infant_total
      }
    );
    
    fileRef.current.value = "";
    setConfirmModal(!confirmModal);
  };

  const showConfirmaDeleteImport = () => {
    setConfirmModalDeleteImport(!confirmModalDeleteImport);
  };

  const handleDeleteImport = () => {
    const deletePassengerImport = formik?.values?.passenger_list?.filter(
      (item) => item?.isFromExcel !== true
    );
    const adult_total =
     deletePassengerImport?.filter(
        (item) => item?.title === "Mrs." || item?.title === "Mr." || item?.title === "Ms."
      )?.length;
    const child_total =
      deletePassengerImport?.filter((item) => item?.title === "Child")?.length;
    const infant_total =
      deletePassengerImport?.filter((item) => item?.title === "Infant")?.length;

    formik?.setFieldValue("passenger_list", deletePassengerImport);
    formik?.setFieldValue(
      "total_passenger",
      {
        adult: adult_total,
        child: child_total,
        infant: infant_total
      }
    );
    fileRef.current.value = "";
    setConfirmModalDeleteImport(!confirmModalDeleteImport);
  };

  const openFileDialogImage = () => {
    imageRef.current.click();
  };

  const handleChangeImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const image = e.target.files[0];
      const imageName = image.name;
      new Compressor(image, {
        quality: 0.6,
        success: (res) => {
          formik?.setFieldValue("paging_logo_url", URL.createObjectURL(res));
          const file = new File([res], imageName);
          formik?.setFieldValue("paging_logo", file);
          formik?.setFieldValue("imagePagingInfo", {
            title: imageName,
            subtitle: (Number(image.size) * 0.001).toFixed(2) + " KB",
          });
        },
        error(err) {
          console.log(err.message);
        },
      });
    }
  };

  return (
    <>
      <Row>
        <Col md={9}>
          <Card className="rounded">
            <CardHeader title={t("field.pagingInfo")} />
            <CardBody>
              <Input
                name="paging_name"
                errors={formik.errors}
                touched={formik.touched}
                value={formik?.values?.paging_name ?? ""}
                onChange={(e) => {
                  let value = e.target.value;
                  formik?.setFieldValue(`paging_name`, value);
                }}
                title={t("field.pagingName")}
                type="text"
              />
              <div className="d-flex align-items-center">
                <div className="form-label">{t("field.pagingImage")}</div>
                {formik?.values?.paging_logo && (
                  <div className="d-flex align-items-center ml-auto">
                    <i
                      className="material-icons-round text-primary"
                      style={{ fontSize: 20 }}
                      onClick={() => setShowPagingLogo(!showPagingLogo)}
                    >
                      visibility
                    </i>
                    <div
                      style={{
                        borderRight: "1px solid #A9A9A9",
                        height: 20,
                        margin: 5,
                      }}
                    ></div>
                    <i
                      className="material-icons-round text-danger"
                      style={{ fontSize: 19 }}
                      onClick={() => {
                        imageRef.current.value = "";
                        formik.setFieldValue("paging_logo", null);
                        formik.setFieldValue("paging_logo_url", null);
                        formik.setFieldValue("imagePagingInfo", {
                          title: t("field.uploadPagingInfo"),
                          subtitle: "File",
                        });
                      }}
                    >
                      delete_outline
                    </i>
                  </div>
                )}
              </div>
              <DynamicButton
                titleLeft={formik?.values?.imagePagingInfo?.title}
                subtitleLeft={formik?.values?.imagePagingInfo?.subtitle}
                iconLeft="add_photo_alternate"
                toggle={openFileDialogImage}
                error={formik?.values?.imagePagingInfo?.message_error}
                marginButton="0 0 20px 0"
              />
              <input
                type="file"
                ref={imageRef}
                style={{ display: "none" }}
                onChange={handleChangeImage}
                accept="image/png, image/jpeg"
              />
              <Checkbox
                style={{ marginTop: "30px" }}
                name="include_logo_joumpa"
                checked={formik?.values?.include_logo_joumpa}
                onChange={(e) => {
                  if (formik?.values?.include_logo_joumpa) {
                    formik?.setFieldValue("include_logo_joumpa", null);
                  } else {
                    formik?.setFieldValue("include_logo_joumpa", ["1"]);
                  }
                }}
                value={1}
                label={t("field.includeLogoJoumpa")}
              />
            </CardBody>
          </Card>
          <Card className="rounded">
            <CardBody>
              <div className="d-flex align-items-center mb-4">
                <p
                  style={{
                    color: "#63ae5c",
                    fontWeight: "bold",
                    lineHeight: "30px",
                  }}
                >
                  {t("field.passengerInfo")}
                </p>
                <div className="ml-auto">
                  <Button
                    style={{ paddingTop: "8px", paddingBottom: "8px" }}
                    title="Download Format Import"
                    type="button"
                    rounded={true}
                    variant="primary"
                    onClick={handleDownloadExcel}
                  />
                </div>
              </div>
              {passengersFromInput?.length ? (
                <div className="d-flex align-items-center">
                  <p
                    className="normal-title text-bold"
                    style={{ color: "#A5A5C0" }}
                  >
                    Data {t("field.passenger")}
                  </p>
                  <div
                    className="ml-auto normal-title text-bold text-danger"
                    onClick={showConfirmaDelete}
                  >
                    {`${t("commons.delete")} ${t("commons.all")}`}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {passengersFromInput?.map((item, index) => {
                return (
                  <CardFlush
                    list
                    padding="15px 0px"
                    label={item?.name}
                    key={index}
                    desc={
                      <div
                        className="d-flex justify-content-end clickable"
                        onClick={() => handleAddPassenger(index, "input")}
                      >
                        <span className="material-icons-outlined">
                          navigate_next
                        </span>
                      </div>
                    }
                    size={{ label: 10, desc: 2 }}
                  />
                );
              })}
              {passengersFromImport?.length ? (
                <div className="d-flex align-items-center mt-4">
                  <p
                    className="normal-title text-bold"
                    style={{ color: "#A5A5C0" }}
                  >
                    {t("field.labelImportResults")}
                  </p>
                  <div
                    className="ml-auto normal-title text-bold text-danger"
                    onClick={showConfirmaDeleteImport}
                  >
                    {`${t("commons.delete")} ${t("commons.all")}`}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {passengersFromImport?.map((item, index) => {
                return (
                  <CardFlush
                    list
                    padding="15px 0px"
                    label={item?.name}
                    key={index}
                    desc={
                      <div
                        className="d-flex justify-content-end clickable"
                        onClick={() => handleAddPassenger(index, "import")}
                      >
                        <span className="material-icons-outlined">
                          navigate_next
                        </span>
                      </div>
                    }
                    size={{ label: 10, desc: 2 }}
                  />
                );
              })}
              {formik?.values?.passenger_list?.length > 0 && (
                <div className="d-flex align-items-center mt-4">
                  <p
                    className="normal-title text-bold"
                    style={{ color: "#A5A5C0" }}
                  >
                    Total PAX
                  </p>
                  <div className="ml-auto">
                    <p
                      className="normal-title text-bold"
                    >
                      {`${
                        formik?.values?.passenger_list?.filter(
                          (item) => item?.title === "Mrs." || item?.title === "Mr." || item?.title === "Ms."
                        )?.length
                      } ${t("commons.adult")}, ${
                        formik?.values?.passenger_list?.filter(
                          (item) => item?.title === "Child"
                        )?.length
                      } ${t("commons.child")}, ${
                        formik?.values?.passenger_list?.filter(
                          (item) => item?.title === "Infant"
                        )?.length
                      } ${t("commons.infantLabel")}`}
                    </p>
                  </div>
                </div>
              )}
              <Divider orientation="horizontal" />
              <div className="d-flex align-items-center mt-4">
                <div
                  className="col-md-5 pl-0"
                  onClick={() => handleAddPassenger()}
                >
                  <div className="left-icon float-left rounded-fill">
                    <span
                      className="material-icons clickable"
                      style={{ fontSize: 25, color: "#63ae5c" }}
                    >
                      person
                    </span>
                  </div>
                  <div
                    className="clickable normal-title text-extra-bold pt-3 pl-3"
                    style={{ color: "#63ae5c" }}
                  >
                    {t("field.addPassenger")}
                  </div>
                </div>
                <div
                  className="col-md-2 normal-title text-bold"
                  style={{ color: "#A5A5C0" }}
                >
                  {t("commons.or")}
                </div>
                <div
                  className="col-md-5 d-flex justify-content-end pr-0"
                  onClick={openFileDialog}
                >
                  <div className="left-icon float-left rounded-fill">
                    <span
                      className="material-icons clickable"
                      style={{ fontSize: 25, color: "#63ae5c" }}
                    >
                      import_export
                    </span>
                  </div>
                  <div
                    className="clickable normal-title text-extra-bold pt-3"
                    style={{ color: "#63ae5c" }}
                  >
                    Import File
                  </div>
                  <input
                    type="file"
                    accept=".xlsx, .xls"
                    ref={fileRef}
                    style={{ display: "none" }}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      readExcel(file);
                    }}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="rounded">
            <CardHeader title={t("field.passengerNotes")} />
            <CardBody>
              <Input
                name="passenger_notes"
                type="text"
                errors={formik?.errors}
                touched={formik?.touched}
                placeholder={`${t("commons.add")} ${t("field.note")}`}
                {...formik?.getFieldProps("passenger_notes")}
              />
            </CardBody>
          </Card>
          <Card className="rounded">
            <CardHeader title={t("field.bookersInfo")} />
            {isValid ? (
              <div style={{ paddingLeft: 30, paddingRight: 30 }}>
                <RadioButton
                  id="1"
                  isSelected={formik?.values?.bookers === "firstPassenger"}
                  label={t("field.bookerSameAsPassenger")}
                  value="firstPassenger"
                  onChange={(e) => {
                    formik?.setFieldValue("bookers", e.target.value);
                    formik?.setFieldValue(
                      "bookers_title",
                      formik?.values?.passenger_list[0]?.title
                    );
                    formik?.setFieldValue(
                      "bookers_name",
                      formik?.values?.passenger_list[0]?.name
                    );
                    formik?.setFieldValue(
                      "bookers_nationality",
                      formik?.values?.passenger_list[0]?.passenger_nationality
                    );
                    formik?.setFieldValue(
                      "bookers_email",
                      formik?.values?.passenger_list[0]?.email
                    );
                    formik?.setFieldValue(
                      "bookers_phone",
                      formik?.values?.passenger_list[0]?.phoneNumber
                    );
                    formik?.setFieldValue(
                      "bookers_country_code",
                      formik?.values?.passenger_list[0]?.countryCode
                    );
                    formik?.setFieldValue(
                      "bookers_country_id",
                      formik?.values?.passenger_list[0]?.countryId
                    );
                  }}
                />
                <RadioButton
                  id="2"
                  isSelected={formik?.values?.bookers === "admin"}
                  label={t("field.bookerSameAsAdmin")}
                  value="admin"
                  onChange={(e) => {
                    formik?.setFieldValue("bookers", e.target.value);
                    formik?.setFieldValue("bookers_title", "Mr.");
                    formik?.setFieldValue(
                      "bookers_name",
                      currentUser?.user?.name
                    );
                    formik?.setFieldValue("bookers_nationality", "Indonesia");
                    formik?.setFieldValue(
                      "bookers_email",
                      currentUser?.user?.email
                    );
                    formik?.setFieldValue("bookers_phone", "");
                  }}
                />
                <RadioButton
                  id="3"
                  isSelected={formik?.values?.bookers === "others"}
                  label={t("masterData.others")}
                  value="others"
                  onChange={(e) => {
                    formik?.setFieldValue("bookers", e.target.value);
                    formik?.setFieldValue("bookers_title", "");
                    formik?.setFieldValue("bookers_name", "");
                    formik?.setFieldValue("bookers_nationality", "");
                    formik?.setFieldValue("bookers_email", "");
                    formik?.setFieldValue("bookers_phone", "");
                  }}
                />
              </div>
            ) : (
              <div></div>
            )}
            <Divider orientation="horizontal" />
            <CardBody>
              <div className="row">
                <div className="col-md-4">
                  <Select2
                    name="bookers_title"
                    type="text"
                    errors={formik.errors}
                    touched={formik.touched}
                    options={[
                      { label: t("commons.mr"), value: "Mr." },
                      { label: t("commons.mrs"), value: "Mrs." },
                      { label: t("commons.ms"), value: "Ms." },
                      { label: t("commons.child"), value: "Child" },
                      { label: t("commons.infant"), value: "Infant" },
                    ]}
                    {...formik.getFieldProps("bookers_title")}
                    value={isValid ? formik?.values?.bookers_title : ""}
                    onChange={(name, value) => {
                      formik?.setFieldValue(`bookers_title`, value);
                    }}
                    onBlur={formik?.setFieldTouched}
                    title={t("field.title")}
                  />
                </div>
                <div className="col-md-8">
                  <Input
                    name="bookers_name"
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik?.values?.bookers_name ?? ""}
                    onChange={(e) => {
                      let value = e.target.value;
                      formik?.setFieldValue("bookers_name", value);
                    }}
                    title={t("field.name")}
                    type="text"
                  />
                </div>
              </div>
              {/* <Input
                name="bookers_nationality"
                errors={formik.errors}
                touched={formik.touched}
                value={formik?.values?.bookers_nationality ?? ""}
                onChange={(e) => {
                  let value = e.target.value;
                  formik?.setFieldValue(`bookers_nationality`, value);
                }}
                title={t("field.nationality")}
                type="text"
              /> */}
               <Select2
                clearable={!!formik?.values?.bookers_nationality}
                name="bookers_nationality"
                title={t("field.nationality")}
                options={dataCountries}
                value={formik?.values?.bookers_nationality}
                onChange={(name, value) => {
                  formik?.setFieldValue(
                    "bookers_nationality", 
                    value
                  );
                }}
                errors={formik.errors}
                touched={formik.touched}
                onBlur={formik.setFieldTouched}
              />
              <Input
                name="bookers_email"
                type="email"
                errors={formik.errors}
                touched={formik.touched}
                value={isValid ? formik?.values?.bookers_email : ""}
                onChange={(e) => {
                  let value = e.target.value;
                  formik?.setFieldValue("bookers_email", value);
                }}
                title={t("field.email")}
              />
              <div className="form-group">
                <div className="form-label">{t("field.phone")}</div>
                <div className="input-wrapper w-100">
                  <PhoneInput
                    enableTerritories={true}
                    countryCodeEditable={false}
                    placeholder={t("field.placeHolderPhoneNumber")}
                    country={formik?.values?.bookers_country_id ?? "id"}
                    inputStyle={{ width: '100%' }}
                    enableSearch={true}
                    value={formik?.values?.bookers_phone}
                    onChange={(phone, country) => {
                      formik?.setFieldValue("bookers_country_code", country?.dialCode);
                      formik?.setFieldValue("bookers_phone", phone);
                      formik?.setFieldValue("bookers_country_id", country?.countryCode);
                      // formik?.setFieldValue("passenger_nationality", country?.name);
                    }}
                  />
                  {/* <NumberFormat
                    allowNegative={false}
                    className="form-control form-input"
                    name="bookers_phone"
                    type="tel"
                    {...formik.getFieldProps("bookers_phone")}
                    style={{ padding: "25px" }}
                    placeholder={`${t("commons.input")} ${t("field.phone")}`}
                  /> */}
                </div>
              </div>
            </CardBody>
          </Card>
          {formik?.values?.group_list?.length > 0 ? (
            <Card className="rounded">
              <CardHeader title={t("field.groupTypeInfo")} />
              <CardBody>
                <Select2
                  isSearchable={false}
                  options={formik?.values?.group_list}
                  title={t("field.groupType")}
                  name="corporate_type_group_id"
                  placeholder="Select group type"
                  errors={formik.errors}
                  touched={formik.touched}
                  value={formik.values?.corporate_type_group_id}
                  onChange={(name, value) => {
                    const selectedGroup = formik?.values?.group_list?.find(
                      (x) => x.id === value
                    );

                    formik.setFieldValue("corporate_type_group_id", value);
                    formik.setFieldValue(
                      "corporate_type_group_property_id",
                      selectedGroup?.property_list[0]?.id
                    );
                    formik.setFieldValue(
                      "corporate_price_id",
                      selectedGroup?.property_list[0]?.prices?.id
                    );
                  }}
                  onBlur={formik.setFieldTouched}
                />
              </CardBody>
            </Card>
          ) : (
            <></>
          )}
          {formik?.values?.property_list?.length > 0 ? (
            <Card className="rounded">
              <CardHeader title={t("field.cooperationType")} />
              <CardBody>
                <Select2
                  isSearchable={false}
                  options={formik?.values?.property_list}
                  title={t("field.cooperationType")}
                  name="corporate_type_group_id"
                  errors={formik.errors}
                  touched={formik.touched}
                  value={formik.values?.corporate_type_group_id}
                  onChange={(name, value) => {
                    const selectedProperty = formik?.values?.property_list?.find(
                      (x) => x.value === value
                    );

                    formik.setFieldValue("corporate_type_group_id", value);
                    formik.setFieldValue(
                      "corporateType",
                      selectedProperty?.corporate_type
                    );
                    formik.setFieldValue(
                      "corporate_type_id",
                      selectedProperty?.corporate_type_id
                    );
                    formik.setFieldValue(
                      "corporate_type_group_property_id",
                      selectedProperty?.corporate_type_group_property_id
                    );
                    formik.setFieldValue(
                      "corporate_price_id",
                      selectedProperty?.corporate_price_id
                    );
                    formik.setFieldValue(
                      "price_period_id",
                      selectedProperty?.price_period_id
                    );
                    formik.setFieldValue(
                      "product_list_id",
                      selectedProperty?.product_list_id
                    );
                    formik.setFieldValue(
                      "product_code",
                      selectedProperty?.product_code
                    );
                    
                    if (selectedProperty?.loungePrice?.length > 0) {
                      formik?.setFieldValue(
                        "loungePrice",
                        selectedProperty?.loungePrice
                      );
                    }
                    if (selectedProperty?.productTransports?.length > 0) {
                      formik?.setFieldValue(
                        "productTransports",
                        selectedProperty?.productTransports
                      );
                    }
                  }}
                  onBlur={formik.setFieldTouched}
                />
              </CardBody>
            </Card>
          ) : (
            <></>
          )}
          {button()}
        </Col>
      </Row>
      <ConfirmationModal
        show={confirmModal}
        toggle={() => setConfirmModal(false)}
        confirm={() => handleDeletePassengers()}
        icon="info"
        param="semua data penumpang"
        isDelete
      />
      <ConfirmDeleteImport
        show={confirmModalDeleteImport}
        toggle={() => setConfirmModalDeleteImport(false)}
        confirm={() => handleDeleteImport()}
        icon="info"
        param="semua data import penumpang"
        isDelete
      />
      <PagingLogoModal
        show={showPagingLogo}
        toggle={() => setShowPagingLogo(!showPagingLogo)}
        image={formik?.values?.paging_logo_url}
      />
      <PassengerModal
        show={modalPass}
        formik={formik}
        idx={indexPassenger}
        dataCountries={dataCountries}
        toggle={showModalPass}
      />
    </>
  );
};

const mapStateToProps = ({ corp_reserv: { error_message, error_message_eng, data }, corporate }) => {
  return { error_message, error_message_eng,  data, corporate };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(PassengerReservation)
);
