import { history } from "../../../../utils/History";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";

import { toastSuccess, toastError } from "../../../../components/commons/toast";

import API from "../../API";
import { handleResponse } from "../../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_OPERATIONAL_PENDING = "GET_OPERATIONAL_PENDING";
export const GET_OPERATIONAL_SUCCESS = "GET_OPERATIONAL_SUCCESS";
export const GET_OPERATIONAL_ERROR = "GET_OPERATIONAL_ERROR";
export const FIND_TOTAL_PAX_PENDING = "FIND_TOTAL_PAX_PENDING";
export const FIND_TOTAL_PAX_SUCCESS = "FIND_TOTAL_PAX_SUCCESS";
export const FIND_TOTAL_PAX_ERROR = "FIND_TOTAL_PAX_ERROR";
export const POST_OPERATIONAL_PENDING = "POST_OPERATIONAL_PENDING";
export const POST_OPERATIONAL_SUCCESS = "POST_OPERATIONAL_SUCCESS";
export const POST_OPERATIONAL_ERROR = "POST_OPERATIONAL_ERROR";
export const PUT_OPERATIONAL_PENDING = "PUT_OPERATIONAL_PENDING";
export const PUT_OPERATIONAL_SUCCESS = "PUT_OPERATIONAL_SUCCESS";
export const PUT_OPERATIONAL_ERROR = "PUT_OPERATIONAL_ERROR";
export const DELETE_OPERATIONAL_PENDING = "DELETE_OPERATIONAL_PENDING";
export const DELETE_OPERATIONAL_SUCCESS = "DELETE_OPERATIONAL_SUCCESS";
export const DELETE_OPERATIONAL_ERROR = "DELETE_OPERATIONAL_ERROR";
export const DOWNLOAD_PENDING = "DOWNLOAD_PENDING";
export const DOWNLOAD_SUCCESS = "DOWNLOAD_SUCCESS";
export const DOWNLOAD_ERROR = "DOWNLOAD_ERROR";

// URL: URL_{URL}
const OPERATIONAL_URL = `v1/order/backoffice`;
const FIND_TOTAL_PAX_URL = `v1/order/backoffice/find-total-pax`;
const CHANGE_ASSISTANT_URL = `v1/order/change-assisstant`;
const HISTORY_ASSISTANT_URL = `v1/order/change-assisstant-history`;

const lang = localStorage.getItem('joumpa_language')

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_OPERATIONAL_PENDING));
  API.get(OPERATIONAL_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_OPERATIONAL_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_OPERATIONAL_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getHistory = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_OPERATIONAL_PENDING));
  API.get(HISTORY_ASSISTANT_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_OPERATIONAL_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_OPERATIONAL_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const post = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(POST_OPERATIONAL_PENDING));
  API.post(OPERATIONAL_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_OPERATIONAL_SUCCESS, res));
      toastSuccess("Berhasil Tambah Data");
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_OPERATIONAL_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const change_assistant = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PUT_OPERATIONAL_PENDING));
  API.put(CHANGE_ASSISTANT_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_OPERATIONAL_SUCCESS, res));
      toastSuccess("Berhasil Ubah Data Assistant");
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_OPERATIONAL_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const download = (param, resolve) => (dispatch) => {
  dispatch(actionPending(DOWNLOAD_PENDING));
  API.get(`${OPERATIONAL_URL}/export/excel`, {
    params: param,
    responseType: "blob",
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DOWNLOAD_SUCCESS, res));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Joumpa Checklist Handling.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      if (resolve) {
        resolve({
          data: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch(actionError(DOWNLOAD_ERROR));
      toastError(lang === 'id' ? "Gagal mengunduh data!" : "Download Unsuccessfull!");
    });
};

const findTotalPax = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(FIND_TOTAL_PAX_PENDING));
  API.get(FIND_TOTAL_PAX_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(FIND_TOTAL_PAX_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        resolve({
          data: res.data.data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(FIND_TOTAL_PAX_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const operational = {
  get,
  getHistory,
  post,
  change_assistant,
  download,
  findTotalPax
};
export default operational;
