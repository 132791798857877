import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody, Container, Row, Col } from "reactstrap";
import { Formik, Form } from "formik";
import ReactTooltip from "react-tooltip";
import { withTrans } from "../../../i18n/withTrans";
import { toastError } from "../../../components/commons/toast";
import Input from "../../../components/forms/Input";
import Select2 from "../../../components/forms/Select2";
import InputPrepend from "../../../components/forms/InputPrepend";
import Checkbox from "../../../components/forms/Checkbox";
import ButtonModal from "../../../components/modal/ButtonModal";
import ConfirmationModal from "../../../components/modal/ConfirmModal";

import Corp_Reservation from "../../../store/actions/corp_reservation";

const AdditionalServiceModal = ({
  t,
  show,
  toggle,
  order_id,
  listPassegers,
  isCorporateLounge,
  listLounge,
  listTransportProduct,
  isChangeLounge,
  isChangeTransport,
  isChangeOther,
  error,
  data_list,
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [confirmModal, setConfirmModal] = useState(false);
  const [includeConcession, setIncludeConcession] = useState();
  const [includePPN, setIncludePPN] = useState();
  const [concessionPercentage, setConcessionPercentage] = useState();
  const [ppnPercentage, setPpnPercentage] = useState();
  const [selectedLounge, setSelectedLounge] = useState("")
  const [loungeAdultNumber, setLoungeAdultNumber] = useState(0);
  const [loungeChildNumber, setLoungeChildNumber] = useState(0);
  const [loungeInfantNumber, setLoungeInfantNumber] = useState(0);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const numberWithCommas = (x) => {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const initial = {
    order_id: order_id,
    corporate_lounge_id: null,
    lounge: null,
    lounge_id: null,
    transport_price_id: null,
    transport_number: 0,
    additional_service_description: null,
    additional_service_price: 0,
    additional_service_dpp: 0,
    additional_service_ppn_percentage: null,
    additional_service_concession_percentage: null,
    additional_service_is_include_ppn: null,
    additional_service_is_include_concession: null,
    additional_service_total_price: 0,
    lounge_adult_number: 0,
    lounge_child_number: 0,
    lounge_infant_number: 0,
    total_lounge_adult: 0,
    total_lounge_child: 0,
    total_lounge_infant: 0,
    total_lounges: 0,
  };
  
  useEffect(() => {
    if (error) {
      setLoadingSubmit(false);
    }
  }, [error]);

  useEffect(() => {
    if (data_list?.data?.status === 200) {
      setLoadingSubmit(false);
    }
  }, [data_list]);

  const showConfirmation = (item) => {
    setConfirmModal(!confirmModal);
  };

  useEffect(() => {
    if (formikRef?.current?.values?.lounge) {
      const index = listLounge
        ?.map(function (e) {
          return e.value;
        })
        .indexOf(formikRef?.current?.values?.lounge);
      if (index > -1) {
        const total_adult = formikRef?.current?.values?.lounge_adult_number * listLounge[index]?.adult_price;
        const total_child = formikRef?.current?.values?.lounge_child_number * listLounge[index]?.child_price;
        const total_infant = formikRef?.current?.values?.lounge_infant_number * listLounge[index]?.infant_price;
        const total_lounges = total_adult + total_child + total_infant;

        formikRef?.current.setFieldValue(
          "total_lounge_adult",
          total_adult
        );
        formikRef?.current.setFieldValue(
          "total_lounge_child",
          total_child
        );
        formikRef?.current.setFieldValue(
          "total_lounge_infant",
          total_infant
        );
        formikRef?.current.setFieldValue(
          "total_lounges",
          total_lounges
        );
      }
    }
  }, [
    loungeAdultNumber,
    loungeChildNumber,
    loungeInfantNumber,
    selectedLounge
  ]);

  const total_tax = (price) => {
    if (
      formikRef?.current?.values?.additional_service_is_include_concession ==
        1 &&
      formikRef?.current?.values?.additional_service_is_include_ppn == 1
    ) {
      return 0;
    }
    if (
      (!(
        formikRef?.current?.values?.additional_service_is_include_concession ==
        1
      ) &&
        formikRef?.current?.values?.additional_service_is_include_ppn == 1) ||
      (formikRef?.current?.values?.additional_service_is_include_ppn == 1 &&
        !(
          formikRef?.current?.values
            ?.additional_service_is_include_concession == 1
        ))
    ) {
      const total =
        (price *
          formikRef?.current?.values
            ?.additional_service_concession_percentage) /
          100;
      return total;
    }
    if (
      (!(formikRef?.current?.values?.additional_service_is_include_ppn == 1) &&
        formikRef?.current?.values?.additional_service_is_include_concession ==
          1) ||
      (formikRef?.current?.values?.additional_service_is_include_concession ==
        1 &&
        !(formikRef?.current?.values?.additional_service_is_include_ppn == 1))
    ) {
      const total =
        (price *
          formikRef?.current?.values?.additional_service_ppn_percentage) /
          100;
      return total;
    }
    if (
      !(
        formikRef?.current?.values?.additional_service_is_include_concession ==
        1
      ) &&
      !(formikRef?.current?.values?.additional_service_is_include_ppn == 1)
    ) {
      let concession_percentage =
        formikRef?.current?.values?.additional_service_concession_percentage ??
        0;
      let ppn_percentage =
        formikRef?.current?.values?.additional_service_ppn_percentage ?? 0;
      const total =
        (price * concession_percentage) / 100 +
        ((price + price * (concession_percentage / 100)) * ppn_percentage) /
          100;
      return total;
    }
  };

  useEffect(() => {
    if (
      formikRef?.current?.values?.additional_service_dpp !== null && 
      formikRef?.current?.values?.additional_service_dpp !== 0
    ) {
      formikRef?.current?.setFieldValue(
        "additional_service_total_price",
        formikRef?.current?.values?.additional_service_price 
          + total_tax(formikRef?.current?.values?.additional_service_dpp)
      );
    } else {
      formikRef?.current?.setFieldValue(
        "additional_service_total_price",
        formikRef?.current?.values?.additional_service_price
          + total_tax(formikRef?.current?.values?.additional_service_price)
      );
    }
  }, [includeConcession, includePPN, concessionPercentage, ppnPercentage]);

  const infoTax = (
    include_ppn,
    include_concession,
    ppn_percentage,
    concession_percentage,
    price
  ) => {
    return (
      <>
        <span
          className="material-icons"
          style={{ fontSize: 18 }}
          data-tip
        >
          info_outlined
        </span>
        <ReactTooltip place="top" effect="solid">
          <p style={{ whiteSpace: "pre-line" }}>
            {!include_ppn && 
            include_concession && 
            ppn_percentage !== null 
            ? (
              <span>
                {`${t("field.price")} plus PPN ${ppn_percentage}% of Rp ${numberWithCommas(price)}`}
              </span>
            ) : include_ppn &&
              !include_concession &&
              concession_percentage !== null ? (
              <span>
                {`${t("field.price")} plus ${t("product.concession")} ${
                  concession_percentage
                }% of Rp ${numberWithCommas(price)}`}
              </span>
            ) : !include_ppn &&
              !include_concession &&
              concession_percentage !== null &&
              ppn_percentage !== null ? (
              <span>
                {`${t("field.price")} plus PPN ${
                  ppn_percentage
                }% & ${t("product.concession")} ${
                  concession_percentage
                }% of Rp ${numberWithCommas(price)}`}
              </span>
            ) : (
              ""
            )}
          </p>
        </ReactTooltip>
      </>
    );
  };

  const handleSubmit = () => {
    let invalid = false;
    const countAdult = listPassegers?.filter(
      (item) =>
        item?.title === "Mr." || item?.title === "Mrs." || item?.title === "Ms."
    ).length;
    const countChild = listPassegers?.filter(
      (item) => item?.title === "Child"
    ).length;
    const countInfant = listPassegers?.filter(
      (item) => item?.title === "Infant"
    ).length;

    if (isChangeLounge) {
      if (
        formikRef?.current?.values?.lounge
      ) {
        if (
          formikRef?.current?.values?.lounge_adult_number === 0 &&
          formikRef?.current?.values?.lounge_child_number === 0 &&
          formikRef?.current?.values?.lounge_infant_number === 0
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.lounge")}!`
          );
          invalid = true;
        }
        if (formikRef?.current?.values?.lounge_adult_number > countAdult) {
          toastError(`${t("booking.handleAdultLounge")}!`);
          invalid = true;
        } else if (formikRef?.current?.values?.lounge_child_number > countChild) {
          toastError(`${t("booking.handleChildLounge")}!`);
          invalid = true;
        } else if (formikRef?.current?.values?.lounge_infant_number > countInfant) {
          toastError(`${t("booking.handleInfantLounge")}!`);
          invalid = true;
        }
      } else {
        toastError(
          `${t("commons.empty_data_message")} ${t("field.lounge")}!`
        );
        invalid = true;
      }
    }

    if (isChangeTransport) {
      if (formikRef?.current?.values?.transport_price_id) {
        if (!formikRef?.current?.values?.transport_number) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.transportation")}!`
          );
          invalid = true;
        }
      } else {
        toastError(
          `${t("commons.empty_data_message")} ${t("field.transportation")}!`
        );
        invalid = true;
      }
    }

    if (isChangeOther) {
      if (
        formikRef?.current?.values?.additional_service_description ||
        formikRef?.current?.values?.additional_service_concession_percentage ||
        formikRef?.current?.values?.additional_service_ppn_percentage ||
        formikRef?.current?.values?.additional_service_price
      ) {
        if (
          !(
            formikRef?.current?.values?.additional_service_description !== "" &&
            formikRef?.current?.values
              ?.additional_service_concession_percentage !== null &&
            formikRef?.current?.values
              ?.additional_service_concession_percentage !== "" &&
            formikRef?.current?.values?.additional_service_ppn_percentage !==
              null &&
            formikRef?.current?.values?.additional_service_ppn_percentage !==
              "" &&
            formikRef?.current?.values?.additional_service_price > 0
          )
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t(
              "field.additionalService"
            )} ${t("masterData.others")}!`
          );
          invalid = true;
        }
      } else {
        toastError(
          `${t("commons.empty_data_message")} ${t(
            "field.additionalService"
          )} ${t("masterData.others")}!`
        );
        invalid = true;
      }
    }

    if (!invalid) {
      new Promise((resolve) => {
        const param = {
          additional_service_concession_percentage: isChangeOther
            ? parseFloat(formikRef?.current?.values?.additional_service_concession_percentage)
            : null,
          additional_service_description: isChangeOther
            ? formikRef?.current?.values?.additional_service_description
            : null,
          additional_service_is_include_concession:
            formikRef?.current?.values
              ?.additional_service_is_include_concession == 1
              ? true
              : false,
          additional_service_is_include_ppn:
            formikRef?.current?.values?.additional_service_is_include_ppn == 1
              ? true
              : false,
          additional_service_ppn_percentage: isChangeOther
            ? parseFloat(formikRef?.current?.values?.additional_service_ppn_percentage)
            : null,
          additional_service_price: isChangeOther
            ? formikRef?.current?.values?.additional_service_price
            : null,
          additional_service_dpp: isChangeOther
            ? formikRef?.current?.values?.additional_service_dpp
            : null,
          corporate_lounge_id: isChangeLounge
            ? formikRef?.current?.values?.corporate_lounge_id
            : null,
          lounge_adult_number: isChangeLounge
            ? formikRef?.current?.values?.lounge_adult_number
            : null,
          lounge_child_number: isChangeLounge
            ? formikRef?.current?.values?.lounge_child_number
            : null,
          lounge_id: isChangeLounge
            ? formikRef?.current?.values?.lounge_id
            : null,
          lounge_infant_number: isChangeLounge
            ? formikRef?.current?.values?.lounge_infant_number
            : null,
          order_id: formikRef?.current?.values?.order_id,
          transport_number: isChangeTransport
            ? formikRef?.current?.values?.transport_number
            : null,
          transport_price_id: isChangeTransport
            ? formikRef?.current?.values?.transport_price_id
            : null,
        };
        setLoadingSubmit(true);
        const callback = () => {
          setConfirmModal(!confirmModal);
          toggle();
        };
        dispatch(Corp_Reservation.addExtraOrder(param, resolve, callback));
      }).then((res) => {
        setLoadingSubmit(false);
      });
    }
  };

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {`${t("commons.change")} ${t("field.additionalService")} ${isChangeLounge
            ? t("field.lounge")
            : isChangeTransport
            ? t("field.transportation")
            : t("masterData.others")}`}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Formik innerRef={formikRef} initialValues={initial}>
          {(props) => (
            <Form>
              <ModalBody>
                <Container>
                  {isChangeLounge && (
                    <div className="wrapper-detail-card">
                      <div className="p-3 pt-0">
                        <Select2
                          clearable={!!props.values?.lounge}
                          name="lounge"
                          title={t("field.lounge")}
                          errors={props.errors}
                          options={listLounge}
                          touched={props.touched}
                          value={props.values?.lounge}
                          onChange={(name, value) => {
                            setSelectedLounge(value);
                            props.setFieldValue("lounge", value);
                            if (isCorporateLounge) {
                              props.setFieldValue(
                                "corporate_lounge_id",
                                value
                              );
                            } else {
                              props.setFieldValue("lounge_id", value);
                            }
                          }}
                          onBlur={props.setFieldTouched}
                        />
                        <Row style={{ marginTop: 20 }}>
                          <Col md={4} style={{ paddingRight: "0px" }}>
                            <div
                              className="form-group"
                              style={{ marginBottom: 0 }}
                            >
                              <label className="form-label">
                                {t("commons.adult")}
                              </label>
                            </div>
                          </Col>
                          <Col md={4} style={{ paddingLeft: "0px" }}>
                            <div
                              className="form-group"
                              style={{ marginBottom: 0 }}
                            >
                              <label className="form-label">
                                {t("commons.child")}
                              </label>
                            </div>
                          </Col>
                          <Col md={4} style={{ paddingLeft: "0px" }}>
                            <div
                              className="form-group"
                              style={{ marginBottom: 0 }}
                            >
                              <label className="form-label">
                                {t("commons.infantLabel")}
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <InputPrepend
                          mb="0"
                          errors={props?.errors}
                          touched={props?.touched}
                          multiple={[
                            {
                              type: "number",
                              name: "lounge_adult_number",
                              value: props?.values?.lounge_adult_number,
                              min: 0,
                              onChange: (data) => {
                                const val = data?.target?.value;
                                props?.setFieldValue(
                                  "lounge_adult_number",
                                  val ? parseInt(val) : null
                                );
                                setLoungeAdultNumber(val ? parseInt(val) : null);
                              },
                              onKeyDown: (e) => {
                                exceptThisSymbols.includes(e.key) && e.preventDefault();
                              },
                            },
                            {
                              type: "number",
                              name: "lounge_child_number",
                              value: props?.values?.lounge_child_number,
                              min: 0,
                              onChange: (data) => {
                                const val = data?.target?.value;
                                props?.setFieldValue(
                                  "lounge_child_number",
                                  val ? parseInt(val) : null
                                );
                                setLoungeChildNumber(val ? parseInt(val) : null);
                              },
                              onKeyDown: (e) => {
                                exceptThisSymbols.includes(e.key) && e.preventDefault();
                              },
                            },
                            {
                              type: "number",
                              name: "lounge_infant_number",
                              value: props?.values?.lounge_infant_number,
                              min: 0,
                              onChange: (data) => {
                                const val = data?.target?.value;
                                props?.setFieldValue(
                                  "lounge_infant_number",
                                  val ? parseInt(val) : null
                                );
                                setLoungeInfantNumber(val ? parseInt(val) : null);
                              },
                              onKeyDown: (e) => {
                                exceptThisSymbols.includes(e.key) && e.preventDefault();
                              },
                            },
                          ]}
                        />
                      </div>
                      <div className="d-flex justify-content-between border-top p-3">
                        <div className="normal-title text-extra-bold">
                          Total
                        </div>
                        <div className="normal-title text-extra-bold">
                          Rp{" "}
                          {numberWithCommas(props?.values?.total_lounges)}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between border-top p-3">
                        <div className="normal-title text-extra-bold">
                          {t("commons.adult")}
                        </div>
                        <div className="normal-title text-extra-bold">
                          Rp{" "}
                          {numberWithCommas(
                            props?.values?.total_lounge_adult
                          )}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between border-top p-3">
                        <div className="normal-title text-extra-bold">
                          {t("commons.child")}
                        </div>
                        <div className="normal-title text-extra-bold">
                          Rp{" "}
                          {numberWithCommas(
                            props?.values?.total_lounge_child
                          )}
                        </div>
                      </div>
                      <div className="d-flex card-body justify-content-between border-top p-3">
                        <div className="normal-title text-extra-bold">
                          {t("commons.infantLabel")}
                        </div>
                        <div className="normal-title text-extra-bold">
                          Rp{" "}
                          {numberWithCommas(
                            props?.values?.total_lounge_infant
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {isChangeTransport && (
                    listTransportProduct?.length > 0 ? (
                      <div className="wrapper-detail-card">
                        <div style={{ padding: 15 }}>
                          <Select2
                            clearable={!!props.values?.transport_price_id}
                            name="transport_price_id"
                            errors={props.errors}
                            title={t("field.carType")}
                            touched={props?.touched}
                            options={listTransportProduct}
                            value={props?.values?.transport_price_id}
                            onChange={(name, value) => {
                              props.setFieldValue("transport_price_id", value);
                            }}
                            onBlur={props.setFieldTouched}
                          />
                          <Input
                            title={t("field.totalUnit")}
                            name="transport_number"
                            type="number"
                            min={0}
                            errors={props.errors}
                            touched={props.touched}
                            {...props.getFieldProps("transport_number")}
                            onKeyDown={(e) =>
                              exceptThisSymbols.includes(e.key) &&
                              e.preventDefault()
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="mb-5">
                        {t("commons.regPriceInfo")}
                      </div>
                    )
                  )}
                  {isChangeOther && (
                    <div className="wrapper-detail-card">
                      <div style={{ padding: 15 }}>
                        <Input
                          title={t("field.desc")}
                          name="additional_service_description"
                          type="text"
                          errors={props.errors}
                          touched={props.touched}
                          {...props.getFieldProps(
                            "additional_service_description"
                          )}
                        />
                        <Checkbox
                          style={{ marginTop: "30px" }}
                          name="additional_service_is_include_concession"
                          checked={
                            props?.values
                              ?.additional_service_is_include_concession
                          }
                          onChange={(e) => {
                            if (
                              props?.values
                                ?.additional_service_is_include_concession
                            ) {
                              props?.setFieldValue(
                                "additional_service_is_include_concession",
                                null
                              );
                              setIncludeConcession(null);
                            } else {
                              props?.setFieldValue(
                                "additional_service_is_include_concession",
                                ["1"]
                              );
                              setIncludeConcession(["1"]);
                            }
                          }}
                          value={1}
                          label={t("product.includes_concession")}
                        />
                        <InputPrepend
                          value={
                            props?.values
                              ?.additional_service_concession_percentage
                          }
                          {...props?.getFieldProps(
                            "additional_service_concession_percentage"
                          )}
                          name="additional_service_concession_percentage"
                          label="concession_percentage"
                          title={`% ${t("product.concession")}`}
                          inputmode="numeric"
                          type="number"
                          min={0}
                          errors={props?.errors}
                          touched={props?.touched}
                          placeholder={t("promo.percentage")}
                          onKeyDown={(e) =>
                            exceptThisSymbols.includes(e.key) &&
                            e.preventDefault()
                          }
                          onChange={(data) => {
                            const val = data.target.value;
                            props?.setFieldValue(
                              "additional_service_concession_percentage",
                              val
                            );
                            setConcessionPercentage(val);
                          }}
                        />
                        <Checkbox
                          style={{ marginTop: "30px" }}
                          name="additional_service_is_include_ppn"
                          checked={
                            props?.values?.additional_service_is_include_ppn
                          }
                          onChange={(e) => {
                            if (
                              props?.values?.additional_service_is_include_ppn
                            ) {
                              props?.setFieldValue(
                                "additional_service_is_include_ppn",
                                null
                              );
                              setIncludePPN(null);
                            } else {
                              props?.setFieldValue(
                                "additional_service_is_include_ppn",
                                ["1"]
                              );
                              setIncludePPN(["1"]);
                            }
                          }}
                          value={1}
                          label={t("product.includes_ppn")}
                        />
                        <InputPrepend
                          value={
                            props?.values?.additional_service_ppn_percentage
                          }
                          {...props?.getFieldProps(
                            "additional_service_ppn_percentage"
                          )}
                          name="additional_service_ppn_percentage"
                          label="ppn_percentage"
                          title="% PPN"
                          inputmode="numeric"
                          type="number"
                          min={0}
                          errors={props?.errors}
                          touched={props?.touched}
                          placeholder={t("promo.percentage")}
                          onKeyDown={(e) =>
                            exceptThisSymbols.includes(e.key) &&
                            e.preventDefault()
                          }
                          onChange={(data) => {
                            const val = data.target.value;
                            props?.setFieldValue(
                              "additional_service_ppn_percentage",
                              val
                            );
                            setPpnPercentage(val);
                          }}
                        />
                        <InputPrepend
                          mb="0"
                          errors={props?.errors}
                          touched={props?.touched}
                          title="DPP"
                          multiple={[
                            {
                              prefix: "Rp ",
                              isCurrency: true,
                              thousandSeparator: true,
                              name: "additional_service_dpp",
                              min: 0,
                              value: props?.values?.additional_service_dpp,
                              placeholder: "DPP",
                              onChange: (data) => {
                                const val = data.target.value;
                                const dpp = Number(val.replace(/[^0-9\.-]+/g, ""));
                                props?.setFieldValue(
                                  "additional_service_dpp",
                                  dpp
                                );
                                if (dpp !== null && dpp !== 0) {
                                  props?.setFieldValue(
                                    "additional_service_total_price",
                                    props?.values?.additional_service_price + total_tax(dpp)
                                  );
                                } else {
                                  props?.setFieldValue(
                                    "additional_service_total_price",
                                    props?.values?.additional_service_price
                                      + total_tax(props?.values?.additional_service_price)
                                  );
                                }
                              },
                              onKeyDown: (e) => {
                                exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault();
                              },
                            },
                          ]}
                        />
                        <InputPrepend
                          mb="0"
                          errors={props?.errors}
                          touched={props?.touched}
                          title={t("field.price")}
                          multiple={[
                            {
                              prefix: "Rp ",
                              isCurrency: true,
                              thousandSeparator: true,
                              name: "additional_service_price",
                              min: 0,
                              value: props?.values?.additional_service_price,
                              placeholder: `${t("field.price")}`,
                              onChange: (data) => {
                                const val = data.target.value;
                                const price = Number(
                                  val.replace(/[^0-9\.-]+/g, "")
                                );
                                props?.setFieldValue(
                                  "additional_service_price",
                                  price
                                );
                                if (
                                  props?.values?.additional_service_dpp !== null && 
                                  props?.values?.additional_service_dpp !== 0
                                ) {
                                  props?.setFieldValue(
                                    "additional_service_total_price",
                                    price + total_tax(props?.values?.additional_service_dpp)
                                  );
                                } else {
                                  props?.setFieldValue(
                                    "additional_service_total_price",
                                    price + total_tax(price)
                                  );
                                }
                              },
                              onKeyDown: (e) => {
                                exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault();
                              },
                            },
                          ]}
                        />
                        <InputPrepend
                          mt="0"
                          errors={props?.errors}
                          touched={props?.touched}
                          title={
                            <div className="d-flex align-items-center normal-text">
                              {`Total ${t("field.price")}`}
                              {(!props?.values?.additional_service_is_include_ppn ||
                                !props?.values?.additional_service_is_include_concession) && ( 
                                <>
                                  {infoTax(
                                    props?.values?.additional_service_is_include_ppn,
                                    props?.values?.additional_service_is_include_concession,
                                    props?.values?.additional_service_ppn_percentage,
                                    props?.values?.additional_service_concession_percentage,
                                    props?.values?.additional_service_dpp !== null
                                      && props?.values?.additional_service_dpp !== 0
                                        ? props?.values?.additional_service_dpp
                                        : props?.values?.additional_service_price
                                  )}
                                </>
                              )}
                            </div>
                          }
                          multiple={[
                            {
                              disabled: true,
                              prefix: "Rp ",
                              isCurrency: true,
                              thousandSeparator: true,
                              name: "additional_service_total_price",
                              value:
                                props?.values?.additional_service_total_price,
                              placeholder: `Total ${t("field.price")}`,
                            },
                          ]}
                        />
                      </div>
                    </div>
                  )}
                </Container>
                {!(isChangeTransport && listTransportProduct?.length < 1) && (
                  <div style={{ margin: "50px 20px 0 20px" }}>
                    <ButtonModal
                      confirm={() => showConfirmation(props?.values)}
                      toggle={toggle}
                      typeConfirm="Button"
                      confirmTitle={t("commons.save")}
                      cancelTitle={t("commons.cancel")}
                    />
                  </div>
                )}
              </ModalBody>
            </Form>
          )}
        </Formik>
      </Modal>
      <ConfirmationModal
        show={confirmModal}
        toggle={() => setConfirmModal(false)}
        confirm={handleSubmit}
        message={t("confirm.addAdditionalService")}
        icon="info"
        disabledConfirm={loadingSubmit}
      />
    </>
  );
};

const mapStateToProps = ({ corp_reserv: { error, data_list } }) => {
  return { error, data_list };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(AdditionalServiceModal)
);
