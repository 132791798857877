import { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Formik, Form } from "formik";
import { withTrans } from "../../../i18n/withTrans";
import { getUser } from "../../../utils/User";
import Select2 from "../../../components/forms/Select2";
import Select2Multi from "../../../components/forms/Select2Multi";
import ButtonModal from "../../../components/modal/ButtonModal";
import DynamicButton from "../../../components/forms/DynamicButton";
import DatePicker from "../../../components/forms/DateRangePicker";
import moment from "moment";

function ModalFilter({
  t,
  show,
  airport,
  product,
  corporate,
  toggle,
  filter,
  toggleFilter,
  isDelete,
  onApplyFilter,
  user
}) {
  const currentUser = getUser();
  const [haveAirport, setHaveAirport] = useState(
    user.role_code === "admin_bandara"
      ? true
      : user.role_code === "supervisor"
      ? true
      : user.role_code === "sales_bandara"
      ? true
      : user.role_code === "finance_bandara"
      ? true
      : false
  );

  const handleReset = (formik) => {
    formik.setFieldValue("start_date", moment().format("YYYY-MM-DD"));
    formik.setFieldValue("end_date", moment().format("YYYY-MM-DD"));
    formik.setFieldValue("corporate_id", "");
    formik.setFieldValue("product_id", "");
    formik.setFieldValue("airport_id", "");
    toggleFilter({
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      corporate_id: "",
      product_id: "",
      airport_id: "",
    });
    toggle();
  };

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Filter{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody style={{ margin: "0px 20px" }}>
          <DynamicButton titleLeft={t("field.chooseFilter")} iconLeft="info" />
          <Formik
            initialValues={{
              corporate_id: filter?.corporate_id ?? "all",
              airport_id: filter?.airport_id ?? "",
              product_id: filter?.product_id ?? "",
              start_date: filter?.start_date ?? "",
              end_date: filter?.end_date ?? "",
            }}
            enableReinitialize
            onSubmit={(values) => {
              onApplyFilter({
                airport_id:
                  currentUser?.user.role_code === "admin_bandara" ||
                  currentUser?.user.role_code === "supervisor"
                    ? currentUser?.user.airport_id
                    : values.airport_id,
                start_date:
                  values.start_date === "" || values.start_date === undefined
                    ? ""
                    : moment(values.start_date).format("YYYY-MM-DD"),
                end_date:
                  values.end_date === "" || values.end_date === undefined
                    ? ""
                    : moment(values.end_date).format("YYYY-MM-DD"),
                product_id: values.product_id,
                corporate_id: values.corporate_id,
              });
              toggle();
            }}
          >
            {(props) => (
              <Form style={{ marginTop: 30 }}>
                <DatePicker
                  title={t("report.serviceTime")}
                  name="start_date"
                  handlechange={(startDate, endDate) => {
                    props.setFieldValue("start_date", startDate);
                    props.setFieldValue("end_date", endDate);
                  }}
                  format="DD MMMM YYYY"
                  startDate={filter ? filter?.start_date : props.values?.start_date}
                  endDate={filter ? filter?.end_date : props.values?.end_date}
                  className="mx-auto"
                  errors={props.errors}
                  touched={props.touched}
                />
                {!haveAirport && (
                  <Select2
                    title={t("field.airport")}
                    name="airport_id"
                    options={[
                      {
                        value: "",
                        label: `${t("commons.all")} ${t("field.airport")}`,
                      },
                      ...airport,
                    ]}
                    errors={props.errors}
                    touched={props.touched}
                    value={props.values?.airport_id}
                    onChange={(name, value) => {
                      props.setFieldValue("airport_id", value);
                    }}
                    onBlur={props.setFieldTouched}
                  />
                )}
                <Select2
                  title={t("report.agent")}
                  name="corporate_id"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t("report.agent")}`,
                    },
                    ...corporate,
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.corporate_id}
                  onChange={(name, value) => {
                    props.setFieldValue("corporate_id", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2Multi
                  title={t("field.product")}
                  placeholder={`${t("commons.all")} ${t("field.product")}`}
                  name="product_id"
                  options={product}
                  className="mb-2"
                  errors={props?.errors}
                  touched={props?.touched}
                  value={props?.values?.product_id}
                  onChange={(name, value) => {
                    const data = value;
                    props?.setFieldValue("product_id", data);
                  }}
                  onBlur={props?.setFieldTouched}
                />
               
                <div style={{ marginTop: 50, marginBottom: 30 }}>
                  <ButtonModal
                    toggle={toggle}
                    reset={() => handleReset(props)}
                    deleted={isDelete}
                    confirmTitle={t("field.apply")}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}

export default withTrans(ModalFilter);
