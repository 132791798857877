import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";
import { Divider } from "@mui/material";
import ReactTooltip from "react-tooltip";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";

import { withTrans } from "../../i18n/withTrans";
import { history } from "../../utils/History";
import { Role } from "../../utils/Constants";
import { getUser } from "../../utils/User";

import Button from "../../components/buttons/Button";
import DetailButton from "../../components/table/detailButton";
import Loader from "../../components/commons/Loader";
import Card from "../../components/cards/card";
import CardFlush from "../../components/cards/cardFlush";
import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import Table from "../../components/table";
import ConfirmationModal from "../../components/modal/ConfirmModal";
import PaymentProofModal from "../../components/modal/";
import PagingLogoModal from "../../components/modal/";
import EditPassengerModal from "./modal/ModalEditPassenger";
import EditBookerModal from "./modal/ModalEditBooker";
import EditPagingModal from "./modal/ModalEditPaging";
import AssignAssistantModal from "./modal/AssignAssistantModal";
import EvidenceAssistantModal from "./modal/ShowEvidenceModal";
import UploadPaymentModal from "./modal/UploadPaymentModal";

import BookingService from "../../store/actions/booking";
import CorpPriceService from "../../store/actions/corp_reservation";
import AirportService from "../../store/actions/master_data/airport";

const DetailBooking = ({ data, error, t, pending, match }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();
  const currentUrl = match?.url?.split("/")?.[1];

  const [airportCrossSelling, setAirportCrossSelling] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [editPassengerModal, setEditPassengerModal] = useState(false);
  const [editBookerModal, setEditBookerModal] = useState(false);
  const [dataDetail, setDataDetail] = useState("");
  const [dataLounge, setDataLounge] = useState([]);
  const [dataTransports, setDataTransports] = useState([]);
  const [dataOther, setDataOther] = useState([]);
  const [dataPassengerRow, setDataPassengerRow] = useState([]);
  const [assignModal, setAssignModal] = useState(false);
  const [evidenceAssistant, setEvidenceAssistant] = useState(false);
  const [paymentProof, setPaymentProof] = useState(false);
  const [uploadPaymentProof, setUploadPaymentProof] = useState(false);
  const [dataPassengerAdult, setDataPassengerAdult] = useState(0);
  const [dataPassengerChild, setDataPassengerChild] = useState(0);
  const [dataPassengerInfant, setDataPassengerInfant] = useState(0);
  const [showPriceService, setShowPriceService] = useState(false);
  const [showLounge, setShowLounge] = useState(false);
  const [showTransport, setShowTransport] = useState(false);
  const [showOthers, setShowOthers] = useState(false);
  const [showPagingLogo, setShowPagingLogo] = useState(false);
  const [showEditPaging, setShowEditPaging] = useState(false);
  const numberWithCommas = (x) => {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleGetDetailAirportCrossSelling = (idAirport) => {
    new Promise((resolve) => {
      let param = {
        id: idAirport,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      if (res) {
        setAirportCrossSelling(
          res?.data?.map((airport) => `${airport?.code}-${airport?.city}-${airport?.name} ${
            airport?.uniform === 'Indonesia' ? "" : "-" + airport?.uniform
          }`)[0]
        );
      }
    });
  };

  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        length: 1,
        page: 1,
        id: match?.params?.id,
      };
      dispatch(BookingService.get(param, resolve));
    }).then((res) => {
      setDataDetail(res?.data[0]);
      setDataPassengerAdult(
        res?.data[0]?.list_order_passengers?.filter(
          (item) =>
            item?.title === "Mr." ||
            item?.title === "Mrs." ||
            item?.title === "Ms."
        ).length
      );
      setDataPassengerChild(
        res?.data[0]?.list_order_passengers?.filter(
          (item) => item?.title === "Child"
        ).length
      );
      setDataPassengerInfant(
        res?.data[0]?.list_order_passengers?.filter(
          (item) => item?.title === "Infant"
        ).length
      );
      setDataLounge(
        res?.data[0]?.list_order_extras?.filter(
          (item) => item?.type === "Lounge"
        )
      );
      setDataTransports(
        res?.data[0]?.list_order_extras?.filter(
          (item) => item?.type === "Transport"
        )
      );
      setDataOther(
        res?.data[0]?.list_order_extras?.filter(
          (item) => item?.type === "Other"
        )
      ); 
      if(res?.data[0]?.is_cross_selling && res?.data[0]?.airport_id_cross_selling) {
        handleGetDetailAirportCrossSelling(res?.data[0]?.airport_id_cross_selling)
      }
    });
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  const infoTax = (
    id,
    include_ppn,
    include_concession,
    ppn_percentage,
    concession_percentage,
    ppn_price,
    concession_price
  ) => {
    return (
      <>
        <span
          className="material-icons"
          style={{ fontSize: 18 }}
          data-tip
          data-for={id}
        >
          info_outlined
        </span>
        <ReactTooltip id={id} place="top" effect="solid">
          <p style={{ whiteSpace: "pre-line" }}>
            {(include_ppn && !include_concession && ppn_percentage !== null) ||
            (include_ppn &&
              include_concession &&
              concession_percentage === null &&
              ppn_percentage !== null) ? (
              <span>
                {id === "taxOthers" &&
                dataOther[0]?.dpp !== null &&
                dataOther[0]?.dpp !== 0
                  ? `${t(
                      "product.includes_ppn"
                    )} ${ppn_percentage}% of DPP Rp ${numberWithCommas(
                      dataOther[0]?.dpp
                    )}, Rp ${numberWithCommas(ppn_price)}`
                  : `${t(
                      "product.includes_ppn"
                    )} ${ppn_percentage}%, Rp ${numberWithCommas(ppn_price)}`}
              </span>
            ) : !include_ppn &&
              include_concession &&
              concession_percentage !== null ? (
              <span>
                {id === "taxOthers" &&
                dataOther[0]?.dpp !== null &&
                dataOther[0]?.dpp !== 0
                  ? `${t(
                      "product.includes_concession"
                    )} ${concession_percentage}% of DPP Rp ${numberWithCommas(
                      dataOther[0]?.dpp
                    )}, Rp ${numberWithCommas(concession_price)}`
                  : `${t(
                      "product.includes_concession"
                    )} ${concession_percentage}%, Rp ${numberWithCommas(
                      concession_price
                    )}`}
              </span>
            ) : include_ppn &&
              include_concession &&
              concession_percentage !== null &&
              ppn_percentage !== null ? (
              <span>
                {id === "taxOthers" &&
                dataOther[0]?.dpp !== null &&
                dataOther[0]?.dpp !== 0
                  ? `${t(
                      "product.includes_ppn"
                    )} ${ppn_percentage}% of DPP Rp ${numberWithCommas(
                      dataOther[0]?.dpp
                    )}, Rp ${numberWithCommas(ppn_price)} & ${t(
                      "product.includes_concession"
                    )} ${concession_percentage}% of DPP Rp ${numberWithCommas(
                      dataOther[0]?.dpp
                    )}, Rp ${numberWithCommas(concession_price)}`
                  : `${t(
                      "product.includes_ppn"
                    )} ${ppn_percentage}%, Rp ${numberWithCommas(
                      ppn_price
                    )} & ${t(
                      "product.includes_concession"
                    )} ${concession_percentage}%, Rp ${numberWithCommas(
                      concession_price
                    )}`}
              </span>
            ) : (
              ""
            )}
          </p>
        </ReactTooltip>
      </>
    );
  };

  const toggleLounge = () => {
    setShowLounge(!showLounge);
  };

  const togglePriceService = () => {
    setShowPriceService(!showPriceService);
  };

  const showAssignModal = () => {
    setAssignModal(!assignModal);
  };

  const showEvidenceAssistant = () => {
    setEvidenceAssistant(!evidenceAssistant);
  };

  const showPaymentProof = () => {
    setPaymentProof(!paymentProof);
  };

  const showUploadPaymentModal = () => {
    setUploadPaymentProof(!uploadPaymentProof);
  };

  const deletePayment = () => {
    const callback = () => {
      setDeleteModal(!deleteModal);
      handleRefresh();
    };

    dispatch(BookingService.delPayment({ id: match?.params?.id }, callback));
  };

  const handleChangeAssistant = (param) => {
    const callback = () => {
      setAssignModal(!assignModal);
      handleRefresh();
    };
    if (param) {
      dispatch(CorpPriceService.change_assistant(param, callback));
    }
  };

  const handleEditBooker = (param) => {
    const callback = () => {
      setEditBookerModal(!editBookerModal);
      handleRefresh();
    };
    if (
      param.bookers_phone !== "" &&
      param.bookers_phone !== undefined &&
      param.bookers_phone !== null &&
      !param.bookers_phone.includes("undefined") &&
      param.bookers_phone !== param.bookers_country_code
    ) {
      const bookerPhone = param.bookers_phone
        .replace("+", "")
        .replace(param?.bookers_country_code, "");

      param.bookers_phone = 
        bookerPhone.charAt(0) == 0
          ? bookerPhone?.substring(1)
          : bookerPhone;
      param.bookers_country_code = `+${param.bookers_country_code}`
    } else {
      param.bookers_phone = ""
      param.bookers_country_code = ""
    }

    if (param) {
      dispatch(BookingService.editBooker(param, callback));
    }
  };

  const showEditPassengerModal = (data) => {
    setEditPassengerModal(!editPassengerModal);
    setDataPassengerRow(data ? data : []);
  };

  const handleEditPassenger = (param) => {
    const callback = () => {
      setEditPassengerModal(!editPassengerModal);
      handleRefresh();
    };

    if (
      param.phoneNumber !== "" &&
      param.phoneNumber !== undefined &&
      param.phoneNumber !== null &&
      !param.phoneNumber.includes("undefined") &&
      param.phoneNumber !== param.countryCode
    ) {
      const phone = param.phoneNumber
        .replace("+", "")
        .replace(param?.countryCode, "");

      param.phoneNumber = 
        phone.charAt(0) == 0
          ? phone?.substring(1)
          : phone;
      param.countryCode = `+${param.countryCode}`
    } else {
      param.phoneNumber = ""
      param.countryCode = ""
    }

    if (param) {
      dispatch(BookingService.editPassenger(param, callback));
    }
  };

  const columnPassengers = [
    {
      Header: "No",
      Cell: ({ row }) => {
        return row?.index + 1;
      },
    },
    {
      Header: t("field.passenger"),
      Cell: ({ row }) => {
        const { original } = row;
        return original?.name ?? "-";
      },
    },
    {
      Header: t("field.nationality"),
      Cell: ({ row }) => {
        const { original } = row;
        return original?.passenger_nationality ?? "-";
      },
    },
    {
      Header: t("field.email"),
      Cell: ({ row }) => {
        const { original } = row;
        return original?.email ?? "-";
      },
    },
    {
      Header: t("field.phone"),
      Cell: ({ row }) => {
        const { original } = row;
        return original?.phone_number && original?.country_code 
          ? `${original?.country_code}${original?.phone_number}` 
          : original?.phone_number && !original?.country_code 
          ? original?.phone_number
          : "-";
      },
    },
    {
      Header: "",
      accessor: "actions",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <DetailButton>
            <MenuItem onClick={() => showEditPassengerModal(original)}>
              <span style={{ fontSize: 14 }}>{t("commons.edit")}</span>
            </MenuItem>
          </DetailButton>
        );
      },
      show:
        (currentUser.user.role_code === "admin_bandara" ||
          currentUser.user.role_code === "supervisor") &&
        currentUser.user.airport_id !== dataDetail?.airport_id_cross_selling,
    },
  ];

  return (
    <div className="menu-container">
      {pending && <Loader loading={pending} />}
      <div className="grid-header">
        <div className="side-menu">
          <div className="menu-title text-extra-bold">
            <div className="side-menu-back-icon ">
              <span
                className="material-icons clickable"
                onClick={() => history.push(`/${currentUrl}`)}
              >
                arrow_back
              </span>
            </div>
            {`Detail ${t("field.booking")}`}
          </div>
          <div style={{ padding: "0px 15px 30px 10px" }}>
            <Card className="rounded">
              <CardFlush
                sideMenu
                title={t("field.product")}
                desc={
                  <>
                    <div>{dataDetail?.product_name_eng}</div>
                    {dataDetail?.status_go_show !== null && (
                      <div className="normal-text text-primary mt-2">
                        {dataDetail?.status_go_show === 1
                          ? "Go Show"
                          : "Go Show On The Spot"}
                      </div>
                    )}
                  </>
                }
                leftIcon="check_circle"
              />
              <CardFlush
                sideMenu
                title={t("field.bookingId")}
                desc={dataDetail?.unique_transaction_id}
                leftIcon="confirmation_number"
              />
              {dataDetail?.booking_no_from_partner && (
                <CardFlush
                  sideMenu
                  title={`${t("field.bookingId")} ${t("field.partner")}`}
                  desc={dataDetail?.booking_no_from_partner}
                  leftIcon="confirmation_number"
                />
              )}
              <CardFlush
                sideMenu
                title="SDF"
                desc={dataDetail?.sdf ? dataDetail?.sdf : "-"}
                leftIcon="book_online"
              />
              <CardFlush
                sideMenu
                title={t("field.flightType")}
                desc={
                  localStorage.getItem("joumpa_language") === "en" ||
                  localStorage.getItem("joumpa_language") === "en-US"
                    ? dataDetail?.type_label_eng
                    : dataDetail?.type_label
                }
                leftIcon="flight"
              />
              {dataDetail?.status !== 0 && (
                <CardFlush
                  sideMenu
                  title={t("field.assistant")}
                  desc={
                    dataDetail?.list_assistants
                      ? dataDetail?.list_assistants
                          ?.map((item) => {
                            return item?.is_main
                              ? `${item?.assistant_name} (main)`
                              : item?.assistant_name;
                          })
                          .join(", ")
                      : "-"
                  }
                  leftIcon="assignment_ind"
                  last={dataDetail?.order_from !== "web-public" ? false : true}
                />
              )}
              {dataDetail?.order_from !== "web-public" && (
                <CardFlush
                  sideMenu
                  title={t("commons.addedBy")}
                  desc={dataDetail?.adder_name ? dataDetail?.adder_name : "-"}
                  leftIcon="group_add"
                  last
                />
              )}
            </Card>
            <Card className="rounded">
              <CardFlush
                sideMenu
                last
                title={"Status"}
                desc={
                  dataDetail?.status === 0
                    ? t("status.new")
                    : dataDetail?.status === 1
                    ? t("status.assigned")
                    : dataDetail?.status === 2
                    ? t("status.onProgress")
                    : dataDetail?.status === 3
                    ? "Fully Booked"
                    : dataDetail?.status === 4
                    ? "Need Refund"
                    : dataDetail?.status === 5
                    ? t("status.done")
                    : dataDetail?.status === 6 &&
                      dataDetail?.refund_img_url === null
                    ? t("status.refund")
                    : dataDetail?.status === 7
                    ? t("status.reschedule")
                    : dataDetail?.status === 6 &&
                      dataDetail?.refund_img_url !== null
                    ? t("status.cancelled")
                    : t("status.cancelled")
                }
                leftIcon={dataDetail?.status === 5 ? "done" : "info"}
                iconColor={
                  dataDetail?.status === 0
                    ? "#D10D0D"
                    : dataDetail?.status === 1 ||
                      dataDetail?.status === 2 ||
                      dataDetail?.status === 3
                    ? "#f0ad4e"
                    : dataDetail?.status === 4
                    ? "#66a7e3"
                    : dataDetail?.status === 5
                    ? "#5cb85c"
                    : dataDetail?.status === 6 &&
                      dataDetail?.refund_img_url === null
                    ? "#D10D0D"
                    : dataDetail?.status === 7
                    ? "#D10D0D"
                    : dataDetail?.status === 6 &&
                      dataDetail?.refund_img_url !== null
                    ? "#A5A5C0"
                    : "#A5A5C0"
                }
              />
            </Card>
            {(
              currentUser.user.airport_id !== dataDetail?.airport_id_cross_selling &&
              (
                currentUser?.user?.role_code === Role.Sub_admin ||
                currentUser?.user?.role_code === Role.Supervisor
              )
            ) && dataDetail?.status === 0 && (
              <Button
                title={`${t("booking.assistantAssign")}`}
                rounded={true}
                style={{ width: "100%" }}
                variant="primary"
                disabled={
                  dataDetail?.date !==
                    moment(new Date()).format("YYYY-MM-DD") &&
                  moment(new Date()).format("YYYY-MM-DD") !==
                    moment(dataDetail?.date)
                      .subtract(1, "days")
                      .format("YYYY-MM-DD")
                }
                onClick={showAssignModal}
              />
            )}
          </div>
        </div>
        <div>
          <HeaderMenu title={t("commons.details")} />
          <div style={{ padding: "0 40px" }}>
            <div>
              <Stepper
                Linear
                activeStep={-1}
                alternativeLabel
                style={{
                  width:
                    dataDetail?.list_order_milestones?.length > 7
                      ? 150 * dataDetail?.list_order_milestones?.length
                      : "100%",
                }}
              >
                {dataDetail?.list_order_milestones?.map((label, index) => (
                  <Step key={label.index} completed={label.status}>
                    <StepButton key={label.index} color="inherit">
                      {localStorage.getItem("joumpa_language") === "en" ||
                      localStorage.getItem("joumpa_language") === "en-US"
                        ? label.name_eng
                        : label.name}
                      <br />{" "}
                      {label.status === 1 && label.updated_at !== null
                        ? moment(label?.updated_at).format("HH:mm")
                        : ""}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </div>
            <div className="wrapper-detail-card">
              {
                (data = [
                  {
                    label: t("field.customer"),
                    desc:
                      dataDetail?.customer_name === null
                        ? dataDetail?.list_order_passengers[0]?.name
                        : dataDetail?.customer_name,
                  },
                  {
                    label: t("field.contact"),
                    desc:
                      dataDetail?.customer_handphone === null &&
                      dataDetail?.customer_email === null
                        ? `${dataDetail?.list_order_passengers[0]?.email} / ${
                            dataDetail?.list_order_passengers[0]?.phone_number &&
                            dataDetail?.list_order_passengers[0]?.country_code
                              ? `${dataDetail?.list_order_passengers[0]?.country_code}${dataDetail?.list_order_passengers[0]?.phone_number}`
                              : dataDetail?.list_order_passengers[0]?.phone_number
                          }`
                        : dataDetail?.customer_handphone !== null &&
                          dataDetail?.customer_email !== null
                        ? `${dataDetail?.customer_email} / ${
                          dataDetail?.customer_handphone &&
                          dataDetail?.customer_country_code
                            ? `${dataDetail?.customer_country_code}${dataDetail?.customer_handphone}`
                            : dataDetail?.customer_handphone
                          }`
                        : dataDetail?.customer_email === null
                        ? `${
                          dataDetail?.customer_handphone &&
                          dataDetail?.customer_country_code
                            ? `${dataDetail?.customer_country_code}${dataDetail?.customer_handphone}`
                            : dataDetail?.customer_handphone
                          }`
                        : dataDetail?.customer_email,
                  },
                  {
                    label: t("field.corporate"),
                    desc: dataDetail?.corporate_name,
                  },
                  {
                    label: t("field.cooperationType"),
                    desc:
                      dataDetail?.corporate_group_type === "MOB"
                        ? "MOB"
                        : dataDetail?.corporate_group_type === "FIT"
                        ? "FIT"
                        : dataDetail?.corporate_group_type === "Group"
                        ? t("field.group") + " " +
                          (dataDetail?.corporate_group_type_name
                            ? dataDetail?.corporate_group_type_name
                            : "")
                        : dataDetail?.corporate_group_type === "Package"
                        ? t("field.package")
                        : "-",
                  },
                  {
                    label: t("field.bookingTime"),
                    desc: `${moment(dataDetail?.booking_date).format(
                      "DD/MM/YYYY"
                    )}, ${moment(dataDetail?.booking_date).format("HH:mm")}`,
                  },
                ].map((item, i) => {
                  let isCorporate = dataDetail?.corporate_name;
                  if (
                    item.label !== t("field.corporate") &&
                    item.label !== t("field.cooperationType")
                  ) {
                    return (
                      <CardFlush
                        list
                        label={item.label}
                        desc={item.desc}
                        size={{ label: 3, desc: 8 }}
                      />
                    );
                  } else {
                    if (isCorporate) {
                      return (
                        <CardFlush
                          list
                          label={item.label}
                          desc={item.desc}
                          size={{ label: 3, desc: 8 }}
                        />
                      );
                    }
                  }
                }))
              }
              <CardFlush
                list
                label={t("field.airline")}
                desc={`${dataDetail?.airplane_name} / ${dataDetail?.airplane_code}${dataDetail?.airplane_number}`}
                size={{ label: 3, desc: 8 }}
              />
              {dataDetail?.is_cross_selling && (
                <CardFlush
                  list
                  label={t("field.cross_selling")}
                  desc={airportCrossSelling}
                  size={{ label: 3, desc: 8 }}
                />
              )}
              {dataDetail?.type_label === "Transfer" ? (
                (data = [
                  {
                    label: t("field.origin"),
                    desc: `${dataDetail?.airport_code_from} - ${
                      dataDetail?.airport_city_from
                    } - ${dataDetail?.airport_name_from}  ${
                      dataDetail?.airport_country_from !== "Indonesia" &&
                      dataDetail?.airport_country_from !== undefined
                        ? "-" + dataDetail?.airport_country_from
                        : ""
                    }`,
                  },
                  {
                    label: `${t("field.transfer")}`,
                    desc: `${dataDetail?.airport_code_transfer} - ${
                      dataDetail?.airport_city_transfer
                    } - ${dataDetail?.airport_name_transfer} ${
                      dataDetail?.airport_country_transfer !== "Indonesia" &&
                      dataDetail?.airport_country_transfer !== undefined
                        ? "-" + dataDetail?.airport_country_transfer
                        : ""
                    }`,
                  },
                  {
                    label: t("field.destination"),
                    desc: `${dataDetail?.airport_code_to} - ${
                      dataDetail?.airport_city_to
                    } - ${dataDetail?.airport_name_to} ${
                      dataDetail?.airport_country_to !== "Indonesia" &&
                      dataDetail?.airport_country_to !== undefined
                        ? "-" + dataDetail?.airport_country_to
                        : ""
                    }`,
                  },
                  {
                    label: t("field.arrivalTime"),
                    desc:
                      moment(dataDetail?.date).format("DD/MM/YYYY") +
                      " " +
                      dataDetail?.time,
                  },
                  {
                    label: t("field.departureTime"),
                    desc: `${moment(dataDetail?.date_transfer_to).format(
                      "DD/MM/YYYY"
                    )}, ${dataDetail?.time_transfer_to}`,
                  },
                ].map((item, i) => (
                  <CardFlush
                    list
                    label={item.label}
                    desc={item.desc}
                    size={{ label: 3, desc: 8 }}
                  />
                )))
              ) : (
                <>
                  <CardFlush
                    list
                    label={t("field.origin")}
                    desc={`${dataDetail?.airport_code_from} - ${
                      dataDetail?.airport_city_from
                    } - ${dataDetail?.airport_name_from}  ${
                      dataDetail?.airport_country_from !== "Indonesia" &&
                      dataDetail?.airport_country_from !== undefined
                        ? "-" + dataDetail?.airport_country_from
                        : ""
                    }`}
                    size={{ label: 3, desc: 8 }}
                  />
                  <CardFlush
                    list
                    label={t("field.destination")}
                    desc={`${dataDetail?.airport_code_to} - ${
                      dataDetail?.airport_city_to
                    } - ${dataDetail?.airport_name_to} ${
                      dataDetail?.airport_country_to !== "Indonesia" &&
                      dataDetail?.airport_country_to !== undefined
                        ? "-" + dataDetail?.airport_country_to
                        : ""
                    }`}
                    size={{ label: 3, desc: 8 }}
                  />
                  <CardFlush
                    list
                    label={
                      dataDetail?.type_label === t("field.departure")
                        ? t("field.departureTime")
                        : t("field.arrivalTime")
                    }
                    desc={`${moment(dataDetail?.date).format("DD/MM/YYYY")}, ${
                      dataDetail?.time
                    }`}
                    size={{ label: 3, desc: 8 }}
                  />
                </>
              )}
              <CardFlush
                list
                label={t("field.note")}
                desc={dataDetail?.passenger_notes ?? "-"}
                size={{ label: 3, desc: 9 }}
                last
              />
            </div>
            <div className="wrapper-detail-card">
              <div
                className="ml-3 text-extra-bold"
                style={{ padding: 15, opacity: 0.5 }}
              >
                {t("field.additionalService")}
              </div>
              <Divider orientation="horizontal" />
              {dataLounge?.length ||
                dataTransports?.length ||
                dataOther?.length
              ? (
                <CardFlush
                  addtService
                  isLounge={dataLounge?.length > 0}
                  isTransport={dataTransports?.length > 0}
                  isOther={dataOther?.length > 0}
                  nameLounge={
                    dataLounge?.length ? dataLounge[0]?.name : t("commons.none")
                  }
                  nameTransport={
                    dataTransports?.length
                      ? dataTransports[0]?.region !== null
                        ? dataTransports[0]?.region +
                          " - " +
                          dataTransports[0]?.name +
                          " ( " +
                          (dataTransports[0]?.total_unit
                            ? dataTransports[0]?.total_unit
                            : "1") +
                          " unit)"
                        : dataTransports[0]?.name +
                          " ( " +
                          (dataTransports[0]?.total_unit
                            ? dataTransports[0]?.total_unit
                            : "1") +
                          " unit)"
                      : t("commons.none")
                  }
                  descOther={
                    dataOther?.length ? dataOther[0]?.name : t("commons.none")
                  }
                />
              ) : (
                <CardFlush
                  addtService
                  isLounge={false}
                  isTransport={false}
                  isOther={false}
                  nameLounge={t("commons.none")}
                  nameTransport={t("commons.none")}
                  descOther={t("commons.none")}
                />
              )}
            </div>
            <div className="wrapper-detail-card">
              <div
                className="ml-3 text-extra-bold"
                style={{ padding: 15, opacity: 0.5 }}
              >
                <Row>
                  <Col md={3}>{t("field.passenger")}</Col>
                </Row>
              </div>
              <Divider orientation="horizontal" />
              <Table
                columns={columnPassengers.filter((item) => item.show !== false)}
                data={dataDetail?.list_order_passengers ?? []}
                minHeight="100%"
              />
              <Divider orientation="horizontal" />
              <div
                className="d-flex align-items-center mt-4"
                style={{ paddingLeft: 15, paddingRight: 15 }}
              >
                <p
                  className="normal-title text-extra-bold"
                  style={{ opacity: 0.5 }}
                >
                  Total PAX
                </p>
                <div className="ml-auto">
                  <p className="normal-title text-bold">
                    {`${
                      dataDetail?.list_order_passengers?.filter(
                        (item) =>
                          item?.title === "Mrs." ||
                          item?.title === "Mr." ||
                          item?.title === "Ms."
                      )?.length
                    } ${t("commons.adult")}, ${
                      dataDetail?.list_order_passengers?.filter(
                        (item) => item?.title === "Child"
                      )?.length
                    } ${t("commons.child")}, ${
                      dataDetail?.list_order_passengers?.filter(
                        (item) => item?.title === "Infant"
                      )?.length
                    } ${t("commons.infantLabel")}`}
                  </p>
                </div>
              </div>
            </div>
            <div className="wrapper-detail-card">
              <div
                className="ml-3 text-extra-bold"
                style={{ padding: 15, opacity: 0.5 }}
              >
                <Row>
                  <Col md={3}>{t("field.booker")}</Col>
                </Row>
              </div>
              <Divider orientation="horizontal" />
              <Row
                className="py-3"
                style={{ fontSize: 14, padding: "15px 25px" }}
              >
                <Col
                  md={3}
                  className="text-extra-bold"
                  style={{ color: "#000" }}
                >
                  {t("field.booker")}
                </Col>
                <Col
                  md={3}
                  className="text-extra-bold"
                  style={{ color: "#000" }}
                >
                  {t("field.nationality")}
                </Col>
                <Col
                  md={3}
                  className="text-extra-bold"
                  style={{ color: "#000" }}
                >
                  {t("field.email")}
                </Col>
                <Col
                  md={2}
                  className="text-extra-bold"
                  style={{ color: "#000" }}
                >
                  {t("field.phone")}
                </Col>
                <Col md={1}></Col>
              </Row>
              <Divider orientation="horizontal" />
              <Row
                className="py-3"
                style={{ fontSize: 14, padding: "15px 25px" }}
              >
                <Col md={3}>{dataDetail?.bookers_name ?? ""}</Col>
                <Col md={3}>{dataDetail?.bookers_nationality ?? ""}</Col>
                <Col md={3}>{dataDetail?.bookers_email ?? ""}</Col>
                <Col md={2}>
                  {dataDetail?.bookers_phone && dataDetail?.bookers_country_code
                    ? `${dataDetail?.bookers_country_code}${dataDetail?.bookers_phone}` 
                    : dataDetail?.bookers_phone && !dataDetail?.bookers_country_code
                    ? dataDetail?.bookers_phone
                    : ""}
                </Col>
                <Col md={1}>
                  {currentUser.user.airport_id !==
                    dataDetail?.airport_id_cross_selling &&
                    (currentUser.user.role_code === "admin_bandara" ||
                    currentUser.user.role_code === "supervisor")
                  ? (
                    <DetailButton>
                      <MenuItem
                        onClick={() => {
                          setEditBookerModal(!editBookerModal);
                        }}
                      >
                        <span style={{ fontSize: 12 }}>
                          {t("commons.edit")}
                        </span>
                      </MenuItem>
                    </DetailButton>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </div>
            <div className="wrapper-detail-card">
              <div
                className="text-extra-bold"
                style={{
                  paddingTop: 15,
                  paddingBottom: 15,
                  paddingLeft: 25,
                  paddingRight: 25,
                  opacity: 0.5,
                }}
              >
                {t("field.detailPrice")}
              </div>
              <Divider orientation="horizontal" />
              <CardFlush
                list
                label={t("field.grandTotal")}
                desc={
                  dataDetail?.total_price
                    ? `Rp ${numberWithCommas(dataDetail.total_price)}`
                    : "Rp 0"
                }
                size={{ label: 6, desc: 6 }}
                descBold
                descEnd
                last
              />
              <div className="card-body border-top py-3">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {(dataDetail?.corporate_group_type === "MOB" &&
                    dataDetail?.under_quota_adult === 0 &&
                    dataDetail?.under_quota_child === 0 &&
                    dataDetail?.under_quota_infant === 0 &&
                    dataDetail?.plus_passenger_adult === 0 &&
                    dataDetail?.plus_passenger_child === 0 &&
                    dataDetail?.plus_passenger_infant === 0) 
                  ? (
                    <>
                      <div className="d-flex align-items-center normal-title text-extra-bold">
                        {t("field.MOBPrice")}
                        <span
                          className="material-icons"
                          style={{ fontSize: 18 }}
                          data-tip
                          data-for="includeMOBQuota"
                        >
                          info_outlined
                        </span>
                        <ReactTooltip
                          id="includeMOBQuota"
                          place="top"
                          effect="solid"
                        >
                          <p style={{ whiteSpace: "pre-line" }}>
                            {t("booking.priceMOBQuota")}
                          </p>
                        </ReactTooltip>
                      </div>
                      <div
                        className="normal-title"
                        style={{ cursor: "pointer" }}
                      >
                        Rp 0
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="normal-title text-extra-bold">
                        {dataDetail?.corporate_group_type === "Group"
                          ? t("field.groupPrice")
                          : dataDetail?.corporate_group_type === "Package"
                          ? t("field.packagePrice")
                          : dataDetail?.corporate_group_type === "FIT"
                          ? t("field.FITPrice")
                          : dataDetail?.corporate_group_type === "MOB" &&
                            (dataDetail?.under_quota_adult !== 0 ||
                              dataDetail?.under_quota_child !== 0 ||
                              dataDetail?.under_quota_infant !== 0)
                          ? `MOB ${t("field.priceUnderQuota")}`
                          : dataDetail?.corporate_group_type === "MOB" &&
                            (dataDetail?.plus_passenger_adult !== 0 ||
                              dataDetail?.plus_passenger_child !== 0 ||
                              dataDetail?.plus_passenger_infant !== 0)
                          ? t("field.individualPrice")
                          : t("field.price")}{" "}
                        {dataDetail?.product_name_eng}
                      </div>
                      <div
                        onClick={togglePriceService}
                        className="d-flex align-items-center"
                        style={{ marginRight: "-25px", cursor: "pointer" }}
                      >
                        <span className="normal-title text-extra-bold mr-2">
                          Rp {numberWithCommas(dataDetail?.price_service)}
                        </span>
                        <span className="material-icons-round left-icon">
                          expand_more
                        </span>
                      </div>
                    </>
                  )}
                </div>
                {showPriceService && (
                  <>
                    {dataDetail?.corporate_group_type === "Package" ? (
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center normal-text mt-2">
                            {t("field.price")}
                            {(dataDetail?.is_include_ppn ||
                              dataDetail?.is_include_concession) && (
                              <>
                                {infoTax(
                                  "taxGroup",
                                  dataDetail?.is_include_ppn,
                                  dataDetail?.is_include_concession,
                                  dataDetail?.ppn_percentage,
                                  dataDetail?.concession_percentage,
                                  dataDetail?.ppn_tax_price,
                                  dataDetail?.concession_tax_price
                                )}
                              </>
                            )}
                          </div>
                          {!dataDetail?.is_include_ppn && (
                            <div className="normal-text mt-1">
                              {`PPN ${dataDetail?.ppn_percentage}%`}
                            </div>
                          )}
                          {!dataDetail?.is_include_concession && (
                            <div className="normal-text mt-1">
                              {`Concession ${dataDetail?.concession_percentage}%`}
                            </div>
                          )}
                        </div>
                        <div className="text-right">
                          <div className="normal-text mt-2">
                            {"Rp " +
                              numberWithCommas(dataDetail?.price_service)}
                          </div>
                          {!dataDetail?.is_include_ppn && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(dataDetail?.ppn_tax_price)}
                            </div>
                          )}
                          {!dataDetail?.is_include_concession && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  dataDetail?.concession_tax_price
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : dataDetail?.corporate_group_type === "MOB" &&
                      (
                        dataDetail?.under_quota_adult !== 0 ||
                        dataDetail?.under_quota_child !== 0 ||
                        dataDetail?.under_quota_infant !== 0
                      ) ? (
                      <>
                        {dataDetail?.under_quota_adult !== 0 && (
                          <>
                            <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                              {t("commons.adult")}
                            </div>
                            <div className="d-flex justify-content-between">
                              <div>
                                <div className="d-flex align-items-center normal-text">
                                  {t("field.price")}
                                  {(dataDetail?.is_include_ppn ||
                                    dataDetail?.is_include_concession) && (
                                    <>
                                      {infoTax(
                                        "taxIndividualAdult",
                                        dataDetail?.is_include_ppn,
                                        dataDetail?.is_include_concession,
                                        dataDetail?.ppn_percentage,
                                        dataDetail?.concession_percentage,
                                        dataDetail?.ppn_adult_price,
                                        dataDetail?.concession_adult_price
                                      )}
                                    </>
                                  )}
                                </div>
                                {!dataDetail?.is_include_ppn && (
                                  <div className="normal-text mt-1">
                                    {`PPN ${dataDetail?.ppn_percentage}%`}
                                  </div>
                                )}
                                {!dataDetail?.is_include_concession && (
                                  <div className="normal-text mt-1">
                                    {`Concession ${dataDetail?.concession_percentage}%`}
                                  </div>
                                )}
                              </div>
                              <div className="text-right">
                                <div className="normal-text">
                                  {dataDetail?.under_quota_adult + " "}
                                  <span>
                                    {" x  Rp " +
                                      numberWithCommas(
                                        dataDetail?.adult_price_original_mob_under_quota
                                      )}
                                  </span>
                                </div>
                                {!dataDetail?.is_include_ppn && (
                                  <div className="normal-text mt-1">
                                    {"Rp " +
                                      numberWithCommas(
                                        dataDetail?.ppn_adult_price
                                      )}
                                  </div>
                                )}
                                {!dataDetail?.is_include_concession && (
                                  <div className="normal-text mt-1">
                                    {"Rp " +
                                      numberWithCommas(
                                        dataDetail?.concession_adult_price
                                      )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        {dataDetail?.under_quota_child !== 0 && (
                          <>
                            <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                              {t("commons.child")}
                            </div>
                            <div className="d-flex justify-content-between">
                              <div>
                                <div className="d-flex align-items-center normal-text">
                                  {t("field.price")}
                                  {(dataDetail?.is_include_ppn ||
                                    dataDetail?.is_include_concession) && (
                                    <>
                                      {infoTax(
                                        "taxIndividualChild",
                                        dataDetail?.is_include_ppn,
                                        dataDetail?.is_include_concession,
                                        dataDetail?.ppn_percentage,
                                        dataDetail?.concession_percentage,
                                        dataDetail?.ppn_child_price,
                                        dataDetail?.concession_child_price
                                      )}
                                    </>
                                  )}
                                </div>
                                {!dataDetail?.is_include_ppn && (
                                  <div className="normal-text mt-1">
                                    {`PPN ${dataDetail?.ppn_percentage}%`}
                                  </div>
                                )}
                                {!dataDetail?.is_include_concession && (
                                  <div className="normal-text mt-1">
                                    {`Concession ${dataDetail?.concession_percentage}%`}
                                  </div>
                                )}
                              </div>
                              <div className="text-right">
                                <div className="normal-text">
                                  {dataDetail?.under_quota_child + " "}
                                  <span>
                                    {" x  Rp " +
                                      numberWithCommas(
                                        dataDetail?.child_price_original_mob_under_quota
                                      )}
                                  </span>
                                </div>
                                {!dataDetail?.is_include_ppn && (
                                  <div className="normal-text mt-1">
                                    {"Rp " +
                                      numberWithCommas(
                                        dataDetail?.ppn_child_price
                                      )}
                                  </div>
                                )}
                                {!dataDetail?.is_include_concession && (
                                  <div className="normal-text mt-1">
                                    {"Rp " +
                                      numberWithCommas(
                                        dataDetail?.concession_child_price
                                      )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        {dataDetail?.under_quota_infant !== 0 && (
                          <>
                            <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                              {t("commons.infantLabel")}
                            </div>
                            <div className="d-flex justify-content-between">
                              <div>
                                <div className="d-flex align-items-center normal-text">
                                  {t("field.price")}
                                  {(dataDetail?.is_include_ppn ||
                                    dataDetail?.is_include_concession) && (
                                    <>
                                      {infoTax(
                                        "taxIndividualInfant",
                                        dataDetail?.is_include_ppn,
                                        dataDetail?.is_include_concession,
                                        dataDetail?.ppn_percentage,
                                        dataDetail?.concession_percentage,
                                        dataDetail?.ppn_infant_price,
                                        dataDetail?.concession_infant_price
                                      )}
                                    </>
                                  )}
                                </div>
                                {!dataDetail?.is_include_ppn && (
                                  <div className="normal-text mt-1">
                                    {`PPN ${dataDetail?.ppn_percentage}%`}
                                  </div>
                                )}
                                {!dataDetail?.is_include_concession && (
                                  <div className="normal-text mt-1">
                                    {`Concession ${dataDetail?.concession_percentage}%`}
                                  </div>
                                )}
                              </div>
                              <div className="text-right">
                                <div className="normal-text">
                                  {dataDetail?.under_quota_infant + " "}
                                  <span>
                                    {" x  Rp " +
                                      numberWithCommas(
                                        dataDetail?.infant_price_original_mob_under_quota
                                      )}
                                  </span>
                                </div>
                                {!dataDetail?.is_include_ppn && (
                                  <div className="normal-text mt-1">
                                    {"Rp " +
                                      numberWithCommas(
                                        dataDetail?.ppn_infant_price
                                      )}
                                  </div>
                                )}
                                {!dataDetail?.is_include_concession && (
                                  <div className="normal-text mt-1">
                                    {"Rp " +
                                      numberWithCommas(
                                        dataDetail?.concession_infant_price
                                      )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : dataDetail?.corporate_group_type === "MOB" &&
                      (dataDetail?.plus_passenger_adult !== 0 ||
                        dataDetail?.plus_passenger_child !== 0 ||
                        dataDetail?.plus_passenger_infant !== 0) ? (
                      <>
                        {dataDetail?.plus_passenger_adult !== 0 && (
                          <>
                            <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                              {t("commons.adult")}
                            </div>
                            <div className="d-flex justify-content-between">
                              <div>
                                <div className="d-flex align-items-center normal-text">
                                  {t("field.price")}
                                  {(dataDetail?.is_include_ppn ||
                                    dataDetail?.is_include_concession) && (
                                    <>
                                      {infoTax(
                                        "taxIndividualAdult",
                                        dataDetail?.is_include_ppn,
                                        dataDetail?.is_include_concession,
                                        dataDetail?.ppn_percentage,
                                        dataDetail?.concession_percentage,
                                        dataDetail?.ppn_adult_price,
                                        dataDetail?.concession_adult_price
                                      )}
                                    </>
                                  )}
                                </div>
                                {!dataDetail?.is_include_ppn && (
                                  <div className="normal-text mt-1">
                                    {`PPN ${dataDetail?.ppn_percentage}%`}
                                  </div>
                                )}
                                {!dataDetail?.is_include_concession && (
                                  <div className="normal-text mt-1">
                                    {`Concession ${dataDetail?.concession_percentage}%`}
                                  </div>
                                )}
                              </div>
                              <div className="text-right">
                                <div className="normal-text">
                                  {dataDetail?.plus_passenger_adult + " "}
                                  <span>
                                    {" x  Rp " +
                                      numberWithCommas(
                                        dataDetail?.adult_price_original
                                      )}
                                  </span>
                                </div>
                                {!dataDetail?.is_include_ppn && (
                                  <div className="normal-text mt-1">
                                    {"Rp " +
                                      numberWithCommas(
                                        dataDetail?.ppn_adult_price
                                      )}
                                  </div>
                                )}
                                {!dataDetail?.is_include_concession && (
                                  <div className="normal-text mt-1">
                                    {"Rp " +
                                      numberWithCommas(
                                        dataDetail?.concession_adult_price
                                      )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        {dataDetail?.plus_passenger_child !== 0 && (
                          <>
                            <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                              {t("commons.child")}
                            </div>
                            <div className="d-flex justify-content-between">
                              <div>
                                <div className="d-flex align-items-center normal-text">
                                  {t("field.price")}
                                  {(dataDetail?.is_include_ppn ||
                                    dataDetail?.is_include_concession) && (
                                    <>
                                      {infoTax(
                                        "taxIndividualChild",
                                        dataDetail?.is_include_ppn,
                                        dataDetail?.is_include_concession,
                                        dataDetail?.ppn_percentage,
                                        dataDetail?.concession_percentage,
                                        dataDetail?.ppn_child_price,
                                        dataDetail?.concession_child_price
                                      )}
                                    </>
                                  )}
                                </div>
                                {!dataDetail?.is_include_ppn && (
                                  <div className="normal-text mt-1">
                                    {`PPN ${dataDetail?.ppn_percentage}%`}
                                  </div>
                                )}
                                {!dataDetail?.is_include_concession && (
                                  <div className="normal-text mt-1">
                                    {`Concession ${dataDetail?.concession_percentage}%`}
                                  </div>
                                )}
                              </div>
                              <div className="text-right">
                                <div className="normal-text">
                                  {dataDetail?.plus_passenger_child + " "}
                                  <span>
                                    {" x  Rp " +
                                      numberWithCommas(
                                        dataDetail?.child_price_original
                                      )}
                                  </span>
                                </div>
                                {!dataDetail?.is_include_ppn && (
                                  <div className="normal-text mt-1">
                                    {"Rp " +
                                      numberWithCommas(
                                        dataDetail?.ppn_child_price
                                      )}
                                  </div>
                                )}
                                {!dataDetail?.is_include_concession && (
                                  <div className="normal-text mt-1">
                                    {"Rp " +
                                      numberWithCommas(
                                        dataDetail?.concession_child_price
                                      )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        {dataDetail?.plus_passenger_infant !== 0 && (
                          <>
                            <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                              {t("commons.infantLabel")}
                            </div>
                            <div className="d-flex justify-content-between">
                              <div>
                                <div className="d-flex align-items-center normal-text">
                                  {t("field.price")}
                                  {(dataDetail?.is_include_ppn ||
                                    dataDetail?.is_include_concession) && (
                                    <>
                                      {infoTax(
                                        "taxIndividualInfant",
                                        dataDetail?.is_include_ppn,
                                        dataDetail?.is_include_concession,
                                        dataDetail?.ppn_percentage,
                                        dataDetail?.concession_percentage,
                                        dataDetail?.ppn_infant_price,
                                        dataDetail?.concession_infant_price
                                      )}
                                    </>
                                  )}
                                </div>
                                {!dataDetail?.is_include_ppn && (
                                  <div className="normal-text mt-1">
                                    {`PPN ${dataDetail?.ppn_percentage}%`}
                                  </div>
                                )}
                                {!dataDetail?.is_include_concession && (
                                  <div className="normal-text mt-1">
                                    {`Concession ${dataDetail?.concession_percentage}%`}
                                  </div>
                                )}
                              </div>
                              <div className="text-right">
                                <div className="normal-text">
                                  {dataDetail?.plus_passenger_infant + " "}
                                  <span>
                                    {" x  Rp " +
                                      numberWithCommas(
                                        dataDetail?.infant_price_original
                                      )}
                                  </span>
                                </div>
                                {!dataDetail?.is_include_ppn && (
                                  <div className="normal-text mt-1">
                                    {"Rp " +
                                      numberWithCommas(
                                        dataDetail?.ppn_infant_price
                                      )}
                                  </div>
                                )}
                                {!dataDetail?.is_include_concession && (
                                  <div className="normal-text mt-1">
                                    {"Rp " +
                                      numberWithCommas(
                                        dataDetail?.concession_infant_price
                                      )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {dataPassengerAdult !== 0 && (
                          <>
                            <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                              {t("commons.adult")}
                            </div>
                            <div className="d-flex justify-content-between">
                              <div>
                                <div className="d-flex align-items-center normal-text">
                                  {t("field.price")}
                                  {(dataDetail?.is_include_ppn ||
                                    dataDetail?.is_include_concession) && (
                                    <>
                                      {infoTax(
                                        "taxAdult",
                                        dataDetail?.is_include_ppn,
                                        dataDetail?.is_include_concession,
                                        dataDetail?.ppn_percentage,
                                        dataDetail?.concession_percentage,
                                        dataDetail?.ppn_adult_price,
                                        dataDetail?.concession_adult_price
                                      )}
                                    </>
                                  )}
                                </div>
                                {!dataDetail?.is_include_ppn && (
                                  <div className="normal-text mt-1">
                                    {`PPN ${dataDetail?.ppn_percentage}%`}
                                  </div>
                                )}
                                {!dataDetail?.is_include_concession && (
                                  <div className="normal-text mt-1">
                                    {`Concession ${dataDetail?.concession_percentage}%`}
                                  </div>
                                )}
                              </div>
                              <div className="text-right">
                                <div className="normal-text">
                                  {dataPassengerAdult + " "}
                                  <span>
                                    {" x  Rp " +
                                      numberWithCommas(
                                        dataDetail?.adult_price_original
                                      )}
                                  </span>
                                </div>
                                {!dataDetail?.is_include_ppn && (
                                  <div className="normal-text mt-1">
                                    {"Rp " +
                                      numberWithCommas(
                                        dataDetail?.ppn_adult_price
                                      )}
                                  </div>
                                )}
                                {!dataDetail?.is_include_concession && (
                                  <div className="normal-text mt-1">
                                    {"Rp " +
                                      numberWithCommas(
                                        dataDetail?.concession_adult_price
                                      )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        {dataPassengerChild !== 0 && (
                          <>
                            <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                              {t("commons.child")}
                            </div>
                            <div className="d-flex justify-content-between">
                              <div>
                                <div className="d-flex align-items-center normal-text">
                                  {t("field.price")}
                                  {(dataDetail?.is_include_ppn ||
                                    dataDetail?.is_include_concession) && (
                                    <>
                                      {infoTax(
                                        "taxChild",
                                        dataDetail?.is_include_ppn,
                                        dataDetail?.is_include_concession,
                                        dataDetail?.ppn_percentage,
                                        dataDetail?.concession_percentage,
                                        dataDetail?.ppn_child_price,
                                        dataDetail?.concession_child_price
                                      )}
                                    </>
                                  )}
                                </div>
                                {!dataDetail?.is_include_ppn && (
                                  <div className="normal-text mt-1">
                                    {`PPN ${dataDetail?.ppn_percentage}%`}
                                  </div>
                                )}
                                {!dataDetail?.is_include_concession && (
                                  <div className="normal-text mt-1">
                                    {`Concession ${dataDetail?.concession_percentage}%`}
                                  </div>
                                )}
                              </div>
                              <div className="text-right">
                                <div className="normal-text">
                                  {dataPassengerChild + " "}
                                  <span>
                                    {" x  Rp " +
                                      numberWithCommas(
                                        dataDetail?.child_price_original
                                      )}
                                  </span>
                                </div>
                                {!dataDetail?.is_include_ppn && (
                                  <div className="normal-text mt-1">
                                    {"Rp " +
                                      numberWithCommas(
                                        dataDetail?.ppn_child_price
                                      )}
                                  </div>
                                )}
                                {!dataDetail?.is_include_concession && (
                                  <div className="normal-text mt-1">
                                    {"Rp " +
                                      numberWithCommas(
                                        dataDetail?.concession_child_price
                                      )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        {dataPassengerInfant !== 0 && (
                          <>
                            <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                              {t("commons.infantLabel")}
                            </div>
                            <div className="d-flex justify-content-between">
                              <div>
                                <div className="d-flex align-items-center normal-text">
                                  {t("field.price")}
                                  {(dataDetail?.is_include_ppn ||
                                    dataDetail?.is_include_concession) && (
                                    <>
                                      {infoTax(
                                        "taxInfant",
                                        dataDetail?.is_include_ppn,
                                        dataDetail?.is_include_concession,
                                        dataDetail?.ppn_percentage,
                                        dataDetail?.concession_percentage,
                                        dataDetail?.ppn_infant_price,
                                        dataDetail?.concession_infant_price
                                      )}
                                    </>
                                  )}
                                </div>
                                {!dataDetail?.is_include_ppn && (
                                  <div className="normal-text mt-1">
                                    {`PPN ${dataDetail?.ppn_percentage}%`}
                                  </div>
                                )}
                                {!dataDetail?.is_include_concession && (
                                  <div className="normal-text mt-1">
                                    {`Concession ${dataDetail?.concession_percentage}%`}
                                  </div>
                                )}
                              </div>
                              <div className="text-right">
                                <div className="normal-text">
                                  {dataPassengerInfant + " "}
                                  <span>
                                    {" x  Rp " +
                                      numberWithCommas(
                                        dataDetail?.infant_price_original
                                      )}
                                  </span>
                                </div>
                                {!dataDetail?.is_include_ppn && (
                                  <div className="normal-text mt-1">
                                    {"Rp " +
                                      numberWithCommas(
                                        dataDetail?.ppn_infant_price
                                      )}
                                  </div>
                                )}
                                {!dataDetail?.is_include_concession && (
                                  <div className="normal-text mt-1">
                                    {"Rp " +
                                      numberWithCommas(
                                        dataDetail?.concession_infant_price
                                      )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="card-body border-top py-3">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="normal-title text-extra-bold">
                    {t("field.lounge")}
                  </div>
                  {dataLounge?.length ? (
                    <div
                      onClick={toggleLounge}
                      className="d-flex align-items-center"
                      style={{ marginRight: "-30px", cursor: "pointer" }}
                    >
                      <span className="normal-title text-extra-bold mr-2">
                        Rp {numberWithCommas(dataLounge[0]?.total_price)}
                      </span>
                      <span className="material-icons-round left-icon">
                        expand_more
                      </span>
                    </div>
                  ) : (
                    <div
                      className="normal-title text-extra-bold"
                      style={{ cursor: "pointer" }}
                    >
                      Rp 0
                    </div>
                  )}
                </div>
                {showLounge && (
                  <>
                    {dataLounge[0]?.number_lounge_adult !== "" &&
                      dataLounge[0]?.number_lounge_adult !== 0 && (
                        <>
                          <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                            {t("commons.adult")}
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>
                              <div className="d-flex align-items-center normal-text mb-1">
                                {t("field.price")}
                                {(dataLounge[0]?.is_include_ppn ||
                                  dataLounge[0]?.is_include_concession) && (
                                  <>
                                    {infoTax(
                                      "taxLoungeAdult",
                                      dataLounge[0]?.is_include_ppn,
                                      dataLounge[0]?.is_include_concession,
                                      dataLounge[0]?.ppn_percentage,
                                      dataLounge[0]?.concession_percentage,
                                      dataLounge[0]?.ppn_adult_lounge,
                                      dataLounge[0]?.concession_adult_lounge
                                    )}
                                  </>
                                )}
                              </div>
                              {!dataLounge[0]?.is_include_ppn && (
                                <div className="normal-text mt-1">
                                  {`PPN ${dataLounge[0]?.ppn_percentage}%`}
                                </div>
                              )}
                              {!dataLounge[0]?.is_include_concession && (
                                <div className="normal-text mt-1">
                                  {`Concession ${dataLounge[0]?.concession_percentage}%`}
                                </div>
                              )}
                            </div>
                            <div className="text-right">
                              <div className="normal-text mb-1">
                                {dataLounge[0]?.number_lounge_adult + " "}
                                <span>
                                  {" x  Rp " +
                                    numberWithCommas(
                                      dataLounge[0]?.adult_price_original
                                    )}
                                </span>
                              </div>
                              {!dataLounge[0]?.is_include_ppn && (
                                <div className="normal-text mt-1">
                                  {"Rp " +
                                    numberWithCommas(
                                      dataLounge[0]?.ppn_adult_lounge
                                    )}
                                </div>
                              )}
                              {!dataLounge[0]?.is_include_concession && (
                                <div className="normal-text mt-1">
                                  {"Rp " +
                                    numberWithCommas(
                                      dataLounge[0]?.concession_adult_lounge
                                    )}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    {dataLounge[0]?.number_lounge_child !== "" &&
                      dataLounge[0]?.number_lounge_child !== 0 && (
                        <>
                          <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                            {t("commons.child")}
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>
                              <div className="d-flex align-items-center normal-text mb-1">
                                {t("field.price")}
                                {(dataLounge[0]?.is_include_ppn ||
                                  dataLounge[0]?.is_include_concession) && (
                                  <>
                                    {infoTax(
                                      "taxLoungeChild",
                                      dataLounge[0]?.is_include_ppn,
                                      dataLounge[0]?.is_include_concession,
                                      dataLounge[0]?.ppn_percentage,
                                      dataLounge[0]?.concession_percentage,
                                      dataLounge[0]?.ppn_child_lounge,
                                      dataLounge[0]?.concession_child_lounge
                                    )}
                                  </>
                                )}
                              </div>
                              {!dataLounge[0]?.is_include_ppn && (
                                <div className="normal-text mt-1">
                                  {`PPN ${dataLounge[0]?.ppn_percentage}%`}
                                </div>
                              )}
                              {!dataLounge[0]?.is_include_concession && (
                                <div className="normal-text mt-1">
                                  {`Concession ${dataLounge[0]?.concession_percentage}%`}
                                </div>
                              )}
                            </div>
                            <div className="text-right">
                              <div className="normal-text mb-1">
                                {dataLounge[0]?.number_lounge_child + " "}
                                <span>
                                  {" x  Rp " +
                                    numberWithCommas(
                                      dataLounge[0]?.child_price_original
                                    )}
                                </span>
                              </div>
                              {!dataLounge[0]?.is_include_ppn && (
                                <div className="normal-text mt-1">
                                  {"Rp " +
                                    numberWithCommas(
                                      dataLounge[0]?.ppn_child_lounge
                                    )}
                                </div>
                              )}
                              {!dataLounge[0]?.is_include_concession && (
                                <div className="normal-text mt-1">
                                  {"Rp " +
                                    numberWithCommas(
                                      dataLounge[0]?.concession_child_lounge
                                    )}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    {dataLounge[0]?.number_lounge_infant !== "" &&
                      dataLounge[0]?.number_lounge_infant !== 0 && (
                        <>
                          <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                            {t("commons.infantLabel")}
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>
                              <div className="normal-text mb-1">
                                {t("field.price")}
                                {(dataLounge[0]?.is_include_ppn ||
                                  dataLounge[0]?.is_include_concession) && (
                                  <>
                                    {infoTax(
                                      "taxLoungeInfant",
                                      dataLounge[0]?.is_include_ppn,
                                      dataLounge[0]?.is_include_concession,
                                      dataLounge[0]?.ppn_percentage,
                                      dataLounge[0]?.concession_percentage,
                                      dataLounge[0]?.ppn_infant_lounge,
                                      dataLounge[0]?.concession_infant_lounge
                                    )}
                                  </>
                                )}
                              </div>
                              {!dataLounge[0]?.is_include_ppn && (
                                <div className="normal-text mt-1">
                                  {`PPN ${dataLounge[0]?.ppn_percentage}%`}
                                </div>
                              )}
                              {!dataLounge[0]?.is_include_concession && (
                                <div className="normal-text mt-1">
                                  {`Concession ${dataLounge[0]?.concession_percentage}%`}
                                </div>
                              )}
                            </div>
                            <div className="text-right">
                              <div className="normal-text mb-1">
                                {dataLounge[0]?.number_lounge_infant + " "}
                                <span>
                                  {" x  Rp " +
                                    numberWithCommas(
                                      dataLounge[0]?.infant_price_original
                                    )}
                                </span>
                              </div>
                              {!dataLounge[0]?.is_include_ppn && (
                                <div className="normal-text mt-1">
                                  {"Rp " +
                                    numberWithCommas(
                                      dataLounge[0]?.ppn_infant_lounge
                                    )}
                                </div>
                              )}
                              {!dataLounge[0]?.is_include_concession && (
                                <div className="normal-text mt-1">
                                  {"Rp " +
                                    numberWithCommas(
                                      dataLounge[0]?.concession_infant_lounge
                                    )}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                  </>
                )}
              </div>
              {dataDetail?.passenger_type_label_eng === "Corporate" ? (
                <div className="card-body border-top py-3">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="normal-title text-extra-bold">
                      {t("field.transportation")}
                    </div>
                    {dataTransports?.length ? (
                      <div
                        onClick={() => setShowTransport(!showTransport)}
                        className="d-flex align-items-center"
                        style={{ marginRight: "-30px", cursor: "pointer" }}
                      >
                        <span className="normal-title text-extra-bold mr-2">
                          Rp {numberWithCommas(dataTransports[0]?.total_price)}
                        </span>
                        <span className="material-icons-round left-icon">
                          expand_more
                        </span>
                      </div>
                    ) : (
                      <div
                        className="normal-title text-extra-bold"
                        style={{ cursor: "pointer" }}
                      >
                        Rp 0
                      </div>
                    )}
                  </div>
                  {showTransport && (
                    <div className="d-flex justify-content-between">
                      <div>
                        <div className="d-flex align-items-center normal-text mt-2">
                          {t("field.price")}
                          {(dataTransports[0]?.is_include_ppn ||
                            dataTransports[0]?.is_include_concession) && (
                            <>
                              {infoTax(
                                "taxGroup",
                                dataTransports[0]?.is_include_ppn,
                                dataTransports[0]?.is_include_concession,
                                dataTransports[0]?.ppn_percentage,
                                dataTransports[0]?.concession_percentage,
                                dataTransports[0]?.ppn,
                                dataTransports[0]?.concession
                              )}
                            </>
                          )}
                        </div>
                        {!dataTransports[0]?.is_include_ppn && (
                          <div className="normal-text mt-1">
                            {`PPN ${dataTransports[0]?.ppn_percentage}%`}
                          </div>
                        )}
                        {!dataTransports[0]?.is_include_concession && (
                          <div className="normal-text mt-1">
                            {`Concession ${dataTransports[0]?.concession_percentage}%`}
                          </div>
                        )}
                      </div>
                      <div className="text-right">
                        <div className="normal-text mt-2">
                          {dataTransports[0]?.total_unit +
                            " unit  x Rp" +
                            numberWithCommas(dataTransports[0]?.price_original)}
                        </div>
                        {!dataTransports[0]?.is_include_ppn && (
                          <div className="normal-text mt-1">
                            {"Rp " + numberWithCommas(dataTransports[0]?.ppn)}
                          </div>
                        )}
                        {!dataTransports[0]?.is_include_concession && (
                          <div className="normal-text mt-1">
                            {"Rp " +
                              numberWithCommas(dataTransports[0]?.concession)}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ) : dataTransports?.length ? (
                <div className="card-body d-flex justify-content-between border-top py-3">
                  <div className="d-flex align-items-center normal-title text-extra-bold">
                    {t("field.transportation")}
                    {dataTransports[0]?.total_unit > 0 &&
                      dataTransports[0]?.is_include_ppn && (
                        <>
                          <span
                            className="material-icons"
                            style={{ fontSize: 18 }}
                            data-tip
                            data-for="taxTransports"
                          >
                            info_outlined
                          </span>
                          <ReactTooltip
                            id="taxTransports"
                            place="top"
                            effect="solid"
                          >
                            <p style={{ whiteSpace: "pre-line" }}>
                              {`${t("product.includes_ppn")} ${
                                dataTransports[0]?.ppn_percentage
                              }%, Rp ${numberWithCommas(
                                dataTransports[0]?.ppn
                              )}`}
                            </p>
                          </ReactTooltip>
                        </>
                      )}
                  </div>
                  <div className="normal-title ">
                    {dataTransports[0]?.total_unit +
                      " unit  x Rp" +
                      numberWithCommas(dataTransports[0]?.price_original)}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {dataOther?.length ? (
                <>
                  <div className="card-body border-top py-3">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="normal-title text-extra-bold">
                        {t("masterData.others")}
                      </div>
                      <div
                        onClick={() => setShowOthers(!showOthers)}
                        className="d-flex align-items-center"
                        style={{ marginRight: "-30px", cursor: "pointer" }}
                      >
                        <span className="normal-title text-extra-bold mr-2">
                          Rp {numberWithCommas(dataOther[0]?.total_price)}
                        </span>
                        <span className="material-icons-round left-icon">
                          expand_more
                        </span>
                      </div>
                    </div>
                    {showOthers && (
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center normal-text mt-2">
                            {t("field.price")}
                            {(dataOther[0]?.is_include_ppn ||
                              dataOther[0]?.is_include_concession) && (
                              <>
                                {infoTax(
                                  "taxOthers",
                                  dataOther[0]?.is_include_ppn,
                                  dataOther[0]?.is_include_concession,
                                  dataOther[0]?.ppn_percentage,
                                  dataOther[0]?.concession_percentage,
                                  dataOther[0]?.ppn,
                                  dataOther[0]?.concession
                                )}
                              </>
                            )}
                          </div>
                          {!dataOther[0]?.is_include_ppn && (
                            <div className="normal-text mt-1">
                              {dataOther[0]?.dpp !== null &&
                              dataOther[0]?.dpp !== 0
                                ? `PPN ${
                                    dataOther[0]?.ppn_percentage
                                  }% x DPP Rp ${numberWithCommas(
                                    dataOther[0]?.dpp
                                  )}`
                                : `PPN ${dataOther[0]?.ppn_percentage}%`}
                            </div>
                          )}
                          {!dataOther[0]?.is_include_concession && (
                            <div className="normal-text mt-1">
                              {dataOther[0]?.dpp !== null &&
                              dataOther[0]?.dpp !== 0
                                ? `Concession ${
                                    dataOther[0]?.concession_percentage
                                  }% x DPP Rp ${numberWithCommas(
                                    dataOther[0]?.dpp
                                  )}`
                                : `Concession ${dataOther[0]?.concession_percentage}%`}
                            </div>
                          )}
                        </div>
                        <div className="text-right">
                          <div className="normal-text mt-2">
                            {"Rp " +
                              numberWithCommas(dataOther[0]?.price_original)}
                          </div>
                          {!dataOther[0]?.is_include_ppn && (
                            <div className="normal-text mt-1">
                              {"Rp " + numberWithCommas(dataOther[0]?.ppn)}
                            </div>
                          )}
                          {!dataOther[0]?.is_include_concession && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(dataOther[0]?.concession)}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div></div>
              )}
              {dataDetail?.promo_id && (
                <>
                  <div className="card-body border-top py-3">
                    <div className="normal-title text-extra-bold mb-1">
                      Promo
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="normal-text mt-1">
                        {dataDetail?.promo_detail?.name}
                        <span
                          style={{
                            borderRight: "1px solid #A9A9A9",
                            height: 20,
                            marginLeft: 8,
                            marginRight: 8,
                          }}
                        ></span>
                        <span className="text-primary">
                          {dataDetail?.promo_detail?.type === "blast"
                            ? dataDetail?.promo_detail?.type_label
                            : dataDetail?.promo_detail?.type === "specific"
                            ? t("promo.special")
                            : ""}
                        </span>
                      </div>
                      <div className="text-right">
                        <div className="normal-text text-danger mb-1">
                          {dataDetail?.price_cut
                            ? `Rp ${numberWithCommas(dataDetail.price_cut)}`
                            : "Rp 0"}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {dataDetail?.promo_id_cross_selling && (
                <>
                  <div className="card-body border-top py-3">
                    <div className="normal-title text-extra-bold mb-2">
                      {t("field.cross_selling")}
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-1">
                        <div className="normal-text">
                          {t("promo.airportCrossSelling")}
                          <span className="text-primary">
                            {dataDetail?.promo_detail_cross_selling
                              ? 
                                <>
                                  <span
                                    style={{
                                      borderRight: "1px solid #A9A9A9",
                                      height: 20,
                                      marginLeft: 8,
                                      marginRight: 8,
                                    }}
                                  ></span>
                                  <span>
                                    {`${dataDetail?.promo_detail_cross_selling?.discount_percent}%`}
                                  </span>
                                </>
                              : ""}
                          </span>
                      </div>
                      <div className="normal-text text-primary">
                        Rp {numberWithCommas(dataDetail?.price_cut_cross_selling ?? 0)}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-1">
                      <div className="normal-text">
                        {t("promo.airportService")}
                        <span className="text-primary">
                          {dataDetail?.promo_detail_cross_selling
                            ? 
                              <>
                                <span
                                  style={{
                                    borderRight: "1px solid #A9A9A9",
                                    height: 20,
                                    marginLeft: 8,
                                    marginRight: 8,
                                  }}
                                ></span>
                                <span>
                                  {`${parseInt(100 - dataDetail?.promo_detail_cross_selling?.discount_percent)}%`}
                                </span>
                              </>
                            : ""}
                        </span>
                      </div>
                      <div className="normal-text text-primary">
                        Rp {numberWithCommas(dataDetail?.total_price_cross_selling ?? 0)}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="wrapper-detail-card">
              <div
                className="text-extra-bold"
                style={{
                  paddingTop: 15,
                  paddingBottom: 15,
                  paddingLeft: 25,
                  paddingRight: 25,
                }}
              >
                <Row>
                  <Col md={7}>
                    <span style={{ opacity: 0.5 }}>
                      {t("field.pagingInfo")}
                    </span>
                    <span class="ml-2 normal-text text-medium">
                      {dataDetail?.show_joumpa_logo
                        ? `(${t("field.includeLogoJoumpa")})`
                        : `(Not ${t("field.includeLogoJoumpa")})`}
                    </span>
                  </Col>
                  <Col md={5}>
                    <div className="d-flex justify-content-end">
                      <a
                        className="link text-bold"
                        style={{
                          fontSize: 14,
                          opacity: 1,
                          cursor: "pointer",
                        }}
                        onClick={
                          dataDetail?.paging_logo !== null
                            ? () => setShowPagingLogo(!showPagingLogo)
                            : null
                        }
                      >
                        {dataDetail?.paging_logo !== null
                          ? t("field.pagingImage")
                          : ""}
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
              <Divider orientation="horizontal" />
              <CardFlush
                list
                label={t("field.pagingName")}
                desc={dataDetail?.paging_name ? dataDetail?.paging_name : "-"}
                size={{ label: 4, desc: 8 }}
                descEnd
                last
              />
              {dataDetail?.passenger_type_label_eng === "Corporate" &&
                ((currentUser.user.role_code === "admin_bandara" ||
                  currentUser.user.role_code === "supervisor" ||
                  currentUser.user.role_code === "assistant") &&
                currentUser.user.airport_id !== dataDetail?.airport_id_cross_selling)
              && (
                <>
                  <Divider orientation="horizontal" />
                  <div
                    className="d-flex justify-content-start align-items-center p-3"
                    onClick={() => setShowEditPaging(!showEditPaging)}
                  >
                    <div
                      className="left-icon float-left rounded-fill"
                      style={{ padding: "4px 6px 1px 6px" }}
                    >
                      <span
                        className="material-icons clickable"
                        style={{ fontSize: "15px", color: "#63ae5c" }}
                      >
                        edit
                      </span>
                    </div>
                    <div
                      className="clickable normal-title text-extra-bold pl-0"
                      style={{ color: "#63ae5c" }}
                    >
                      Edit {t("field.pagingInfo")}
                    </div>
                  </div>
                </>
              )}
            </div>
            {dataDetail?.passenger_type_label_eng !== "Corporate" && (
              <div className="wrapper-detail-card">
                <div
                  className="text-extra-bold"
                  style={{
                    paddingTop: 15,
                    paddingBottom: 15,
                    paddingLeft: 25,
                    paddingRight: 25,
                  }}
                >
                  <Row>
                    <Col md={3} style={{ opacity: 0.5 }}>
                      {t("field.payment")}
                    </Col>
                    <Col md={9}>
                      <div className="d-flex justify-content-end">
                        <div
                          className="text-primary text-bold"
                          style={{
                            fontSize: 14,
                            cursor: "pointer",
                          }}
                          onClick={
                            dataDetail?.payment_proof && !dataDetail?.midtrans_order_id
                              ? showPaymentProof
                              : null
                          }
                        >
                          {dataDetail?.payment_proof && !dataDetail?.midtrans_order_id
                            ? t("booking.openPaymentProof")
                            : ""}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Divider orientation="horizontal" />
                <CardFlush
                  list
                  label={t("booking.paymentType")}
                  desc={
                    dataDetail?.payment_type
                      ? dataDetail?.payment_type?.charAt(0).toUpperCase() +
                        dataDetail?.payment_type?.slice(1)
                      : "-"
                  }
                  size={{ label: 3, desc: 9 }}
                  descEnd
                />
                {dataDetail?.midtrans_order_id && (
                  <CardFlush
                    list
                    label="Order ID"
                    desc={dataDetail?.midtrans_order_id ?? "-"}
                    size={{ label: 3, desc: 9 }}
                    descEnd
                    last
                  />
                )}
                {dataDetail?.order_from === "web-admin" &&
                  !dataDetail?.is_booked_by_contact_center &&
                  currentUser.user.airport_id !== dataDetail?.airport_id_cross_selling &&
                  (
                    currentUser.user.role_code === "admin_bandara" ||
                    currentUser.user.role_code === "supervisor" ||
                    currentUser.user.role_code === "sales_bandara" ||
                    currentUser.user.role_code === "finance_bandara"
                  ) && (
                    <>
                      <Divider orientation="horizontal" />
                      <div className="d-flex justify-content-start align-items-center p-3">
                        <div
                          className="left-icon float-left rounded-fill"
                          style={{ padding: "3px 7px 1px 7px" }}
                        >
                          <span
                            className="material-icons clickable"
                            style={{ fontSize: "15px", color: "#63ae5c" }}
                          >
                            {dataDetail?.payment_proof === null
                              ? "add"
                              : "edit"}
                          </span>
                        </div>
                        <div
                          className="clickable normal-title text-extra-bold pl-0"
                          onClick={showUploadPaymentModal}
                          style={{ color: "#63ae5c" }}
                        >
                          {dataDetail?.payment_proof === null ? "Add" : "Edit"}{" "}
                          {t("field.payment")}
                        </div>
                        {dataDetail?.payment_proof !== null && (
                          <>
                            <div
                              style={{
                                borderRight: "1px solid #A9A9A9",
                                height: 20,
                                marginLeft: 10,
                                marginRight: 10,
                              }}
                            ></div>
                            <div
                              className="left-icon float-left rounded-fill"
                              style={{
                                padding: "3px 7px 1px 7px",
                                border: "1px solid #d53a34",
                              }}
                            >
                              <span
                                className="material-icons clickable"
                                style={{ fontSize: "15px", color: "#d53a34" }}
                              >
                                delete
                              </span>
                            </div>
                            <div
                              className="clickable normal-title text-extra-bold pl-0"
                              onClick={() => setDeleteModal(!deleteModal)}
                              style={{ color: "#d53a34" }}
                            >
                              {`${t("commons.delete")} ${t("field.payment")}`}
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
              </div>
            )}
            {dataDetail?.status === 5 && dataDetail?.evidences?.length ? (
              <div className="wrapper-detail-card">
                <div
                  className="text-extra-bold"
                  style={{
                    paddingTop: 15,
                    paddingBottom: 15,
                    paddingLeft: 25,
                    paddingRight: 25,
                  }}
                >
                  <Row>
                    <Col md={6} style={{ opacity: 0.5 }}>
                      {t("booking.evidenceAssistant")}
                    </Col>
                    <Col md={6}>
                      <div className="d-flex justify-content-end">
                        <div
                          className="text-primary text-bold"
                          style={{
                            fontSize: 14,
                            cursor: "pointer",
                          }}
                          onClick={showEvidenceAssistant}
                        >
                          {t("booking.openEvidenceAssistant")}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <EditPassengerModal
        show={editPassengerModal}
        toggle={showEditPassengerModal}
        action={handleEditPassenger}
        data={dataPassengerRow}
      />

      <EditBookerModal
        show={editBookerModal}
        toggle={() => setEditBookerModal(!editBookerModal)}
        action={handleEditBooker}
        data={dataDetail}
      />

      <EditPagingModal
        show={showEditPaging}
        toggle={() => setShowEditPaging(!showEditPaging)}
        callback={() => {
          setShowEditPaging(!showEditPaging);
          handleRefresh();
        }}
        data={dataDetail}
      />

      <AssignAssistantModal
        data={dataDetail}
        show={assignModal}
        toggle={showAssignModal}
        action={handleChangeAssistant}
        id={match?.params?.id}
      />

      <EvidenceAssistantModal
        show={evidenceAssistant}
        title={t("booking.evidenceAssistant")}
        toggle={() => setEvidenceAssistant(!evidenceAssistant)}
        images={dataDetail?.evidences}
      />

      <PagingLogoModal
        show={showPagingLogo}
        title={t("field.pagingImage")}
        toggle={() => setShowPagingLogo(!showPagingLogo)}
        content={
          <div>
            {process.env.REACT_APP_API_URL +
              dataDetail?.paging_logo?.substr(1, dataDetail?.paging_logo?.length)
            ? (
              <img
                src={
                  process.env.REACT_APP_API_URL +
                  dataDetail?.paging_logo?.substr(1, dataDetail?.paging_logo?.length)
                }
                alt={t("field.pagingImage")}
                className="img img-fluid"
                width="100%"
                style={{ objectFit: "cover", width: "100%" }}
              />
            ) : (
              <p>Dokumen tidak ditemukan</p>
            )}
          </div>
        }
      />

      <PaymentProofModal
        show={paymentProof}
        toggle={() => setPaymentProof(!paymentProof)}
        title={t("booking.paymentProof")}
        content={
          <div>
            {process.env.REACT_APP_API_URL +
              dataDetail?.payment_proof?.substr(1, dataDetail?.payment_proof?.length) 
            ? (
              <img
                src={
                  process.env.REACT_APP_API_URL +
                  dataDetail?.payment_proof?.substr(1, dataDetail?.payment_proof?.length)
                }
                alt={t("booking.paymentProof")}
                className="img img-fluid"
                width="100%"
                style={{ objectFit: "cover", width: "100%" }}
              />
            ) : (
              <p>Dokumen tidak ditemukan</p>
            )}
          </div>
        }
      />

      <UploadPaymentModal
        show={uploadPaymentProof}
        toggle={() => {
          setUploadPaymentProof(!uploadPaymentProof);
          handleRefresh();
        }}
        payment_type={dataDetail?.payment_type ?? null}
        payment_proof={
          dataDetail?.payment_proof
            ? process.env.REACT_APP_API_URL +
              dataDetail?.payment_proof?.substr(
                1,
                dataDetail?.payment_proof?.length
              )
            : null
        }
        order_id={match?.params?.id}
      />

      <ConfirmationModal
        show={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        confirm={deletePayment}
        icon="info"
        message={`${t("commons.deleteConfirmation")} ${t("field.payment")} `}
        messageData={`#${dataDetail?.unique_transaction_id}`}
        isDelete
      />
    </div>
  );
};

const mapStateToProps = ({ booking: { error } }) => {
  return { error };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(DetailBooking)
);
