import { Divider } from "@mui/material";
import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { withTrans } from "../../../i18n/withTrans";

import Select2Multi from "../../../components/forms/Select2Multi";
import ButtonModal from "../../../components/modal/ButtonModal";
import { Form, Formik } from "formik";

const ShiftAsstModal = ({ t, show, toggle, data, action, day }) => {
  const initial = {
    id: data?.id,
    assistant_id: data?.assistant_id,
    name: data?.name,
    gender: data?.gender,
    email: data?.email,
    handphone: data?.handphone,
    country_code: data?.country_code,
    airport_id: data?.airport_id,
    origin_shifts: data?.list_shifts,
    shifts: data?.list_shifts
      ? data?.list_shifts
          .map(function (e) {
            return e.day_of_week;
          })
          .indexOf(day) > -1
        ? data?.list_shifts[
            data?.list_shifts
              .map(function (e) {
                return e.day_of_week;
              })
              .indexOf(day)
          ]
        : []
      : [],
  };
  const title = `Edit ${t( "field.shiftAssistant" )}`;

  const handleReset = (props) => {
    props?.setFieldValue("shifts", []);
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    if (data?.list_shifts) {
      let arrayShift = [];
      data.list_shifts.forEach((item, index) => {
        if (item.day_of_week == day) {
          item?.shifts?.forEach((el, i) => {
            arrayShift.push(el?.id);
          })
        }
      });
      initial.shifts = arrayShift;
    }
  }, [data?.list_shifts]);

  const shift_lists = [];
  data?.airport_data?.shifts?.map((item) => {
    return shift_lists?.push({
      label: `${item?.start_time} - ${item?.end_time}`,
      value: item?.id
    })
  });

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {title}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          initialValues={initial}
          enableReinitialize
          onSubmit={ async (values) => {
            values.origin_shifts.map((item, index) => {
              const airport_shift_ids = [];
              item?.shifts?.forEach((el, i) => {
                airport_shift_ids.push(el?.id);
              });
              item.airport_shift_id = airport_shift_ids;
            });

            let indexOriginShift = values.origin_shifts
              .map(function (e) {
                return e.day_of_week;
              })
              .indexOf(parseInt(day));
            if (indexOriginShift > -1) {
              values.origin_shifts[indexOriginShift] = {
                day_of_week: parseInt(day),
                airport_shift_id: values.shifts,
              };
            } else {
              values.origin_shifts.push({
                day_of_week: parseInt(day),
                airport_shift_id: values.shifts,  
              });
            }
            values.shifts = values.origin_shifts;
            action(values);
            await delay(3000);
          }}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <div style={{ margin: "0px 20px" }}>
                  <div
                    className="title pb-10 text-extra-bold"
                    style={{ fontSize: 20 }}
                  >
                    {data?.name}
                  </div>
                  <div className="normal-title">#{data?.assistant_id}</div>
                  <Divider orientation="horizontal" className="my-4" />
                  <Select2Multi
                    title={t("commons.shift")}
                    name={`shifts`}
                    options={shift_lists}
                    className="mb-4"
                    errors={props?.errors}
                    touched={props?.touched}
                    value={props?.values?.shifts}
                    onChange={(name, value) => {
                      props?.setFieldValue(`shifts`, value);
                    }}
                    onBlur={props?.setFieldTouched}
                  />
                </div>
                <div style={{ margin: "100px 20px 0 20px" }}>
                  <ButtonModal
                    toggle={toggle}
                    reset={() => handleReset(props)}
                    confirmTitle={t("commons.save")}
                    disabledConfirm={props.isSubmitting}
                  />
                </div>
              </ModalBody>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default withTrans(ShiftAsstModal);
