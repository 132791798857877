import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux";
import moment from "moment";

import ModalFilter from "./ModalFilter";
import Table from "../../../components/table";
import Button from "../../../components/buttons/Button";
import { withTrans } from "../../../i18n/withTrans";

import ReportService from "../../../store/actions/report/";

const ReportAgent = ({ t, user, airports, corporates, products }) => {
  const dispatch = useDispatch();
  const [dataReport, setdataReport] = useState([]);
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [paramFilter, setParamFilter] = useState({
    airport_id:
      user.role_code === "admin_bandara" ||
      user.role_code === "supervisor"
        ? user.airport_id
        : "",
    product_id: "",
    end_date: moment().format("YYYY-MM-DD"),
    start_date: moment().format("YYYY-MM-DD"),
    corporate_id: "",
  });

  const handleFilterModal = () => {
    setShowModalFilter(true);
  };

  const onApplyFilter = (param) => {
    setParamFilter({
      start_date: param.start_date,
      end_date: param.end_date,
      airport_id: param.airport_id,
      corporate_id: param.corporate_id,
      product_id: param.product_id,
    });
  };

  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        ...paramFilter,
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
      };
      dispatch(ReportService.getCorporate(param, resolve));
    }).then((res) => {
      setdataReport(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, paramFilter]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [paramFilter]);

  const handleDownloadReport = () => {
    new Promise((resolve) => {
      setLoadingDownload(true);
      dispatch(ReportService.downloadCorporate(paramFilter, resolve))
    }).then((res) => {
      setLoadingDownload(false);
    });
  };

  const numberWithCommas = (x) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return 0;
    }
  };

  const column = [
    {
      Header: "NO",
      accessor: "no",
    },
    {
      Header: t("report.serviceTime").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const service_time = original?.service_date && original?.service_time !== null 
          ? `${moment(original?.service_date).format("DD/MM/YYYY")}, ${original?.service_time}`
          : "-";
        return service_time;
      },
    },
    {
      Header: t("field.airport").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const airport = original?.airport !== null  ? original?.airport : "-";
        return airport;
      },
    },
    {
      Header: "ROUTE",
      minWidth: 150,
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const route = original?.route !== null  ? original?.route : "-";
        return route;
      },
    },
    {
      Header: t("field.name").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const name = 
          original?.passenger_list !== null  ? original?.passenger_list
            ?.map((item) => item?.name)
            ?.join(", ") : "-";
        return name;
      },
    },
    {
      Header: t("field.bookingId").toUpperCase(),
      accessor: "booking_id",
    },
    {
      Header: t("field.flight").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const flight = original?.flight !== null  ? original?.flight : "-";
        return flight;
      },
    },
    {
      Header: t("report.agent").toUpperCase(),
      accessor: "corporate_name",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return <>{original?.corporate_name ?? "-"}</>;
      },
    },
    {
      Header: t("field.productName").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.product ?? "";
      },
    },
    {
      Header: `${t("field.paymentType").toUpperCase()}`,
      Cell: ({ row }) => {
        const { original } = row;
        return original?.payment_type ?? "-"
      },
    },
    {
      Header: "GRAND TOTAL",
      accessor: "grand_total",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return <>{"Rp " + numberWithCommas(original?.grand_total)}</>;
      },
      minWidth: 150,
    },
    {
      Header: "REMARK",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const remark = original?.remark !== null ? original?.remark : "-";
        return remark;
      },
    },
    {
      Header: "SDF",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const sdf = original?.sdf !== null ? original?.sdf : "-";
        return sdf;
      },
    },
  ];

  return (
    <>
      <div className="d-flex mb-4 justify-content-end">
        <Button
          title="Filter"
          size="sm"
          rounded={true}
          variant="button-light btn-lg"
          onClick={handleFilterModal}
        />
        <div className="nav-separator mr-3 ml-3" />
        <Button
          title={(
            <>
              {loadingDownload && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
              )}
              {t("report.downloadReport")}
            </>
          )}
          leftIcon={!loadingDownload ? "download" : null}
          rounded={true}
          variant="button-light btn-lg"
          onClick={handleDownloadReport}
        />
      </div>
      <Table
        columns={column.filter((item) => item.show !== false)}
        data={dataReport ?? []}
        pagination={{ page, totalData, maxPage, toggle: setPage }}
      />
      
      <ModalFilter
        user={user}
        show={showModalFilter}
        toggle={() => setShowModalFilter(false)}
        filter={paramFilter}
        airport={airports}
        product={products}
        corporate={corporates}
        toggleFilter={() => {
          setParamFilter({});
          handleRefresh();
        }}
        onApplyFilter={onApplyFilter}
      />
    </>
  )
}

export default withTrans(ReportAgent)