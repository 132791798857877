import React, { useRef, useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Container,
  Col,
} from "reactstrap";
import { connect, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import moment from "moment";
import * as Yup from "yup";
import { Divider } from "@mui/material";
import { withTrans } from "../../i18n/withTrans";
import { toastError } from "../../components/commons/toast";
import { getUser } from "../../utils/User";
import Select2 from "../../components/forms/Select2";
import Input from "../../components/forms/Input";
import ButtonModal from "../../components/modal/ButtonModal";
import Textarea from "../../components/forms/Textarea";
import Select2Multi from "../../components/forms/Select2Multi";
import DynamicButton from "../../components/forms/DynamicButton";
import ConfirmationModal from "../../components/modal/ConfirmModal";

const FormPromoModal = ({
  t,
  show,
  toggle,
  action,
  data,
  dataAirport,
  dataProduct,
  confirm,
  data_post,
  data_put,
  error_message
}) => {
  const formikRef = useRef();
  const fileRef = useRef();
  const currentUser = getUser();
  const [discountType, setDiscountType] = useState("");
  const [type, setType] = useState("");
  // const [mstProduct, setMstProduct] = useState([]);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [messageConfirm, setMessageConfirm] = useState("");
  const [fileProp, setFileProp] = useState({
    title: "Upload Banner",
    subtitle: "Format .JPG or .PNG (Max. 2MB)",
    description: "Dimensions: 312 x 120 px",
    message_error: false,
  });
  const [haveAirport, setHaveAirport] = useState(
    currentUser?.user.role_code === "admin_bandara" 
      ? true 
    : currentUser?.user.role_code === "supervisor" 
      ? true
    : currentUser?.user.role_code === "finance_bandara" 
      ? true
    : currentUser?.user.role_code === "sales_bandara" 
      ? true
    : currentUser?.user.role_code === "assistant" 
      ? true
      : false
  );
  const [image, setImage] = useState(null);

  const dispatch = useDispatch();
  const title = (data ? t("commons.edit") : t("commons.add")) + " " + "Promo";
  const initial = data
    ? {
      id: data?.id,
      name: data?.name,
      code: data?.code,
      type: data?.type,
      is_service_date_period: data?.is_service_date_period,
      period_end: moment(data?.period_end).format("YYYY-MM-DD"),
      period_start: moment(data?.period_start).format("YYYY-MM-DD"),
      quantity: data?.quantity,
      description: data?.description,
      discount_percent: data?.discount_percent,
      discount_price: data?.discount_price,
      promo_type: data?.discount_price === 0 ? 0 : 1,
      banner_file_path: data?.banner,
      airport_ids: data?.list_airports_detail
        ? data?.list_airports_detail?.map((item, index) => item?.id).join().split(',')
        : currentUser?.user?.role_code === "admin_bandara"
          ? [currentUser?.user?.airport_id]
          : "",
      list_airport_cross_selling: data?.list_airport_cross_selling
        ? data?.list_airport_cross_selling.map((item, index) => item.airport_id).join().split(',')
        : [],
      service_type: data?.list_products
        ? data?.list_products.map((item, index) => item).join().split(',')
        : "",
    }
    : {
      airport_ids:
        currentUser?.user?.role_code === "admin_bandara"
          ? [currentUser?.user?.airport_id]
          : "",
      list_airport_cross_selling : [],
      banner: "",
      code: "",
      name: "",
      description: "",
      discount_price: "",
      discount_percent: "",
      order_number: 0,
      type: "",
      service_type: "",
      quantity: "",
      is_service_date_period: null,
      period_end: "",
      period_start: "",
      promo_type: "",
    };

  const handleConfirm = () => {
    setFileProp({
      title: "Upload Banner",
      subtitle: "Format .JPG or .PNG (Max. 2MB)",
      description: "Dimensions: 312 x 120 px",
      message_error: false,
    });
    setShowModalConfirm(false);
    toggle(false);
  };

  const validation = Yup.object().shape({
    name: Yup.string().required(`${t("promo.promoName")} ${t("commons.required")}`).nullable(),
    description: Yup.string().required(`${t("promo.description")} ${t("commons.required")}`).nullable(),
    is_service_date_period: Yup.boolean().required(`${t("promo.typePeriodPromo")} ${t("commons.required")}`).nullable(),
    period_end: Yup.string().required(`${t("promo.endDate")} ${t("commons.required")}`).nullable(),
    period_start: Yup.string().required(`${t("promo.startDate")} ${t("commons.required")}`).nullable(),
    type: Yup.string().required(`${t("promo.promoType")} ${t("commons.required")}`).nullable(),
    promo_type: Yup.string().required(`${t("promo.promoValue")} ${t("commons.required")}`).nullable(),
    quantity:
      type === "specific"
        ? Yup.string().required(`${t("promo.numberOfPromo")} ${t("commons.required")}`).nullable()
        : "",
    code:
      type === "blast"
        ? Yup.string().required(`${t("promo.promoCode")} ${t("commons.required")}`).nullable()
        : "",
    discount_price:
      discountType === 1
        ? Yup.string().required(`${t("promo.promoValue")} ${t("commons.required")}`).nullable()
        : "",
    discount_percent:
      discountType === 0 || initial?.promo_type === 0
        ? Yup.string().required(`${t("promo.promoValue")} ${t("commons.required")}`).nullable()
        : "",
    airport_ids: Yup.array().required(`${t("field.airport")} ${t("commons.required")}`).nullable(),
    list_airport_cross_selling:
      type === "cross-selling"
        ? Yup.array().required(`${t("promo.airportCrossSelling")} ${t("commons.required")}`).nullable()
        : "",
    service_type: Yup.array().required(`${t("field.product")} ${t("commons.required")}`).nullable(),
  });

  const airport_list = dataAirport?.map((airport) => ({
    value: airport?.id,
    label: `${airport?.code}-${airport?.city}-${airport?.name}`,
  }));

  const product_list = dataProduct?.map((product) => ({
    value: product?.id,
    label: product?.name_eng,
  }));

  useEffect(() => {
    if (error_message) {
      setLoadingSubmit(false);
    }
  }, [error_message]);

  useEffect(() => {
    if (data_post?.data?.status === 200) {
      setLoadingSubmit(false);
      setFileProp({
        title: "Upload Banner",
        subtitle: "Format .JPG or .PNG (Max. 2MB)",
        description: "Dimensions: 312 x 120 px",
        message_error: false,
      });
    }
  }, [data_post]);

  useEffect(() => {
    if (data_put?.data?.status === 200) {
      setLoadingSubmit(false);
      setFileProp({
        title: "Upload Banner",
        subtitle: "Format .JPG or .PNG (Max. 2MB)",
        description: "Dimensions: 312 x 120 px",
        message_error: false,
      });
    }
  }, [data_put]);

  const openFileDialog = () => {
    fileRef.current.click();
  };

  const handleChange = (selectorFiles, formik) => {
    if (Number(selectorFiles.files[0].size) < 2097152) {
      formik.setFieldValue("banner_file", selectorFiles.files[0]);
      const img = new Image()
      img.src = window.URL.createObjectURL(selectorFiles.files[0])
      img.onload = () => {
        setFileProp({
          title: selectorFiles.files[0].name,
          subtitle:
            `Size: ${(Number(selectorFiles.files[0].size) * 0.001).toFixed(2)} KB`,
          description: `Dimensions: ${img.width} x ${img.height} px`,
          message_error: false,
        });
      }
    } else {
      toastError("Maksimal file yang dapat diimport 2 MB");
    }
  };

  useEffect(() => {
    if (data?.banner) {
      convertImgToBase64URL(
        process.env.REACT_APP_API_URL +
        data?.banner.substr(1, data?.banner?.length),
        function (base64Img) {
          const name = data?.banner.split("/");

          var arr = base64Img.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }

          const file = new File([u8arr], name[name.length - 1], { type: mime });
          setImage(file);
          const img = new Image();
          img.src = window.URL.createObjectURL(file)
          img.onload = () => {
            setFileProp({
              title: file.name,
              subtitle:
                `Size: ${(Number(file.size) * 0.001).toFixed(2)} KB`,
              description: `Dimensions: ${img.width} x ${img.height} px`,
              message_error: false,
            });
          }
        }
      );
    }
  }, [data?.banner]);

  const convertImgToBase64URL = (url, callback) => {
    var img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      var canvas = document.createElement("CANVAS"),
        ctx = canvas.getContext("2d"),
        dataURL;
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      dataURL = canvas.toDataURL("image/jpeg");
      callback(dataURL);
      canvas = null;
    };
    img.src = url;
  };

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {title}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={initial}
          validationSchema={validation}
          enableReinitialize
          onSubmit={(values, { setStatus }) => {
            setStatus();
            if (values.airport_ids?.length < 1) {
              setLoadingSubmit(false);
              toastError(
                `${t("commons.empty_data_message")} ${t("field.airport")}!`
              );
              return;
            }
            if (values?.type === 'cross-selling' && values.list_airport_cross_selling?.length < 1) {
              setLoadingSubmit(false);
              toastError(
                `${t("commons.empty_data_message")} ${t("promo.airportCrossSelling")}!`
              );
              return;
            }
            if (values.service_type?.length < 1) {
              setLoadingSubmit(false);
              toastError(
                `${t("commons.empty_data_message")} ${t("field.product")}!`
              );
              return;
            }
            if (values.type === 'blast' && !(values?.banner_file || values?.banner_file_path)) {
              setLoadingSubmit(false);
              setFileProp({
                title: "Upload Banner",
                subtitle: "Format .JPG or .PNG (Max. 2MB)",
                description: "Dimensions: 312 x 120 px",
                message_error: `Banner ${t("commons.required")}`,
              });
              return;
            }
            if (data) {
              values.id = data?.id;
            }
            let formData = new FormData();
            if (values.type === "blast") {
              formData.append("code", values.code);
            }
            if (values.id) {
              formData.append("id", values.id);
              if (values.banner_file) {
                formData.append("file", values?.banner_file);
              } else {
                formData.append("file", image);
              }
            } else {
              formData.append("file", values?.banner_file);
            }
            formData.append("description", values.description);
            if (values.promo_type === 1) {
              formData.append("discount_percent", 0);
              if (!Number.isInteger(values.discount_price)) {
                formData.append(
                  "discount_price",
                  Number(values.discount_price.replace(/[^0-9\.-]+/g, ""))
                );
              } else {
                formData.append("discount_price", values.discount_price);
              }
            } else {
              formData.append("discount_price", 0);
              formData.append("discount_percent", values.discount_percent);
            }
            formData.append("list_airports", values.airport_ids);
            if (values.list_airport_cross_selling.length) {
              formData.append("list_airport_cross_selling", values.list_airport_cross_selling);
            }
            formData.append("list_products", values.service_type);
            formData.append("name", values.name);
            formData.append("order_number", 0);
            formData.append("is_service_date_period", values?.is_service_date_period);
            formData.append("period_end", moment(values.period_end).format("YYYY-MM-DD HH:mm:ss"));
            formData.append("period_start", moment(values.period_start).format("YYYY-MM-DD HH:mm:ss"));
            formData.append("quantity", values.quantity ? values.quantity : 0);
            formData.append("type", values.type);
            setLoadingSubmit(true);
            action(formData);
          }}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Container>
                  <Input
                    name="name"
                    errors={props.errors}
                    touched={props.touched}
                    {...props.getFieldProps("name")}
                    title={t("promo.promoName")}
                    type="text"
                  />
                  <Select2
                    title={t("promo.promoType")}
                    name="type"
                    options={
                      haveAirport ? [
                        {
                          value: "specific",
                          label: t("promo.special"),
                        },
                        {
                          value: "blast",
                          label: "Blast",
                        },
                      ] : [
                      {
                        value: "specific",
                        label: t("promo.special"),
                      },
                      {
                        value: "blast",
                        label: "Blast",
                      },
                      {
                        value: "cross-selling",
                        label: t("field.cross_selling"),
                      },
                    ]}
                    type="text"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps("type")}
                    value={props.values.type}
                    onChange={(name, value) => {
                      setType(value);
                      props?.setFieldValue("type", value);
                    }}
                  />
                  <Divider orientation="horizontal" />
                  {props?.values?.type === "specific" && (
                    <>
                      {" "}
                      <Input
                        name="quantity"
                        errors={props.errors}
                        touched={props.touched}
                        value={props.values.quantity}
                        {...props.getFieldProps("quantity")}
                        title={t("promo.numberOfPromo")}
                        type="number"
                        style={{ marginTop: "10px" }}
                        placeholder={t("promo.numberOfPromo")}
                      />
                    </>
                  )}
                  {props?.values?.type === "blast" && (
                    <>
                      {" "}
                      <Input
                        name="code"
                        errors={props.errors}
                        touched={props.touched}
                        value={props.values.code}
                        {...props.getFieldProps("code")}
                        title={t("promo.promoCode")}
                        type="text"
                        style={{ marginTop: "10px" }}
                      />
                    </>
                  )}
                  <Row>
                    <Col md={12} style={{ paddingRight: "0px" }}>
                      <div
                        className="form-group"
                        style={{ marginBottom: 0, marginTop: 10 }}
                      >
                        <label className="form-label">
                          {
                            props?.values?.type === "cross-selling" 
                              ? t("promo.labelPromoValueService") 
                              : t("promo.promoValue")
                          }
                        </label>
                      </div>
                    </Col>
                    <Col md={4} style={{ paddingRight: "0px" }}>
                      <Select2
                        name="promo_type"
                        options={
                          props?.values?.type === "cross-selling" 
                          ? [
                            {
                              value: 0,
                              label: `${t("promo.percentage")} (%)`,
                            }
                          ] : [
                            {
                              value: 0,
                              label: `${t("promo.percentage")} (%)`,
                            },
                            {
                              value: 1,
                              label: `${t("promo.rupiah")} (Rp)`,
                            },
                          ]
                        }
                        type="text"
                        errors={props?.errors}
                        touched={props?.touched}
                        {...props?.getFieldProps("promo_type")}
                        value={props.values.promo_type}
                        onChange={(name, value) => {
                          setDiscountType(value);
                          props?.setFieldValue("promo_type", value);
                        }}
                      />
                    </Col>
                    {props.values.promo_type === 0 ? (
                      <Col md={8} style={{ paddingLeft: "0px" }}>
                        <Input
                          name="discount_percent"
                          errors={props.errors}
                          value={props.values.discount_percent}
                          touched={props.touched}
                          {...props.getFieldProps("discount_percent")}
                          type="number"
                          min="0"
                          max="100"
                          placeholder={t("promo.promoValue")}
                        />
                      </Col>
                    ) : (
                      <Col md={8} style={{ paddingLeft: "0px" }}>
                        <Input
                          currency
                          errors={props.errors}
                          touched={props.touched}
                          thousandSeparator={true}
                          prefix={"Rp "}
                          onChangeValue={(e) => {
                            props.setFieldValue(
                              "discount_price",
                              e.target.value
                            );
                          }}
                          value={props.values.discount_price}
                          {...props.getFieldProps("discount_price")}
                          placeholder={t("promo.promoValue")}
                          name="discount_price"
                        />
                      </Col>
                    )}
                  </Row>
                  <Select2
                    title={t("promo.typePeriodPromo")}
                    name="is_service_date_period"
                    options={
                      [
                        {
                          value: true,
                          label: t("report.serviceDate"),
                        },
                        {
                          value: false,
                          label: t("report.bookingDate"),
                        }
                      ]
                    }
                    errors={props?.errors}
                    touched={props?.touched}
                    value={props.values.is_service_date_period}
                    onChange={(name, value) => {
                      props?.setFieldValue("is_service_date_period", value)
                      
                    }}
                  />
                  <Row style={{ marginTop: "10px" }}>
                    <Col md={6}>
                      <Input
                        title={props?.values?.is_service_date_period
                          ? t("promo.serviceStartDate")
                          : t("promo.bookingStartDate")}
                        name="period_start"
                        type="date"
                        errors={props.errors}
                        touched={props.touched}
                        {...props?.getFieldProps("period_start")}
                        value={props.values?.period_start}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        title={props?.values?.is_service_date_period
                          ? t("promo.serviceEndDate")
                          : t("promo.bookingEndDate")}
                        name="period_end"
                        type="date"
                        errors={props.errors}
                        touched={props.touched}
                        {...props?.getFieldProps("period_end")}
                        value={props.values?.period_end}
                      />
                    </Col>
                  </Row>
                  {props?.values?.type === "cross-selling" && (
                    <Select2Multi
                      title={t("promo.airportCrossSelling")}
                      name="list_airport_cross_selling"
                      options={[
                        {
                          label: `${t("commons.all")} ${t("field.airport")}`,
                          value: 'all',
                        },
                        ...airport_list
                      ]}
                      className="mb-2"
                      errors={props?.errors}
                      touched={props?.touched}
                      value={props?.values?.list_airport_cross_selling}
                      onChange={(name, value) => {
                        let data;
                        if (value.includes("all")) {
                          data = airport_list?.map((item) => {
                            return item?.value
                          });
                        } else {
                          data = value;
                        }
                        props?.setFieldValue("list_airport_cross_selling", data);
                      }}
                      onBlur={props?.setFieldTouched}
                    />
                  )}
                  {currentUser?.user?.role_code === "admin_bandara" ? (
                    <Select2Multi
                      title={t("field.airport")}
                      name="airport_ids"
                      options={[
                        {
                          label: `${currentUser?.user?.airport_code} - ${currentUser?.user?.airport_city} - ${currentUser?.user?.airport_name}`,
                          value: currentUser?.user?.airport_id,
                        },
                      ]}
                      className="mb-4"
                      errors={props?.errors}
                      value={props?.values?.airport_ids}
                      disabled
                      onBlur={props?.setFieldTouched}
                    />
                  ) : (
                    <Select2Multi
                      title={t("field.airport")}
                      name="airport_ids"
                      options={[
                        {
                          label: `${t("commons.all")} ${t("field.airport")}`,
                          value: 'all',
                        },
                        ...airport_list
                      ]}
                      className="mb-2"
                      errors={props?.errors}
                      touched={props?.touched}
                      value={props?.values?.airport_ids}
                      onChange={(name, value) => {
                        let data;
                        if (value.includes("all")) {
                          data = airport_list?.map((item) => {
                            return item?.value
                          });
                        } else {
                          data = value;
                        }
                        props?.setFieldValue("airport_ids", data);
                      }}
                      onBlur={props?.setFieldTouched}
                    />
                  )}
                  <Select2Multi
                    title={t("field.product")}
                    name="service_type"
                    options={product_list}
                    className="mb-2"
                    errors={props?.errors}
                    touched={props?.touched}
                    value={props?.values?.service_type}
                    onChange={(name, value) => {
                      props?.setFieldValue("service_type", value);
                    }}
                    onBlur={props?.setFieldTouched}
                  />{" "}
                  <Textarea
                    title={t("promo.description")}
                    rows="4"
                    name="description"
                    errors={props?.errors}
                    touched={props?.touched}
                    value={props?.values?.description}
                    {...props?.getFieldProps("description")}
                  />
                  <DynamicButton
                    titleLeft={fileProp?.title}
                    subtitleLeft={fileProp?.subtitle}
                    description={fileProp?.description}
                    iconLeft="insert_drive_file"
                    toggle={openFileDialog}
                    error={fileProp?.message_error}
                  />
                  <input
                    type="file"
                    ref={fileRef}
                    style={{ display: "none" }}
                    onChange={(e) => handleChange(e.target, props)}
                    accept="image/png, image/jpeg, application/pdf"
                  />
                </Container>
              </ModalBody>
              <ModalFooter>
                <ButtonModal
                  toggle={() => {
                    setShowModalConfirm(true);
                    setMessageConfirm("cancel");
                  }}
                  confirm={confirm}
                  confirmTitle={t("commons.save")}
                  disabledConfirm={loadingSubmit}
                  icon
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
      <ConfirmationModal
        show={showModalConfirm}
        toggle={() => setShowModalConfirm(false)}
        confirm={handleConfirm}
        isDelete="delete"
        cancelTitle={t("commons.no")}
        confirmTitle={t("commons.yes")}
        message={
          data
            ? `${t("confirm.edit")} <b>Promo</b>`
            : `${t("confirm.add")} <b>Promo</b>`
        }
        icon="info"
      />
    </>
  );
};

const mapStateToProps = ({ promo: { pending, data_post, data_put, error_message } }) => {
  return { pending, data_post, data_put, error_message };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(FormPromoModal));
