import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import { withTrans } from "../../../../i18n/withTrans";
import { CarList } from "../../../../utils/Constants";
import HeaderMenu from "../../../../components/commons/menu/HeaderMenu";
import CardFlush from "../../../../components/cards/cardFlush";
import Card from "../../../../components/cards/card";
import CardHeader from "../../../../components/cards/cardHeader";
import CardBody from "../../../../components/cards/cardBody";
import Input from "../../../../components/forms/Input";
import InputPrepend from "../../../../components/forms/InputPrepend";
import Select2Multi from "../../../../components/forms/Select2Multi";
import Select2 from "../../../../components/forms/Select2";
import Checkbox from "../../../../components/forms/Checkbox";
import DynamicForm from "../../../../components/forms/DynamicForm";
import CorporateLoungeModal from "./CorporateLoungeModal";

import AirportService from "../../../../store/actions/master_data/airport";

const DetailPriceCorporate = ({
  formik,
  toggle,
  idTypeList,
  index,
  indexCancel,
  t,
}) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("0");
  const [dataAirport, setDataAirport] = useState([]);
  const [showGroup, setShowGroup] = useState(0);
  const [carsList, setCarsList] = useState(CarList);
  const [loungesList, setLoungesList] = useState([]);
  const [indexCorpLounge, setIndexCorpLounge] = useState();
  const [modalCorpLounge, setModalCorpLounge] = useState(false);

  const idType = formik?.values?.type_list
    .map((item) => {
      return item?.id;
    })
    .indexOf(idTypeList);

  let corporateType = formik?.values?.type_list[idType]?.corporate_type;

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 99999,
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      let list = [];
      if (res && res.data) {
        res.data?.map(
          (airport) =>
            (list = [
              ...list,
              {
                ...airport,
                value: airport?.id,
                label: `${airport?.code}-${airport?.city}-${airport?.name}`,
              },
            ])
        );
      }
      setDataAirport(list);
    });
  }, []);

  const flightTypeList = [
    {
      label: `${t("field.arrival")}`,
      value: 5,
    },
    {
      label: `${t("field.departure")}`,
      value: 6,
    },
  ];

  const flightTypeTransferList = [
    {
      label: "International to Domestic",
      value: 2,
    },
    {
      label: "International to International",
      value: 1,
    },
    {
      label: "Domestic to International",
      value: 3,
    },
    {
      label: "Domestic to Domestic",
      value: 4,
    },
  ];

  const ShowTab = (active, props) => {
    let name, value, checked, nameChecked, labelChecked;

    if (active === "0") {
      name = `type_list[${idType}].period_list[${index}].meet_and_greet_dom`;
      value = props?.values?.type_list[idType]?.period_list[index]?.meet_and_greet_dom;
      nameChecked = `type_list[${idType}].period_list[${index}].is_checked_meet_dom`;
      checked = props?.values?.type_list[idType]?.period_list[index]?.is_checked_meet_dom;
      labelChecked = `${t("product.activate_meet")}`;
    } else if (active === "1") {
      name = `type_list[${idType}].period_list[${index}].meet_and_greet_inter`;
      value = props?.values?.type_list[idType]?.period_list[index]?.meet_and_greet_inter;
      nameChecked = `type_list[${idType}].period_list[${index}].is_checked_meet_inter`;
      checked = props?.values?.type_list[idType]?.period_list[index]?.is_checked_meet_inter;
      labelChecked = `${t("product.activate_meet_inter")}`;
    } else if (active === "2") {
      name = `type_list[${idType}].period_list[${index}].fast_track`;
      value = props?.values?.type_list[idType]?.period_list[index]?.fast_track;
      nameChecked = `type_list[${idType}].period_list[${index}].is_checked_fast`;
      checked = props?.values?.type_list[idType]?.period_list[index]?.is_checked_fast;
      labelChecked = `${t("product.activate_fast")}`;
    } else if (active === "3") {
      name = `type_list[${idType}].period_list[${index}].transfer`;
      value = props?.values?.type_list[idType]?.period_list[index]?.transfer;
      nameChecked = `type_list[${idType}].period_list[${index}].is_checked_transfer`;
      checked = props?.values?.type_list[idType]?.period_list[index]?.is_checked_transfer;
      labelChecked = `${t("product.activate_transfer")}`;
    } else if (active === "4") {
      name = `type_list[${idType}].period_list[${index}].lounge`;
      value = props?.values?.type_list[idType]?.period_list[index]?.lounge;
      nameChecked = `type_list[${idType}].period_list[${index}].is_checked_lounge`;
      checked = props?.values?.type_list[idType]?.period_list[index]?.is_checked_lounge;
      labelChecked = `${t("product.activate_lounge")}`;
    } else if (active === "5") {
      name = `type_list[${idType}].period_list[${index}].transport`;
      value = props?.values?.type_list[idType]?.period_list[index]?.transport;
      nameChecked = `type_list[${idType}].period_list[${index}].is_checked_transport`;
      checked = props?.values?.type_list[idType]?.period_list[index]?.is_checked_transport;
      labelChecked = `${t("product.activate_transport")}`;
    }

    useEffect(() => {
      if (active === "4" && value?.airport_list?.length) {
        new Promise((resolve) => {
          let param = {
            length: 99999,
            airport_ids: value?.airport_list,
          };
          dispatch(AirportService.getListLounge(param, resolve));
        }).then((res) => {
          let list = [];
          if (res && res.data) {
            res.data?.map(
              (item) =>
                (list = [
                  ...list,
                  {
                    ...item,
                    value: item?.id,
                    label: `${item?.code}-${item?.name}`,
                  },
                ])
            );
          }
          setLoungesList(list);
        });
      }
    }, [
      value?.airport_list
    ]);

    const detailCorporateLounge = (index) => {
      if (index !== null && index !== undefined) {
        setIndexCorpLounge(index);
      }
      setModalCorpLounge(true);
    }

    return (
      <>
        <div className="checkbox-wrapper">
          <Checkbox
            style={{ marginTop: "30px" }}
            name={nameChecked}
            checked={checked}
            disabled
            value={1}
            label={labelChecked}
          />
        </div>
        {checked == 1 && (
          <>
            <Select2Multi
              title={t("field.airport")}
              name={`${name}.airport_list`}
              options={dataAirport}
              className="mb-4"
              errors={props?.errors}
              touched={props?.touched}
              value={value?.airport_list}
              disabled
              onBlur={props?.setFieldTouched}
            />
            {(active !== "4" && active !== "5") && (
              <Select2Multi
                title={t("commons.activities")}
                name={`${name}.flight_list`}
                options={
                  active === "3" ? flightTypeTransferList : flightTypeList
                }
                className="mb-4"
                errors={props?.errors}
                touched={props?.touched}
                value={value?.flight_list}
                disabled
                onBlur={props?.setFieldTouched}
              />
            )}
            <Card className="rounded">
              <CardHeader title={t("field.price")} />
              <CardBody style={{ paddingTop: 0 }}>
                {active === "5" ? (
                  <div>
                    <div className="row" style={{ marginTop: 20 }}>
                      <div className="col-md-4" style={{ paddingRight: "0px" }}>
                        <div className="form-group" style={{ marginBottom: 0 }}>
                          <label className="form-label">{t("field.carType")}</label>
                        </div>
                      </div>
                      <div className="col-md-4" style={{ paddingLeft: "0px" }}>
                        <div className="form-group" style={{ marginBottom: 0 }}>
                          <label className="form-label">{t("field.capacity")}</label>
                        </div>
                      </div>
                      <div className="col-md-4" style={{ paddingLeft: "0px" }}>
                        <div className="form-group" style={{ marginBottom: 0 }}>
                          <label className="form-label">{t("field.price")}</label>
                        </div>
                      </div>
                    </div>
                    {value?.transport_list?.map((item, i) => {
                      return (
                        <>
                          <DynamicForm
                            pbRow="0"
                            nameLeft={`${name}.transport_list[${i}].car_name`}
                            name={`${name}.transport_list[${i}].price`}
                            nameCenter={`${name}.transport_list[${i}].capacity`}
                            placeholderLeft={t("field.carType")}
                            placeholder={t("field.price")}
                            placeholderCenter={t("field.capacity")}
                            optionLeft={carsList}
                            valueLeft={value?.transport_list[i]?.car_name ?? ""}
                            formik={props}
                            type="number"
                            typeCenter="number"
                            currency
                            disabledLeft={true}
                            disabledCenter={true}
                            disabledRight={true}
                            size={{ title: 4, center: 4, value: 4 }}
                            toggle={null}
                          />
                        </>
                      );
                    })}
                  </div>
                ) : active === "4" ? (
                  value?.lounge_list?.length ? (
                    <div>
                      <div className="row" style={{ marginTop: 20 }}>
                        <div className="col-md-3" style={{ paddingRight: "0px" }}>
                          <div className="form-group" style={{ marginBottom: 0 }}>
                            <label className="form-label">
                              {t("field.lounge")}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                          <div className="form-group" style={{ marginBottom: 0 }}>
                            <label className="form-label">
                              {t("commons.adult")}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                          <div className="form-group" style={{ marginBottom: 0 }}>
                            <label className="form-label">
                              {t("commons.child")}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                          <div className="form-group" style={{ marginBottom: 0 }}>
                            <label className="form-label">
                              {t("commons.infantLabel")}
                            </label>
                          </div>
                        </div>
                      </div>
                      {value?.lounge_list?.map((item, i) => {
                        return (
                          <div>
                            <Row style={{ marginBottom: 0 }}>
                              <Col
                                md={3}
                                style={{ padding: 0 }}
                              >
                                <Select2
                                  classForm="detail-select"
                                  name={`${name}.lounge_list[${i}].lounge_id`}
                                  options={loungesList}
                                  errors={props?.errors}
                                  touched={props?.touched}
                                  disabled
                                  value={value?.lounge_list[i]?.lounge_id ?? ""}
                                  onBlur={props?.setFieldTouched}
                                />
                              </Col>
                              <Col md={9} style={{ padding: 0 }}>
                                <InputPrepend
                                  errors={props?.errors}
                                  touched={props?.touched}
                                  multiple={[
                                    {
                                      disabled: true,
                                      prefix: "Rp ",
                                      isCurrency: true,
                                      thousandSeparator: true,
                                      placeholder: `${t("commons.adult")}`,
                                      name: `${name}.lounge_list[${i}].adult_price`,
                                      value: value?.lounge_list[i]?.adult_price,
                                    },
                                    {
                                      disabled: true,
                                      prefix: "Rp ",
                                      isCurrency: true,
                                      thousandSeparator: true,
                                      placeholder: `${t("commons.child")}`,
                                      name: `${name}.lounge_list[${i}].child_price`,
                                      value: value?.lounge_list[i]?.child_price,
                                    },
                                    {
                                      disabled: true,
                                      prefix: "Rp ",
                                      isCurrency: true,
                                      thousandSeparator: true,
                                      placeholder: `${t("commons.infantLabel")}`,
                                      name: `${name}.lounge_list[${i}].infant_price`,
                                      value: value?.lounge_list[i]?.infant_price,
                                    },
                                  ]}
                                />
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div>{t("commons.no_data")}</div>
                  )
                ) : (
                  <div>
                    {value?.group_list?.map((item, index) => {
                      return (
                        <>
                          {item?.property_list?.length ? (
                            <>
                              {corporateType === "Group" && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingTop: 15,
                                    fontSize: 14,
                                  }}
                                >
                                  <div className="text-extra-bold">
                                    {item?.group_type}
                                  </div>
                                  <div
                                    onClick={() =>
                                      setShowGroup(
                                        showGroup === index ? null : index
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <span className="material-icons-round left-icon">
                                      expand_more
                                    </span>
                                  </div>
                                </div>
                              )}
                              {showGroup === index && (
                                <div>
                                  {item?.property_list?.map((property, i) => {
                                    return (
                                      <>
                                        {corporateType === "MOB" ? (
                                          <>
                                            <div className="row">
                                              <div className="col-md-3"></div>
                                              <div className="col-md-3">
                                                <div className="form-group" style={{ marginBottom: 0 }}>
                                                  <label className="form-label mb-1">
                                                    {t("commons.adult")}
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <div className="form-group" style={{ marginBottom: 0 }}>
                                                  <label className="form-label mb-1">
                                                    {t("commons.child")}
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <div className="form-group" style={{ marginBottom: 0 }}>
                                                  <label className="form-label mb-1">
                                                    {t("commons.infantLabel")}
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <InputPrepend
                                              mb="2"
                                              errors={props?.errors}
                                              touched={props?.touched}
                                              title={`${t(
                                                "field.price"
                                              )} Individu`}
                                              multiple={[
                                                {
                                                  prefix: "Rp ",
                                                  isCurrency: true,
                                                  thousandSeparator: true,
                                                  name: `${name}.group_list[${index}].property_list[${i}].prices.adult_price`,
                                                  value: value?.group_list[index]?.property_list[i]?.prices?.adult_price,
                                                  placeholder: `${t(
                                                    "commons.adult"
                                                  )}`,
                                                  disabled: true,
                                                },
                                                {
                                                  prefix: "Rp ",
                                                  isCurrency: true,
                                                  thousandSeparator: true,
                                                  name: `${name}.group_list[${index}].property_list[${i}].prices.child_price`,
                                                  value: value?.group_list[index]?.property_list[i]?.prices?.child_price,
                                                  placeholder: `${t(
                                                    "commons.child"
                                                  )}`,
                                                  disabled: true,
                                                },
                                                {
                                                  prefix: "Rp ",
                                                  isCurrency: true,
                                                  thousandSeparator: true,
                                                  name: `${name}.group_list[${index}].property_list[${i}].prices.infant_price`,
                                                  value: value?.group_list[index]?.property_list[i]?.prices?.infant_price,
                                                  placeholder: `${t(
                                                    "commons.infantLabel"
                                                  )}`,
                                                  disabled: true,
                                                },
                                              ]}
                                            />
                                            <InputPrepend
                                              mb="2"
                                              errors={props?.errors}
                                              touched={props?.touched}
                                              title={`${t("field.price")} MOB`}
                                              multiple={[
                                                {
                                                  prefix: "Rp ",
                                                  isCurrency: true,
                                                  thousandSeparator: true,
                                                  name: `${name}.group_list[${index}].property_list[${i}].prices.price`,
                                                  value: value?.group_list[index]?.property_list[i]?.prices?.price,
                                                  placeholder: `${t(
                                                    "field.price"
                                                  )}`,
                                                  disabled: true,
                                                },
                                              ]}
                                            />
                                          </>
                                        ) : corporateType === "Package" ? (
                                          <div className="checkbox-wrapper">
                                            <Checkbox
                                              disabled
                                              style={{ marginTop: "30px" }}
                                              name={`${name}.group_list[${index}].property_list[${i}].prices.infant_is_free`}
                                              checked={value?.group_list[index]?.property_list[i]?.prices?.infant_is_free}
                                              onChange={props?.handleChange}
                                              value={1}
                                              label={t("product.infantIsFree")}
                                            />
                                            <InputPrepend
                                              mb="2"
                                              errors={props?.errors}
                                              touched={props?.touched}
                                              title={`${property?.min_pax} - ${property?.max_pax} pax`}
                                              multiple={[
                                                {
                                                  prefix: "Rp ",
                                                  isCurrency: true,
                                                  thousandSeparator: true,
                                                  name: `${name}.group_list[${index}].property_list[${i}].prices.price`,
                                                  value:
                                                    value?.group_list[index]?.property_list[i]?.prices?.price,
                                                  placeholder: `${t(
                                                    "field.price"
                                                  )}`,
                                                  disabled: true,
                                                },
                                              ]}
                                            />
                                          </div>
                                        ) : (
                                          <>
                                            <div className="row">
                                              <div className="col-md-3"></div>
                                              <div className="col-md-3">
                                                <div className="form-group" style={{ marginBottom: 0 }}>
                                                  <label className="form-label mb-1">
                                                    {t("commons.adult")}
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <div className="form-group" style={{ marginBottom: 0 }}>
                                                  <label className="form-label mb-1">
                                                    {t("commons.child")}
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <div className="form-group" style={{ marginBottom: 0 }}>
                                                  <label className="form-label mb-1">
                                                    {t("commons.infantLabel")}
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <InputPrepend
                                              mb="2"
                                              mt="2"
                                              errors={props?.errors}
                                              touched={props?.touched}
                                              title={
                                                corporateType === "Group" 
                                                  ? `${property?.min_pax} - ${property?.max_pax} pax` 
                                                  : `FIT ${t("field.price")}`
                                              }
                                              multiple={[
                                                {
                                                  prefix: "Rp ",
                                                  isCurrency: true,
                                                  thousandSeparator: true,
                                                  name: `${name}.group_list[${index}].property_list[${i}].prices.adult_price`,
                                                  value:
                                                    value?.group_list[index]
                                                      ?.property_list[i]?.prices
                                                      ?.adult_price,
                                                  placeholder: `${t(
                                                    "commons.adult"
                                                  )}`,
                                                  disabled: true,
                                                },
                                                {
                                                  prefix: "Rp ",
                                                  isCurrency: true,
                                                  thousandSeparator: true,
                                                  name: `${name}.group_list[${index}].property_list[${i}].prices.child_price`,
                                                  value:
                                                    value?.group_list[index]
                                                      ?.property_list[i]?.prices
                                                      ?.child_price,
                                                  placeholder: `${t(
                                                    "commons.child"
                                                  )}`,
                                                  disabled: true,
                                                },
                                                {
                                                  prefix: "Rp ",
                                                  isCurrency: true,
                                                  thousandSeparator: true,
                                                  name: `${name}.group_list[${index}].property_list[${i}].prices.infant_price`,
                                                  value:
                                                    value?.group_list[index]
                                                      ?.property_list[i]?.prices
                                                      ?.infant_price,
                                                  placeholder: `${t(
                                                    "commons.infantLabel"
                                                  )}`,
                                                  disabled: true,
                                                },
                                              ]}
                                            />
                                          </>
                                        )}
                                      </>
                                    );
                                  })}
                                </div>
                              )}
                            </>
                          ) : (
                            <div></div>
                          )}
                        </>
                      );
                    })}
                  </div>
                )}
              </CardBody>
            </Card>
            {active === "4" &&
              value?.corporate_lounge_list?.length > 0 && (
              <Card className="rounded">
                <CardHeader title={`${t("field.price")} Corporate Lounge`} />
                <CardBody style={{ paddingTop: 0 }}>
                  {value?.corporate_lounge_list?.map((item, index) => {
                    return (
                      <CardFlush
                        list
                        label={
                          `${item?.name} ${item?.route_type 
                            ? `(${item?.route_type?.charAt(0).toUpperCase() + item?.route_type?.slice(1)})` 
                            : ''}`
                        }
                        button={
                          <div
                            className="d-flex justify-content-end"
                            style={{ cursor: 'pointer' }}
                            onClick={() => detailCorporateLounge(index)}
                          >
                            <span className="material-icons-round right-icon">
                              navigate_next
                            </span>
                          </div>
                        }
                        key={index}
                        size={{ label: 7, desc: 5 }}
                      />
                    )
                  })}
                </CardBody>
              </Card>
              )
            }
            {active !== "4" && active !== "5" && corporateType === "MOB" && (
              <Card className="rounded">
                <CardHeader title={t("field.priceUnderQuota")} />
                <CardBody style={{ paddingTop: 0 }}>
                  {value?.group_list?.map((item, index) => {
                    return (
                      <>
                        {item?.property_list?.length && item?.property_list?.map((property, i) => {
                          return (
                            <> 
                            <div className="row">
                              <div className="col-md-3"></div>
                              <div className="col-md-3">
                                <div className="form-group" style={{ marginBottom: 0 }}>
                                  <label className="form-label mb-1">
                                    {t("commons.adult")}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                <div className="form-group" style={{ marginBottom: 0 }}>
                                  <label className="form-label mb-1">
                                    {t("commons.child")}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                <div className="form-group" style={{ marginBottom: 0 }}>
                                  <label className="form-label mb-1">
                                    {t("commons.infantLabel")}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <InputPrepend
                              mb="0"
                              errors={props?.errors}
                              touched={props?.touched}
                              title={t("field.price")}
                              multiple={[
                                {
                                  disabled: true,
                                  prefix: "Rp ",
                                  isCurrency: true,
                                  thousandSeparator: true,
                                  name: `${name}.group_list[${index}].property_list[${i}].prices.adult_price_under_quota`,
                                  value: value?.group_list[index]?.property_list[i]?.prices?.adult_price_under_quota,
                                  placeholder: `${t("commons.adult")}`,
                                },
                                {
                                  disabled: true,
                                  prefix: "Rp ",
                                  isCurrency: true,
                                  thousandSeparator: true,
                                  name: `${name}.group_list[${index}].property_list[${i}].prices.child_price_under_quota`,
                                  value: value?.group_list[index]?.property_list[i]?.prices?.child_price_under_quota,
                                  placeholder: `${t("commons.child")}`,
                                },
                                {
                                  disabled: true,
                                  prefix: "Rp ",
                                  isCurrency: true,
                                  thousandSeparator: true,
                                  name: `${name}.group_list[${index}].property_list[${i}].prices.infant_price_under_quota`,
                                  value: value?.group_list[index]?.property_list[i]?.prices?.infant_price_under_quota,
                                  placeholder: `${t(
                                    "commons.infantLabel"
                                  )}`,
                                },
                              ]}
                            />
                            </>
                          )
                        })}
                      </>
                    )
                  })}

                </CardBody>
              </Card>
            )}
            <Card className="rounded">
              <CardHeader title={t("product.priceTaxInfo")} />
              <CardBody style={{ paddingTop: '16px' }}>
                <Checkbox
                  disabled
                  name={`${name}.is_includes_concession`}
                  checked={value?.is_includes_concession}
                  value={1}
                  label={t("product.includes_concession")}
                />
                <Checkbox
                  disabled
                  name={`${name}.is_includes_ppn`}
                  checked={value?.is_includes_ppn}
                  value={1}
                  label={t("product.includes_ppn")}
                />
              </CardBody>
            </Card>
          </>
        )}
        <CorporateLoungeModal
          show={modalCorpLounge}
          formik={props}
          index={indexCorpLounge}
          value={value}
          name={name}
          toggle={() => setModalCorpLounge(false)}
        />
      </>
    );
  };

  return (
    <>
    <div className="menu-container">
      <div className="grid-header">
        <div className="side-menu">
          <div className="menu-title text-extra-bold">
            <div className="side-menu-back-icon ">
              <span
                className="material-icons clickable"
                onClick={() => toggle()}
              >
                arrow_back
              </span>
            </div>
            Detail {t("field.price")}
          </div>
          <div style={{ padding: "0px 15px 30px 10px" }}>
            <Card className="rounded">
              <CardFlush
                sideMenu
                title={t("field.corporateName")}
                desc={formik?.values?.name}
                leftIcon="assignment_ind"
              />
              <CardFlush
                sideMenu
                title={t("field.cooperationType")}
                desc={formik?.values?.type_list[idType]?.corporate_type}
                leftIcon="swap_horiz"
              />
            </Card>
          </div>
        </div>
        <div className="side-menu-detail">
          <Container>
            <HeaderMenu
              title={t("commons.details")}
            />
            <Card className="rounded mx-3">
              <CardHeader title={t("product.period")} />
              <CardBody>
                <div className="d-flex justify-content-between">
                  <div className="text-bold">{t("promo.startDate")}</div>
                  <Col md={8}>
                    <Input
                      name={`type_list[${idType}].period_list[${index}].start_date`}
                      type="date"
                      errors={formik.errors}
                      touched={formik.touched}
                      value={
                        formik?.values?.type_list[idType]?.period_list[index]
                          ?.start_date ?? ""
                      }
                      disabled
                    />
                  </Col>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="text-bold">{t("promo.endDate")}</div>
                  <Col md={8}>
                    <Input
                      name={`type_list[${idType}].period_list[${index}].end_date`}
                      type="date"
                      errors={formik.errors}
                      touched={formik.touched}
                      value={
                        formik?.values?.type_list[idType]?.period_list[index]
                          ?.end_date ?? ""
                      }
                      disabled
                    />
                  </Col>
                </div>
              </CardBody>
            </Card>
            <Card className="rounded mx-3">
              <CardHeader title={t("field.product")} />
              <CardBody>
                <div
                  className="content-container-side"
                  style={{ padding: "0" }}
                >
                  <div className="tab-menu overflow">
                    <div style={{ width: "fit-content" }}>
                      <Tabs
                        scrollButtons="auto"
                        variant="scrollable"
                        value={activeTab}
                        textColor="primary"
                        indicatorColor="primary"
                        onChange={(event, newValue) => {
                          setActiveTab(newValue);
                        }}
                      >
                        <Tab
                          label={`${t("product.meetandgreet")} Domestic`}
                          value="0"
                        />
                        <Tab
                          label={`${t("product.meetandgreet")} International`}
                          value="1"
                        />
                        <Tab label={t("product.fasttrack")} value="2" />
                        <Tab label="Transfer" value="3" />
                        <Tab label="Lounge" value="4" />
                        <Tab label="Transport" value="5" />
                      </Tabs>
                    </div>
                  </div>
                  <div>{ShowTab(activeTab, formik)}</div>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      </div>
    </div>
    </>
  );
};

export default withTrans(DetailPriceCorporate);
