import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux";
import moment from "moment";

import ModalFilter from "./ModalFilter";
import Table from "../../../components/table";
import Button from "../../../components/buttons/Button";
import { withTrans } from "../../../i18n/withTrans";

import ReportService from "../../../store/actions/report/";

const ReportSales = ({ t, user, airports, corporates, products }) => {
  const dispatch = useDispatch();
  const [dataReport, setdataReport] = useState([]);
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [haveAirport, setHaveAirport] = useState(
    user.role_code === "admin_bandara"
      ? true
      : user.role_code === "supervisor"
      ? true
      : user.role_code === "sales_bandara"
      ? true
      : user.role_code === "finance_bandara"
      ? true
      : false
  );
  const [paramFilter, setParamFilter] = useState({
    end_date: moment().format("YYYY-MM-DD"),
    start_date: moment().format("YYYY-MM-DD"),
    airport_id:
      user.role_code === "admin_bandara" ||
      user.role_code === "supervisor"
        ? user.airport_id
        : "",
    product_id: "",
    corporate_id: "",
    application: "",
    service_type: "",
    flight_type: "",
    payment_type: "",
    region: "",
  });

  const handleFilterModal = () => {
    setShowModalFilter(true);
  };

  const onApplyFilter = (param) => {
    setParamFilter({
      airport_id: param.airport_id,
      application: param.application,
      product_id: param.product_id,
      corporate_id: param.corporate_id,
      service_type: param.service_type,
      flight_type: param.flight_type,
      start_date: param.start_date,
      end_date: param.end_date,
      payment_type: param.payment_type,
      region: param.region,
    });
  };

  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        ...paramFilter,
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        export_show_commission: false,
      };
      dispatch(ReportService.getSales(param, resolve));
    }).then((res) => {
      setdataReport(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, paramFilter]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [paramFilter]);

  const handleDownloadReport = () => {
    new Promise((resolve) => {
      setLoadingDownload(true);
      dispatch(ReportService.downloadSales(paramFilter, resolve))
    }).then((res) => {
      setLoadingDownload(false);
    });
  };

  const numberWithCommas = (x) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return 0;
    }
  };

  const column = [
    {
      Header: "NO",
      accessor: "no",
    },
    {
      Header: t("report.bookingDate").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <>{moment(original?.booking_date).format("DD/MM/YYYY, HH:mm:ss")}</>
        );
      },
    },
    {
      Header: t("report.serviceTime").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const service_time = original?.date && original?.date !== null 
          ? `${moment(original?.date).format("DD/MM/YYYY")}, ${original?.time}`
          : "-";
        return service_time;
      },
    },
    {
      Header: t("field.bookingId").toUpperCase(),
      accessor: "booking_id",
    },
    {
      Header: t("field.airport").toUpperCase(),
      accessor: "airport",
    },
    {
      Header: t("field.flightNumber").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.flight ?? "-";
      },
    },
    {
      Header: t("field.product").toUpperCase(),
      accessor: "product",
    },
    {
      Header: t("report.agent").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.agent?? "-";
      },
    },
    {
      Header: t("field.paymentType").toUpperCase(),
      accessor: "payment_type",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.payment_type ?? "-";
      },
    },
    {
      Header: "APP",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const app =
          original?.application === "Mobile"
            ? "Mobile"
            : original.application === "Web Admin"
            ? "Web Admin"
            : "Web Public";
        return <>{app}</>;
      },
    },
    {
      Header: "PAX",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const total_pax = parseInt(original?.adult_number) + parseInt(original?.child_number) + parseInt(original?.infant_number);
        return total_pax !== undefined
          ? total_pax
          : "-";
      },
    },
    {
      Header: "GRAND TOTAL",
      accessor: "grand_total",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        let grandTotal = 0;
        if (original?.is_cross_selling) {
          if (haveAirport) {
            if (user?.airport_id === original?.airport_id_cross_selling) {
              grandTotal = original?.price_cut_cross_selling;
            } else {
              grandTotal = original?.total_price_cross_selling;
            }
          } else {
            if (paramFilter?.airport_id) {
              if (paramFilter?.airport_id === original?.airport_id_cross_selling) {
                grandTotal = original?.price_cut_cross_selling;
              } else {
                grandTotal = original?.total_price_cross_selling;
              }
            } else {
              grandTotal = original?.grand_total;
            }
          }

        } else {
          grandTotal = original?.grand_total
        }
        return <>{"Rp " + numberWithCommas(grandTotal)}</>;
      },
    },
  ];

  return (
    <>
      <div className="d-flex mb-4 justify-content-end">
        <Button
          title="Filter"
          size="sm"
          rounded={true}
          variant="button-light btn-lg"
          onClick={handleFilterModal}
        />
        <div className="nav-separator mr-3 ml-3" />
        <Button
          title={(
            <>
              {loadingDownload && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
              )}
              {t("report.downloadReport")}
            </>
          )}
          leftIcon={!loadingDownload ? "download" : null}
          rounded={true}
          variant="button-light btn-lg"
          onClick={handleDownloadReport}
        />
      </div>
      <Table
        columns={column.filter((item) => item.show !== false)}
        data={dataReport ?? []}
        pagination={{ page, totalData, maxPage, toggle: setPage }}
      />
      
      <ModalFilter
        user={user}
        haveAirport={haveAirport}
        show={showModalFilter}
        toggle={() => setShowModalFilter(false)}
        filter={paramFilter}
        airport={airports}
        product={products}
        corporate={corporates}
        toggleFilter={() => {
          setParamFilter({});
          handleRefresh();
        }}
        onApplyFilter={onApplyFilter}
      />
    </>
  )
}

export default withTrans(ReportSales)