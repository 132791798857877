import { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { Row, Col } from "reactstrap";
import * as Yup from "yup";
import moment from "moment";

import AddPriceRegular from "./AddPriceRegular";
import Loader from "../../../../components/commons/Loader";
import ButtonModal from "../../../../components/modal/ButtonModal";
import ConfirmationModal from "../../../../components/modal/ConfirmModal";
import CardFlush from "../../../../components/cards/cardFlush";
import Card from "../../../../components/cards/card";
import CardHeader from "../../../../components/cards/cardHeader";
import CardBody from "../../../../components/cards/cardBody";
import Input from "../../../../components/forms/Input";
import Checkbox from "../../../../components/forms/Checkbox";
import InputPrepend from "../../../../components/forms/InputPrepend";
import Select2Multi from "../../../../components/forms/Select2Multi";
import LabelPills from "../../../../components/table/labelPills";
import { toastError } from "../../../../components/commons/toast";
import { history } from "../../../../utils/History";
import { withTrans } from "../../../../i18n/withTrans";

//Service
import AirportService from "../../../../store/actions/master_data/airport";
import RegularService from "../../../../store/actions/product/regular_price";

const Index = ({ pending, error, data_post, data_put, t }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [dataAirport, setDataAirport] = useState([]);
  const [dataEditAirport, setDataEditAirport] = useState([]);
  const [indexPrice, setIndexPrice] = useState();
  const [indexCancel, setIndexCancel] = useState(null);
  const [lastPriceValue, setLastPriceValue] = useState();
  const [showPageIndex, setShowPageIndex] = useState(true);
  const [showPagePrice, setShowPagePrice] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  const showCancelModal = () => {
    setCancelModal(!cancelModal);
  };
  const handleCancel = () => {
    history.push({ pathname: "/product", state: { tab: "regular" } });
  };

  useEffect(() => {
    if (error) {
      setLoadingSubmit(false);
    }
  }, [error]);

  useEffect(() => {
    if (data_post?.data?.status === 200) {
      setLoadingSubmit(false);
    }
  }, [data_post]);

  useEffect(() => {
    if (data_put?.data?.status === 200) {
      setLoadingSubmit(false);
    }
  }, [data_put]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        id,
      };
      if (id) {
        dispatch(RegularService.get(param, resolve));
      }
    }).then((res) => {
      if (res) {
        formikRef?.current?.setFieldValue("name", res?.data[0]?.name);
        formikRef?.current?.setFieldValue(
          "period_list",
          res?.data[0]?.period_list
        );
        // setDataEditAirport(res?.data[0]?.airport_list);
        formikRef?.current?.setFieldValue(
          `airport_list`,
          res?.data[0]?.airport_list?.map((x) => x.airport_id)
        );
        const airportTooltips = [];
        res?.data[0]?.airport_list?.map((airport) => {
          const airportFormat = 
            `${airport?.airport_detail?.code} - ${airport?.airport_detail?.city}`;
          airportTooltips?.push(airportFormat);
        });
        formikRef?.current?.setFieldValue("airports", airportTooltips);
        formikRef?.current?.setFieldValue("tax", res?.data[0]?.tax);

        if (res?.data[0]?.tax?.is_include_concession) {
          formikRef?.current?.setFieldValue("tax.is_includes_concession", [
            "1",
          ]);
        } 
        if (res?.data[0]?.tax?.is_include_ppn) {
          formikRef?.current?.setFieldValue("tax.is_includes_ppn", ["1"]);
        } 

        res?.data[0]?.period_list?.map((period, index) => {
          if (period?.product_list?.length) {
            period?.product_list?.map((product) => {
              if (product?.product_name === 1) {
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].is_checked_meet_inter`,
                  ["1"]
                );
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].meet_and_greet_inter`,
                  product
                );
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].meet_and_greet_inter.flight_types`,
                  product?.flight_types?.map((x) => x.flight_type)
                );
              }
              if (product?.product_name === 2) {
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].is_checked_meet_dom`,
                  ["1"]
                );
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].meet_and_greet_dom`,
                  product
                );
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].meet_and_greet_dom.flight_types`,
                  product?.flight_types?.map((x) => x.flight_type)
                );
              }
              if (product?.product_name === 3) {
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].is_checked_fast`,
                  ["1"]
                );
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].fast_track`,
                  product
                );
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].fast_track.flight_types`,
                  product?.flight_types?.map((x) => x.flight_type)
                );
              }
              if (product?.product_name === 4) {
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].is_checked_transfer`,
                  ["1"]
                );
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].transfer`,
                  product
                );
                formikRef?.current?.setFieldValue(
                  `period_list[${index}].transfer.flight_types`,
                  product?.flight_types?.map((x) => x.flight_type)
                );
              }
              if (product?.group_prices?.length) {
                product.group_prices = 
                  product?.group_prices?.sort((a, b) => {
                    return a.min_pax - b.min_pax;
                  });
              }
            });
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 99999,
        is_service: true,
        regularPriceUnpriced: id ? false : true,
        product_regular_id: id ?? "",
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      let list = [];
      if (res && res.data) {
        res?.data?.map((airport) => {
          list = [
            ...list,
            {
              ...airport,
              value: airport?.id,
              label: `${airport?.code}-${airport?.city}-${airport?.name}`,
            },
          ];
        });
      }
      setDataAirport(list);
    });
  }, []);

  const validation = Yup.object().shape({
    name: Yup.string().required(t("commons.required")).nullable(),
    // airport_list: Yup.array().required(t("commons.required")).nullable(),
    period_list: Yup.array().required(t("commons.required")).nullable(),
  });

  const initialValues = {
    id: id ?? "",
    name: "",
    airport_list: "",
    tax: {
      is_includes_concession: null,
      is_includes_ppn: null,
      is_include_concession: false,
      is_include_ppn: false,
    },
    period_list: [],
  };

  const addPrice = (formik, index) => {
    if (index !== null && index !== undefined) {
      setIndexPrice(index);
      setIndexCancel(index);
      setLastPriceValue(formik?.values?.period_list);
    } else {
      setIndexPrice(formik?.values?.period_list?.length);
      setIndexCancel(null);
    }
    setShowPagePrice(!showPagePrice);
    setShowPageIndex(!showPageIndex);
  };

  const compareFlightTypes = (array1, array2) => {
    //filter array1 that have same flight_type with array2
    const sameValuesInArray1 = array1.filter((item) =>
      array2.some((entry) => entry.flight_type === item.flight_type)
    );

    //filter array2 that not have same flight_type with array1
    const spliceSameValuesInArray2 = array2.filter(
      (item) => !array1.some((entry) => item.flight_type === entry.flight_type)
    );

    const results = [...sameValuesInArray1, ...spliceSameValuesInArray2];
    return results;
  };

  const compareDataAirports = (array1, array2) => {
    //filter array1 that have same airport_id with array2
    const sameValuesInArray1 = array1.filter((item) =>
      array2.some((entry) => entry.airport_id === item.airport_id)
    );

    //filter array2 that not have same airport_id with array1
    const spliceSameValuesInArray2 = array2.filter(
      (item) => !array1.some((entry) => item.airport_id === entry.airport_id)
    );

    const results = [...sameValuesInArray1, ...spliceSameValuesInArray2];
    return results;
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={async (values, { setStatus }) => {
          if (!values?.airport_list?.length) {
            toastError(
              `${t("commons.empty_data_message")} ${t("field.airport")}!`
            );
            setLoadingSubmit(false);
            return;
          }
          if (!values?.period_list?.length) {
            toastError(
              `${t("commons.empty_data_message")} ${t("field.price")}!`
            );
            setLoadingSubmit(false);
            return;
          }

          const airport_list = values?.airport_list?.map((item, index) => ({
            airport_id: item,
          }));

          if (id) {
            values.airport_list = compareDataAirports(
              dataEditAirport,
              airport_list
            );
          } else {
            values.airport_list = airport_list;
          }

          if (values?.tax?.is_includes_concession == 1) {
            values.tax.is_include_concession = true;
          } else {
            values.tax.is_include_concession = false;
          }

          if (values?.tax?.is_includes_ppn == 1) {
            values.tax.is_include_ppn = true;
          } else {
            values.tax.is_include_ppn = false;
          }

          values.tax.is_concession_from_airport = true;
          values.tax.is_ppn_from_airport = true;

          values?.period_list?.map((el) => {
            let product_list = [];
            if (el?.is_checked_meet_inter == 1) {
              el.meet_and_greet_inter.flight_types =
                el?.meet_and_greet_inter?.flight_types?.map((item, index) => ({
                  flight_type: item,
                }));
              el.meet_and_greet_inter["product_name"] = 1;

              if (el?.product_list?.length) {
                const idx = el?.product_list
                  ?.map((product) => product?.product_name)
                  ?.indexOf(1);
                if (idx > -1) {
                  el.meet_and_greet_inter.flight_types = compareFlightTypes(
                    el?.product_list[idx]?.flight_types,
                    el.meet_and_greet_inter.flight_types
                  );
                }
              }

              product_list?.push(el?.meet_and_greet_inter);
            }

            if (el?.is_checked_meet_dom == 1) {
              el.meet_and_greet_dom.flight_types =
                el?.meet_and_greet_dom?.flight_types?.map((item, index) => ({
                  flight_type: item,
                }));
              el.meet_and_greet_dom["product_name"] = 2;

              if (el?.product_list?.length) {
                const idx = el?.product_list
                  ?.map((product) => product?.product_name)
                  ?.indexOf(2);
                if (idx > -1) {
                  el.meet_and_greet_dom.flight_types = compareFlightTypes(
                    el?.product_list[idx]?.flight_types,
                    el.meet_and_greet_dom.flight_types
                  );
                }
              }

              product_list?.push(el?.meet_and_greet_dom);
            }

            if (el?.is_checked_fast == 1) {
              el.fast_track.flight_types = el?.fast_track?.flight_types?.map(
                (item, index) => ({ flight_type: item })
              );
              el.fast_track["product_name"] = 3;

              if (el?.product_list?.length) {
                const idx = el?.product_list
                  ?.map((product) => product?.product_name)
                  ?.indexOf(3);
                if (idx > -1) {
                  el.fast_track.flight_types = compareFlightTypes(
                    el?.product_list[idx]?.flight_types,
                    el.fast_track.flight_types
                  );
                }
              }

              product_list?.push(el?.fast_track);
            }

            if (el?.is_checked_transfer == 1) {
              el.transfer.flight_types = el?.transfer?.flight_types?.map(
                (item, index) => ({ flight_type: item })
              );
              el.transfer["product_name"] = 4;

              if (el?.product_list?.length) {
                const idx = el?.product_list
                  ?.map((product) => product?.product_name)
                  ?.indexOf(4);
                if (idx > -1) {
                  el.transfer.flight_types = compareFlightTypes(
                    el?.product_list[idx]?.flight_types,
                    el.transfer.flight_types
                  );
                }
              }

              product_list?.push(el?.transfer);
            }

            el["product_list"] = product_list;
          });
          const callback = () => {
            history.push({ pathname: "/product", state: { tab: "regular" } });
          };
          setStatus();

          if (id) {
            setLoadingSubmit(true);
            dispatch(RegularService.put(values, callback));
          } else {
            setLoadingSubmit(true);
            dispatch(RegularService.post(values, callback));
          }
        }}
      >
        {(props) => (
          <Form>
            <>
              {showPagePrice && (
                <AddPriceRegular
                  formik={props}
                  index={indexPrice}
                  indexCancel={indexCancel}
                  lastPriceValue={lastPriceValue}
                  toggle={addPrice}
                />
              )}
              {showPageIndex && (
                <div className="menu-container">
                  {pending && <Loader loading={pending} />}
                  <div className="col-md-12 px-5">
                    <div className="d-flex align-items-center">
                      <div className="treatment-title menu-title text-extra-bold pl-0">
                        <div className="treatment-title-icon ">
                          {id
                            ? `${t("commons.edit")} ${t("field.regularPrice")}`
                            : `${t("commons.set")} ${t("field.regularPrice")}`}
                        </div>
                      </div>
                    </div>
                    <Card className="rounded">
                      <CardHeader title={t("field.priceName")} />
                      <CardBody>
                        <Input
                          title={t("field.priceName")}
                          label="name"
                          type="text"
                          errors={props?.errors}
                          touched={props?.touched}
                          {...props?.getFieldProps("name")}
                          value={props.values.name}
                        />
                      </CardBody>
                    </Card>
                    <Card className="rounded">
                      <CardHeader title={t("field.airport")} />
                      <CardBody>
                        <Select2Multi
                          title={t("field.airport")}
                          name="airport_list"
                          options={dataAirport}
                          className="mb-4"
                          errors={props?.errors}
                          touched={props?.touched}
                          value={props?.values?.airport_list}
                          onChange={(name, value) => {
                            let data = value;
                            if (!data.length > 0) {
                              data = "";
                            }
                            const airports = [];
                            if (data.length) {
                              data?.map((i) => {
                                const index = dataAirport?.findIndex(
                                  (x) => x.id === i
                                );
                                const airportFormat = `${dataAirport[index].code} - ${dataAirport[index].city}`;
                                airports?.push(airportFormat);
                              });
                            };
                            props?.setFieldValue("airport_list", data);
                            props?.setFieldValue("airports", airports);
                          }}
                          onBlur={props?.setFieldTouched}
                        />
                      </CardBody>
                    </Card>
                    <Card className="rounded">
                      <CardHeader title={t("product.priceTaxInfo")} />
                      <CardBody style={{ paddingTop: '16px' }}>
                        <Checkbox
                          style={{ marginTop: "30px" }}
                          name="tax.is_includes_concession"
                          checked={props?.values?.tax?.is_includes_concession}
                          onChange={props?.handleChange}
                          value={1}
                          label={t("product.includes_concession")}
                        />
                        <Checkbox
                          style={{ marginTop: "30px" }}
                          name="tax.is_includes_ppn"
                          checked={props?.values?.tax?.is_includes_ppn}
                          onChange={props?.handleChange}
                          value={1}
                          label={t("product.includes_ppn")}
                        />
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader title={t("field.price")} />
                      <CardBody>
                        {props?.values?.period_list !== undefined && (
                          <Row
                            style={{
                              fontWeight: 800,
                              fontSize: "16px",
                              color: "rgba(36, 39, 38, 0.3)",
                              paddingBottom: 3,
                            }}
                          >
                            <Col md={7} className="px-0">
                              <Row>
                                <Col md={2} className="px-0">
                                  <div className="text-center">
                                    {t("commons.number")}
                                  </div>
                                </Col>
                                <Col md={5}>
                                  <div>{t("field.product")}</div>
                                </Col>
                                <Col md={5}>
                                  <div>{t("commons.activities")}</div>
                                </Col>
                              </Row>
                            </Col>
                            <Col md={2} className="pr-0">
                              <div className="text-center">
                                {t("promo.startDate")}
                              </div>
                            </Col>
                            <Col md={3} className="px-0">
                              <div className="text-center">
                                {t("promo.endDate")}
                              </div>
                            </Col>
                          </Row>
                        )}
                        {props?.values?.period_list?.length < 1 && (
                          <CardFlush
                            list
                            label={t("commons.no_data")}
                            size={{ label: 12 }}
                          />
                        )}
                        {props?.values?.period_list?.map((item, index) => {
                          const flights = [];
                          const serviceList = [];
                          if (item?.is_checked_fast == 1) {
                            serviceList.push("Fast Track");
                            item?.fast_track?.flight_types?.map((flight) => {
                              if (flight === 5) {
                                flights?.push(t("field.arrival"));
                              }
                              if (flight === 6) {
                                flights?.push(t("field.departure"));
                              }
                            });
                          }
                          if (item?.is_checked_meet_dom == 1) {
                            serviceList.push("Meet and Greet Domestic");
                            item?.meet_and_greet_dom?.flight_types?.map(
                              (flight) => {
                                if (flight === 5) {
                                  flights?.push(t("field.arrival"));
                                }
                                if (flight === 6) {
                                  flights?.push(t("field.departure"));
                                }
                              }
                            );
                          }
                          if (item?.is_checked_meet_inter == 1) {
                            serviceList.push("Meet and Greet International");
                            item?.meet_and_greet_inter?.flight_types?.map(
                              (flight) => {
                                if (flight === 5) {
                                  flights?.push(t("field.arrival"));
                                }
                                if (flight === 6) {
                                  flights?.push(t("field.departure"));
                                }
                              }
                            );
                          }
                          if (item?.is_checked_transfer == 1) {
                            serviceList.push("Transfer");
                            item?.transfer?.flight_types?.map((flight) => {
                              if (flight === 1) {
                                flights?.push("International to International");
                              }
                              if (flight === 2) {
                                flights?.push(t("International to Domestic"));
                              }
                              if (flight === 3) {
                                flights?.push(t("Domestic to International"));
                              }
                              if (flight === 4) {
                                flights?.push(t("Domestic to Domestic"));
                              }
                            });
                          }
                          if (item?.is_checked_lounge == 1) {
                            serviceList.push("Lounge");
                          }

                          const flightList = [...new Set(flights)];

                          return (
                            <Row
                              style={{
                                paddingTop: 8,
                                paddingBottom: 8,
                              }}
                            >
                              <Col md={7} className="px-0">
                                <Row>
                                  <Col md={2} className="px-0">
                                    <div className="text-center">
                                      {index + 1}
                                    </div>
                                  </Col>
                                  <Col md={5}>
                                    <div className="row">
                                      {serviceList?.map((item, i) => (
                                        <LabelPills data={item} />
                                      ))}
                                    </div>
                                  </Col>
                                  <Col md={5}>
                                    <div className="row">
                                      {flightList?.map((item, i) => (
                                        <LabelPills data={item} />
                                      ))}
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={2} className="pr-0">
                                <div className="text-center">
                                  {moment(item?.start_date).format(
                                    "D MMMM YYYY"
                                  )}
                                </div>
                              </Col>
                              <Col md={3} className="px-0">
                                <div className="text-center">
                                  {moment(item?.end_date).format("D MMMM YYYY")}
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      float: "right",
                                    }}
                                    onClick={() => addPrice(props, index)}
                                    className="material-icons-round right-icon"
                                  >
                                    navigate_next
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          );
                        })}
                        {props?.values?.period_list !== undefined && (
                          <div
                            style={{
                              color: "#63ae5c",
                              padding: "10px 15px",
                              display: "flex",
                              cursor: "pointer",
                            }}
                            onClick={() => addPrice(props)}
                          >
                            <div
                              style={{
                                borderRadius: "50%",
                                border: "solid 1px #63ae5c",
                                width: 30,
                                height: 30,
                                paddingTop: 2,
                                marginRight: 20,
                                textAlign: "center",
                              }}
                            >
                              <span className="material-icons-round">add</span>
                            </div>
                            <p
                              className="text-bold"
                              style={{
                                fontSize: 14,
                                margin: 0,
                                alignSelf: "center",
                              }}
                            >
                              {t("commons.add") + " " + t("field.price")}
                            </p>
                          </div>
                        )}
                      </CardBody>
                    </Card>
                    <div style={{ float: "right" }}>
                      <ButtonModal
                        cancelTitle={t("commons.cancel")}
                        confirmTitle={t("commons.save")}
                        toggle={() => showCancelModal()}
                        disabledConfirm={loadingSubmit}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          </Form>
        )}
      </Formik>
      <ConfirmationModal
        show={cancelModal}
        toggle={showCancelModal}
        confirm={handleCancel}
        icon="info"
        isDelete
        isCancel={t("commons.back")}
        isConfirm={t("commons.yesCancel")}
      >
        <Col md={8}>
          <div className="normal-title">
            {t("commons.areYouSureCancel")}{" "}
            <strong>{t("field.regularPrice")}</strong> ?
          </div>
        </Col>
      </ConfirmationModal>
    </>
  );
};

const mapStateToProps = ({ reg_price: { error, data_post, data_put } }) => {
  return { error, data_post, data_put };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Index));
