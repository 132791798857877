import React, { useState, useEffect } from "react"
import { connect, useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";
import Select from "react-select";
import moment from "moment";

import ModalFilter from "./ModalFilter";
import Table from "../../../components/table";
import Button from "../../../components/buttons/Button";
import Card from "../../../components/cards/card";
import CardHeader from "../../../components/cards/cardHeader";
import CardBody from "../../../components/cards/cardBody";
import ProductionCard from "../../../components/cards/ProductionCard";
import DatePicker from "../../../components/forms/DateRangePicker";
import { withTrans } from "../../../i18n/withTrans";
import { reactSelectCustomStyles } from "../../../utils/Constants";

import ReportService from "../../../store/actions/report/";

const ReportCommission = ({ t, pending, user, airports, products, corporates }) => {
  const dispatch = useDispatch();
  const [dataReport, setdataReport] = useState([]);
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [totalReservation, setTotalReservation] = useState(0);
  const [totalPax, setTotalPax] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  const [commissionPaxIndividual, setCommissionPaxIndividual] = useState(0);
  const [subCommissionIndividual, setSubCommissionIndividual] = useState(0);
  const [commissionPaxGroup, setCommissionPaxGroup] = useState(0);
  const [subCommissionGroup, setSubCommissionGroup] = useState(0);
  const [commissionPaxOther, setCommissionPaxOther] = useState(0);
  const [subCommissionOther, setSubCommissionOther] = useState(0);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [haveAirport, setHaveAirport] = useState(
    user.role_code === "admin_bandara"
      ? true
      : user.role_code === "supervisor"
      ? true
      : user.role_code === "sales_bandara"
      ? true
      : user.role_code === "finance_bandara"
      ? true
      : false
  );
  const [paramFilter, setParamFilter] = useState({
    end_date: moment().format("YYYY-MM-DD"),
    start_date: moment().format("YYYY-MM-DD"),
    airport_id:
      user.role_code === "admin_bandara" ||
      user.role_code === "supervisor"
        ? user.airport_id
        : "",
    corporate_id: "",
    product_id: "",
    payment_type: "",
    booking_type: "",
    report_type: true,
  });
  const [filterCommissionSummary, setFilterCommissionSumary] = useState({
    end_date: moment().format("YYYY-MM-DD"),
    start_date: moment().format("YYYY-MM-DD"),
    airport_id: "",
  });

  const handleFilterModal = () => {
    setShowModalFilter(true);
  };

  const onApplyFilter = (param) => {
    setParamFilter({
      airport_id: param.airport_id,
      corporate_id: param.corporate_id,
      payment_type: param.payment_type,
      start_date: param.start_date,
      end_date: param.end_date,
      product_id: param.product_id,
      booking_type: param.booking_type,
      report_type: param.report_type,
    });
  };

  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        ...paramFilter,
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
      };
      dispatch(ReportService.getCommission(param, resolve))
    }).then((res) => {
      setdataReport(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, paramFilter]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [paramFilter]);

  const handleSummary = () => {
    new Promise((resolve) => {
      const param = {
        airport_id: filterCommissionSummary?.airport_id,
        start_date: filterCommissionSummary?.start_date,
        end_date: filterCommissionSummary?.end_date,
      };
      dispatch(ReportService.getResumeCommission(param, resolve));
    }).then((res) => {
      const data = res.data;
      if (data) {
        setTotalReservation(
          data?.filter((item) => item.field === "total_reservation")?.length > 0
            ? data?.filter((item) => item.field === "total_reservation")[0]?.value
            : 0
        );
        setTotalPax(
          data?.filter((item) => item.field === "total_pax")?.length > 0
            ? data?.filter((item) => item.field === "total_pax")[0]?.value
            : 0
        );
        setTotalCommission(
          data?.filter((item) => item.field === "total_commission")?.length > 0
            ? `Rp ${numberWithCommas(
              data?.filter((item) => item.field === "total_commission")[0]?.value
            )}`
            : 'Rp 0'
        );
        setCommissionPaxIndividual(
          data?.filter((item) => item.field === "commission_pax_individual")?.length > 0
            ? data?.filter((item) => item.field === "commission_pax_individual")[0]?.value
            : 0
        );
        setSubCommissionIndividual(
          data?.filter((item) => item.field === "sub_commission_individual")?.length > 0
            ? `Rp ${numberWithCommas(
              data?.filter((item) => item.field === "sub_commission_individual")[0]?.value
            )}`
            : 'Rp 0'
        );
        setCommissionPaxGroup(
          data?.filter((item) => item.field === "commission_pax_group")?.length > 0
            ? data?.filter((item) => item.field === "commission_pax_group")[0]?.value
            : 0
        );
        setSubCommissionGroup(
          data?.filter((item) => item.field === "sub_commission_group")?.length > 0
            ? `Rp ${numberWithCommas(
              data?.filter((item) => item.field === "sub_commission_group")[0]?.value
            )}`
            : 'Rp 0'
        );
        setCommissionPaxOther(
          data?.filter((item) => item.field === "commission_pax_other")?.length > 0
            ? data?.filter((item) => item.field === "commission_pax_other")[0]?.value
            : 0
        );
        setSubCommissionOther(
          data?.filter((item) => item.field === "sub_commission_other")?.length > 0
            ? `Rp ${numberWithCommas(
              data?.filter((item) => item.field === "sub_commission_other")[0]?.value
            )}`
            : 'Rp 0'
        );
      }
    });
  };

  useEffect(() => {
    handleSummary();
  }, [filterCommissionSummary]);

  const handleDownloadReport = () => {
    new Promise((resolve) => {
      const filterDownloadCommission = {
        ...paramFilter,
        export_excel: true,
      }
      setLoadingDownload(true);
      dispatch(ReportService.downloadCommission(filterDownloadCommission, resolve))
    }).then((res) => {
      setLoadingDownload(false);
    });
  };

  const numberWithCommas = (x) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return 0;
    }
  };

  const column = [
    {
      Header: "NO",
      accessor: "no",
    },
    {
      Header: "ID BOOKING",
      accessor: "booking_id",
    },
    {
      Header: t("field.bookingTime").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.booking_time ? moment(original?.booking_time).format("DD/MM/YYYY, HH:mm") : '-';
      },
    },
    {
      Header: t("report.serviceTime").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.service_time ? moment(original?.service_time).format("DD/MM/YYYY, HH:mm") : '-';
      },
    },
    {
      Header: t("field.airport").toUpperCase(),
      accessor: "airport",
    },
    {
      Header: t("field.bookingType").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.booking_type ?? "-";
      },
    },
    {
      Header: t("field.passenger").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.passenger ?? "-";
      },
    },
    {
      Header: "TOTAL PAX",
      accessor: "total_pax",
    },
    {
      Header: t("report.agent").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.corporate ?? "-";
      },
    },
    {
      Header: t("field.product").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.product ?? "-";
      },
    },
    {
      Header: t("report.productCost").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <>
            {"Rp " +
              (original?.product_price
                ? numberWithCommas(original?.product_price)
                : 0)}
          </>
        );
      },
    },
    {
      Header: t("field.additionalService").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <>
            {"Rp " +
              (original?.additional_service_price
                ? numberWithCommas(original?.additional_service_price) 
                : 0)}
          </>
        );
      },
    },
    {
      Header: "GRAND TOTAL",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <>
            {"Rp " +
              (original?.grand_total
                ? numberWithCommas(original?.grand_total)
                : 0)}
          </>
        );
      },
    },
    {
      Header: t("booking.paymentType").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.payment_type ?? "-";
      },
    },
    {
      Header: t(t("field.commission")).toUpperCase(),
      accessor: "commission",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return <>{"Rp " + numberWithCommas(original?.commission)}</>;
      },
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-end">
        <Select
          isClearable={!!filterCommissionSummary?.airport_id}
          classNamePrefix="select"
          className="basic-single mr-3"
          isSearchable
          name="airport_id"
          styles={reactSelectCustomStyles}
          options={[
            {
              value: "",
              label: `${t("commons.all")} ${t("field.airport")}`,
            },
            ...airports,
          ]}
          value={
            airports?.filter((obj) => {
              return obj?.value === filterCommissionSummary?.airport_id;
            })[0] ?? {
              label: `${t("commons.all")} ${t("field.airport")}`,
              value: "",
            }
          }
          onChange={(item) => {
            setFilterCommissionSumary({
              ...filterCommissionSummary,
              airport_id: item?.value,
            });
          }}
        />
        <div className="nav-separator mb-3 mx-2" />
        <div style={{ width: 300, marginLeft: 15 }}>
          <DatePicker
            name="start_date"
            handlechange={(startDate, endDate) => {
              setFilterCommissionSumary({
                ...filterCommissionSummary,
                start_date: startDate === "" || startDate === undefined
                  ? "" 
                  : moment(startDate).format("YYYY-MM-DD"),
                end_date: endDate === "" || endDate === undefined
                  ? "" 
                  : moment(endDate).format("YYYY-MM-DD"),
              });
            }}
            containerPosition="auto"
            notReadOnly
            format="DD MMMM YYYY"
            startDate={filterCommissionSummary?.start_date}
            endDate={filterCommissionSummary?.end_date}
          />
        </div>
      </div>
      <Row className="mb-4">
        <Col md="4">
          <Card rounded className="col-md mr-4" style={{ height: '98%' }}>
            <CardHeader isDashboard title={`${t("menuItem.report")} ${t(t("field.commission"))}`} />
            <CardBody>
              <div style={{ padding: '24px 0' }}>
                <ProductionCard
                  color="hijau"
                  title={`Total ${t("field.reservation")}`}
                  total={totalReservation}
                  icon="done"
                  pending={pending}
                />
              </div>
              <div style={{ padding: '30px 0' }}>
                <ProductionCard
                  color="hijau"
                  title="Total Pax"
                  total={totalPax}
                  icon="done"
                  pending={pending}
                />
              </div>
              <div style={{ padding: '24px 0' }}>
                <ProductionCard
                  color="hijau"
                  title={`Total ${t("field.commission")}`}
                  total={totalCommission !== "Rp undefined" ? totalCommission : "Rp 0"}
                  icon="done"
                  pending={pending}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md="8">
          <Card style={{ marginBottom: 10 }} className="col-md" rounded>
            <CardHeader
              isDashboard
              title={`${t("field.commission")} Individual`}
            />
            <Row className="py-3 px-4">
              <Col md="6">
                <ProductionCard
                  color="abu"
                  title="Pax Individual"
                  total={commissionPaxIndividual}
                  icon="paid"
                  pending={pending}
                />
              </Col>
              <Col md="6">
                <ProductionCard
                  color="abu"
                  title={`Sub ${t("field.commission")} Individual`}
                  total={subCommissionIndividual !== "Rp undefined" ? subCommissionIndividual : "Rp 0"}
                  icon="done"
                  pending={pending}
                />
              </Col>
            </Row>
          </Card>
          <Card style={{ marginBottom: 10 }} className="col-md" rounded>
            <CardHeader
              isDashboard
              title={`${t("field.commission")} Chart/Hajj/Umrah`}
            />
            <Row className="py-3 px-4">
              <Col md="6">
                <ProductionCard
                  color="abu"
                  title="Pax Chart/Hajj/Umrah"
                  total={commissionPaxGroup}
                  icon="paid"
                  pending={pending}
                />
              </Col>
              <Col md="6">
                <ProductionCard
                  color="abu"
                  title={`Sub ${t("field.commission")} Chart/Hajj/Umrah`}
                  total={subCommissionGroup !== "Rp undefined" ? subCommissionGroup : "Rp 0"}
                  icon="done"
                  pending={pending}
                />
              </Col>
            </Row>
          </Card>
          <Card style={{ marginBottom: 10 }} className="col-md" rounded>
            <CardHeader
              isDashboard
              title={`${t("field.commission")} ${t("masterData.others")}`}
            />
            <Row className="py-3 px-4">
              <Col md="6">
                <ProductionCard
                  color="abu"
                  title={`Pax ${t("field.group")} ${t("masterData.others")}`}
                  total={commissionPaxOther}
                  icon="done"
                  pending={pending}
                />
              </Col>
              <Col md="6">
                <ProductionCard
                  color="abu"
                  title={`Sub ${t("field.commission")} ${t("field.group")} ${t("masterData.others")}`}
                  total={subCommissionOther !== "Rp undefined" ? subCommissionOther : "Rp 0"}
                  icon="done"
                  pending={pending}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <hr />
      <div className="d-flex mb-4 justify-content-end">
        <Button
          title="Filter"
          size="sm"
          rounded={true}
          variant="button-light btn-lg"
          onClick={handleFilterModal}
        />
        <div className="nav-separator mr-3 ml-3" />
        <Button
          title={(
            <>
              {loadingDownload && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
              )}
              {t("report.downloadReport")}
            </>
          )}
          leftIcon={!loadingDownload ? "download" : null}
          rounded={true}
          variant="button-light btn-lg"
          onClick={handleDownloadReport}
        />
      </div>
      <Table
        columns={column.filter((item) => item.show !== false)}
        data={dataReport ?? []}
        pagination={{ page, totalData, maxPage, toggle: setPage }}
      />
      
      <ModalFilter
        user={user}
        haveAirport={haveAirport}
        show={showModalFilter}
        toggle={() => setShowModalFilter(false)}
        filter={paramFilter}
        airport={airports}
        product={products}
        corporate={corporates}
        toggleFilter={() => {
          setParamFilter({});
          handleRefresh();
        }}
        onApplyFilter={onApplyFilter}
      />
    </>
  )
}

const mapStateToProps = ({ report: { pending, data } }) => {
  return { pending, data };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(ReportCommission)
);