import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import * as Yup from "yup";
import { withTrans } from "../../../i18n/withTrans";
import { toastError } from "../../../components/commons/toast";
import Select2 from "../../../components/forms/Select2";
import Input from "../../../components/forms/Input";
import ConfirmationModal from "../../../components/modal/ConfirmModal";
import ButtonModal from "../../../components/modal/ButtonModal";

//Service
import AirportService from "../../../store/actions/master_data/airport";
import CorporateService from "../../../store/actions/master_data/corporate";

const FormUserModal = ({ data, t, action, show, toggle, confirm }) => {
  const formikRef = useRef();

  const dispatch = useDispatch();
  const [dataAirport, setDataAirport] = useState([]);
  const [dataCorp, setDataCorp] = useState([]);
  const title = (data ? t("commons.edit") : t("commons.add")) + " " + t("field.user_back_office");
  const initial = data
    ? {
      id: data?.id,
      name: data?.name,
      email: data?.email,
      role_code: data?.role_code,
      airport_id: data?.airport_id,
      corporate_id: data?.corporate_id,
    }
    : {
      id: "",
      name: "",
      email: "",
      status: "",
      role_code: "",
      role_name: "",
      airport_id: "",
      airport_name: "",
      corporate_id: "",
      corporate_name: "",
    };

  const handleAirport = () => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      setDataAirport(res?.data);
    });
  };
  const handleCorporate = () => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
      };
      dispatch(CorporateService.get(param, resolve));
    }).then((res) => {
      setDataCorp(res?.data);
    });
  };

  useEffect(() => {
    handleAirport();
    handleCorporate();
  }, []);

  const airport_list = dataAirport.map((airport) => ({
    value: airport?.id,
    label:  `${airport?.code}-${airport?.city}-${airport?.name}`,
  }));

  const corp_list = dataCorp.map((corp) => ({
    value: corp?.id,
    label: corp?.name,
  }));

  const role_list = [
    { value: "admin_bandara", label: "Admin Bandara" },
    { value: "contact_center", label: "Contact Center" },
    { value: "corporate", label: "Corporate" },
    { value: "finance", label: "Finance Pusat" },
    { value: "finance_bandara", label: "Finance Bandara" },
    { value: "sales", label: "Sales Pusat" },
    { value: "sales_bandara", label: "Sales Bandara" },
    { value: "supervisor", label: "Supervisor" },
  ];

  const validation = Yup.object().shape({
    name:
      Yup.string()
        .required(`${t("field.name")} ${t("commons.required")}`)
        .nullable(),
    email:
      Yup.string()
        .email(t("commons.invalidEmail"))
        .required(`Email ${t("commons.required")}`)
        .nullable(),
    role_code:
      Yup.string()
        .required(`Role ${t("commons.required")}`)
        .nullable(),
  });

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}{title}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={initial}
          validationSchema={validation}
          onSubmit={ async (values) => {
            if (
             (values?.role_code === "admin_bandara" ||
              values?.role_code === "supervisor" ||
              values?.role_code === "sales_bandara" ||
              values?.role_code === "finance_bandara") &&
              values?.airport_id === ""
            ) {
              toastError(`${t('commons.empty_data_message')} ${t("field.airport")}`);
            } else if (values?.role_code === "corporate" && values?.corporate_id === "") {
              toastError(`${t('commons.empty_data_message')} ${t("field.corporate")}`);
            } else {
              action(values);
              await delay(3000);
            }
          }}
        >
          {(props) => (
            <Form>
              <Container>
                <ModalBody>
                  <div className="px-2">
                    <Input
                      title={t("field.name")}
                      label="name"
                      type="text"
                      errors={props?.errors}
                      touched={props?.touched}
                      {...props?.getFieldProps("name")}
                      value={props.values.name}
                    />

                    <Input
                      title="Email"
                      label="email"
                      type="email"
                      errors={props?.errors}
                      touched={props?.touched}
                      {...props?.getFieldProps("email")}
                      value={props.values.email}
                    />

                    <Select2
                      title="Role"
                      name="role_code"
                      options={role_list}
                      errors={props?.errors}
                      touched={props?.touched}
                      value={props?.values?.role_code}
                      onChange={(name, value) => {
                        props?.setFieldValue("role_code", value);
                      }}
                      onBlur={props?.setFieldTouched}
                      placeholder="Pilih Role"
                    />

                    {(props?.values?.role_code === "admin_bandara" ||
                      props?.values?.role_code === "supervisor" ||
                      props?.values?.role_code === "sales_bandara" ||
                      props?.values?.role_code === "finance_bandara") && 
                    (
                      <Select2
                        title={t("field.airport")}
                        name="airport_id"
                        options={airport_list}
                        errors={props?.errors}
                        touched={props?.touched}
                        value={props?.values?.airport_id}
                        onChange={(name, value) => {
                          props?.setFieldValue("airport_id", value);
                        }}
                        onBlur={props?.setFieldTouched}
                      />
                    )}

                    {props?.values?.role_code === "corporate" && (
                      <Select2
                        title={t("field.corporate")}
                        name="corporate_id"
                        options={corp_list}
                        errors={props?.errors}
                        touched={props?.touched}
                        value={props?.values?.corporate_id}
                        onChange={(name, value) => {
                          props?.setFieldValue("corporate_id", value);
                        }}
                        onBlur={props?.setFieldTouched}
                      />
                    )}
                  </div>
                </ModalBody>
              </Container>
              <ModalFooter>
                <ButtonModal
                  toggle={toggle}
                  confirm={confirm}
                  confirmTitle={t("commons.save")}
                  disabledConfirm={props.isSubmitting}
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
      <ConfirmationModal
        cancelTitle={t("commons.no")}
        confirmTitle={t("commons.yes")}
        icon="info"
      />
    </>
  );
};

export default withTrans(FormUserModal);
