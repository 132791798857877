import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { Divider } from "@mui/material";

import Input from "../../../../components/forms/Input";
import DynamicForm from "../../../../components/forms/DynamicForm";
import Button from "../../../../components/buttons/Button";
import InputTags from "../../../../components/forms/InputTags";
import CardFlush from "../../../../components/cards/cardFlush";
import { toastError } from "../../../../components/commons/toast";
import { withTrans } from "../../../../i18n/withTrans";
import { getUser } from "../../../../utils/User";
import { CarList } from "../../../../utils/Constants";

const Zone = ({ t, formik, idx, detailRegion }) => {
  const currentUser = getUser();
  const [carsList, setCarsList] = useState(CarList);

  const index = idx;

  const arrayRegion = formik?.values?.transports[index]?.region_list?.length
    ? formik?.values?.transports[index]?.region_list?.map((item) => {
        return { value: item, name: item };
      })
    : [];

  const handleDeleteList = (item, i, formik) => {
    if (
      formik?.values?.transports[index].prices &&
      item &&
      (i !== undefined || i !== null)
    ) {
      if (formik?.values?.transports[index].prices === 1) {
        formik.values.transports[index].prices = [
          { car_name: "", price: "", capacity: "" }
        ];
        formik?.setFieldValue("transports", formik.values.transports);
      } else {
        let transports = formik?.values?.transports;
        let prices = formik?.values?.transports[index].prices;
        prices.splice(i, 1);
        transports[index].prices = prices;
        formik?.setFieldValue("transports", transports);
      }
    }
  };

  const handleAddCar = (formik) => {
    let transports = formik.values.transports;
    let prices = formik.values.transports[index].prices;
    let last_element = prices.length > 0 ? prices[prices.length - 1] : null;
    if (last_element) {
      if (last_element.car_name && last_element.price && last_element.capacity) {
        prices = [...prices, { car_name: "", price: "", capacity: "" }];
      } else {
        toastError(t("commons.empty_data_message"));
      }
    } else {
      prices = [...prices, { car_name: "", price: "", capacity: ""  }];
    }

    transports[index].prices = prices;
    formik?.setFieldValue(`transports`, transports);
  };

  const handleSelect = (name, value) => {
    let list = formik.values.transports[index].prices;
    let i = list.findIndex((x) => x.car_name === value);
    if (i === -1) {
      formik.setFieldValue(name, value);
    } else {
      toastError("Data sudah ditambahkan");
    }
  };

  return (
    <>
      <Input
        name={`transports[${index}].zone_name`}
        errors={formik.errors}
        touched={formik.touched}
        {...formik.getFieldProps(`transports[${index}].zone_name`)}
        title={t("field.zone")}
        type="text"
        disabled={currentUser?.user.role_code !== "admin_pusat"}
      />
      <Divider orientation="horizontal" />
      {currentUser?.user?.role_code === "admin_pusat" ? (
        <InputTags 
          name={`transports[${index}].region_list`}
          title={t("field.region")} 
          formik={formik}
          index={index}
          placeholder={t("commons.placeholderRegion")} 
          {...formik.getFieldProps(`transports[${index}].region_list`)}
        />
      ) : (
        detailRegion = [
          {
            label: t("field.region"),
          },
        ].map((detailRegion, index) => (
          <CardFlush
            multipleList={arrayRegion}
            key={index}
            label={detailRegion.label}
          />
        ))
      )}
      <Divider orientation="horizontal" />
      <Row style={{ marginTop: 20 }}>
        <Col md={5} style={{ paddingRight: "0px" }}>
          <div className="form-group" style={{ marginBottom: 0 }}>
            <label className="form-label">{t("field.carType")}</label>
          </div>
        </Col>
        <Col md={3} style={{ paddingLeft: "0px" }}>
          <div className="form-group" style={{ marginBottom: 0 }}>
            <label className="form-label">{t("field.capacity")}</label>
          </div>
        </Col>
        <Col md={4} style={{ paddingLeft: "0px" }}>
          <div className="form-group" style={{ marginBottom: 0 }}>
            <label className="form-label">{t("field.price")}</label>
          </div>
        </Col>
      </Row>
      {formik.values.transports[index]?.prices?.map((item, i) => {
        return (
          <>
            <DynamicForm
              nameLeft={`transports[${index}].prices[${i}].car_name`}
              name={`transports[${index}].prices[${i}].price`}
              nameCenter={`transports[${index}].prices[${i}].capacity`}
              placeholderLeft={t("field.carType")}
              placeholder={t("field.price")}
              placeholderCenter={t("field.capacity")}
              optionLeft={carsList}
              valueLeft={
                formik.values.transports[index].prices[i].car_name ?? ""
              }
              type="number"
              typeCenter="number"
              currency
              formik={formik}
              handleOptionLeft={(name, value) => handleSelect(name, value)}
              size={{ title: 5, value: 5 }}
              onChangeRight={(e) => {
                const val = Number(e?.target?.value.replace(/[^0-9\.-]+/g, ""));
                formik?.setFieldValue(`transports[${index}].prices[${i}].price`, val);
              }}
              toggle={
                currentUser?.user.role_code === "admin_pusat"
                  ? () => handleDeleteList(item, i, formik)
                  : false
              }
              disabledLeft={currentUser?.user.role_code !== "admin_pusat"}
              disabledCenter={currentUser?.user.role_code !== "admin_pusat"}
              disabledRight={currentUser?.user.role_code !== "admin_pusat"}
            />
          </>
        );
      })}
      {currentUser?.user.role_code === "admin_pusat" && (
        <div style={{ textAlign: "center" }}>
          <Button
            variant="button secondary"
            rounded
            title={t("commons.add") + " " + t("commons.car")}
            onClick={() => handleAddCar(formik)}
            type="Button"
            style={{ padding: "8px 20px" }}
          />
        </div>
      )}
    </>
  );
};

export default withTrans(Zone);
