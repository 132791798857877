import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Carousel,
  CarouselItem,
  CarouselControl,
} from "reactstrap";
import ButtonModal from "../../../components/modal/ButtonModal";
import { withTrans } from "../../../i18n/withTrans";

function ShowEvidenceModal({ title, t, show, toggle, images }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const items = images?.length ? images?.map((item, i) => {
    return {
      src: process.env.REACT_APP_API_URL + item?.evidence?.substr(1, item?.evidence?.length),
      altText: title,
      key: i
    }
  }) : [];

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img
          src={item.src}
          alt={item.altText}
          className="img img-fluid"
          width="100%"
          style={{ objectFit: "cover", width: "100%", padding: "0 15px" }}
        />
      </CarouselItem>
    );
  });

  return (
    <Modal
      isOpen={show}
      modalTransition={{ timeout: 200 }}
      backdropTransition={{ timeout: 1300 }}
    >
      <ModalHeader className="text-extra-bold pt-5">
        {" "}{title}{" "}
        <span className="close clickable" onClick={() => toggle()}>
            &times;
        </span>
      </ModalHeader>
      <ModalBody style={{ margin: "0px 20px" }}>
        {images?.length ? (
          <Carousel
            dark={true}
            activeIndex={activeIndex}
            next={next}
            previous={previous}
          >
            {slides}
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={next}
            />
          </Carousel>
        ) : (
          <p>Dokumen tidak ditemukan</p>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonModal
          toggle={() => toggle()}
          hideConfirm
          cancelTitle={t("commons.close")}
        />
      </ModalFooter>
    </Modal>
  );
}

export default withTrans(ShowEvidenceModal);
