import React from "react";
import { useState, useEffect } from "react";
import { withTrans } from "../../../i18n/withTrans";
import { connect, useDispatch } from "react-redux";

import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import DetailButton from "../../../components/table/detailButton";
import MenuItem from "@mui/material/MenuItem";
import Loader from "../../../components/commons/Loader";
import SearchButton from "../../../components/buttons/SearchButton";
import Table from "../../../components/table";
import LabelPlus from "../../../components/table/labelPlus";
import TableStatus from "../../../components/table/status";
import { history } from "../../../utils/History";
import Button from "../../../components/buttons/Button";
import moment from "moment";

// Service
import OperationalService from "../../../store/actions/schedule/operational";
import AirportService from "../../../store/actions/master_data/airport";
import ProductService from "../../../store/actions/master_data/product";
import ModalFilter from "./modalFilter";
import { getUser } from "../../../utils/User";

const Operational = ({ t, pending }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();

  const [dataOperational, setDataOperational] = useState([]);
  const [dataProduct, setDataProduct] = useState([]);
  const [dataAirport, setDataAirport] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalData, setTotalData] = useState(null);
  const [totalPax, setTotalPax] = useState(null);
  const [totalActualPax, setTotalActualPax] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [originFilter, setOriginFilter] = useState({});
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [paramFilter, setParamFilter] = useState({
    airport_id:
      currentUser?.user.role_code === "admin_bandara" ||
      currentUser?.user.role_code === "supervisor" 
        ? currentUser?.user.airport_id
        : "",
    product_id: "",
    status: "",
    booking_type: "",
    end_date: moment().format("YYYY-MM-DD"),
    start_date: moment().format("YYYY-MM-DD"),
  });

  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };

  const handleFilter = (value) => {
    setFilter(value);
  };

  const handleFilterModal = () => {
    setShowModalFilter(true);
  };

  const handleAirport = () => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      let list_airports = [];
      if (res) {
        list_airports = res?.data?.map((airport) => ({
          value: airport?.id,
          label: `${airport?.code}-${airport?.city}-${airport?.name} ${airport?.uniform === 'Indonesia' ? "" : "-" + airport?.uniform}`,
        }));
      }
      setDataAirport(list_airports);
    });
  };

  const handleProduct = () => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
      };
      dispatch(ProductService.get(param, resolve));
    }).then((res) => {
      let list_products = [];
      if (res) {
        list_products = res?.data
          ?.filter((item) => 
            item?.name_eng !== 'Meet and Greet' && item?.name_eng !== 'Check In and Baggage')
          ?.map((product) => ({
            value: product?.id,
            label: `${product?.name_eng}`,
          }));
      }
      setDataProduct(list_products);
    });
  };

  useEffect(() => {
    handleAirport();
    handleProduct();
    setOriginFilter({
      airport_id:
        currentUser?.user.role_code === "admin_bandara" ||
        currentUser?.user.role_code === "supervisor"
          ? currentUser?.user.airport_id
          : "",
      product_id: "",
      status: "",
      booking_type: "",
      end_date: moment().format("YYYY-MM-DD"),
      start_date: moment().format("YYYY-MM-DD"),
    });
  }, []);

  const onApplyFilter = (param) => {
    setParamFilter({
      airport_id: param.airport_id,
      product_id: param.product_id,
      end_date: param.end_date ? moment(param.end_date).format("YYYY-MM-DD") : "",
      start_date: param.start_date ? moment(param.start_date).format("YYYY-MM-DD") : "",
      status: param.status,
      booking_type: param.booking_type,
    });
  };

  const handleDownloadReport = () => {
    let param = {
      ...paramFilter,
      page: page === 0 ? 1 : page ?? 1,
      length: 10,
      search_text: searchText,
    }
    new Promise((resolve) => {
      setLoadingDownload(true);
      dispatch(OperationalService.download(param, resolve));
    }).then((res) => {
      setLoadingDownload(false);
    });
  };
  
  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = {
        ...paramFilter,
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        id: null,
        search_text: searchText,
        // status: filter,
      };
      dispatch(OperationalService.get(param, resolve));
    }).then((res) => {
      setDataOperational(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  const handleFindTotalPax = () => {
    new Promise((resolve) => {
      let param = {
        ...paramFilter,
        search_text: searchText,
      };
      dispatch(OperationalService.findTotalPax(param, resolve));
    }).then((res) => {
      if (res) {
        setTotalPax(res?.data?.total_pax);
        setTotalActualPax(res?.data?.total_actual_pax);
      }
    });
  }

  useEffect(() => {
    handleRefresh();
  }, [page, searchText, paramFilter]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
    setTotalPax(null);
    setTotalActualPax(null);
    handleFindTotalPax();
  }, [searchText, paramFilter]);

  const columns = [
    {
      Header: "#ID BOOKING",
      accessor: "unique_transaction_id",
    },
    {
      Header: t("report.serviceDate").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return <>{original?.date ? moment(original?.date).format("DD/MM/YYYY") : '-'}</>;
      },
    },
    {
      Header: t("report.serviceTime").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return <>{original?.time ? original?.time : '-'}</>;
      },
    },
    {
      Header: t("field.product").toUpperCase(),
      accessor: "product_name_eng",
    },
    {
      Header: t("field.additionalService").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const additionalService = original?.list_order_extras?.map((item, index) => ({
          id: index,
          name: item?.type,
        }));
        return original?.list_order_extras?.length 
          ? <LabelPlus data={additionalService} /> 
          : "-";
      },
    },
    {
      Header: t("field.airport").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        if(original?.type === 1) {
          let { airport_code_to } = original;
          return airport_code_to !== null
            ? airport_code_to
            : '-';
        } else if(original?.type === 2) {
          let { airport_code_from } = original;
          return airport_code_from !== null
            ? airport_code_from
            : '-';
        } else if(original?.type === 3) {
          let { airport_code_transfer } = original;
          return airport_code_transfer !== null
            ? airport_code_transfer
            : '-';
        }
      },
    },
    {
      Header: t("field.flightNumber").toUpperCase(),
      Cell: ({ row }) => {
        const { original } = row;
        return original?.airplane_code && original?.airplane_number
          ? original?.airplane_code + original?.airplane_number
          : "-";
      },
    },
    {
      Header: t("field.route").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        if(original?.type === 1) {
          let { airport_code_to, airport_code_from } = original;
          return airport_code_from !== null || airport_code_to !== null
            ? `${airport_code_to}-${airport_code_from}`
            : '-';
        } else if(original?.type === 2) {
          let { airport_code_to, airport_code_from } = original;
          return airport_code_to !== null || airport_code_from !== null
            ? `${airport_code_from}-${airport_code_to}`
            : '-';
        } else if(original?.type === 3) {
          let { airport_code_transfer, airport_code_to } = original;
          return airport_code_to !== null || airport_code_transfer !== null
            ? `${airport_code_transfer}-${airport_code_to}`
            : '-';
        }
      },
    },
    {
      Header: t("field.assistant").toUpperCase(),
      Cell: ({ row }) => {
        const { original } = row;
        let { assistant_name, list_assistants } = original;

        let name = list_assistants ? list_assistants?.map(item => item?.assistant_name).join(", ") : assistant_name === null ? "-" : assistant_name;

        return name && name.length > 20 ? name.substring(0, 20) + '...' : name;
      },
    },
    {
      Header: "PAX",
      accessor: "total_pax",
    },
    {
      Header: "ACTUAL PAX",
      accessor: "actual_pax",
    },
    {
      Header: "STATUS",
      Cell: ({ row }) => {
        const { original } = row;
        let { status } = original;
        return (
          <TableStatus
            status={
              status === 0
                ? t("status.new")
                : status === 1
                  ? t("status.assigned")
                  : status === 2
                    ? t("status.onProgress")
                    : t("status.done")
            }
            color={
              status === 0
                ? "danger"
                : status === 5
                  ? "primary"
                  : "warning"
            }
          />
        );
      },
      minWidth: 125,
    },
    {
      Header: "",
      accessor: "id",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <DetailButton>
            <MenuItem
              onClick={() =>
                history.push(`/schedule/operational/detail/${original?.id}`)
              }
            >
              <span style={{ fontSize: 14 }}>{t("commons.detail")}</span>
            </MenuItem>
          </DetailButton>
        );
      },
    },
  ];

  return (
    <>
      {pending && <Loader sideContent loading={pending} />}
      <HeaderMenu title={t("field.operational")}>
        <SearchButton
          placeholder={t("commons.enterKeyword")}
          toggle={(value) => handleSearch(value)}
        />
        <div className="nav-separator mr-3 ml-3" />
        <Button
          title="Filter"
          size="sm"
          rounded={true}
          variant="button-light btn-lg"
          onClick={handleFilterModal}
        />
        <div className="nav-separator mr-3 ml-3" />
        <Button
          title={(
            <>
              {loadingDownload && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
              )}
              Download
            </>
          )}
          leftIcon={!loadingDownload ? "download" : null}
          rounded={true}
          variant="button-light btn-lg"
          onClick={handleDownloadReport}
        />
        {/* <SelectFilter
          options={[
            { value: "", label: t("schedule.all_status") },
            { value: "1", label: t("status.upcoming") },
            { value: "2", label: t("status.onProgress") },
            { value: "5", label: t("status.done") },
          ]}
          placeholder="Status"
          onChange={(value) => handleFilter(value.value)}
        /> */}
      </HeaderMenu>
      <div className="content-container-side">
        <div className="overflow">
          <Table
            footer
            columns={columns}
            data={dataOperational ?? []}
            pagination={{ 
              page, 
              totalData, 
              maxPage, 
              toggle: setPage, 
              subInfo: 'Total Pax', 
              subSubInfo: 'Total Actual Pax',
              subInfoTotal: totalPax, 
              subSubInfoTotal: totalActualPax 
            }}
          />
        </div>
      </div>
      <ModalFilter
        show={showModalFilter}
        toggle={() => setShowModalFilter(false)}
        filter={paramFilter}
        airport={dataAirport}
        product={dataProduct}
        toggleFilter={() => {
          setParamFilter(originFilter);
          handleRefresh();
        }}
        onApplyFilter={onApplyFilter}
      />
    </>
  );
};

const mapStateToProps = ({ operational: { pending, data } }) => {
  return { pending, data };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(Operational)
);
