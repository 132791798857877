import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ButtonModal from "./ButtonModal";
import parse from "html-react-parser";
import { withTrans } from "../../i18n/withTrans";

const ConfirmationModal = ({
  t,
  show,
  toggle,
  title,
  message,
  isDelete,
  children,
  icon,
  confirmTitle,
  cancelTitle,
  variantIcon,
  confirm,
  disabledConfirm,
  messageData,
  param,
  noIcon,
  reason
}) => {
  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        toggle={toggle}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {title ?? t("commons.confirm")}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              marginLeft: 20,
              marginRight: 20,
              display: "flex",
              lineHeight: "2rem",
            }}
          >
            {icon && (
              <i
                className={`material-icons-outlined icon-modal ${variantIcon}`}
                style={{ marginRight: 10 }}
              >
                {icon}
              </i>
            )}
            {message && (
              <p style={{ fontSize: "16px", margin: "0 20px" }}>
                {parse(message) ?? t("commons.areYouSureDelete")}
                <strong> {messageData} ?</strong>
              </p>
            )}
            {children}
            {param && (
              <div>
                {t ? t("commons.deleteConfirmation") : ""}
                <strong>{param}</strong> ?
              </div>
            )}
          </div>
          {reason && (
            <>
              <hr style={{ margin: "30px" }}/>
              <div
                style={{
                  padding: "0 30px",
                  display: "flex",
                }}
              >
                {reason}
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <ButtonModal
            toggle={toggle}
            deleted={isDelete}
            confirm={confirm}
            confirmTitle={confirmTitle}
            cancelTitle={cancelTitle}
            disabledConfirm={disabledConfirm}
            noIcon={noIcon}
          />
        </ModalFooter>
      </Modal>
    </>
  );
};

export default withTrans(ConfirmationModal);
