import React, { useRef } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";

import { Formik, Form } from "formik";
import { withTrans } from "../../i18n/withTrans";
import * as Yup from "yup";
import Select2 from "../../components/forms/Select2";
import ButtonModal from "../../components/modal/ButtonModal";
import Input from "../../components/forms/Input";
import DynamicButton from "../../components/forms/DynamicButton";

const ModalData = ({ t, title, show, toggle, action, data, pending }) => {
    const formikRef = useRef();

    const validation = Yup.object().shape({
        year: Yup.string().required(t("commons.required")).nullable(),
        type: Yup.string().required(t("commons.required")).nullable(),
        price: Yup.number().required(t("commons.required")).nullable(),
    });

    const handleClose = () => {
        toggle();
    };

    let yearOptions = [];
    for (let i = new Date().getFullYear(); i <= new Date().getFullYear() + 4; i++) {
        yearOptions = [...yearOptions, { label: i, value: i }];
    }

    const rawPrice = (val) => {
        if (val) {
            return Number(val.toString().replace(/[^0-9\.-]+/g, ""));
        }
        return val
    }

    return (
        <Modal
            isOpen={show}
            modalTransition={{ timeout: 700 }}
            backdropTransition={{ timeout: 1300 }}
        >
            <ModalHeader className="text-extra-bold pt-5">
                {" "}
                {title}{" "}
                <span className="close clickable" onClick={() => handleClose()}>
                    &times;
                </span>
            </ModalHeader>
            <ModalBody style={{ margin: "0px 20px" }}>
                <Formik
                    enableReinitialize
                    innerRef={formikRef}
                    initialValues={{
                        id: data?.id ?? null,
                        year: data?.year ?? "",
                        type: data?.type ?? "",
                        price: data?.price ?? "",
                    }}
                    validationSchema={validation}
                    onSubmit={(values) => {
                        let val = {
                            id: values?.id ?? null,
                            year: values.year,
                            type: values.type,
                            price: rawPrice(values.price)
                        };
                        action(val);
                    }}
                >
                    {(props) => (
                        <Form style={{ marginTop: 30 }}>
                            <Select2
                                title={t('field.year')}
                                name="year"
                                options={yearOptions}
                                errors={props.errors}
                                touched={props.touched}
                                value={props.values?.year}
                                placeholder="Pilih tahun"
                                onChange={(name, value) => {
                                    props.setFieldValue("year", value);
                                }}
                                onBlur={props.setFieldTouched}
                            />
                            <Select2
                                title={`${t('commons.type')}`}
                                name="type"
                                options={[
                                    {
                                        value: 'Individual',
                                        label: 'Individual',
                                    },
                                    {
                                        value: 'Group',
                                        label: 'Group (Charter, Hajj, Umrah)',
                                    },
                                    {
                                        value: 'Other',
                                        label: `Group ${t('masterData.others')}`,
                                    },
                                ]}
                                errors={props.errors}
                                touched={props.touched}
                                value={props.values?.type}
                                placeholder={t('commons.type')}
                                onChange={(name, value) => {
                                    props.setFieldValue("type", value);
                                    props.setFieldValue("price", "");
                                }}
                                onBlur={props.setFieldTouched}
                            />
                            {
                                props.values.type !== null && props.values.type !== undefined && props.values.type !== "" && (
                                    <React.Fragment>
                                        <DynamicButton titleLeft={props.values.type === "Group" ? t("masterData.groupInfo") : props.values.type === "Other" ? t("masterData.otherInfo") : t("masterData.individualInfo")} iconLeft="info" />
                                    </React.Fragment>
                                )
                            }
                            {
                                props.values.type !== "" && (
                                    <Input
                                        currency
                                        name="price"
                                        errors={props.errors}
                                        touched={props.touched}
                                        value={props.values.price ?? 0}
                                        {...props.getFieldProps("price")}
                                        title={t("field.price")}
                                        placeholder={t("field.price")}
                                        type="text"
                                        toggleCurrency={props}
                                    />
                                )
                            }
                            <div style={{ marginTop: 50, marginBottom: 30 }}>
                                <ButtonModal
                                    toggle={toggle}
                                    confirmTitle={t("commons.save")}
                                    disabledConfirm={pending}
                                />
                            </div>

                        </Form>
                    )}
                </Formik>
            </ModalBody>

        </Modal>
    )
}

export default withTrans(ModalData);

