import React, { useRef } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import Input from "../../../components/forms/Input";
import ButtonModal from "../../../components/modal/ButtonModal";
import { withTrans } from "../../../i18n/withTrans";

const MasterDataModal = ({
  t,
  show,
  toggle,
  action,
  data,
  confirm,
  pending,
}) => {
  const formikRef = useRef();

  const title =
    (data ? t("commons.edit") : t("commons.add")) + " " + t("field.airline");
  const initial = data
    ? {
        id: data?.id,
        name: data?.name,
        code: data?.code,
      }
    : {
        name: "",
        code: "",
      };

  const validation = Yup.object().shape({
    name: Yup.string().required(`${t("field.airlineName")} ${t("commons.required")}`).nullable(),
    code: Yup.string().required(`${t("field.airlineCode")} ${t("commons.required")}`).nullable(),
  });

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {title}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={initial}
          validationSchema={validation}
          onSubmit={async (values, { setStatus }) => {
            setStatus();
            action(values);
            await delay(3000);
          }}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Container>
                  <Input
                    name="code"
                    errors={props.errors}
                    touched={props.touched}
                    {...props.getFieldProps("code")}
                    title={t("field.airlineCode")}
                    type="text"
                  />
                  <Input
                    name="name"
                    errors={props.errors}
                    touched={props.touched}
                    {...props.getFieldProps("name")}
                    title={t("field.airlineName")}
                    type="text"
                  />
                </Container>
              </ModalBody>
              <ModalFooter>
                <ButtonModal
                  toggle={toggle}
                  confirm={confirm}
                  confirmTitle={t("commons.save")}
                  disabledConfirm={props.isSubmitting}
                  icon
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default withTrans(MasterDataModal);
